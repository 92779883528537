import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import styled from 'react-emotion'
import { Link } from 'react-router-dom'
import { max, Board, Break, Relative, Space } from 'spaced-components'
import Layout from '../../Layout'
// import LinesPattern from "../../components/LinesPattern"
import TileTitle from '../../components/TileTitle'
import TextLink from '../../components/TextLink'
import backgroundImage from './backgroundImage.jpg'

const Wrapper = styled('div')`
  height: 100vh;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: 22% center;

  ${max('laptop')} {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
      url(${backgroundImage});
  }

  ${max('tablet')} {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)),
      url(${backgroundImage});
  }
`

export default () => (
  <Fragment>
    <Helmet>
      <title>Page Not Found</title>
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <Layout.Shell>
      <Relative>
        {/* <LinesPattern color="rgba(255, 255, 255, .8)" /> */}
        <Wrapper>
          <Board height="100%" middle center={{ mobile: true, tablet: false }}>
            <Board.Item offset={{ tablet: 1 / 2 }}>
              <Space xl />
              <TileTitle color="rgba(0, 0, 0, .5)" active>
                <span>
                  <span>Looks like</span>
                  <Break />
                  <span>we hit a wall.</span>
                </span>
              </TileTitle>
              <Space md />
              <Link to="/">
                <TextLink is="span" sm>
                  Go back to the homepage and <Break max="tablet" />
                  let's try this again...
                </TextLink>
              </Link>
            </Board.Item>
          </Board>
        </Wrapper>
      </Relative>
    </Layout.Shell>
  </Fragment>
)
