import styled from '../styles/styled'

const VideoWrapper = styled('div')(({ ratio = 2 / 3 }) => ({
  position: 'relative',
  backgroundColor: '#000',
  overflow: 'hidden',
  '::after': {
    content: '""',
    display: 'block',
    paddingTop: `${ratio * 100}%`,
  },
  '& video': {
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    objectFit: 'cover',
  },
}))

export default VideoWrapper
