import React, { Component } from 'react'

export const BusinessTypeContext = React.createContext({})

export const BusinessTypeConsumer = BusinessTypeContext.Consumer

export class BusinessTypeProvider extends Component {
  setSelected = selected => this.setState({ selected })

  state = {
    selected: 0,
    setSelected: this.setSelected,
  }

  render() {
    return (
      <BusinessTypeContext.Provider value={this.state}>
        {this.props.children}
      </BusinessTypeContext.Provider>
    )
  }
}
