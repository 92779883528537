import React from "react"

export default ({
  width = 49,
  fill = "none",
  stroke = "currentColor",
  strokeWidth = 2,
  strokeLinecap = "round"
}) => (
  <svg
    viewBox="0 0 49.2 18.6"
    width={width}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap={strokeLinecap}
  >
    <polyline points="39.5,17.6 47.8,9.3 39.5,1" />
    <line x1="47.3" y1="9.3" x2="1" y2="9.3" />
  </svg>
)
