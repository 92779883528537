import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Component from '@reach/component-component'
import { keyframes } from 'emotion'
import styled from '../../styles/styled'
import {
  Inset,
  Break,
  Relative,
  Stack,
  Grid,
  Space,
  On,
  Columns,
} from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'

import CaseStudyLayout, {
  boxShadow,
  dropShadow,
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'

import bubble01 from './assets/bubble-01.png'
import bubble02 from './assets/bubble-02.png'
import buzzfeed from './assets/buzzfeed.png'
import cinemagraph01 from './assets/cinemagraph1.jpg'
import cinemagraph02 from './assets/cinemagraph2.jpg'
import imgur from './assets/imgur.png'
import ipad01 from './assets/ipad-01.png'
import ipad02 from './assets/ipad-02.png'
import memeFloating from './assets/meme-floating.png'
import nerdist from './assets/nerdist.png'
import poster from './assets/poster.png'
import preacherStreet from './assets/preacher-street.png'
import sketch01 from './assets/sketch-01.png'
import sketch02 from './assets/sketch-02.png'
import social01 from './assets/social-01.png'
import social02 from './assets/social-02.png'
import social03 from './assets/social-03.png'
import social04 from './assets/social-04.png'
import spotify01 from './assets/spotify-01.png'
import spotify02 from './assets/spotify-02.png'
import stagecoach from './assets/stagecoach.png'
import webpageAnimation from './assets/webpage-animation.mp4'

const currentProject = getProject('amc-preacher')

const buzz = keyframes`
  1% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  2% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  3% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  4% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  5% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  6% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  7% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  9% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  10% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  11% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  12% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  13% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  14% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
  15% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  16% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  17% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  18% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  19% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  20% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  21% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  22% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  23% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  24% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  25% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
`

const BuzzImg = styled(Img)(() => ({
  ':hover': {
    animation: `${buzz} 1s infinite linear`,
  },
}))

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#c87100' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  Activating fans of a cult classic
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  When boutique advertising agency Leroy & Rose needed extra
                  muscle in the digital department, they called on us to lead a
                  digital campaign to launch and promote Season 1 of AMC’s
                  brand-new comic book adaptation: Preacher, based on the cult
                  classic Vertigo Comics series written by Garth Ennis. Our goal
                  was to activate comic book and graphic novel fans as well as
                  those who love action dramas by creating interactive digital
                  content.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>AMC</DetailValue>
                </li>
                <li>
                  <Detail muted>Agency:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Leroy & Rose</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Entertainment</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Social Content
                    <Break />
                    Digital Advertising
                  </DetailValue>
                </li>
              </Stack>
            }
          />
        </Inset>
        <Space tablet={-30} />
        <Box
          css={{
            position: 'relative',
            maxWidth: { mobile: '95%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <Grid
              template={`
                0.95fr
                2fr
              / 1fr 1fr 2fr
            `}
            >
              <Grid.Item row="1 / 3" column="1 / 3">
                <AutoSlide up>
                  <Img
                    src={ipad01}
                    ratio={865 / 605}
                    style={dropShadow(0.5, '14px 14px 25px')}
                  />
                </AutoSlide>
              </Grid.Item>
              <Grid.Item row="2 / 3" column="2 / 4">
                <AutoSlide up>
                  <Img
                    src={ipad02}
                    ratio={604 / 865}
                    style={dropShadow(0.5, '14px 14px 25px')}
                  />
                </AutoSlide>
              </Grid.Item>
            </Grid>
          </Inset>
        </Box>
        <Space y="-22vw" />
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <Space y="22vw" />
          <Space xl />
          <Box css={{ width: { tablet: '66.666%' }, margin: 'auto' }}>
            <On min="tabletSm">
              <WhenVisible>
                {inView => (
                  <Space x={{ tabletSm: '-5.55vmax' }}>
                    <BackgroundText is="h4" animate={inView}>
                      CREATIVE
                    </BackgroundText>
                  </Space>
                )}
              </WhenVisible>
            </On>
            <Space y={{ tabletSm: '-6.35vmax' }} />
            <Columns columnWidth={250} gap={20}>
              <AutoFade>
                <Img src={cinemagraph01} ratio={1} style={boxShadow(0.9)} />
              </AutoFade>
              <AutoFade delay={150}>
                <Img src={cinemagraph02} ratio={1} style={boxShadow(0.9)} />
              </AutoFade>
            </Columns>
            <Space xl tablet="xxl" />
            <Component initialState={{ ref: React.createRef() }}>
              {({ state: { ref } }) => (
                <WhenVisible>
                  {inView => {
                    inView && ref.current.play()
                    return (
                      <video
                        ref={ref}
                        src={webpageAnimation}
                        loop
                        style={{
                          display: 'block',
                          width: '100%',
                          transition: 'opacity 1s',
                          opacity: inView ? 1 : 0,
                          ...boxShadow(0.9),
                        }}
                      />
                    )
                  }}
                </WhenVisible>
              )}
            </Component>
            <Space xl tablet="xxl" />
            <Relative>
              <AutoFade>
                <Img src={imgur} ratio={901 / 1227} style={boxShadow(0.9)} />
              </AutoFade>
              <AutoFade
                delay={500}
                style={{
                  width: '43%',
                  position: 'absolute',
                  top: '7.8%',
                  left: '14.7%',
                  ...boxShadow(0.9),
                }}
              >
                <Img src={memeFloating} ratio={1} />
              </AutoFade>
            </Relative>
            <Space y={60} />
            <On min="tablet">
              <WhenVisible>
                {inView => (
                  <Space x={{ tablet: '-5.55vmax' }}>
                    <BackgroundText is="h4" animate={inView}>
                      SOCIAL
                    </BackgroundText>
                  </Space>
                )}
              </WhenVisible>
            </On>
            <Space mobile="8vmax" tablet={20} />
            <Columns columnCount={{ mobile: 2, tabletLg: 4 }} gap={30}>
              <AutoSlide up delay={0 * 150}>
                <Img
                  src={social01}
                  ratio={636 / 266}
                  style={{
                    marginTop: '-8vmax',
                  }}
                />
              </AutoSlide>
              <AutoSlide up delay={1 * 150}>
                <Img src={social02} ratio={634 / 266} />
              </AutoSlide>
              <AutoSlide up delay={2 * 150}>
                <Img
                  src={social03}
                  ratio={636 / 266}
                  style={{
                    marginTop: '-8vmax',
                  }}
                />
              </AutoSlide>
              <Relative>
                <AutoSlide up delay={3 * 150}>
                  <Img src={social04} ratio={634 / 266} />
                </AutoSlide>
                <On min="tablet">
                  <AutoFade
                    delay={4 * 150}
                    style={{
                      width: '135%',
                      position: 'absolute',
                      top: '17.6%',
                      left: '27.7%',
                    }}
                  >
                    <Img src={bubble01} ratio={215 / 343} />
                  </AutoFade>
                  <AutoFade
                    delay={4 * 150 + 100}
                    style={{
                      width: '135%',
                      position: 'absolute',
                      top: '40.3%',
                      left: '7.2%',
                    }}
                  >
                    <Img src={bubble02} ratio={215 / 343} />
                  </AutoFade>
                </On>
              </Relative>
            </Columns>
            <Space xl tablet="xxl" />
            <Relative>
              <AutoFade style={{ width: '80%' }}>
                <Img src={spotify01} ratio={491 / 938} />
              </AutoFade>
              <AutoFade
                delay={150}
                style={{
                  width: '27%',
                  position: 'absolute',
                  top: '33%',
                  right: '3%',
                }}
              >
                <Img src={spotify02} ratio={379 / 300} />
              </AutoFade>
            </Relative>
            <Space mobile={160} tablet={280} />
            <Relative>
              <AutoSlide
                up
                style={{
                  width: '67%',
                  position: 'absolute',
                  top: '-30%',
                  right: 0,
                }}
              >
                <Img src={nerdist} ratio={548 / 769} />
              </AutoSlide>
              <AutoSlide
                up
                delay={150}
                style={{ width: '67%', position: 'relative' }}
              >
                <Img src={buzzfeed} ratio={548 / 769} />
              </AutoSlide>
            </Relative>
            <Space xl tablet="xxl" />
            <Grid templateColumns="repeat(10, 1fr)">
              <Grid.Item row="1 / span 3" column="1 / -4" zIndex={1}>
                <AutoSlide up>
                  <Img
                    src={sketch01}
                    ratio={588 / 809}
                    style={boxShadow(0.5)}
                  />
                </AutoSlide>
              </Grid.Item>
              <Grid.Item row="3 / span 3" column="4 / -1">
                <AutoSlide up delay={150}>
                  <Img
                    src={sketch02}
                    ratio={571 / 785}
                    style={boxShadow(0.5)}
                  />
                </AutoSlide>
              </Grid.Item>
              <Grid.Item row="3 / span 2" column="1 / 4" zIndex={2}>
                <AutoSlide up>
                  <Img
                    src={preacherStreet}
                    ratio={371 / 248}
                    style={{
                      width: '72%',
                      margin: '15% 0 0 17%',
                      ...boxShadow(0.5),
                    }}
                  />
                </AutoSlide>
              </Grid.Item>
              <Grid.Item row="5 / span 3" column="1 / -1">
                <AutoSlide up>
                  <Img
                    src={stagecoach}
                    ratio={762 / 1170}
                    style={{
                      width: '105%',
                      margin: '7% 0 0 -2%',
                      ...dropShadow(0.5),
                    }}
                  />
                </AutoSlide>
              </Grid.Item>
            </Grid>
            <Space xl />
            <AutoFade
              style={{
                width: '50%',
                margin: 'auto',
                ...boxShadow(0.5),
              }}
            >
              <BuzzImg src={poster} ratio={836 / 564} />
            </AutoFade>
            <Space xl tablet="xxl" />
          </Box>
        </Inset>
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
