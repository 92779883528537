import React from "react"
import styled from "react-emotion"
import { duration } from "../styles/helpers"

const HamburgerInner = ({ active, ...props }) => (
  <div {...props}>
    <span />
    <span />
    <span />
    <span />
  </div>
)

const Hamburger = styled(HamburgerInner)`
  display: inline-block;
  width: 23px;
  height: 18px;
  position: relative;

  > span {
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    background-color: currentColor;
    transform-origin: center;
    transition-property: transform;
    transition-duration: ${duration("fast")};
    transition-timing-function: ease-in-out;
    will-change: transform;
  }

  > span:nth-of-type(1) {
    top: 0;
  }

  > span:nth-of-type(4) {
    bottom: 0;
  }

  > span:nth-of-type(1),
  > span:nth-of-type(4) {
    transition-delay: ${duration("fast")};
    ${p =>
      p.active &&
      `transform: scaleX(0);
       transition-delay: 0s`};
  }

  > span:nth-of-type(2),
  > span:nth-of-type(3) {
    top: calc(50% - 1px);
    transition-delay: 0s;
  }

  > span:nth-of-type(2) {
    ${p =>
      p.active &&
      `transform: rotate(45deg);
       transition-delay: ${duration("fast")(p)}`};
  }

  > span:nth-of-type(3) {
    ${p =>
      p.active &&
      `transform: rotate(-45deg);
       transition-delay: ${duration("fast")(p)}`};
  }
`

export default Hamburger
