import styled from '../styles/styled'

const StrikeThrough = styled('s')(({ inView, length, delay = 0 }) => ({
  display: 'inline',
  textDecoration: 'none',
  backgroundImage: 'linear-gradient(currentColor, currentColor)',
  backgroundPositionY: '62%',
  backgroundPositionX: inView ? 0 : `${length}em`,
  backgroundSize: '100% 20%',
  backgroundRepeat: 'no-repeat',
  transition: '1s ease-out',
  transitionDelay: `${delay}ms`,
  ':hover': {
    opacity: 0.6,
    backgroundPositionX: `${length}em !important`,
    transition: '1s ease-out',
  },
}))

export default StrikeThrough
