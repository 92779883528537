import React, { Component } from "react"
import imagesLoaded from "imagesloaded"

class ImagesLoaded extends Component {
  static defaultProps = {
    is: "div",
    background: false
  }

  componentDidMount() {
    const {
      handleAlways,
      handleDone,
      handleFail,
      handleProgress,
      background
    } = this.props

    this.imagesLoaded = imagesLoaded(this.ref, { background })
    this.imagesLoaded.on("always", handleAlways)
    this.imagesLoaded.on("done", handleDone)
    this.imagesLoaded.on("fail", handleFail)
    this.imagesLoaded.on("progress", handleProgress)
  }

  componentWillUnmount() {
    const { handleAlways, handleDone, handleFail, handleProgress } = this.props

    this.imagesLoaded.off("always", handleAlways)
    this.imagesLoaded.off("done", handleDone)
    this.imagesLoaded.off("fail", handleFail)
    this.imagesLoaded.off("progress", handleProgress)
  }

  render() {
    const {
      is: Is,
      handleAlways,
      handleDone,
      handleFail,
      handleProgress,
      background,
      ...props
    } = this.props
    return <Is ref={ref => (this.ref = ref)} {...props} />
  }
}

export default ImagesLoaded
