import styled from "react-emotion"
import { rule, max, Box } from "spaced-components"

const ContentColumn = styled(Box)`
  height: 100%;
  ${p => rule("padding-right", p.theme.barWidth)(p)};
  ${max("laptopSm")} {
    padding-right: 0;
  }
`

export default ContentColumn
