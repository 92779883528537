import React from 'react'
import styled from '../styles/styled'

const VideoWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  '& video': {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center top',
  },
}))

const FlagVideo = () => (
  <VideoWrapper>
    <video
      autoPlay
      playsInline
      loop
      muted
      poster={`${process.env.PUBLIC_URL}/assets/videos/flag-poster.jpg`}
      src={`${process.env.PUBLIC_URL}/assets/videos/flag.mp4`}
    />
  </VideoWrapper>
)

export default FlagVideo
