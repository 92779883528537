import React, { Fragment } from "react"
import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import { NavbarThemeSwitch } from "../../components/NavbarTheme"
import { Space, Board, Inset, Columns, Flex, On } from "spaced-components"
import LinesPattern from "../../components/LinesPattern"
import DarkWrapper from "../../components/DarkWrapper"
import Layout from "../../Layout"
import ShowCells from "../../components/ShowCells"
import LogoCell from "../../components/LogoCell"
import TextLink from "../../components/TextLink"
import ScrollToLink from "../../components/ScrollToLink"
import BackgroundText from "../../components/BackgroundText"
import { Footer } from "../../components/Footer"
import ChallengeUsFooter from "../../components/ChallengeUsFooter"
import Headline from "../../components/Headline"
import Paragraph from "../../components/Paragraph"
import HelloFab from "../../components/HelloFab"
import WhenVisible from "../../components/WhenVisible"
import VerticalLine from "../../components/VerticalLine"

export default () => (
  <Fragment>
    <Helmet>
      <title>Our Clients</title>
      <meta name="description" content="Our clients description" />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <Layout.Shell>
      <Footer>
        <DarkWrapper>
          <LinesPattern />
          <Board.Item
            offset={{ mobileSm: 1 / 10, tabletMd: 1 / 6 }}
            width={{ mobileSm: 8 / 10, tabletMd: 4 / 6 }}
          >
            <Space y={100} />
            <Space x={{ tabletMd: "-5.55vmax" }}>
              <BackgroundText animate>CLIENTS</BackgroundText>
            </Space>
            <On min="tabletSm">
              <Space y={{ tabletMd: "-6.35vmax" }} />
              <Space x={{ tabletMd: -5 }} style={{ position: "relative" }}>
                <Headline maxWidth="13em">
                  From disruptive startups to industry titans.
                </Headline>
              </Space>
              <Space tabletMd="lg" />
            </On>
            <Board.Item width={{ tabletMd: 3 / 4 }}>
              <On min="tabletMd">
                <Paragraph>
                  Our experience includes creative and strategic work for scores
                  of clients, served while working with other agencies,
                  startups, and Fortune 500 corporations.
                </Paragraph>
              </On>
            </Board.Item>
            <Space xl />
            <ShowCells defaultActive>
              <Inset mobileSm={5}>
                <Columns columnCount={{ mobileSm: 2, tabletSm: 4 }} gap={10}>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="65%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/1stdibs.svg`}
                        alt="1stdibs logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="50%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/abc-logo.svg`}
                        alt="ABC logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="46%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/ac-hotels-logo.svg`}
                        alt="AC hotels logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="65%"
                        style={{ transform: "translateY(-20px) scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/adonit.svg`}
                        alt="Adonit logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="80%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/alisoviejo.svg`}
                        alt="Aliso Viejo logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="60%"
                        style={{ transform: "scale(.8)" }}
                        src={`${process.env.PUBLIC_URL}/assets/logos/amc.svg`}
                        alt="AMC logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="70%"
                        style={{ transform: "scale(.8)" }}
                        src={`${process.env.PUBLIC_URL}/assets/logos/azubu.svg`}
                        alt="Azubu logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="60%"
                        style={{ transform: "scale(.8)" }}
                        src={`${process.env.PUBLIC_URL}/assets/logos/bravo.svg`}
                        alt="Bravo logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="72%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/burbank.svg`}
                        alt="Burbank logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/butterfinger-logo.svg`}
                        alt="Butterfinger logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/cbs-interactive.svg`}
                        alt="CBS Interactive logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="72%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/coldwell-banker.svg`}
                        alt="Coldwell Banker logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="85%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/dignity-health.svg`}
                        alt="Dignity Health logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/directtv-logo.svg`}
                        alt="Directtv logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="60%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/espn-logo.svg`}
                        alt="ESPN logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="50%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/fox-logo.svg`}
                        alt="Fox logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="70%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/gatorade.svg`}
                        alt="Gatorade logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="70%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/hampton-inn.svg`}
                        alt="Hampton inn logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/hot-pockets.svg`}
                        alt="Hot Pockets logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/kraft-foods-logo.svg`}
                        alt="Kraft Foods logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${process.env.PUBLIC_URL}/assets/logos/motiv.svg`}
                        alt="Motiv logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${process.env.PUBLIC_URL}/assets/logos/msnbc.svg`}
                        alt="MSNBC logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="65%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/nestea.svg`}
                        alt="Nestea logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="70%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/paramount.svg`}
                        alt="Paramount logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="70%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/press-bros.svg`}
                        alt="Press Bros logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/presspops.svg`}
                        alt="Presspops logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/princess-cruises.svg`}
                        alt="Princess Cruises logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/sapphire-ventures.svg`}
                        alt="Sapphire Ventures logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="60%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/smokey-bones.svg`}
                        alt="Smokey Bones logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/sothebys.svg`}
                        alt="Sothebys logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="40%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/tennis-channel.svg`}
                        alt="Tennis Channel logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="45%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/toyota-logo.svg`}
                        alt="Toyota logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="70%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/universal.svg`}
                        alt="universal logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="72%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/voskos.svg`}
                        alt="Voskos logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="72%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/w-hotel.svg`}
                        alt="w-hotel logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell solid>
                      <img
                        width="75%"
                        style={{ transform: "scale(.8)" }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/westfield.svg`}
                        alt="Westfield logo"
                      />
                    </LogoCell>
                  </Columns.Item>
                </Columns>
              </Inset>
            </ShowCells>
            <Space xl />
            <Flex column center>
              <Link to="/our-work">
                <TextLink is="span">SEE OUR WORK</TextLink>
              </Link>
              <Space y={60} />
              <ScrollToLink href="#">
                <Flex column center>
                  <svg style={{ width: 36, height: 36 }} viewBox="0 0 24 24">
                    <path
                      fill="#fff"
                      d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
                    />
                  </svg>
                  <Space y={20} />
                  <WhenVisible triggerOnce={false}>
                    {inView => <VerticalLine animateToTop={inView} />}
                  </WhenVisible>
                </Flex>
              </ScrollToLink>
            </Flex>
          </Board.Item>
        </DarkWrapper>
      </Footer>
      <ChallengeUsFooter />
      <HelloFab />
    </Layout.Shell>
  </Fragment>
)
