import React from 'react'
import styled, { keyframes } from 'react-emotion'
import { Space, Fixed } from 'spaced-components'
import ScrollPosition from './ScrollPosition'
import theme from '../styles/theme'

const helloFabWave = keyframes`
    0% { transform: rotate(0) translateZ(0) }
    4% { transform: rotate(6deg) translate3d(1px,0,0) }
    7% { transform: rotate(0) translateZ(0) }
   14% { transform: rotate(-6deg) translate3d(-1px,0,0) }
   15% { transform: rotate(0) translateZ(0) }
   20% { transform: rotate(6deg) translate3d(1px,0,0) }
   25% { transform: rotate(0) translateZ(0) }
   30% { transform: rotate(-6deg) translate3d(-1px,0,0) }
   35% { transform: rotate(0) translateZ(0) }
  100% { transform: rotate(0) translateZ(0) }
`

const helloFabFade = keyframes`
    0% { opacity: 0; transform: scale(1) }
   40% { opacity: 1; transform: scale(1.2) }
   80% { opacity: .5; transform: scale(1.3) }
  100% { opacity: 0; transform: scale(1.4) }
`

const HelloFabLink = styled(props => (
  <a {...props}>
    <span role="img" aria-label="Waving hand">
      👋
    </span>
  </a>
))(({ active }) => ({
  display: 'inline-block',
  width: 60,
  height: 60,
  position: 'relative',
  borderRadius: '50%',
  backgroundColor: '#161616',
  boxShadow: '0 7px 30px #000',
  transition: 'opacity 1s',
  opacity: active ? 1 : 0,
  '& > span': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    top: 3,
    fontSize: 24,
    lineHeight: 1,
    transformOrigin: 'bottom right',
    animation: `${helloFabWave} 4s linear infinite`,
  },
  '&::before': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#ddd',
    borderRadius: '50%',
    transform: 'scale(0)',
    transition: 'transform .3s',
  },
  ':hover::before': {
    transform: 'scale(1)',
  },
  '&::after': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    border: '1px solid #ddd',
    borderRadius: '50%',
    opacity: 0,
  },
  ':hover::after': {
    animation: `${helloFabFade} .4s .2s linear`,
  },
}))

const HelloFab = () => (
  <Fixed right={theme.barWidth} zIndex={1} bottom={27}>
    <ScrollPosition below={200}>
      {matches => (
        <HelloFabLink
          active={matches}
          href="mailto:hello@advictorem.agency?subject=Hi, from your website"
        />
      )}
    </ScrollPosition>
    <Space inline x={20} />
  </Fixed>
)

export default HelloFab
