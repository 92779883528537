import styled from '../styles/styled'

const Headline = styled('p')(({ maxWidth }) => ({
  maxWidth,
  fontWeight: 800,
  fontSize: { mobile: 33, mobileLg: 40, laptop: '4.05vmax' },
  lineHeight: 1.1,
}))

export default Headline
