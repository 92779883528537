const Play = ({ sounds, volume = 0.4, children }) => {
  const players = Object.entries(sounds).reduce((acc, [key, src]) => {
    if (typeof window === "undefined") {
      acc[key] = () => {}
      return acc
    }

    const audio = new Audio(src)
    const player = () => {
      audio.currentTime = 0
      audio.volume = volume
      audio.play()
    }
    acc[key] = player
    return acc
  }, {})

  return children(players)
}

export default Play
