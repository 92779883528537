import React from 'react'
import styled from '../styles/styled'
import { Link } from 'react-router-dom'
import {
  Absolute,
  Break,
  Fixed,
  On,
  Relative,
  Space,
  Grid,
} from 'spaced-components'
import theme from '../styles/theme'

import Headline from './Headline'
import Select from './Select'
import TextLink from './TextLink'
import NextArrow from './NextArrow'
import FlagVideo from './FlagVideo'
import { BusinessTypeConsumer } from './BusinessTypeContext'
import { DecideWhenToShowFooter, FooterContent } from './Footer'
import FooterData from './FooterData'

const Text = styled('p')(() => ({
  fontWeight: 700,
  fontSize: { mobile: 18, laptop: '1.45vw' },
  lineHeight: 1.6,
}))

const ChallengeUsFooter = () => (
  <DecideWhenToShowFooter>
    <Fixed is="footer" bottom left={theme.barWidth} right={theme.barWidth}>
      <FooterContent>
        <Absolute top left height="100%">
          <FlagVideo />
        </Absolute>

        <Relative width="100%">
          <Grid
            template={{
              mobile: "'. details .' / 1fr 8fr 1fr",
              tabletMd: "'. form . details' / 1fr 3fr 2rem 2fr",
            }}
            alignItems="center"
          >
            <Grid.Item area="form">
              <On min="tabletMd">
                <Headline is="h2">Challenge us.</Headline>
                <Space mobileSm={20} />
                <Text is="div">
                  If you’re a{' '}
                  <BusinessTypeConsumer>
                    {({ selected, setSelected }) => (
                      <Select
                        selected={selected}
                        onChange={({ value, selected }) => {
                          setSelected(selected)
                        }}
                        options={[
                          'foreign brand coming to the 🇺🇸',
                          'startup disrupting the market',
                          'company that needs to rebrand',
                        ]}
                        color="#999"
                        whiteSelected
                        border
                      />
                    )}
                  </BusinessTypeConsumer>
                  <Break />
                  then challenge us with your business problem or idea.
                </Text>
                <Space mobileSm={30} />
                <Link to="/contact#form">
                  <TextLink is="span" sm>
                    Start a project <NextArrow width="1.9em" />
                  </TextLink>
                </Link>
              </On>
            </Grid.Item>
            <Grid.Item area="details">
              <On max="tabletSm">
                <Headline is="h2">Challenge us.</Headline>
                <Space lg />
              </On>
              <FooterData />
            </Grid.Item>
          </Grid>
        </Relative>
      </FooterContent>
    </Fixed>
  </DecideWhenToShowFooter>
)

export default ChallengeUsFooter
