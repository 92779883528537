import React from "react"
import GSAPComponent from "../../components/GSAPComponent"

export default class extends GSAPComponent {
  animate() {
    /* eslint-disable */
    const aliso = this.svg.querySelectorAll("#aliso path")
    const viejo = this.svg.querySelectorAll("#viejo path")
    const signetTop = this.svg.querySelector("#signet-top")
    const signetCenter = this.svg.querySelector("#signet-center")
    const signetBottom = this.svg.querySelector("#signet-bottom")
    const tl = new TimelineMax({ delay: 0.2 })

    tl.staggerFrom(aliso, 1, { x: -50, opacity: 0 }, -0.1, 0)
    tl.staggerFrom(viejo, 1, { x: 50, opacity: 0 }, 0.1, 0)
    tl.from(signetCenter, 1, { y: 10, opacity: 0, ease: Power2.easeOut }, 0)
    tl.from(
      signetBottom,
      1,
      { y: 10, opacity: 0, ease: Power2.easeOut },
      "-=.9"
    )
    tl.from(signetTop, 1, { y: -10, opacity: 0, ease: Power2.easeOut }, "-=.9")

    return tl
    /* eslint-enable */
  }

  render() {
    return (
      <svg ref={ref => (this.svg = ref)} viewBox="-50 -10 200 45" width={550}>
        <g id="aliso" fill="#fff">
          <path d="M7.46 13.9999857c-.1599998-.3999996-2.0599999-5.54-2.0900002-5.6100006l-.5-1.4400005L4 7.2599854s-1.6300001 4.3500009-2.9300001 7.23c-.215883.6250391-.5560459 1.1999149-1 1.6899996.1152195.1317348.285401.2020264.46.1900005.4498699.0101662.8688108-.2281303 1.09-.6199989.3987037-.8372431.7525378-1.6951246 1.0599998-2.5699997h3.0800004c.3200002.8699999.5799999 1.5500002.5900002 1.5799999a7.46005736 7.46005736 0 0 0 .4000001.9200001l-1 .1300001c-.0566177.1228924-.090498.2550249-.0999999.3899984h3.5099993c.0536966-.1176167.0907202-.2421503.1099997-.3699989v-.0500002s-1-.0900002-1.2200003-.3800001A14.52005441 14.52005441 0 0 1 7.46 13.9999857zm-4.5900001-1.4899998C3.55 10.6899853 4.1999998 8.6499853 4.1999998 8.6499853s.71 2.2299995 1.3099999 3.8599997l-2.6399998 9e-7z" />
          <path d="M14.8500004 15.3199854c-.416996.2524824-.9052382.361371-1.3900003.3100004-.377821.0024137-.7553654-.0209742-1.1300001-.0699997.0422783-.2308855.0623703-.4652872.0600004-.6999998v-4.1300001c-.0076237-.3646612.0157937-.72931.0699996-1.0900002 0-.1199999.1800003-.1599998.4799995-.25s.46-.0900002.4700003-.1099997c.0475922-.1183776.0779028-.2429885.0900002-.3699998h-3.21c-.055377.1134663-.0924997.2349606-.1099997.3599997v.0600004l1.0799999.1300001v4.92c.0021658.3645229-.021225.7287493-.0699997 1.0900002-.0500002.1199999-.1499996.1499996-.4499998.2399998-.149025.0307884-.2959776.0708666-.4399996.1199999a1.3299584 1.3299584 0 0 0-.0900002.3699989h5.4899988l.4499998-1.999999-.3299999-.1400003c-.2810649.4509086-.6059751.8729562-.9699992 1.2599992z" />
          <path d="M19.6599998 10.7299852c-.0076237-.3646612.0157928-.72931.0699997-1.0900002 0-.1199999.1499996-.1499996.4400005-.2399998s.4599991-.1000004.4799995-.1199999c.0475922-.1183777.0779018-.2429886.0900002-.3699999h-3.2099991c-.055378.1134653-.0925007.2349606-.1100006.3599997v.0600004l1.08.1300002v4.920001c.0065498.3650103-.0202274.7298574-.0799999 1.0900002 0 .1199999-.1399994.1499996-.4400005.2399998a3.64984128 3.64984128 0 0 0-.4400005.1199999c-.0475922.1183777-.0779018.2429867-.0900002.3699989h3.210001a1.40993675 1.40993675 0 0 0 .1000004-.3599987v-.0600004l-1.1200008-.1300001v-.7300005c-.0100003-.0600004.0200003-3.9200001.0200003-4.1900006z" />
          <path d="M25.3400002 12.0799856c-1-.3800001-1.8500004-.75-1.8500004-1.5699997 0-.6300001.5900002-1 1.289999-1 .815897.0094757 1.6021938.3069944 2.2199993.8400002.2462635-.2027626.4127712-.4861794.4699993-.8000002 0-.4700003-1.0799999-.7699995-2.1100006-.7699995-1.7199993 0-2.9200001.9099998-2.9200001 2.0799999s1.2000008 1.79 2.3099995 2.1999998 1.8799992.6999998 1.8799992 1.5100002-.4899998 1.1300001-1.5599995 1.1300001C24.18009 15.6873388 23.3758564 15.1667223 23 14.3599854c0 0-.7199993.2799997-.7199993.8299999s1 1.1399994 2.4400005 1.1399994c2.2199993 0 3-1.1799994 3-2.0599985-.0100021-1.2700005-1.2500019-1.7300005-2.380001-2.1900006z" />
          <path d="M33.3600006 8.8099861c-2.0486031-.155549-3.8354206 1.3790731-3.9909687 3.4276762-.0099106.1305246-.0129261.2614813-.0090313.3923235-.0833569 1.1052933.3617764 2.184741 1.2000008 2.9099998.7114792.533886 1.5806198.8153877 2.4700012.7999992 1.22826.0348663 2.4004402-.5141268 3.1599998-1.4799986.5143967-.6879748.7979279-1.5210666.8099976-2.380001.0679283-1.0357399-.3234329-2.0488844-1.0699997-2.7700005-.7250481-.5913324-1.6344414-.9097966-2.5699997-.8999986zm1.0699997 6.6799994c-.347393.1211824-.7121086.1853447-1.0800018.1899996-2.4699993 0-2.7700005-2.4000006-2.7700005-3.3499994 0-1.54.7000008-2.8699999 2.3400002-2.8699999 1.9399986 0 2.7099991 1.4499998 2.7400017 3.1199999-1e-7.0299997-.0200005 2.21-1.2299996 2.9099998z" />
        </g>
        <g id="viejo" fill="#fff">
          <path d="M71.7799988 6.9999852h-3.1500015c-.0616608.1152253-.1022949.2405171-.1200027.3699999L70 7.5099854s-.3099976.1900001-.8099976 1.73c-.5899963 1.8000002-1.6999969 5-1.6999969 5s-1.6299973-4.5200005-2.0899963-5.8400002-.9199982-1.5900006-1.6899986-1.5900006c-.4978676.0208321-.9930267.0843992-1.480011.1900005v.3899999h.2900009c.6300011 0 1.0699997.2399998 1.5000038 1.3500004.6100006 1.5599995 2.8700027 7.4699984 2.8700027 7.4699984l.8899994-.5299988s1-2.8299999 1.75-4.9299994c.9100037-2.5299997 1.0599976-2.8400006 1.3000031-3.0700002.217926-.1649637.4777832-.2654414.75-.29.1099929-.05.1999893-.3100003.1999893-.3900002z" />
          <path d="M73.8099976 10.7299852c-.0076218-.3646612.0157928-.72931.0699997-1.0900002.0500031-.1199999.1500015-.1499996.4499969-.2399998s.4599991-.1000004.4700012-.1199999c.0475922-.1183786.0778961-.2429886.0899963-.3699999h-3.2099915c-.0553741.1134653-.0924988.2349606-.1100006.3599997v.0600004l1.0800018.1300001v4.920001c.006546.3650103-.0202332.7298574-.0800018 1.0900002 0 .1199999-.1399994.1499996-.4400024.2399998-.149025.0307884-.2959824.0708666-.4400024.1199999-.0475922.1183786-.0778961.2429867-.0899963.3699989h3.2099991c.0491562-.1150322.0827866-.2360945.0999985-.3599987v-.0600004l-1.1200027-.1300001v-.7300005c-.0099946-.0600003.0200042-3.92.0200042-4.1900005z" />
          <path d="M80.8099976 15.3199854c-.4392319.251874-.9461746.3602543-1.4499969.3100004-.3778229.0024137-.7553635-.0209742-1.1299973-.0699997.0422745-.2308855.0623703-.4652872.0599976-.6999998v-2h1.5599976c.6900024 0 1.3700027 0 1.3700027-.5699997a1.00023437 1.00023437 0 0 0 0-.1599998c-.7565765.0885296-1.5185776.1219511-2.2799988.1000004h-.6399994V9.4899855h.1500015c.6727066-.0111227 1.3442764.0593929 2 .21.3597412.3058376.6941605.6402607 1 1l.2799988-.1099997-.3099976-1.71h-5.2300034c-.0491562.1150322-.0827866.2360954-.0999985.3599997v.0600004l1.0800018.1300001v4.92c.0076218.3646612-.0157928.72931-.0699997 1.0900002 0 .1199999-.1500015.1499996-.4499969.2399998-.1486511.0323496-.295517.072403-.4400024.1199999-.0475922.1183786-.0778961.2429867-.0899963.3699989h5.5999985l.4400024-1.999999-.3199997-.1400003c-.2996216.4631595-.644661.895296-1.030014 1.2899999z" />
          <path d="M83.1200027 9.2699862v.0600004l1.0800018.1300001v4.9699993c.0349121.7994547-.0734634 1.5987082-.3199997 2.3599997-.2534103.5689716-.6668777 1.0519295-1.1900024 1.3899994.0841064.0940533.2038345.1484756.3300018.1499996.5638046-.0505161 1.0797882-.3375816 1.4199982-.7900009.6941299-.880722 1.02668-1.9927959.9300003-3.1099987v-3.6999998c-.006546-.3650103.0202332-.7298574.0800018-1.0900002 0-.1199999.1500015-.1499996.4400024-.2399998s.4599991-.1000004.4700012-.1199999c.0475922-.1183786.0778961-.2429886.0899963-.3699999H83.220001c-.049156.1150332-.0827863.2360955-.0999983.3600007z" />
          <path d="M94 9.7099857c-.7144318-.582571-1.6081543-.9004955-2.5299988-.8999996-2.0485992-.155549-3.8354187 1.3790731-3.9909668 3.4276762-.0099106.1305246-.0129242.2614813-.0090332.3923235-.0882645 1.1060686.3577423 2.1876488 1.1999969 2.9099998.7114792.533886 1.5806198.8153877 2.4700012.7999992 1.2254486.0348835 2.3946075-.5144329 3.1500015-1.4799986.5223465-.6844101.8099289-1.5190916.8199997-2.3800001.0576554-1.0420188-.3487319-2.0561427-1.1100005-2.7700004zm-1.4599991 5.7799998c-.3473969.1211824-.7121048.1853447-1.0800018.1899996-2.4800034 0-2.7699966-2.4000006-2.7699966-3.3499994 0-1.54.6900024-2.8699999 2.3399963-2.8699999 1.9400024 0 2.7099991 1.4499998 2.7300034 3.1199999.0099945.0299997 0 2.21-1.2099991 2.9099998h-.0100022z" />
        </g>
        <path
          id="signet-bottom"
          d="M59.5400009 12.3999853c-.7400017-.79-1.5400009-1.7200003-2.3800011-2.75-.4799995.4700003-.9199982 1-1.3600006 1.4799995s-.8899994 1.1000004-1.2900009 1.6800003c-.2599983.3800002-.5099983.7700005-.75 1.1599999s-.5999985 1.0799999-.8699989 1.6400003a22.99936653 22.99936653 0 0 0-1.0699997 2.6099987c-.307312.9110584-.5610771 1.8393097-.7599983 2.7800007l-.4300003 2.0699997-.4300003-2.0699997c-.0699997-.3199997-.1399994-.6399994-.2200012-.9500008-.1533356-.6200008-.3333321-1.2299995-.5400009-1.8299999-.7790108-2.3601685-1.963398-4.5665121-3.5-6.5199995-.2099991-.2700005-.4399986-.54-.6699982-.8000002-.3699989-.4300003-.7599983-.8500004-1.1699982-1.25-.8899994 1.1000004-1.75 2.0900002-2.5299988 2.9200001 1.8682709 1.6763763 3.5451584 3.5544872 5 5.5999985 1.6476402 2.160841 2.9933357 4.5359974 4 7.0599995 0-.1000004.0800018-.2199993.1199989-.3299999.2628746-.6827583.5633354-1.3504467.9000015-2 0-.0499992.0499992-.0900002.0699997-.1399994.2200012-.4099998.4700012-.8500004.7400017-1.2999992s.7200012-1.1700001 1.1300011-1.7900009.6899986-1 1.0699997-1.5300007S55.5499992 16.9999847 56 16.4499855c.6599998-.8099995 1.3100014-1.5599985 1.9300003-2.2099991.5584641-.5923166 1.1493721-1.1531792 1.7700005-1.6800003l-.1599999-.1600008z"
          fill="#fff"
        />
        <path
          id="signet-top"
          d="M47.4700012 12.2799854c.2700005.3999996.5299988.8100004.7799988 1.2200003.4413033-1.1259556 1.0297508-2.1885233 1.75-3.1599998l.2400017-.3500004.3699989-.54L51 9.9999857c.0800018.1000004.1599998.2200003.25.3500004.7230148.9660854 1.3117065 2.0257359 1.75 3.1499996.25-.4099998.5099983-.8199997.7900009-1.2200003.5491142-.7988253 1.150425-1.5604849 1.7999992-2.2799997.3199997-.3599997.6599998-.7200003 1-1.0599995-.2599983-.3199997-.5200005-.6599998-.7799988-1.0000005-.2599982-.3400001-.5100021-.6900005-.8100013-1.0500006-.9500008-1.29-1.8100014-2.54-2.5499992-3.7099998-.1899986-.29-.3699989-.5799999-.5400009-.8600001-.3133355-.56-.6166649-1.0899998-.9099999-1.5899998-.1399994-.25-.2700005-.5-.3899994-.73-.1199989.23-.25.48-.3899994.73-.2799988.5-.5900002 1-.9399986 1.5900003l-.5299988.8600001c-.5600014.8699996-1.1800003 1.8-1.8600006 2.7499998-.2200012.3200002-.4599991.6399999-.6899986 1-.5299988.7200003-1 1.4000001-1.5600014 2.0500007 1.0430183 1.0104312 1.9903832 2.1151322 2.829998 3.2999991zm3.6399994-4.9300003l.0600014.1100001.1500015.25.25.4100003.1699982.29h-2.2200012c.0600014-.1000004.1100006-.1999998.1699982-.29.1399994-.2500005.2700005-.4600005.4000015-.6600003s.2000008-.3600001.2999992-.5599999.1199989-.27.1800003-.4200001l.0600014-.1399999a.78005458.78005458 0 0 1 0 .1399999c.1393699.3009134.2997665.5916334.4799995.8699999z"
          fill="#fff"
        />
        <path
          id="signet-center"
          d="M50.6300011 20.2799854c.0128517-.3971691.0630722-.7922478.1500015-1.1800003.3894043-1.5527306.9390182-3.0608215 1.6399994-4.499999-.1496429-.5950584-.3642654-1.1718588-.6399994-1.7200003-.3410339-.6515808-.7252235-1.2796459-1.1500015-1.8800001l-.2400017.3500004c-.1599998.2399998-.3300018.5-.5.7799997l-.25.4399996c-.1713943.323288-.3217087.6573172-.4500008 1-.1435204.3473158-.2605247.7050056-.3499985 1.0699997.2200012.46.4399986 1 .6500015 1.4799986.2100029.4799995.4099998 1 .5800018 1.5599995.3069077.8501396.4953423 1.7384739.5600014 2.6399994l-.0000037-.0399972z"
          fill="#fff"
        />
      </svg>
    )
  }
}
