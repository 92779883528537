import React from 'react'
import styled from 'react-emotion'
import { em, rem } from 'spaced-components'

import ScrollPosition from './ScrollPosition'

import theme from '../styles/theme'

const navbarHeight = rem(theme.navbarHeight.mobile)
const mediaQuery = `@media (min-height: ${em(720)})`

export const Footer = styled('div')({
  position: 'relative',
  zIndex: 1,
  marginBottom: `calc(100vh - ${navbarHeight})`,
  [mediaQuery]: {
    marginBottom: '70vh',
  },
})

export const FooterContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: `calc(100vh - ${navbarHeight})`,
  [mediaQuery]: {
    height: '70vh',
  },
})

export const DecideWhenToShowFooter = ({ children, ...rest }) => (
  <ScrollPosition
    below={() => document.documentElement.scrollHeight - window.innerHeight * 3}
    {...rest}
  >
    {matches => matches && children}
  </ScrollPosition>
)
