import React from "react"
import GSAPComponent from "./GSAPComponent"

class ShowCells extends GSAPComponent {
  animate() {
    /* eslint-disable */
    const items = this.root.querySelectorAll(".LogoCell")
    const tl = new TimelineMax({ paused: true })
    this.props.defaultActive && tl.play()

    tl.staggerFrom(
      items,
      1,
      { opacity: 0, transformOrigin: "center", scale: 0.7 },
      0.1
    )

    return tl
    /* eslint-enable */
  }

  componentWillReceiveProps(nextProps) {
    nextProps.active && this.animation.play()
  }

  render() {
    return <div ref={ref => (this.root = ref)}>{this.props.children}</div>
  }
}

export default ShowCells
