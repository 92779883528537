import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import {
  Parallax as ParallaxObject,
  ParallaxProvider,
} from 'react-scroll-parallax'
import { On, Inset, Break, Flex, Stack, Space } from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import Parallax from '../../components/Parallax'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'
import VideoPlayer from '../../components/VideoPlayer'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'
import bottle01 from './assets/bottle-01.png'
import bottle02 from './assets/bottle-02.png'
import bottle03 from './assets/bottle-03.png'
import bottle04 from './assets/bottle-04.png'
import bottle05 from './assets/bottle-05.png'
import bottle06 from './assets/bottle-06.png'
import devicesScreen from './assets/devices-screen.png'
import devices from './assets/devices.png'
import fruit01 from './assets/fruit-01.png'
import fruit02 from './assets/fruit-02.png'
import fruit03 from './assets/fruit-03.png'
import glowBg from './assets/glow-bg.png'
import ipad from './assets/ipad.png'
import pageScreenLeft from './assets/page-screen-left.png'
import pageScreenRight from './assets/page-screen-right.png'
import pageScreenTop from './assets/page-screen-top.png'
import wave from './assets/wave.png'
import webpage from './assets/webpage.png'
import video from './assets/video.mp4'

const currentProject = getProject('nestea')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <ParallaxProvider>
      <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
        <Box
          css={{
            background: `url(${glowBg}) center bottom / 150% auto no-repeat #00a2fd`,
          }}
        >
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <InfoSection
              text={
                <Fragment>
                  <Title is="h1" css={{ maxWidth: '11em' }}>
                    Adding flavor to summertime
                  </Title>
                  <Space lg />
                  <Paragraph
                    css={{
                      width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                    }}
                  >
                    As Nestea announced the revival of the brand’s iconic
                    “Nestea Plunge” advertising campaign we designed a bold
                    website to accompany the bold marketing effort. The goal was
                    to promote the new recipes full of more rich flavor and
                    fewer calories. To support the launch, we created a new
                    interactive website highlighting Nestea’s refreshed product
                    line featuring bold visuals, product locator and social
                    media integration for the #NESTEAplunge campaign.
                  </Paragraph>
                </Fragment>
              }
              details={
                <Stack is="ul" rowGap={15}>
                  <li>
                    <Detail muted>Client:</Detail>
                    <Break />
                    <Space
                      inline={{ mobile: true, tabletMd: false }}
                      sm
                      tabletMd="xs"
                    />
                    <DetailValue>Nestlé</DetailValue>
                  </li>
                  <li>
                    <Detail muted>Industry:</Detail>
                    <Break />
                    <Space
                      inline={{ mobile: true, tabletMd: false }}
                      sm
                      tabletMd="xs"
                    />
                    <DetailValue>Food & Beverage</DetailValue>
                  </li>
                  <li>
                    <Detail muted>Services:</Detail>
                    <Break />
                    <Space
                      inline={{ mobile: true, tabletMd: false }}
                      sm
                      tabletMd="xs"
                    />
                    <DetailValue>Visual Design</DetailValue>
                  </li>
                </Stack>
              }
            />
            <WhenVisible>
              {inView => (
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: { mobile: '-36vw', tablet: '-18vw' },
                  }}
                >
                  <Box
                    css={{
                      width: { mobile: '26%', tablet: '15%' },
                      position: 'relative',
                      zIndex: 0,
                      left: { mobile: '-6.5%', tablet: '-3%' },
                      transition: '.8s ease-out',
                      transform: inView ? '' : 'translateY(10%)',
                      opacity: inView ? 1 : 0,
                    }}
                  >
                    <Img
                      src={bottle03}
                      ratio={904 / 246}
                      style={{ zIndex: 10 }}
                    />
                    <On min="tabletMd" dynamic>
                      <Box
                        css={{
                          width: '100%',
                          position: 'absolute',
                          zIndex: 1,
                          top: '-13%',
                          left: '-202%',
                          // transition: '1s 1.2s ease-out',
                          // opacity: inView ? 1 : 0,
                        }}
                      >
                        <ParallaxObject
                          offsetYMin={-60}
                          offsetYMax={100}
                          slowerScrollRate
                        >
                          <Img src={fruit01} ratio={312 / 227} />
                        </ParallaxObject>
                      </Box>
                      <Box
                        css={{
                          width: '140%',
                          position: 'absolute',
                          zIndex: 0,
                          top: '0%',
                          left: '169%',
                          // transition: '1s 1.2s ease-out',
                          // opacity: inView ? 1 : 0,
                        }}
                      >
                        <ParallaxObject
                          offsetYMin={-70}
                          offsetYMax={120}
                          slowerScrollRate
                        >
                          <Img src={fruit02} ratio={324 / 321} />
                        </ParallaxObject>
                      </Box>
                      <Box
                        css={{
                          width: '120%',
                          position: 'absolute',
                          zIndex: 1,
                          top: '69%',
                          left: '-231%',
                          // transition: '1s 1.2s ease-out',
                          // opacity: inView ? 1 : 0,
                        }}
                      >
                        <ParallaxObject
                          offsetYMin={-60}
                          offsetYMax={150}
                          slowerScrollRate
                        >
                          <Img src={fruit03} ratio={326 / 332} />
                        </ParallaxObject>
                      </Box>
                    </On>
                    <Box
                      css={{
                        width: '80%',
                        position: 'absolute',
                        zIndex: 1,
                        top: '9%',
                        left: '-62%',
                        transition: '.8s .5s ease-out',
                        transform: inView ? '' : 'translateX(92%)',
                      }}
                    >
                      <Img src={bottle02} ratio={904 / 246} />
                    </Box>
                    <Box
                      css={{
                        width: '74%',
                        position: 'absolute',
                        top: '9%',
                        left: '-110%',
                        transition: '.8s .5s ease-out',
                        transform: inView ? '' : 'translateX(170%)',
                      }}
                    >
                      <Img src={bottle01} ratio={685 / 186} />
                    </Box>
                    <Box
                      css={{
                        width: '89%',
                        position: 'absolute',
                        zIndex: 2,
                        top: '2.3%',
                        left: '72%',
                        transition: '.8s .5s ease-out',
                        transform: inView ? '' : 'translateX(-75%)',
                      }}
                    >
                      <Img src={bottle04} ratio={796 / 218} />
                    </Box>
                    <Box
                      css={{
                        width: '80%',
                        position: 'absolute',
                        zIndex: 1,
                        top: '5%',
                        left: '135%',
                        transition: '.8s .5s ease-out',
                        transform: inView ? '' : 'translateX(-155%)',
                      }}
                    >
                      <Img src={bottle05} ratio={703 / 191} />
                    </Box>
                    <Box
                      css={{
                        width: '72%',
                        position: 'absolute',
                        top: '9%',
                        left: '187.7%',
                        transition: '.8s .5s ease-out',
                        transform: inView ? '' : 'translateX(-239%)',
                      }}
                    >
                      <Img src={bottle06} ratio={655 / 178} />
                    </Box>
                  </Box>
                </Box>
              )}
            </WhenVisible>
          </Inset>
        </Box>
        <Box css={{ backgroundColor: '#0a0a0a' }}>
          <Space y={{ mobile: '36vw', tablet: '18vw' }} />
          <Space xl tabletMd="xxl" />
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
            }}
          >
            <WhenVisible>
              {inView => (
                <Fragment>
                  <On min="tabletSm">
                    <Space x={{ tabletSm: '-5.55vmax' }}>
                      <BackgroundText
                        is="h4"
                        animate={inView}
                        style={{ textAlign: 'left' }}
                      >
                        DESIGN
                      </BackgroundText>
                    </Space>
                  </On>
                  <Space y={{ tabletSm: '-7vw' }} />
                </Fragment>
              )}
            </WhenVisible>
          </Box>
          <WhenVisible>
            {inView => (
              <Box
                css={{
                  width: { mobile: '86%', tabletMd: '76%' },
                  position: 'relative',
                  left: '-3%',
                  margin: 'auto',
                  paddingBottom: '38vw',
                }}
              >
                <Box
                  css={{
                    position: 'relative',
                    zIndex: 1,
                    transition: '1s ease-out',
                    opacity: inView ? 1 : 0,
                  }}
                >
                  <Img src={devices} ratio={821 / 1380} />
                </Box>
                <Box
                  css={{
                    width: '30.7%',
                    position: 'absolute',
                    zIndex: 2,
                    top: '38.9%',
                    left: '40%',
                    transition: '1s 1.5s ease-out',
                    opacity: inView ? 1 : 0,
                  }}
                >
                  <Img src={wave} ratio={945 / 423} />
                </Box>
                <Box
                  css={{
                    width: '74.9%',
                    position: 'absolute',
                    top: '41.8%',
                    left: '11.4%',
                    transition: '1s 2s ease-out',
                    opacity: inView ? 1 : 0,
                    transform: inView ? '' : 'translateY(-20%)',
                  }}
                >
                  <Img src={devicesScreen} ratio={759 / 1027} />
                </Box>
              </Box>
            )}
          </WhenVisible>
          <Space lg tabletMd="xl" />
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
            }}
          >
            <AutoFade>
              <Img src={webpage} ratio={2025 / 1227} />
            </AutoFade>
          </Box>
          <Space xl tabletMd="xxl" />
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
            }}
          >
            <WhenVisible>
              {inView => (
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                    transition: '1s ease-out',
                    opacity: inView ? 1 : 0,
                  }}
                >
                  <Box css={{ width: '85%', position: 'relative', zIndex: 1 }}>
                    <Img src={pageScreenTop} ratio={744 / 991} />
                  </Box>
                  <Box
                    css={{
                      width: '65%',
                      position: 'absolute',
                      left: '-10%',
                      top: '9%',
                      transition: '1s .5s ease-out',
                      transform: inView ? '' : 'translateX(30%)',
                    }}
                  >
                    <Img src={pageScreenLeft} ratio={602 / 795} />
                  </Box>
                  <Box
                    css={{
                      width: '65%',
                      position: 'absolute',
                      right: '-10%',
                      top: '9%',
                      transition: '1s .5s ease-out',
                      transform: inView ? '' : 'translateX(-30%)',
                    }}
                  >
                    <Img src={pageScreenRight} ratio={602 / 795} />
                  </Box>
                </Box>
              )}
            </WhenVisible>
          </Box>
          <Space xl tabletMd="xxl" />
          <Box css={{ backgroundColor: '#bd1b70' }}>
            <Box
              css={{
                width: { mobile: '80%', tabletMd: '66.666%' },
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <Space xl tabletMd="xxl" />
              <AutoSlide up>
                <Img
                  src={ipad}
                  ratio={740 / 1019}
                  style={{ marginBottom: '-16vw' }}
                />
              </AutoSlide>
            </Box>
          </Box>
          <Box css={{ backgroundColor: '#0a0a0a' }}>
            <Space y="16vw" />
            <Space xl tabletMd="xxl" />
            <AutoSlide up>
              <Box
                css={{
                  width: { mobile: '80%', tabletMd: '66.666%' },
                  margin: 'auto',
                }}
              >
                <div style={{ boxShadow: '0 20px 50px rgba(0, 0, 0, .3)' }}>
                  <VideoPlayer
                    webroll={video}
                    video={video}
                    ratio={1080 / 1920}
                  />
                </div>
                <Space lg />
                <Box
                  is="p"
                  css={{
                    font: '16px "Source Serif Pro", serif',
                    textAlign: 'center',
                    opacity: 0.4,
                  }}
                >
                  Advertising campaign, created and produced by Publicis Hawkeye
                </Box>
              </Box>
            </AutoSlide>
          </Box>
          <Space xl tabletMd="xxl" />
        </Box>
        <Parallax direction="both" height="65vh" maxHeight={600}>
          <Parallax.Background height="150%">
            <Box css={{ height: '100%', backgroundColor: '#00a2fd' }}>
              <Box
                css={{
                  width: { mobile: '200%', tabletLg: '80%' },
                  top: { mobile: '10%', tabletMd: '-90%' },
                  'max:tabletLg': {
                    position: 'relative',
                    left: '-50%',
                  },
                }}
              >
                <Logo fill="#0299ee" width="80%" />
              </Box>
            </Box>
          </Parallax.Background>
          <Parallax.Content>
            <Flex height="100%" center middle style={{ textAlign: 'center' }}>
              <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
                <Title is="blockquote" css={{ maxWidth: '18em' }}>
                  “The Nestea Plunge epitomizes that invigorating dose of
                  refreshment our fans expect from Nestea.”
                </Title>
                <Space lg />
                <Paragraph
                  is="div"
                  css={{
                    fontWeight: 700,
                    fontFamily: 'RidleyGrotesk, sans-serif',
                    opacity: 1,
                  }}
                >
                  Sara Hilliard
                </Paragraph>
                <Paragraph is="div">Nestea, Senior Brand Manager</Paragraph>
              </Inset>
            </Flex>
          </Parallax.Content>
        </Parallax>
      </CaseStudyLayout>
    </ParallaxProvider>
  </Fragment>
)
