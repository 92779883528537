import React from "react"
import styled from "react-emotion"
import { rem, InlineList } from "spaced-components"

const Twitter = () => (
  <svg viewBox="10 -37 128 128" fill="#FFF">
    <path d="M115 3.2c-2.9 1.3-6.1 2.2-9.4 2.6 3.4-2 6-5.2 7.2-9.1-3.2 1.9-6.7 3.2-10.4 4-3-3.2-7.3-5.2-12-5.2C81.3-4.5 74 2.8 74 11.9c0 1.3.1 2.5.4 3.7C60.8 15 48.7 8.4 40.6-1.5a16.55 16.55 0 0 0 5.1 22c-2.7-.1-5.2-.8-7.4-2.1v.2c0 8 5.7 14.6 13.2 16.1a15.4 15.4 0 0 1-7.4.3c2.1 6.5 8.1 11.3 15.3 11.4-5.6 4.4-12.7 7-20.4 7-1.3 0-2.6-.1-3.9-.2 7.3 4.7 15.9 7.4 25.2 7.4 30.2 0 46.7-25 46.7-46.7v-2.1c3-2.4 5.8-5.3 8-8.6" />
  </svg>
)

const Instagram = () => (
  <svg viewBox="10 -37 128 128" fill="#FFF">
    <path d="M92.3 67H55.7C43.7 67 34 57.3 34 45.3V8.7C34-3.3 43.7-13 55.7-13h36.7C104.3-13 114-3.3 114 8.7v36.7c0 11.9-9.7 21.6-21.7 21.6zM55.7-6.3c-8.3 0-15 6.7-15 15v36.7c0 8.3 6.7 15 15 15h36.7c8.3 0 15-6.7 15-15V8.7c0-8.3-6.7-15-15-15H55.7z" />
    <path d="M74 47c-11 0-20-9-20-20S63 7 74 7s20 9 20 20-9 20-20 20zm0-33.3A13.3 13.3 0 1 0 87.3 27c0-7.4-5.9-13.3-13.3-13.3z" />
    <circle cx="95.2" cy="5.8" r="4.6" />
    <path
      fill="#EAE613"
      d="M74-37C38.7-37 10-8.3 10 27s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zM62.8 33.8L72.4 58l1.6 4.5 10.8-27.2 10.7 27L110 25.4h-5.3l-9.3 23.7-10.7-26.4-10.6 26.4-8.7-22 22.7-57.5c26.1 6.4 45 30.4 45 57.4 0 32.6-26.5 59.1-59.1 59.1-10.1 0-20.1-2.6-28.9-7.6l17.7-44.7zm0-13.3L51.5-8 36.7 29.1h5.2l9.5-23.9 8.7 22L40.9 76a58.8 58.8 0 0 1-26-48.9 59.15 59.15 0 0 1 68.3-58.4L62.8 20.5z"
      display="none"
    />
  </svg>
)

const Behance = () => (
  <svg viewBox="-10 -37 128 128" fill="#FFF">
    <path d="M63.8 5.3h20.9v4.8H63.8zM47.1 24.8c1.6-.8 2.8-1.8 3.7-2.8 1.5-1.8 2.2-4.2 2.2-7.1 0-2.8-.7-5.3-2.2-7.3-2.5-3.3-6.6-5-12.5-5.1H15.1v48.1h21.7a29 29 0 0 0 6.8-.7c2.1-.4 3.9-1.2 5.4-2.4 1.4-1 2.5-2.3 3.4-3.8 1.4-2.3 2.1-4.8 2.1-7.7 0-2.8-.6-5.1-1.9-7.1-1.2-1.7-3-3.2-5.5-4.1zM24.7 10.9h10.5c2.3 0 4.2.2 5.7.8 1.7.7 2.6 2.2 2.6 4.5 0 2-.7 3.4-2 4.2s-3 1.2-5.1 1.2H24.7V10.9zm16.5 30.5c-1.2.6-2.8.8-4.9.8H24.7V29.5h11.9c2.1 0 3.7.3 4.8.8 2 .9 3.1 2.7 3.1 5.2-.1 2.9-1.2 4.9-3.3 5.9zm51-13c-.4-2.8-1.4-5.2-2.8-7.3-1.6-2.4-3.7-4.2-6.2-5.3s-5.3-1.7-8.4-1.7c-5.2 0-9.4 1.7-12.7 4.9-3.3 3.3-4.9 8-4.9 14.2 0 6.6 1.8 11.3 5.4 14.2s7.8 4.4 12.5 4.4c5.7 0 10.2-1.7 13.4-5.2 2.1-2.2 3.2-4.3 3.4-6.4h-9.5c-.6 1.1-1.2 1.8-1.9 2.4-1.3 1.1-3.1 1.6-5.2 1.6-2 0-3.7-.4-5.1-1.3-2.3-1.4-3.6-3.9-3.7-7.5h25.9c.2-3 .1-5.3-.2-7zm-25.3.9c.3-2.3 1.2-4.2 2.5-5.5s3.2-2 5.6-2c2.2 0 4 .7 5.5 1.9 1.5 1.3 2.3 3.2 2.5 5.6H66.9z" />
  </svg>
)

const Link = styled("a")`
  display: inline-block;

  svg {
    display: block;
    width: ${rem(30)};
  }
`

const SocialIcons = () => (
  <InlineList md center>
    <Link
      href="https://twitter.com/Ad_Victorem"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Twitter />
    </Link>
    <Link
      href="https://instagram.com/ad_victorem"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Instagram />
    </Link>
    <Link
      href="https://www.behance.net/ad_victorem"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Behance />
    </Link>
  </InlineList>
)

export default SocialIcons
