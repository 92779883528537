import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Inset, Break, Stack, Space } from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import { AutoSlide } from '../../components/Animated'
import VideoPlayer from '../../components/VideoPlayer'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'
import webroll from './assets/webroll.mp4'
import video from './assets/video.mp4'

const currentProject = getProject('burbank')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#574a90' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  Energizing the pulse of downtown Burbank
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  We helped create the story of how downtown Burbank evolves.
                  The challenge was to paint a picture of how the vacant IKEA
                  land could transform the heart of downtown Burbank. Our goal
                  was to help the developers win city approval for plans to
                  begin new construction. With massive relocation plans in
                  place, we focused on how the new vision to renovate the
                  Burbank Town Center would impact the local community. The
                  result was a short docu-style video featuring local residents
                  and small business owners sharing their stories of how much
                  they want to see the city grow and project come to life. The
                  project won city approval for a $65 million renovation, making
                  the Town Center the new bustling heart of Downtown Burbank.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Burbank Town Center</DetailValue>
                </li>
                <li>
                  <Detail muted>Agency:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Consensus Inc.</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Retail</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Video Production
                    <Break />
                    Creative Direction
                    <Break />
                    Web Design + Development
                    <Break />
                    Content Development
                  </DetailValue>
                </li>
                <li>
                  <Detail muted>Awards:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Video Program - Consumer
                    <Break />
                    PRISM Awards 2016
                  </DetailValue>
                </li>
              </Stack>
            }
          />
          <Space y={{ tablet: -40 }} />
          <AutoSlide up>
            <Box
              css={{
                width: { tablet: '80%' },
                margin: 'auto',
                marginBottom: '-25vw',
                boxShadow: '0 20px 50px rgba(0, 0, 0, .3)',
              }}
            >
              <VideoPlayer
                webroll={webroll}
                video={video}
                ratio={1080 / 1920}
              />
            </Box>
          </AutoSlide>
        </Inset>
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Space y="25vw" />
        <Space xl tabletMd="xxl" />
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
