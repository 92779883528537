import React from "react"
import { Route } from "react-router-dom"
import Component from "@reach/component-component"

const ScrollToTop = () => (
  <Route>
    {props => (
      <Component
        {...props}
        didUpdate={({ prevProps }) => {
          props.location.pathname !== prevProps.location.pathname &&
            window.scrollTo(0, 0)
        }}
      />
    )}
  </Route>
)

export default ScrollToTop
