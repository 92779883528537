import { PureComponent } from 'react'

let _importGSAP = () => import(/* webpackChunkName: "gsap" */ 'gsap')
export const importGSAP = importFun => (_importGSAP = importFun)

class Timeline extends PureComponent {
  gsap = null
  timeline = null
  _refs = this.props.refs || {}
  fns = this.props.fns || {}

  createRefFunction = key => ref => (this._refs[key] = ref)

  componentDidUpdate(prevProps) {
    ;[
      'paused',
      'reversed',
      'yoyo',

      'play',
      'pause',
      'restart',
      'resume',
      'reverse',

      'progress',
      'totalProgress',
      'seek',
      'time',
      'totalTime',

      'timeScale',
      'delay',
      'duration',
      'totalDuration',
      'repeatDelay',
      'repeat',
    ].forEach(method => {
      if (this.props[method] !== prevProps[method]) {
        this.timeline[method](this.props[method])
      }
    })

    if (this.props.invalidate) this.timeline.invalidate()
    if (this.props.kill) this.timeline.kill()
  }

  async componentDidMount() {
    this.gsap = await _importGSAP()

    this.timeline = new window.TimelineMax({
      paused: this.props.paused,
      delay: this.props.delay,
      repeat: this.props.repeat,
      repeatDelay: this.props.repeatDelay,
      yoyo: this.props.yoyo,
    })

    this.props.didMount({
      timeline: this.timeline,
      refs: this._refs,
      fns: this.fns,
      props: this.props,
      gsap: this.gsap,
    })
  }

  componentWillUnmount() {
    this.timeline && this.timeline.kill()

    this.props.willUnmount &&
      this.props.willUnmount({
        timeline: this.timeline,
        refs: this._refs,
        fns: this.fns,
        props: this.props,
        gsap: this.gsap,
      })
  }

  render() {
    return this.props.children({
      ref: this.createRefFunction,
      refs: this._refs,
    })
  }
}

export default Timeline
