import styled from 'react-emotion'

const CloseButton = styled('button')`
  width: 40px;
  height: 40px;
  position: relative;
  color: #bbb;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  transition: 0.2s;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(45deg);
    background-color: currentColor;
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    color: #000;
  }

  &:active {
    color: #bbb;
    transition: 0s;
  }
`

export default CloseButton
