import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import {
  Inset,
  Flex,
  Break,
  Absolute,
  Stack,
  On,
  Space,
} from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import Parallax from '../../components/Parallax'
import { Fade, AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'
import VideoWrapper from '../../components/VideoWrapper'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'

import cbsTvBbtBotm from './assets/cbs-tv-bbt-botm.png'
import cbsTvBbtTop from './assets/cbs-tv-bbt-top.png'
import cbsTvBottom from './assets/cbs-tv-bottom.png'
import cbsTvMenuMid from './assets/cbs-tv-menu-mid.png'
import cbsTvNcis1 from './assets/cbs-tv-ncis1.png'
import cbsTvNcis2 from './assets/cbs-tv-ncis2.png'
import cbsTvSearchMid from './assets/cbs-tv-search-mid.png'
import cbsTvShowsTop from './assets/cbs-tv-shows-top.png'
import cbsTvTwoGirls from './assets/cbs-tv-two-girls.png'
import parallaxImg from './assets/parallax-img.png'
import showTitles from './assets/show-titles.png'
import tvDisplay from './assets/tv-display.png'

import loop from './assets/loop.mp4'

const currentProject = getProject('cbs')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: currentProject.color }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '14em' }}>
                  Visualizing America's Most Watched Network
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  Before their subscription service, CBS All Access debuted on
                  Roku, CBS challenged us with conceptualizing what a “universal
                  streaming app” could look like regardless of platform. Working
                  with the in-house design team, our goal was to create a visual
                  concept that showcased the best way to present CBS content on
                  any OTT app. Our approach was to break away from templates
                  that platforms like Roku typically provide. We created a
                  custom UI for CBS with a consistent user and visual experience
                  across multiple platforms.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>CBS Interactive</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Entertaiment</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    UX Design
                    <Break />
                    Visual Design
                  </DetailValue>
                </li>
              </Stack>
            }
          />
          <Box
            css={{
              width: { tabletMd: '66.666%' },
              position: 'relative',
              margin: '0 auto -25vw',
            }}
          >
            <AutoSlide up>
              <Img src={tvDisplay} ratio={790 / 1177} />
              <Absolute width="90.2%" top="3.2%" left="4.5%">
                <VideoWrapper ratio={768 / 1366}>
                  <video autoPlay loop playsInline muted src={loop} />
                </VideoWrapper>
              </Absolute>
            </AutoSlide>
          </Box>
        </Inset>
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Space y="25vw" />
        <Space xl />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <WhenVisible>
            {inView => (
              <Fragment>
                <On min="tabletSm">
                  <Space x={{ tabletSm: '-5.55vmax' }}>
                    <BackgroundText
                      is="h4"
                      animate={inView}
                      style={{ textAlign: 'left' }}
                    >
                      CONCEPT
                    </BackgroundText>
                  </Space>
                </On>
                <Space y={{ tabletSm: '-7vw' }} />
                <Fade inView={inView}>
                  <Img src={cbsTvNcis1} ratio={733 / 1227} />
                </Fade>
              </Fragment>
            )}
          </WhenVisible>
          <Space xl />
          <AutoFade>
            <Img src={cbsTvNcis2} ratio={733 / 1227} />
          </AutoFade>
        </Box>
        <Space xl tabletMd="xxl" />
        <Parallax direction="both" height="95vh" maxHeight={800}>
          <Parallax.Background height="120%">
            <Img src={showTitles} width="100%" height="100%" />
          </Parallax.Background>
          <Parallax.Content> </Parallax.Content>
        </Parallax>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            position: 'relative',
            margin: 'auto',
          }}
        >
          <AutoSlide up>
            <Img
              src={cbsTvBbtBotm}
              ratio={619 / 1024}
              style={{ width: '88%', marginLeft: '-2%' }}
            />
          </AutoSlide>
          <Absolute width="88%" top="18%" left="14.9%">
            <AutoSlide up delay={200}>
              <Img src={cbsTvBbtTop} ratio={619 / 1024} />
            </AutoSlide>
          </Absolute>
        </Box>
        <Space xl tabletMd="xxl" />
        <Space lg />
        <Box
          css={{
            width: { mobile: '70%', tabletMd: '60%' },
            position: 'relative',
            left: '4%',
            margin: 'auto',
          }}
        >
          <AutoFade>
            <Img src={cbsTvBottom} ratio={619 / 1024} />
          </AutoFade>
          <Absolute width="36%" top="-8%" left="-12%">
            <AutoSlide left delay={1 * 400}>
              <Img src={cbsTvMenuMid} ratio={619 / 371} />
            </AutoSlide>
          </Absolute>
          <Absolute width="36%" top="7%" left="12%">
            <AutoSlide left delay={2 * 400}>
              <Img src={cbsTvSearchMid} ratio={619 / 371} />
            </AutoSlide>
          </Absolute>
          <Absolute width="33%" top="25%" left="24%">
            <AutoSlide left delay={3 * 400}>
              <Img src={cbsTvShowsTop} ratio={533 / 334} />
            </AutoSlide>
          </Absolute>
        </Box>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            position: 'relative',
            margin: 'auto',
          }}
        >
          <AutoFade>
            <Img src={cbsTvTwoGirls} ratio={734 / 1228} />
          </AutoFade>
        </Box>
        <Space xl tabletMd="xxl" />
        <Parallax direction="both" height="95vh" maxHeight={800}>
          <Parallax.Background height="150%">
            <Img src={parallaxImg} width="100%" height="100%" />
          </Parallax.Background>
          <Parallax.Content> </Parallax.Content>
        </Parallax>
        <Parallax direction="both" height="70vh" maxHeight={600}>
          <Parallax.Background height="150%">
            <Box css={{ height: '100%', backgroundColor: '#0e3a6a' }}>
              <Box
                css={{
                  width: { mobile: '80%', tabletLg: '45%' },
                  position: 'relative',
                  top: { mobile: '60%', tabletLg: '35%' },
                  left: '-9%',
                }}
              >
                <svg viewBox="0 0 56.373 56.373" fill="#0d345e">
                  <path d="M56.373,28.187c0,15.567-12.62,28.187-28.187,28.187S0,43.754,0,28.187S12.62,0,28.187,0S56.373,12.62,56.373,28.187z M54.199,27.323c-5.8-10.07-14.48-15.26-26.26-15.77c-1.4,0.15-3,0.24-4.59,0.52c-9.62,1.72-16.64,7-21.3,15.55c-0.152,0.347-0.152,0.743,0,1.09c5.757,11.554,18.444,17.928,31.15,15.65c9.45-1.71,16.36-7.06,21-15.45C54.518,28.431,54.518,27.805,54.199,27.323z M28.139,13.053c-8.356,0-15.13,6.774-15.13,15.13s6.774,15.13,15.13,15.13s15.13-6.774,15.13-15.13S36.495,13.053,28.139,13.053z" />
                </svg>
              </Box>
            </Box>
          </Parallax.Background>
          <Parallax.Content>
            <Flex height="100%" center middle>
              <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
                <Title
                  is="blockquote"
                  css={{ maxWidth: '21em', textAlign: 'center' }}
                >
                  Our design exploration laid the groundwork for cord cutters to
                  enjoy their favorite CBS programs whenever, wherever!
                </Title>
              </Inset>
            </Flex>
          </Parallax.Content>
        </Parallax>
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
