import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Inset, Break, Stack, Space } from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import { AutoSlide } from '../../components/Animated'
import VideoPlayer from '../../components/VideoPlayer'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'
import webroll from './assets/webroll.mp4'
import video from './assets/video.mp4'

const currentProject = getProject('aliso-viejo')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#005c81' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  Imagining the future of retail
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  To help the city of Aliso Viejo spearhead a site assembly
                  process, we created a narrative film communicating the
                  benefits of redeveloping the Aliso Viejo Town Center. The goal
                  of the film was to generate community interest and attendance
                  in a planning charrette to create design guidelines with city
                  officials. Our agency partner Consensus tasked us to create a
                  video campaign that featured a broad array of voices from the
                  community, including business leaders, merchants, students,
                  families, and city officials.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>City of Aliso Viejo</DetailValue>
                </li>
                <li>
                  <Detail muted>Agency:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Consensus Inc.</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Government</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Video Production
                    <Break />
                    Website Design + Development
                  </DetailValue>
                </li>
                <li>
                  <Detail muted>Awards:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Award of Excellence <Break />
                    Video Program - Government <Break />
                    PRISM Awards 2017
                  </DetailValue>
                </li>
              </Stack>
            }
          />
          <Space y={{ tablet: -40 }} />
          <AutoSlide up>
            <Box
              css={{
                width: { tablet: '80%' },
                margin: 'auto',
                marginBottom: '-25vw',
                boxShadow: '0 20px 50px rgba(0, 0, 0, .3)',
              }}
            >
              <VideoPlayer
                webroll={webroll}
                video={video}
                ratio={1080 / 1920}
              />
            </Box>
          </AutoSlide>
        </Inset>
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Space y="25vw" />
        <Space xl tabletMd="xxl" />
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
