import { injectGlobal } from "react-emotion"
import { globalSelection } from "./helpers"

const basename = process.env.PUBLIC_URL

injectGlobal`
  /* latin-ext */
  @font-face {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url(https://fonts.gstatic.com/s/sourceserifpro/v5/neIQzD-0qpwxpaWvjeD0X88SAOeauXo-oBOL.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url(https://fonts.gstatic.com/s/sourceserifpro/v5/neIQzD-0qpwxpaWvjeD0X88SAOeauXQ-oA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: Dinosaur;
    font-style: italic;
    font-weight: 700;
    src: url("${basename}/assets/fonts/dinosaur-bold-italic.woff2") format("woff2"),
         url("${basename}/assets/fonts/dinosaur-bold-italic.woff") format("woff");
  }

  @font-face {
    font-family: RidleyGrotesk;
    font-style: normal;
    font-weight: 800;
    src: url("${basename}/assets/fonts/RidleyGroteskExtraBold.woff2") format("woff2"),
         url("${basename}/assets/fonts/RidleyGroteskExtraBold.woff") format("woff");
  }

  @font-face {
    font-family: RidleyGrotesk;
    font-style: italic;
    font-weight: 800;
    src: url("${basename}/assets/fonts/RidleyGroteskExtraBoldItalic.woff2") format("woff2"),
         url("${basename}/assets/fonts/RidleyGroteskExtraBoldItalic.woff") format("woff");
  }

  @font-face {
    font-family: RidleyGrotesk;
    font-style: normal;
    font-weight: 500;
    src: url("${basename}/assets/fonts/RidleyGroteskMedium.woff2") format("woff2"),
         url("${basename}/assets/fonts/RidleyGroteskMedium.woff") format("woff");
  }

  @font-face {
    font-family: RidleyGrotesk;
    font-style: normal;
    font-weight: 300;
    src: url("${basename}/assets/fonts/RidleyGroteskLight.woff2") format("woff2"),
         url("${basename}/assets/fonts/RidleyGroteskLight.woff") format("woff");
  }

  @font-face {
    font-family: TungstenMedium;
    font-style: normal;
    font-weight: normal;
    src: url("${basename}/assets/fonts/TungstenMedium.woff") format("woff");
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  ${globalSelection("rgba(40, 40, 40, .99)", "#fff")};

  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, .08);
  }

  html {
    font-size: 16px;
    font-family: RidleyGrotesk, sans-serif;
    color: #fff;
    overflow-x: hidden;
  }

  body, figure, h1, h2, h3, h4, h5, h6, p, ol, ul, blockquote {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  ol li, ul li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit
  }

  button {
    margin: 0;
    padding: 0;
    font: inherit;
    color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
  }

  blockquote {
    font: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`
