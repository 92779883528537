import styled from 'react-emotion'
import { Box } from 'spaced-components'
import { duration } from '../styles/helpers'

const TextLink = styled(Box.is('a'))`
  display: inline-block;
  position: relative;
  line-height: 1;
  ${p => (p.sm ? p.theme.typo.linkSm : p.theme.typo.linkLg)};
  ${p => p.wrap && 'white-space: nowrap; line-height: 0.6;'};

  &::after {
    content: '';
    display: block;
    width: calc(100% - 0.15em);
    height: 2px;
    position: absolute;
    top: 80%;
    left: 0;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: center right;
    transition: transform ${duration('normal')} cubic-bezier(0.55, 0, 0.1, 1);
  }

  .hoverTextLink:hover &::after,
  .active > &::after,
  &:hover::after,
  &:focus::after {
    transform: scaleX(1);
    transform-origin: center left;
  }
`

export default TextLink
