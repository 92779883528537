import emotionStyled from "react-emotion"
import { Box, rem, em, defaultTheme } from "spaced-components"

const theme = property => props =>
  (props.theme && props.theme[property]) || defaultTheme[property]

const isNumber = val => typeof parseInt(val, 10) === "number" && !isNaN(val)
const parsePrivateNumber = val => parseInt(val.slice(1), 10)

// Get value in pixels from breakpoint key (e.g. "mobileSm" or "_1200" or 1200).
const breakpointRaw = key => props => {
  const localValue = isNumber(key) ? parseInt(key, 10) : parsePrivateNumber(key)
  const isLocal = !isNaN(localValue) // eslint-disable-line

  return isLocal ? localValue : theme("breakpoints")(props)[key]
}

const breakpoint = key => props => em(breakpointRaw(key)(props))

/**
 * Copied from react.
 * CSS properties which accept numbers but are not in units of "px".
 */
const isUnitlessNumber = {
  animationIterationCount: true,
  borderImageOutset: true,
  borderImageSlice: true,
  borderImageWidth: true,
  boxFlex: true,
  boxFlexGroup: true,
  boxOrdinalGroup: true,
  columnCount: true,
  columns: true,
  flex: true,
  flexGrow: true,
  flexPositive: true,
  flexShrink: true,
  flexNegative: true,
  flexOrder: true,
  gridRow: true,
  gridRowEnd: true,
  gridRowSpan: true,
  gridRowStart: true,
  gridColumn: true,
  gridColumnEnd: true,
  gridColumnSpan: true,
  gridColumnStart: true,
  fontWeight: true,
  lineClamp: true,
  lineHeight: true,
  opacity: true,
  order: true,
  orphans: true,
  tabSize: true,
  widows: true,
  zIndex: true,
  zoom: true,

  // SVG-related properties
  fillOpacity: true,
  floodOpacity: true,
  stopOpacity: true,
  strokeDasharray: true,
  strokeDashoffset: true,
  strokeMiterlimit: true,
  strokeOpacity: true,
  strokeWidth: true
}

const bpPropRegExp = /^(min|max):(.+)/i
const isShorthandMediaProp = prop => bpPropRegExp.test(prop)
const isBreakpointsToMergeProp = (key, val) =>
  /breakpointsToMerge.+/.test(key) && typeof val === "object"
const isMediaProp = prop => /@media.+/.test(prop)
const isSpecialProp = (key, val) =>
  typeof val === "object" &&
  (/^:.+/.test(key) ||
    /^@media.+/.test(key) ||
    /^min:.+/.test(key) ||
    /^max:.+/.test(key) ||
    /\s/.test(key) ||
    /&/.test(key) ||
    /\./.test(key) ||
    /#/.test(key))
const isResponsiveProp = val => typeof val === "object"

const entries = obj =>
  "entries" in Object
    ? Object.entries(obj)
    : Object.keys(obj).map(key => [key, obj[key]])

const sortBreakpoints = (obj, props) =>
  Object.keys(obj)
    .sort((a, b) => breakpointRaw(a)(props) - breakpointRaw(b)(props))
    .reduce((acc, key) => {
      acc[breakpoint(key)(props)] = obj[key]
      return acc
    }, {})

const mergeBreakpoints = (breakpoints, bpKey, rules) => {
  if (bpKey in breakpoints) {
    breakpoints[bpKey] = {
      ...breakpoints[bpKey],
      ...rules
    }
  } else {
    breakpoints[bpKey] = rules
  }

  return breakpoints[bpKey]
}

const withUnit = (key, val) => {
  if (typeof val === "number") return isUnitlessNumber[key] ? val : rem(val)
  return val
}

const parse = (stylesObj, props) => {
  const breakpoints = {}
  const parsedObj = {}

  entries(stylesObj).forEach(([stylePropKey, stylePropVal]) => {
    if (isBreakpointsToMergeProp(stylePropKey, stylePropVal)) {
      const breakpointsToMerge = stylePropVal
      entries(breakpointsToMerge).forEach(([bpKey, bpVal]) => {
        const rules = bpVal

        parsedObj[bpKey] = mergeBreakpoints(breakpoints, bpKey, rules)
      })
    } else if (isMediaProp(stylePropKey)) {
      const bpKey = stylePropKey
      const rules = stylePropVal

      parsedObj[bpKey] = mergeBreakpoints(breakpoints, bpKey, rules)
    } else if (isShorthandMediaProp(stylePropKey)) {
      const [, minOrMax, namedBpKey] = bpPropRegExp.exec(stylePropKey)
      const bpKey = `@media (${minOrMax}-width: ${breakpoint(namedBpKey)(
        props
      )})`
      const rules = stylePropVal

      parsedObj[bpKey] = mergeBreakpoints(breakpoints, bpKey, rules)
    } else if (isSpecialProp(stylePropKey, stylePropVal)) {
      parsedObj[stylePropKey] = parse(stylePropVal, props)
    } else if (isResponsiveProp(stylePropVal)) {
      const sortedBreakpointsObj = sortBreakpoints(stylePropVal, props)

      entries(sortedBreakpointsObj).forEach(([bpKey, bpVal]) => {
        const finalBpKey = /@media.+/.test(bpKey)
          ? bpKey
          : `@media (min-width: ${bpKey})`
        const rules = { [stylePropKey]: withUnit(stylePropKey, bpVal) }

        parsedObj[finalBpKey] = mergeBreakpoints(breakpoints, finalBpKey, rules)
      })
    } else {
      parsedObj[stylePropKey] = withUnit(stylePropKey, stylePropVal)
    }
  })

  return parsedObj
}

// in parse() look for /when.*/.test(prop)
// and there merge breakpoints
const when = props => (prop, ifTrue = {}, ifFalse = {}) => {
  const propIsBreakpointsObj = typeof prop === "object"
  const propValueIsObj = typeof ifTrue === "object"
  const propValueIsFun = typeof ifTrue === "function"

  if (propIsBreakpointsObj && propValueIsObj) {
    const breakpointsObj = prop
    const sortedBreakpointsObj = sortBreakpoints(breakpointsObj, props)
    const sortedBreakpointsLength = Object.keys(sortedBreakpointsObj).length
    const breakpointPairs = []

    for (let i = 0; i < sortedBreakpointsLength; i += 2) {
      breakpointPairs.push([
        Object.keys(sortedBreakpointsObj)[i],
        Object.keys(sortedBreakpointsObj)[i + 1]
      ])
    }

    const breakpointsToMergeInParse = breakpointPairs.reduce(
      (acc, [minKey, maxKey]) => {
        const minWidth = `(min-width:${minKey})`
        const maxWidth = maxKey ? `and (max-width:${maxKey})` : ""
        const bpKey = `@media ${minWidth} ${maxWidth}`

        acc[bpKey] = ifTrue
        return acc
      },
      {}
    )

    return { [`breakpointsToMerge${Math.random()}`]: breakpointsToMergeInParse }
  }

  if (propIsBreakpointsObj && propValueIsFun) {
    const breakpointsObj = prop
    const sortedBreakpointsObj = sortBreakpoints(breakpointsObj, props)
    const propFun = ifTrue

    const breakpointsToMergeInParse = entries(sortedBreakpointsObj).reduce(
      (acc, [namedBpKey, bpVal]) => {
        const bpKey = `@media (min-width: ${namedBpKey})`
        const rules = propFun(rem(bpVal), bpVal)

        acc[bpKey] = rules
        return acc
      },
      {}
    )

    return { [`when${Math.random()}`]: breakpointsToMergeInParse }
  }

  if (propValueIsFun) {
    const propFun = ifTrue
    return prop ? propFun(rem(prop), prop) : ifFalse
  }

  return prop ? ifTrue : ifFalse
}

const variant = (prop, optionsObject) => optionsObject[prop]

const scale = props => (...scales) => {
  return scales.reduce((breakpointsObj, scale) => {
    if (Object.keys(scale).length === 1) {
      breakpointsObj = { ...breakpointsObj, ...scale }
    } else {
      const [minKey, maxKey] = Object.keys(scale)
      const minKeyRaw = breakpointRaw(minKey)(props)
      const maxKeyRaw = breakpointRaw(maxKey)(props)
      const minVal = scale[minKey]
      const maxVal = scale[maxKey]

      breakpointsObj[minKey] = `
          calc(
            ${rem(minVal)} + (${maxVal / 16} - ${minVal / 16})
            * (100vw - ${rem(minKeyRaw)})
            / (${maxKeyRaw / 16} - ${minKeyRaw / 16})
          )
        `
    }

    return breakpointsObj
  }, {})
}

const styled = (is, options) => stylesObjOrFun => {
  const Component = typeof is === "string" ? Box.is(is) : is
  const stylesFunForEmotion = props => {
    const tools = {
      when: when(props),
      scale: scale(props),
      variant,
      rem,
      em
    }
    const stylesToParse =
      typeof stylesObjOrFun === "object"
        ? { ...stylesObjOrFun, ...props.css }
        : { ...stylesObjOrFun(props, tools), ...props.css }

    return parse(stylesToParse, props)
  }

  return emotionStyled(Component, options)(stylesFunForEmotion)
}

export default styled
