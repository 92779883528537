import React, { Component } from 'react'

const NavbarTheme = React.createContext({})

export const NavbarThemeConsumer = NavbarTheme.Consumer

export class NavbarThemeProvider extends Component {
  changeTheme = newTheme =>
    newTheme !== this.state.theme && this.setState({ theme: newTheme })

  changeDelay = newDelay =>
    newDelay !== this.state.delay && this.setState({ delay: newDelay })

  state = {
    theme: 'dark',
    delay: false,
    changeTheme: this.changeTheme,
    changeDelay: this.changeDelay,
  }

  render() {
    return (
      <NavbarTheme.Provider value={this.state}>
        {this.props.children}
      </NavbarTheme.Provider>
    )
  }
}

export const NavbarThemeSwitch = ({ theme, delay }) => (
  <NavbarThemeConsumer>
    {({ changeTheme, changeDelay }) => {
      changeTheme(theme)
      changeDelay(delay)
    }}
  </NavbarThemeConsumer>
)
