import React from 'react'
import styled from 'react-emotion'
import { min } from 'spaced-components'
import Timeline from './Timeline'

const IntroTextWrapper = styled('h1')`
  position: relative;
  font-style: italic;
  font-weight: 800;
  font-family: Dinosaur, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 18vw;
  ${min('mobileLg')} {
    font-size: 18vw;
  }
  ${min('tabletSm')} {
    font-size: 9vw;
  }
  line-height: 1.1;
  ${min('mobileLg')} {
    line-height: 1.05;
  }
`

function shuffle(o) {
  for (
    var j, x, i = o.length;
    i;
    j = parseInt(Math.random() * i, 10), x = o[--i], o[i] = o[j], o[j] = x
  );
  return o
}

const IntroText = props => (
  <Timeline
    didMount={({
      timeline,
      refs: { wrapper },
      gsap: { SplitText, TweenMax, Power2 },
    }) => {
      const text = new SplitText(wrapper, { type: 'words, chars' })
      shuffle(text.chars)

      TweenMax.set(text.chars, {
        opacity: 0,
        x: 20,
        ease: Power2.easeOut,
      })

      timeline.staggerTo(text.chars, 0.5, { opacity: 1, x: 0 }, 0.04)
    }}
  >
    {({ ref }) => <IntroTextWrapper innerRef={ref('wrapper')} {...props} />}
  </Timeline>
)

export default IntroText
