import { Component } from "react"

class WhenTabIsInactive extends Component {
  state = { showQuote: false }

  componentDidMount() {
    window.addEventListener("focus", this.onFocus)
    window.addEventListener("blur", this.onBlur)
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus)
    window.removeEventListener("blur", this.onBlur)
  }

  onFocus = () => {
    clearTimeout(this.quotesTimeout)
  }

  onBlur = () => {
    this.quotesTimeout = setTimeout(() => {
      if (this.props.showOnce) {
        window.removeEventListener("focus", this.onFocus)
        window.removeEventListener("blur", this.onBlur)
      }

      this.setState({ showQuote: true })
    }, this.props.showAfter || 0)
  }

  render() {
    return this.props.children(this.state.showQuote)
  }
}

export default WhenTabIsInactive
