import React from 'react'
import styled from 'react-emotion'

const LogoCell = styled(({ children, solid, color, ...props }) => (
  <div {...props}>
    <div>{children}</div>
  </div>
))`
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  background-color: #191919;
  transition: background-color 0.5s;

  ${p =>
    p.color &&
    `
    &:hover {
      background-color: ${p.color};
    }
  `};

  div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img {
    display: block;
    transition: transform 2s;
  }
`

LogoCell.defaultProps = {
  className: 'LogoCell',
}

export default LogoCell
