import React from 'react'
import styled from 'react-emotion'
import Toggle from 'react-toggled'
import { NavLink } from 'react-router-dom'
import {
  Box,
  Columns,
  Flex,
  inset,
  Inset,
  min,
  On,
  prop,
  Relative,
  rem,
  rule,
  Space,
  spaceProp,
  StackList,
} from 'spaced-components'
import { color, typo, duration } from '../styles/helpers'
import globalTheme from '../styles/theme'

import { NavbarThemeConsumer } from '../components/NavbarTheme'
import GSAPComponent from '../components/GSAPComponent'
import Logo from '../components/Logo'
import AdVictoremLogo from '../components/AdVictoremLogo'
import LinesPattern from '../components/LinesPattern'
import LinesCurtain from '../components/LinesCurtain'
import CurrentPageLabel from '../components/CurrentPageLabel'
import Hamburger from '../components/Hamburger'
import TextLink from '../components/TextLink'
import ContentColumn from '../components/ContentColumn'
import ScrollPosition from '../components/ScrollPosition'
import SocialIcons from '../components/SocialIcons'
import Play from '../components/Play'

const basename = process.env.PUBLIC_URL

const NavbarWrapper = styled(Box)`
  ${prop('height')};
  color: #fff;
  background-color: ${color('dark', 900)};
  transition: ${duration('normal')} background-color,
    ${duration('normal')} color;

  .linesPattern {
    transition-duration: ${duration('normal')};
  }

  ${min('laptopSm')} {
    background-color: ${p =>
      p.transparentBackground ? 'transparent' : color('dark', 700)};
    ${p => {
      if (p.transparentBackground && p.delay)
        return `
          transition-duration: 0s, ${duration('normal')(p)};
          transition-delay: 0s;
        `
      if (p.delay)
        return `
          transition-delay: ${duration('normal')(p)}, 0s;
          .linesPattern { transition-delay: ${duration('normal')(p)} }
        `
    }};

    ${p => p.darkText && `color: #000`};
    ${p => p.transparentBackground && `.linesPattern { opacity: 0 }`};
  }
`

const MobileNavWrapper = styled(Box)`
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: ${color('dark', 900)};
  transition-property: visibility, opacity;
  transition-timing-function: ease-out;
  transition-duration: ${duration('normal')};
  ${p =>
    p.active &&
    `opacity: 1;
    visibility: visible;
    transition-delay: ${duration('normal')(p)};
     transition-duration: ${duration('slow')(p)}`};
`

const MobileNavLinkInner = ({
  is: Is = NavLink,
  children,
  center,
  ...props
}) => (
  <Is {...props}>
    <TextLink is="span" wrapped>
      {children}
    </TextLink>
  </Is>
)

const MobileNavLink = styled(MobileNavLinkInner)`
  display: inline-block;
  width: 100%;
  ${inset('lg')};
  ${p => p.center && 'text-align: center'};
  -webkit-tap-highlight-color: transparent;
`

const DesktopNavListItem = styled('li')`
  overflow: hidden;
`

const DesktopNavLink = styled(NavLink)`
  display: inline-block;
  position: relative;
  width: 100%;
  ${inset({ mobileSm: ['lg', 20], laptopLg: ['lg', 30] })};
  ${typo('linkLg')};
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: ${rem(6)};
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: center top;
    transition-property: transform, opacity, background-color;
    transition-duration: ${duration('normal')};
    will-change: transform, opacity;
  }

  .theme--light &::before {
    background-color: ${color('dark', 900)};
  }

  .theme--dark &::before {
    background-color: ${color('light', 400)};
  }

  &.active::before,
  &:hover::before,
  &:focus::before {
    opacity: 1;
    transform: scaleY(1);
  }

  > span {
    display: none;
    ${min(1220)} {
      display: block;
    }
    margin-top: ${rem(4)};
    margin-bottom: ${rem(-12)};
    ${rule('font-size', {
      mobileSm: '60%',
      _1485: '80%',
      _1545: '90%',
      _1660: '100%',
    })};
    white-space: normal;
    opacity: 0;
    transition: opacity ${duration('normal')};
  }

  &:hover > span {
    opacity: 0.5;
  }

  > * {
    pointer-events: none;
  }
`

const MobileNavButton = styled(Box.is('button'))`
  ${prop('height')};
  ${spaceProp(val => `padding: ${val}`)};
  -webkit-tap-highlight-color: transparent;
`

const MobileNavLabel = styled(Box.is('span'))`
  margin-top: 2px;
  ${typo('linkSm')};
  line-height: 1;
  opacity: 0;
  visibility: hidden;
  ${p =>
    p.active &&
    `opacity: 1;
     visibility: visible;`};
  transition-property: visibility, opacity;
  transition-timing-function: ease-out;
  transition-duration: ${duration('normal')};
`

const MobileNavbar = () => (
  <Toggle>
    {({ on, getTogglerProps, setOff }) => (
      <Flex height="100%" middle>
        <Space mobileSm={10} />

        <Relative zIndex={1}>
          <NavLink to="/">
            <Logo width={rem(33)} onClick={setOff} />
          </NavLink>
        </Relative>

        <Flex.Item right />
        <Relative zIndex={1}>
          <MobileNavButton
            md
            height={globalTheme.navbarHeight}
            {...getTogglerProps()}
          >
            <Flex middle>
              <MobileNavLabel active={!on}>
                <CurrentPageLabel />
              </MobileNavLabel>
              <Space mobileSm={6} />
              <Hamburger active={on} />
            </Flex>
          </MobileNavButton>
        </Relative>

        <LinesCurtain active={on} />

        <MobileNavWrapper active={on}>
          <Relative>
            <Flex height="100%">
              <Flex.Item center>
                <Inset md>
                  <AdVictoremLogo />
                </Inset>
                <Inset y={{ mobileSm: '5vh' }}>
                  <StackList
                    md
                    center
                    height="80vh"
                    justifyContent="space-between"
                    onClick={setOff}
                  >
                    <MobileNavLink exact to="/" center>
                      WELCOME
                    </MobileNavLink>
                    <MobileNavLink to="/our-work" center>
                      OUR WORK
                    </MobileNavLink>
                    {/* <MobileNavLink to="/our-agency" center>
                      OUR AGENCY
                    </MobileNavLink> */}
                    <MobileNavLink to="/our-playbook" center>
                      OUR PLAYBOOK
                    </MobileNavLink>
                    <MobileNavLink to="/our-blog" center>
                      OUR BLOG
                    </MobileNavLink>
                    <MobileNavLink to="/contact" center>
                      YOUR TURN
                    </MobileNavLink>
                    <SocialIcons />
                  </StackList>
                </Inset>
              </Flex.Item>
            </Flex>
          </Relative>
        </MobileNavWrapper>
      </Flex>
    )}
  </Toggle>
)

class DesktopNavbar extends GSAPComponent {
  animate() {
    /* eslint-disable */
    const items = this.wrapper.querySelectorAll('li > a')
    const tl = new TimelineMax()

    tl.staggerFrom(
      items,
      1,
      {
        x: '-100%',
        opacity: 0,
        ease: Power3.easeInOut,
        delay: 0.5,
      },
      -0.07
    )

    return tl
    /* eslint-enable */
  }

  render() {
    return (
      <Play sounds={{ playOnClick: `${basename}/assets/sounds/click_18.wav` }}>
        {({ playOnClick }) => (
          <div ref={ref => (this.wrapper = ref)}>
            <ContentColumn>
              <Columns is="ul" columnCount={6}>
                <DesktopNavListItem>
                  <DesktopNavLink exact to="/" onClick={playOnClick}>
                    WELCOME
                  </DesktopNavLink>
                </DesktopNavListItem>
                <DesktopNavListItem>
                  <DesktopNavLink to="/our-work" onClick={playOnClick}>
                    OUR WORK
                    <span>CLIENTS + CASE STUDIES</span>
                  </DesktopNavLink>
                </DesktopNavListItem>
                {/* <DesktopNavListItem>
                  <DesktopNavLink to="/our-agency" onClick={playOnClick}>
                    OUR AGENCY
                    <span>PRINCIPALS + PRINCIPLES</span>
                  </DesktopNavLink>
                </DesktopNavListItem> */}
                <DesktopNavListItem>
                  <DesktopNavLink to="/our-playbook" onClick={playOnClick}>
                    OUR PLAYBOOK
                    <span>SERVICES + PROCESS</span>
                  </DesktopNavLink>
                </DesktopNavListItem>
                <DesktopNavListItem>
                  <DesktopNavLink to="/our-blog" onClick={playOnClick}>
                    OUR BLOG
                    <span>NEWS + VIEWS</span>
                  </DesktopNavLink>
                </DesktopNavListItem>
                <DesktopNavListItem>
                  <DesktopNavLink to="/contact" onClick={playOnClick}>
                    YOUR TURN
                    <span>Work With Us</span>
                  </DesktopNavLink>
                </DesktopNavListItem>
              </Columns>
            </ContentColumn>
          </div>
        )}
      </Play>
    )
  }
}

const Navbar = () => (
  <NavbarThemeConsumer>
    {({ theme, delay }) => (
      <ScrollPosition below={20}>
        {matches => (
          <NavbarWrapper
            is="nav"
            height={globalTheme.navbarHeight}
            darkText={theme === 'light' && !matches}
            delay={delay}
            className={
              theme === 'light' && !matches ? 'theme--light' : 'theme--dark'
            }
            transparentBackground={!matches}
          >
            <On max="laptopSm" dynamic>
              <MobileNavbar />
            </On>
            <On min="laptopSm" dynamic>
              <LinesPattern
                right={globalTheme.barWidth}
                className="linesPattern"
              />
              <DesktopNavbar />
            </On>
          </NavbarWrapper>
        )}
      </ScrollPosition>
    )}
  </NavbarThemeConsumer>
)

export default Navbar
