import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Inset, Break, Flex, Stack, On, Space } from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import Parallax from '../../components/Parallax'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'

import devices from './assets/devices.png'
import bg from './assets/bg.png'
import ipad from './assets/ipad.png'
import webpage from './assets/webpage.jpg'

const currentProject = getProject('dignity-health')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#d6763a' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  Discovering the healing power of awe
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  Surfing. It's all about connection. We created a microsite for
                  the Hello Humankindness™ campaign to introduce Dignity Health
                  to the surfing community as the title sponsor for The Pismo
                  Beach Open, a World Surf League – Qualifying Series 1,000
                  Contest that put Pismo Beach on the map as one of the top
                  California surf destinations.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Dignity Health</DetailValue>
                </li>
                <li>
                  <Detail muted>Agency:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Dirango</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Health</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Website Design</DetailValue>
                </li>
              </Stack>
            }
          />
          <Space y={-30} />
          <AutoSlide up>
            <Box css={{ width: { tablet: '80%' }, margin: 'auto' }}>
              <Img
                src={devices}
                ratio={821 / 1380}
                style={{ marginBottom: '-40vmin' }}
              />
            </Box>
          </AutoSlide>
        </Inset>
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Space y="40vmin" />
        <Inset x={{ mobile: 'lg', tabletMd: 0 }} style={{ textAlign: 'left' }}>
          <Box css={{ width: '66.666%', margin: 'auto' }}>
            <Space x={{ tabletSm: '-5.55vmax' }}>
              <WhenVisible>
                {inView => (
                  <On min="tabletSm">
                    <BackgroundText is="h4" animate={inView}>
                      DESIGN
                    </BackgroundText>
                  </On>
                )}
              </WhenVisible>
            </Space>
            <Space y={{ tabletSm: '-6.35vmax' }} />
            <AutoFade>
              <Img src={webpage} ratio={2591 / 1133} />
            </AutoFade>
          </Box>
        </Inset>
        <Space xl tablet="xxl" />
        <Box
          css={{
            padding: '0 16.666% 20vw',
            background: `url(${bg}) center / cover`,
          }}
        >
          <AutoSlide up>
            <Img src={ipad} ratio={740 / 1019} />
          </AutoSlide>
        </Box>
        <Parallax direction="both" height="65vh" maxHeight={700}>
          <Parallax.Background height="150%">
            <Box css={{ height: '100%', backgroundColor: '#d6763a' }}>
              <Box
                css={{
                  width: { mobile: '200%', tabletLg: '50%' },
                }}
              >
                <svg
                  viewBox="0 0 20.6 18.5"
                  fill="#b66431"
                  style={{ margin: '20% 0 0 -30%' }}
                >
                  <path
                    d="M0.2,8.5C0,8.8-0.1,9.1,0.1,9.4c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0.1-0.1c0,0,0,0,0,0 l0,0c0.2,0.2,0.4,0.3,0.6,0.4c1.9,1.3,4.5,1.2,6.4,0c0.1,0.1,0.1,0.2,0.2,0.3C8,11,8,11.1,8.1,11.2C5.9,12,4.4,14,4.3,16.3 c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0.1,0.8c0.1,0.4,0.5,0.7,0.9,0.6c0.5-0.1,1-0.3,1.5-0.5c2.4-1.2,3.6-3.8,3.1-6.4  c-0.2-1-0.7-1.9-1.3-2.6c-1.8-2-4.6-2.5-7-1.3C1.1,7.9,0.6,8.2,0.2,8.5z M6.1,16.6c-0.1,0-0.2,0.1-0.3,0.1l0-0.3 c0.1-1.6,1.1-3.1,2.6-3.7C8.5,14.3,7.6,15.8,6.1,16.6z M6.7,9.4c-1.4,0.8-3.1,0.8-4.5-0.1L2,9.1C2,9.1,2.1,9,2.2,9 C3.7,8.3,5.4,8.4,6.7,9.4z M19.1,7.6c-2.4-1.2-5.2-0.7-7,1.3c-0.7,0.7-1.1,1.6-1.3,2.6c-0.6,2.6,0.7,5.2,3.1,6.4 c0.5,0.2,1,0.4,1.5,0.5c0.4,0.1,0.8-0.2,0.9-0.6c0-0.3,0.1-0.5,0.1-0.8c0-0.3,0-0.5,0-0.8c-0.1-2.3-1.6-4.3-3.8-5.2 c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.3c1.9,1.3,4.4,1.3,6.4,0c0.2-0.1,0.4-0.3,0.6-0.4l0,0c0,0,0,0,0,0c0,0,0,0,0.1,0.1 c0.2-0.2,0.4-0.4,0.6-0.6c0-0.1,0.1-0.1,0.1-0.2c0.2-0.3,0.1-0.7-0.2-0.9C20,8.2,19.5,7.9,19.1,7.6z M12.2,12.7 c1.5,0.6,2.5,2.1,2.6,3.7l0,0.3c-0.1,0-0.2-0.1-0.3-0.1C13,15.8,12.1,14.3,12.2,12.7z M18.4,9c0.1,0,0.2,0.1,0.2,0.1l-0.2,0.2  c-1.4,0.9-3.1,0.9-4.5,0.1C15.2,8.4,16.9,8.3,18.4,9z M15.9,0.6C15.8,0.3,15.5,0,15.2,0C15.1,0,15,0,15,0c-0.3,0.1-0.5,0.2-0.8,0.3 c0,0,0,0.1,0,0.1c0,0,0,0,0,0l0,0c-0.2,0.1-0.5,0.2-0.7,0.3c-2,1.2-3.1,3.4-2.9,5.7c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.4,0 c0.3-2.3-0.9-4.5-2.9-5.7C6.9,0.5,6.6,0.4,6.4,0.3C6.2,0.2,5.9,0.1,5.7,0C5.3-0.1,4.8,0.2,4.7,0.6c-0.1,0.5-0.2,1-0.2,1.5 c0,2.6,1.8,5,4.3,5.6c1,0.3,2,0.3,2.9,0c2.6-0.7,4.3-3,4.3-5.6C16.1,1.6,16,1.1,15.9,0.6z M6,2.1c0-0.1,0-0.2,0-0.3L6.3,2 c1.4,0.8,2.2,2.4,2.1,4C7,5.3,6,3.8,6,2.1z M12.2,5.9c-0.1-1.6,0.7-3.1,2.1-4l0.3-0.1c0,0.1,0,0.2,0,0.3C14.6,3.8,13.6,5.2,12.2,5.9
                z"
                  />
                </svg>
              </Box>
            </Box>
          </Parallax.Background>
          <Parallax.Content>
            <Flex height="100%" center middle>
              <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
                <Box css={{ textAlign: 'center' }}>
                  <Title is="blockquote" css={{ maxWidth: '22em' }}>
                    WSL’s inaugural event drew hundreds of spectators to the
                    Pismo Beach pier and around 40,000 viewers to the live
                    webcast.
                  </Title>
                </Box>
              </Inset>
            </Flex>
          </Parallax.Content>
        </Parallax>
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
