import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Inset, Break, Flex, Stack, Space, On } from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import Parallax from '../../components/Parallax'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'
// import LinesPattern from '../../components/LinesPattern'

import CaseStudyLayout, {
  boxShadow,
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'
import person1 from './assets/person1.png'
import person2 from './assets/person2.png'
import person3 from './assets/person3.png'
import person4 from './assets/person4.png'
import parallaxBg01 from './assets/parallax-bg-01.png'
import parallaxBg02 from './assets/parallax-bg-02.png'
import devices from './assets/devices.png'
import icon from './assets/icon.png'
import mobile01 from './assets/mobile-01.png'
import mobile02 from './assets/mobile-02.png'
import newLogo from './assets/new-logo.png'
import oldLogo from './assets/old-logo.png'
import theAthlete from './assets/the-athlete.png'
import theChef from './assets/the-chef.png'
import webpage from './assets/webpage.png'

const currentProject = getProject('plum-lending')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: currentProject.color }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  Marketing to the new adventurers
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  Plum is for the new adventurers — the ones hungry to invest in
                  something truly big, not just another food truck, YouTube
                  channel, or messaging app. They've been there, done that, made
                  their money, and now want to really make a mark.
                </Paragraph>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  The new adventurers are also hardcore optimists — they have to
                  be. They don't have the backing of private equity funds or
                  family fortunes or foreign governments. They can't afford the
                  usual "safe" deals in the usual megatropolis locations. (A
                  high-rise hotel in Beverly Hills isn't even on their wish
                  list.)
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Plum Lending</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Financial</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Brand Strategy
                    <Break />
                    Brand Messaging
                    <Break />
                    Brand Identity
                    <Break />
                    Marketing Planning
                    <Break />
                    Web Design + Development
                  </DetailValue>
                </li>
              </Stack>
            }
          />
        </Inset>
        <Box
          css={{
            width: {
              mobile: '80%',
              tabletMd: '66.666%',
            },
            position: 'relative',
            margin: '-1vw auto -13.7vw',
          }}
        >
          <AutoSlide up style={{ width: '29%', ...boxShadow() }}>
            <Img src={person1} ratio={544 / 325} />
          </AutoSlide>
          <AutoSlide
            up
            delay={1 * 150}
            style={{
              width: '29%',
              position: 'absolute',
              top: '20%',
              left: '24%',
              ...boxShadow(),
            }}
          >
            <Img src={person2} ratio={544 / 325} />
          </AutoSlide>
          <AutoSlide
            up
            delay={2 * 150}
            style={{
              width: '29%',
              position: 'absolute',
              top: '3%',
              left: '47%',
              ...boxShadow(),
            }}
          >
            <Img src={person3} ratio={544 / 325} />
          </AutoSlide>
          <AutoSlide
            up
            delay={3 * 150}
            style={{
              width: '29%',
              position: 'absolute',
              top: '30%',
              left: '71%',
              ...boxShadow(),
            }}
          >
            <Img src={person4} ratio={544 / 325} />
          </AutoSlide>
        </Box>
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Space y="22.3vw" />
        <Space xl tabletMd="xxl" />
        <WhenVisible>
          {inView => (
            <Fragment>
              <On min="tabletSm">
                <Space x={{ tabletSm: '-5.55vmax' }}>
                  <Box
                    css={{
                      width: { mobile: '80%', tabletMd: '66.666%' },
                      margin: 'auto',
                    }}
                  >
                    <BackgroundText
                      is="h4"
                      animate={inView}
                      style={{ textAlign: 'left' }}
                    >
                      STRATEGY
                    </BackgroundText>
                  </Box>
                </Space>
              </On>
              <Space y={{ tabletSm: '-7vw' }} />
              <Box
                css={{
                  width: { mobile: '80%', tabletMd: '66.666%' },
                  margin: 'auto',
                }}
              >
                <Box css={{ width: '70%' }}>
                  <AutoFade>
                    <Title is="h4" css={{ maxWidth: '16em' }}>
                      Putting audiences first
                    </Title>
                  </AutoFade>
                  <Space lg />
                  <AutoFade>
                    <Paragraph>
                      Why? Because the digital world thinks audience first, not
                      media first. Brands who wait to consider how audiences
                      evolve will risk far more than their competitors who
                      continuously invest in brand analysis and audience
                      outreach. While still supporting experienced CRE
                      investors, we positioned Plum to serve a new generation of
                      affluent young professionals seeking investments more
                      tangible and personal than stocks and bonds.
                    </Paragraph>
                  </AutoFade>
                </Box>
              </Box>
            </Fragment>
          )}
        </WhenVisible>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <Box
            css={{
              width: { mobile: '100%', tabletMd: '84%', _1200: '63%' },
              position: 'relative',
            }}
          >
            <AutoFade>
              <Img src={theAthlete} ratio={482 / 723} />
            </AutoFade>
            <AutoFade delay={100}>
              <Box
                css={{
                  width: { mobile: '100%', tabletMd: '60%' },
                  position: { tabletMd: 'absolute' },
                  bottom: '-10%',
                  right: '-19%',
                  padding: 30,
                  backgroundColor: '#421690',
                }}
              >
                <Title
                  is="h3"
                  css={{
                    marginTop: '-1.5em',
                    whiteSpace: 'nowrap',
                    display: 'block',
                    marginBottom: 15,
                    fontSize: 35,
                  }}
                >
                  The Athlete
                </Title>
                <Paragraph css={{ fontSize: 14 }}>
                  A young star of a professional sports team, large guaranteed
                  salary over multiple years, needs a moderate investment but
                  not multiple houses, bored with most "deals" that come through
                  his business manager, wants to be part of the community he
                  plays in.
                </Paragraph>
              </Box>
            </AutoFade>
          </Box>
          <Space xl tabletMd="xxl" />
          <Box
            css={{
              width: { mobile: '100%', tabletMd: '84%', _1200: '63%' },
              marginLeft: { _1200: '25%' },
              position: 'relative',
            }}
          >
            <AutoFade>
              <Img src={theChef} ratio={482 / 723} />
            </AutoFade>
            <AutoFade delay={100}>
              <Box
                css={{
                  width: { mobile: '100%', tabletMd: '60%' },
                  position: { tabletMd: 'absolute' },
                  bottom: '-10%',
                  right: '-19%',
                  padding: 30,
                  backgroundColor: '#421690',
                }}
              >
                <Title
                  is="h3"
                  css={{
                    marginTop: '-1.5em',
                    whiteSpace: 'nowrap',
                    display: 'block',
                    marginBottom: 15,
                    fontSize: 35,
                  }}
                >
                  The Chef
                </Title>
                <Paragraph css={{ fontSize: 14 }}>
                  A young chef who launched a successful food truck and now
                  wants to buy his own restaurant, his financial partners have
                  just enough for the down payment, very particular about
                  property and takes pride in ownership.
                </Paragraph>
              </Box>
            </AutoFade>
          </Box>
        </Box>
        <Space xl tabletMd="xxl" />
        <WhenVisible>
          {inView => (
            <Box css={{ color: '#000', backgroundColor: '#fff' }}>
              <Space xl tabletMd="xxl" />
              <On min="tabletSm">
                <Space x={{ tabletSm: '-5.55vmax' }}>
                  <Box
                    css={{
                      width: { mobile: '80%', tabletMd: '66.666%' },
                      margin: 'auto',
                    }}
                  >
                    <BackgroundText
                      is="h4"
                      animate={inView}
                      style={{ textAlign: 'left', opacity: 0.07 }}
                    >
                      IDENTITY
                    </BackgroundText>
                  </Box>
                </Space>
              </On>
              <Space y={{ tabletSm: '-7vw' }} />
              <Box
                css={{
                  width: { mobile: '80%', tabletMd: '66.666%' },
                  margin: 'auto',
                }}
              >
                <Box
                  css={{
                    maxWidth: '40em',
                    margin: 'auto',
                    textAlign: 'center',
                  }}
                >
                  <AutoFade>
                    <Title is="h4">Creating an icon</Title>
                  </AutoFade>
                  <Space lg />
                  <AutoFade>
                    <Paragraph>
                      For digital-forward brands such as Plum, the app icon is
                      king. Plum’s previous logo wasn’t quite suited to serve as
                      a stand-alone icon. We modernized the fruit illustration
                      so the logo could be better suited for digital
                      applications.
                    </Paragraph>
                  </AutoFade>
                </Box>
              </Box>
            </Box>
          )}
        </WhenVisible>
        <Box
          css={{
            display: 'flex',
            flexDirection: { mobile: 'column', tablet: 'row' },
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '13% 10%',
            margin: 'auto',
            color: '#000',
            backgroundColor: '#fff',
            'max:tablet': {
              '> *': {
                width: '100%',
                margin: '8% 0 !important',
                flex: 'initial !important',
              },
            },
          }}
        >
          {/* <LinesPattern /> */}
          <AutoFade
            style={{ flex: 1, marginTop: '-10%', transform: 'scale(0.75)' }}
          >
            <Img src={oldLogo} ratio={214 / 496} />
          </AutoFade>
          <On min="tablet" style={{ flex: 0.3, margin: '0 7%' }}>
            <AutoFade delay={1 * 150}>
              <svg viewBox="0 0 94.728 21.309" fill="#bbb">
                <path d="M94.605,10.99c0.028-0.036,0.053-0.073,0.071-0.113c0.012-0.026,0.021-0.051,0.029-0.079c0.009-0.031,0.013-0.061,0.016-0.091c0.003-0.032,0.008-0.062,0.006-0.093c-0.002-0.023-0.01-0.045-0.015-0.069c-0.013-0.061-0.036-0.119-0.07-0.173c-0.016-0.025-0.031-0.05-0.05-0.071c-0.009-0.011-0.014-0.025-0.025-0.035L84.095,0.149c-0.214-0.203-0.554-0.198-0.758,0.015c-0.203,0.214-0.199,0.554,0.015,0.758l9.524,9.196H0.534C0.239,10.118,0,10.357,0,10.652c0,0.296,0.239,0.535,0.534,0.54h92.335l-9.517,9.196c-0.214,0.203-0.219,0.544-0.015,0.758c0.107,0.107,0.244,0.163,0.387,0.163c0.132,0,0.269-0.051,0.371-0.153l10.473-10.117c0.003-0.003,0.005-0.007,0.008-0.009c0.003-0.003,0.005-0.003,0.008-0.006C94.593,11.014,94.597,11.001,94.605,10.99" />
              </svg>
            </AutoFade>
          </On>
          <AutoFade
            delay={2 * 150}
            style={{ flex: 1, transform: 'scale(0.75)' }}
          >
            <Img src={newLogo} ratio={184 / 486} />
          </AutoFade>
        </Box>
        <Parallax direction="both" height="80vh" maxHeight={650}>
          <Parallax.Background
            height="150%"
            style={{ backgroundColor: '#111' }}
          >
            <img
              src={parallaxBg01}
              alt=""
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Parallax.Background>
          <Parallax.Content>
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                padding: '0 8%',
              }}
            >
              <Title is="blockquote" css={{ maxWidth: '13em' }}>
                Modernizing Plum’s visual identity helped move the brand forward
                into the digital era.
              </Title>
            </Box>
          </Parallax.Content>
        </Parallax>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <WhenVisible>
            {inView => (
              <Box>
                <On min="tabletSm">
                  <Space x={{ tabletSm: '-5.55vmax' }}>
                    <BackgroundText is="h4" animate={inView}>
                      DIGITAL
                    </BackgroundText>
                  </Space>
                </On>
                <Space y={{ tabletSm: '-7vw' }} />
                <Box css={{ display: 'flex' }}>
                  <Box
                    css={{
                      width: { mobile: '100%', tabletMd: '50%' },
                    }}
                  >
                    <AutoFade>
                      <Img
                        src={icon}
                        ratio={1}
                        style={{
                          width: 74,
                          height: 74,
                          borderRadius: 10,
                          overflow: 'hidden',
                        }}
                      />
                    </AutoFade>
                    <Space lg />
                    <AutoFade>
                      <Title is="h4">#GetPlum</Title>
                    </AutoFade>
                    <Space lg />
                    <AutoFade>
                      <Paragraph css={{ paddingRight: { tabletMd: 15 } }}>
                        Besides requesting an instant loan quote, we wanted to
                        create more opportunity for Plum to connect with their
                        customers. We conceived a commercial property search app
                        that enabled curious investors and adventurers to type
                        in a commercial property address, get estimated monthly
                        payment and evaluation of city/neighborhood. Users also
                        have access to watch videos, read content, access
                        bulletin board and apply for loans all within the app.
                      </Paragraph>
                    </AutoFade>
                  </Box>
                  <Box
                    css={{
                      display: { mobile: 'none', tabletMd: 'flex' },
                      width: '50%',
                      alignItems: 'flex-start',
                      '> *': { flex: 1, margin: '0 10px' },
                    }}
                  >
                    <AutoSlide up style={{ marginTop: '-80px' }}>
                      <Img src={mobile01} ratio={532 / 266} />
                    </AutoSlide>
                    <AutoSlide up delay={100}>
                      <Img src={mobile02} ratio={532 / 266} />
                    </AutoSlide>
                  </Box>
                </Box>
              </Box>
            )}
          </WhenVisible>
        </Box>
        <Space xl tabletMd="xxl" />
      </Box>
      <Box css={{ backgroundColor: '#421690' }}>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <WhenVisible>
            {inView => (
              <Box>
                <On min="tabletSm">
                  <Space x={{ tabletSm: '-5.55vmax' }}>
                    <BackgroundText
                      is="h4"
                      animate={inView}
                      style={{ opacity: 0.1 }}
                    >
                      WEBSITE
                    </BackgroundText>
                  </Space>
                </On>
                <Space y={{ tabletSm: '-7vw' }} />
                <AutoFade>
                  <Img src={devices} ratio={896 / 1254} />
                </AutoFade>
              </Box>
            )}
          </WhenVisible>
        </Box>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            maxWidth: '40em',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          <AutoFade>
            <Title is="h4">From the ground up</Title>
          </AutoFade>
          <Space lg />
          <AutoFade>
            <Paragraph>
              We approached the redesign of the website from the ground up. From
              the overall layout to the smallest details, such as the user
              experience and call to action messaging — the website was key to
              establishing the new positioning and identity.
            </Paragraph>
          </AutoFade>
        </Box>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <AutoFade>
            <Img src={webpage} ratio={4395 / 1133} />
          </AutoFade>
        </Box>
        <Space xl tabletMd="xxl" />
      </Box>
      <Parallax direction="both" height="80vh" maxHeight={650}>
        <Parallax.Background height="150%" style={{ backgroundColor: '#111' }}>
          <img
            src={parallaxBg02}
            alt=""
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Parallax.Background>
        <Parallax.Content>
          <Flex height="100%" center middle>
            <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
              <Title
                is="blockquote"
                css={{ maxWidth: '20em', textAlign: 'center' }}
              >
                We proposed Plum’s long game strategy to include a college bowl
                game sponsorship, reaching a national audience made up of
                wealthy alumni, former athletes, and business students.
              </Title>
            </Inset>
          </Flex>
        </Parallax.Content>
      </Parallax>
    </CaseStudyLayout>
  </Fragment>
)
