import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Inset, Break, Flex, Stack, Space } from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import Parallax from '../../components/Parallax'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'
import panels from './assets/panels.png'
import backgroundImage from './assets/background-image.jpg'
import quoteBackground from './sungreen.svg'

const currentProject = getProject('sungreen-systems')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#6d7900' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  Taking green to the next level
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  SunGreen consisted of a team of architects who designed and
                  installed solar energy solutions for residential clients in
                  Southern California. They provide customized, solar-power
                  systems for homes and businesses of all sizes — all backed by
                  meticulous attention to quality and service.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>SunGreen Systems</DetailValue>
                </li>
                <li>
                  <Detail muted>Agency:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Atomic Tango</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Technology</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Brand Strategy</DetailValue>
                </li>
              </Stack>
            }
          />
        </Inset>
        <AutoSlide up>
          <Img
            src={panels}
            ratio={805 / 1031}
            style={{ width: '70%', margin: '-5vw auto -16.7vw' }}
          />
        </AutoSlide>
      </Box>
      <Box css={{ background: `url(${backgroundImage}) center / cover` }}>
        <Inset x={{ mobile: 'lg', tabletMd: '16.666%' }}>
          <Space y={{ mobile: 100, tablet: 200, laptop: 280 }} />
          <AutoFade>
            <Box css={{ textAlign: { tablet: 'right' } }}>
              <Title is="h3">Our Challenge</Title>
              <Space y="md" />
              <Paragraph css={{ maxWidth: '30em', marginLeft: 'auto' }}>
                The company found itself undercut by independent contractors
                ("guys with a pickup truck and a ladder") and heavily funded
                ventures that could offer financing (such as Solar City).
              </Paragraph>
            </Box>
          </AutoFade>
          <Space y={40} />
          <AutoFade>
            <Box>
              <Title is="h3">Our Solution</Title>
              <Space y="md" />
              <Paragraph css={{ maxWidth: '40em' }}>
                Since residential customers were price-centric and didn't need
                architectural expertise, SunGreen pivoted to focus on marketing
                to businesses. To support this new position, the brand changed
                from "Solar Power That Makes Cents" (literally counting pennies)
                to "The Smart Approach To Solar" (underscoring the expertise).
              </Paragraph>
            </Box>
          </AutoFade>
          <Space y={{ mobile: 100, tablet: 200 }} />
        </Inset>
      </Box>
      <Parallax direction="both" height="70vh" maxHeight={600}>
        <Parallax.Background height="150%">
          <Box css={{ height: '100%', backgroundColor: '#6d7800' }}>
            <Box
              css={{
                width: { mobile: '200%', tabletLg: '100%' },
                position: 'relative',
                top: { mobile: '50%', tabletLg: '50%' },
                left: { mobile: '-50%', tabletLg: 0 },
              }}
            >
              <Img src={quoteBackground} ratio={31.39 / 92.6} />
            </Box>
          </Box>
        </Parallax.Background>
        <Parallax.Content>
          <Flex height="100%" center middle>
            <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
              <Box css={{ textAlign: 'center' }}>
                <Title is="blockquote" css={{ maxWidth: '22em' }}>
                  SunGreen started landing large, profitable enterprise
                  accounts, including hotels and warehouses.
                </Title>
              </Box>
            </Inset>
          </Flex>
        </Parallax.Content>
      </Parallax>
    </CaseStudyLayout>
  </Fragment>
)
