import React, { Fragment } from "react"
import Helmet from "react-helmet"
import { NavbarThemeSwitch } from "../../components/NavbarTheme"
import styled from "react-emotion"
import Component from "@reach/component-component"
import { Route, Link } from "react-router-dom"
import {
  Relative,
  Space,
  Stack,
  StackList,
  Flex,
  Inline,
  Inset,
  On,
  rule,
  rem
} from "spaced-components"
import { typo } from "../../styles/helpers"
import Layout from "../../Layout"
import LinesPattern from "../../components/LinesPattern"
import MobileTile from "../../components/MobileTile"
import Title from "../../components/Title"
import Img from "../../components/Img"
import TextLink from "../../components/TextLink"
import ScrollToLink from "../../components/ScrollToLink"
import WhenVisible from "../../components/WhenVisible"
import GSAPComponent from "../../components/GSAPComponent"
import ImagesLoaded from "../../components/ImagesLoaded"
import VerticalLine from "../../components/VerticalLine"
import { listedProjects, projectURL, projectImageURL } from "../../projects"

class StaggerLinks extends GSAPComponent {
  animate() {
    /* eslint-disable */
    const tl = new TimelineMax()

    tl.staggerFrom(
      ".item-link",
      0.5,
      {
        y: 30,
        opacity: 0
      },
      -0.08
    )

    return tl
    /* eslint-enable */
  }

  render() {
    return this.props.children
  }
}

const BackgroundImagesContainer = styled("div")`
  height: 100vh;
  position: fixed;
  top: 0;
  ${p => rule("right", p.theme.barWidth)(p)};
  ${p => rule("left", p.theme.barWidth)(p)};
  opacity: 0;
  transition: 1s opacity;
  will-change: opacity;
  ${p => p.loaded && "opacity: 1"};

  > * {
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
  }
`

// const initialZoom = keyframes`to { transform: scale(2) }`

const BackgroundImg = styled(Img)`
  ${p => p.active && "z-index: 1"};
  will-change: opacity;
  transition: 1s opacity, 600ms transform;
  opacity: ${p => (p.active ? 1 : 0)};
  overflow: hidden;
`
// .BackgroundImgHover:hover & img {
//   ${p =>
//     p.zoom && `transform: scale(2); transition: 1s opacity, 30s transform;`};
// }

const TagLabel = styled("span")`
  ${typo("linkLg")};
  ${p => !p.white && "opacity: 0.8"};
`

const TagSeparator = styled("span")`
  margin: 0 ${rem(10)};
  line-height: 1;
  opacity: 0.8;
`

const ItemLink = styled("a")`
  display: inline-block;
  text-align: center;
`

const FadeOut = styled("div")`
  transition: 600ms ease-out;
  ${p => p.active && "opacity: 0"};
`

export default () => (
  <Fragment>
    <Helmet>
      <title>Our Work</title>
      <meta name="description" content="Our work description" />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <Layout.Shell>
      <Relative className="BackgroundImgHover">
        <On max={769} dynamic>
          <LinesPattern />
          <Inset y={100}>
            <Stack mobileSm={20} overflowX="hidden">
              {listedProjects.map(project => (
                <MobileTile key={project.key} data={project} />
              ))}
            </Stack>
          </Inset>
        </On>
        <Route>
          {({ history }) => (
            <Component initialState={{ fadeOut: false }}>
              {({ state: { fadeOut }, setState }) => {
                const navigate = to => e => {
                  e.preventDefault()
                  setState({ fadeOut: true })
                  window.setTimeout(() => history.push(to), 600)
                }
                return (
                  <Fragment>
                    <On min="tabletMd" dynamic>
                      <Component
                        initialState={{
                          loaded: false,
                          active: listedProjects[0].key
                        }}
                      >
                        {({ state: { loaded, active }, setState }) => (
                          <Fragment>
                            <ImagesLoaded
                              handleAlways={() => setState({ loaded: true })}
                            >
                              <BackgroundImagesContainer loaded={loaded}>
                                {listedProjects.map(
                                  ({ key, title, color }, index) => {
                                    if (index === 0) {
                                      return (
                                        <BackgroundImg
                                          key={key}
                                          zoom={!fadeOut}
                                          active={active === key}
                                          color={color}
                                          src={projectImageURL(key)}
                                          alt={title}
                                        />
                                      )
                                    }

                                    return (
                                      <BackgroundImg
                                        key={key}
                                        active={active === key}
                                        color={color}
                                        src={projectImageURL(key)}
                                        alt={title}
                                      />
                                    )
                                  }
                                )}
                              </BackgroundImagesContainer>
                            </ImagesLoaded>
                            <LinesPattern color="rgba(255, 255, 255, .5)" />
                            <FadeOut active={fadeOut}>
                              <Relative zIndex={1}>
                                <Inset y={120}>
                                  <StaggerLinks>
                                    <StackList lg center>
                                      {listedProjects.map(
                                        ({
                                          key,
                                          title,
                                          tags,
                                          color,
                                          colors
                                        }) => (
                                          <ItemLink
                                            key={key}
                                            className="item-link"
                                            href={projectURL(key)}
                                            onClick={navigate(projectURL(key))}
                                          >
                                            <Flex column center md>
                                              <Title
                                                underlineColors={colors}
                                                onMouseOver={() =>
                                                  setState({ active: key })
                                                }
                                              >
                                                <span>{title}</span>
                                              </Title>
                                              <Inline center>
                                                {tags.map((tag, index) =>
                                                  index === 0 ? (
                                                    <TagLabel key={index}>
                                                      {tag}
                                                    </TagLabel>
                                                  ) : (
                                                    <Fragment key={index}>
                                                      <TagSeparator>
                                                        |
                                                      </TagSeparator>
                                                      <TagLabel>{tag}</TagLabel>
                                                    </Fragment>
                                                  )
                                                )}
                                              </Inline>
                                            </Flex>
                                          </ItemLink>
                                        )
                                      )}
                                    </StackList>
                                  </StaggerLinks>
                                </Inset>
                              </Relative>
                            </FadeOut>
                          </Fragment>
                        )}
                      </Component>
                    </On>
                    <FadeOut active={fadeOut}>
                      <Relative zIndex={2}>
                        <Flex column center>
                          <Link to="/clients">
                            <TextLink is="span">BRANDS WE WORK WITH</TextLink>
                          </Link>
                          <Space y={60} />
                          <ScrollToLink href="#">
                            <Flex column center>
                              <svg
                                style={{ width: 36, height: 36 }}
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#fff"
                                  d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
                                />
                              </svg>
                              <Space y={20} />
                              <WhenVisible rootMargin="0px" triggerOnce={false}>
                                {inView => (
                                  <VerticalLine animateToTop={inView} />
                                )}
                              </WhenVisible>
                            </Flex>
                          </ScrollToLink>
                        </Flex>
                      </Relative>
                    </FadeOut>
                  </Fragment>
                )
              }}
            </Component>
          )}
        </Route>
      </Relative>
    </Layout.Shell>
  </Fragment>
)
