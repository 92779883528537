import React from "react"
import GSAPComponent from "../../components/GSAPComponent"

export default class extends GSAPComponent {
  animate() {
    /* eslint-disable */
    const cbs = this.svg.querySelector("#cbs")
    const interactive = this.svg.querySelector("#interactive")
    const eye = this.svg.querySelector("#eye")
    const circle01 = this.svg.querySelector("#circle01")
    const circle02 = this.svg.querySelector("#circle02")
    const tl = new TimelineMax({ delay: 0.2 })
    const ease = Power1.easeInOut

    tl.staggerFrom(
      [cbs, interactive],
      1,
      { y: 35, opacity: 0, ease: Back.easeOut },
      0.1
    )

    tl.staggerFrom(
      [circle01, eye, circle02],
      1,
      {
        transformOrigin: "center",
        rotation: 20,
        scale: 0.5,
        opacity: 0,
        ease: Back.easeOut.config(2)
      },
      0.12,
      0
    )

    return tl
    /* eslint-enable */
  }

  render() {
    return (
      <svg
        ref={ref => (this.svg = ref)}
        viewBox="0 0 144.98 150"
        width={260}
        fill="#fff"
      >
        <path
          id="cbs"
          d="M2.08,98.493c1.207-1.233,2.875-1.904,4.6-1.85c2.129-0.171,4.195,0.766,5.47,2.48
	c0.587,0.819,0.933,1.785,1,2.79H10c-0.129-0.571-0.374-1.108-0.72-1.58c-0.631-0.694-1.544-1.062-2.48-1
	c-1.06-0.03-2.06,0.492-2.64,1.38c-0.724,1.167-1.073,2.528-1,3.9c-0.104,1.334,0.249,2.663,1,3.77
	c0.616,0.804,1.577,1.268,2.59,1.25c0.943,0.056,1.855-0.346,2.45-1.08c0.384-0.522,0.656-1.118,0.8-1.75h3.11
	c-0.208,1.53-0.935,2.942-2.06,4c-1.151,1.032-2.655,1.58-4.2,1.53c-1.892,0.089-3.725-0.67-5-2.07
	c-1.308-1.613-1.967-3.656-1.85-5.73C-0.142,102.323,0.607,100.147,2.08,98.493z M23,96.853h-7.27v15h6.73
	c0.712,0.006,1.422-0.061,2.12-0.2c1.149-0.223,2.149-0.925,2.75-1.93c0.449-0.715,0.682-1.545,0.67-2.39
	c0.017-0.756-0.181-1.502-0.57-2.15c-0.413-0.621-1.022-1.085-1.73-1.32c0.433-0.204,0.821-0.493,1.14-0.85
	c0.484-0.628,0.728-1.408,0.69-2.2c0.022-0.818-0.22-1.621-0.69-2.29C25.903,97.381,24.474,96.76,23,96.853z M22,99.523
	c0.588-0.015,1.176,0.063,1.74,0.23c0.541,0.234,0.866,0.794,0.8,1.38c0.057,0.519-0.178,1.027-0.61,1.32
	c-0.483,0.266-1.029,0.394-1.58,0.37h-3.64v-3.3H22z M22.4,105.333c0.5-0.021,1,0.057,1.47,0.23c0.647,0.263,1.051,0.913,1,1.61
	c0.081,0.769-0.315,1.51-1,1.87c-0.481,0.198-1,0.286-1.52,0.26h-3.64v-4L22.4,105.333z M30,107.283
	c-0.079,1.435,0.543,2.818,1.67,3.71c1.354,0.96,2.993,1.433,4.65,1.34c1.613,0.089,3.204-0.408,4.48-1.4
	c1.007-0.842,1.574-2.098,1.54-3.41c0.072-1.176-0.427-2.315-1.34-3.06c-0.776-0.543-1.659-0.914-2.59-1.09l-2.63-0.62
	c-0.688-0.129-1.359-0.337-2-0.62c-0.461-0.219-0.747-0.69-0.73-1.2c-0.016-0.553,0.267-1.073,0.74-1.36
	c0.604-0.357,1.3-0.528,2-0.49c0.641-0.019,1.278,0.111,1.86,0.38c0.737,0.356,1.207,1.101,1.21,1.92h3
	c0.026-1.427-0.632-2.78-1.77-3.64c-1.189-0.854-2.626-1.294-4.09-1.25c-1.551-0.103-3.086,0.371-4.31,1.33
	c-0.965,0.838-1.508,2.062-1.48,3.34c-0.096,1.269,0.476,2.497,1.51,3.24c1.019,0.57,2.128,0.959,3.28,1.15l1.6,0.35
	c0.725,0.124,1.427,0.361,2.08,0.7c0.417,0.231,0.671,0.674,0.66,1.15c0.022,0.824-0.537,1.551-1.34,1.74
	c-0.571,0.171-1.164,0.252-1.76,0.24c-0.983,0.106-1.961-0.231-2.67-0.92c-0.328-0.447-0.525-0.977-0.57-1.53H30z"
        />
        <path
          id="interactive"
          d="M52.41,111.903v-15H51v15H52.41z M57.35,106.063c0-0.347,0-0.68,0-1
	c0.033-0.342,0.111-0.678,0.23-1c0.101-0.282,0.242-0.548,0.42-0.79c0.185-0.255,0.408-0.481,0.66-0.67
	c0.262-0.198,0.552-0.357,0.86-0.47c0.34-0.123,0.699-0.184,1.06-0.18c0.785-0.076,1.565,0.19,2.14,0.73
	c0.472,0.624,0.7,1.4,0.64,2.18v7h1.25v-7.05c0.002-0.428-0.022-0.855-0.07-1.28c-0.057-0.432-0.218-0.844-0.47-1.2
	c-0.356-0.523-0.861-0.927-1.45-1.16c-0.589-0.231-1.217-0.347-1.85-0.34c-0.674-0.019-1.34,0.155-1.92,0.5
	c-0.569,0.362-1.09,0.796-1.55,1.29v-1.5h-1.24v10.78h1.24L57.35,106.063z M71.38,110.973l-0.51,0.1c-0.17,0.014-0.34,0.014-0.51,0
	c-0.206,0.009-0.413-0.018-0.61-0.08c-0.136-0.048-0.25-0.144-0.32-0.27c-0.075-0.149-0.116-0.313-0.12-0.48c0-0.193,0-0.423,0-0.69
	v-7.39h2v-1h-2v-3h-1.25v3h-1.52v1h1.53v7.88c-0.067,0.603,0.11,1.207,0.49,1.68c0.395,0.334,0.904,0.503,1.42,0.47
	c0.28,0.004,0.559-0.029,0.83-0.1l0.6-0.15L71.38,110.973z M82.54,106.773c0.12-1.581-0.323-3.154-1.25-4.44
	c-0.863-1.009-2.144-1.563-3.47-1.5c-0.719-0.011-1.43,0.143-2.08,0.45c-0.6,0.292-1.13,0.711-1.55,1.23
	c-0.443,0.54-0.782,1.157-1,1.82c-0.241,0.715-0.366,1.465-0.37,2.22c-0.007,0.75,0.091,1.497,0.29,2.22
	c0.173,0.65,0.476,1.259,0.89,1.79c0.423,0.512,0.961,0.916,1.57,1.18c0.702,0.309,1.463,0.459,2.23,0.44
	c0.608,0.012,1.213-0.08,1.79-0.27c0.488-0.165,0.94-0.423,1.33-0.76c0.376-0.324,0.693-0.71,0.94-1.14
	c0.255-0.452,0.456-0.932,0.6-1.43h-1.39c-0.157,0.731-0.573,1.38-1.17,1.83c-0.61,0.441-1.348,0.669-2.1,0.65
	c-0.546,0.012-1.087-0.105-1.58-0.34c-0.441-0.218-0.827-0.533-1.13-0.92c-0.31-0.403-0.541-0.861-0.68-1.35
	c-0.155-0.536-0.232-1.092-0.23-1.65L82.54,106.773z M74.18,105.793c0.05-0.5,0.168-0.991,0.35-1.46
	c0.167-0.452,0.41-0.871,0.72-1.24c0.3-0.348,0.667-0.63,1.08-0.83c0.459-0.216,0.963-0.322,1.47-0.31
	c0.509-0.011,1.015,0.092,1.48,0.3c0.412,0.188,0.778,0.464,1.07,0.81c0.295,0.356,0.516,0.767,0.65,1.21
	c0.139,0.498,0.199,1.014,0.18,1.53L74.18,105.793z M86.28,106.643c-0.001-0.577,0.053-1.153,0.16-1.72
	c0.088-0.506,0.279-0.99,0.56-1.42c0.27-0.423,0.646-0.767,1.09-1c0.547-0.253,1.148-0.366,1.75-0.33v-1.35
	c-0.757-0.034-1.508,0.142-2.17,0.51c-0.654,0.428-1.152,1.056-1.42,1.79l0,0v-2H85v10.78h1.29L86.28,106.643z M95.2,105.833
	c-2.513,0.333-3.77,1.417-3.77,3.25c-0.011,0.47,0.088,0.936,0.29,1.36c0.186,0.388,0.456,0.73,0.79,1
	c0.342,0.267,0.732,0.464,1.15,0.58c0.442,0.126,0.9,0.19,1.36,0.19c0.761,0.006,1.515-0.151,2.21-0.46
	c0.689-0.313,1.306-0.766,1.81-1.33c-0.015,0.183-0.015,0.367,0,0.55c0.027,0.168,0.081,0.33,0.16,0.48
	c0.074,0.142,0.176,0.268,0.3,0.37c0.145,0.111,0.318,0.18,0.5,0.2c0.184,0.041,0.372,0.061,0.56,0.06
	c0.186,0,0.371-0.027,0.55-0.08l0.43-0.12v-1c-0.527,0.193-0.877,0.223-1.05,0.09c-0.173-0.133-0.26-0.443-0.26-0.93v-5.87
	c0.016-0.542-0.086-1.082-0.3-1.58c-0.188-0.398-0.473-0.742-0.83-1c-0.376-0.266-0.801-0.456-1.25-0.56
	c-0.501-0.121-1.015-0.182-1.53-0.18c-0.556-0.002-1.11,0.066-1.65,0.2c-0.492,0.118-0.957,0.329-1.37,0.62
	c-0.412,0.286-0.754,0.662-1,1.1c-0.265,0.504-0.415,1.061-0.44,1.63h1.27c0.035-0.412,0.151-0.813,0.34-1.18
	c0.162-0.299,0.391-0.556,0.67-0.75c0.286-0.196,0.61-0.332,0.95-0.4c0.392-0.078,0.791-0.115,1.19-0.11
	c0.743-0.074,1.486,0.145,2.07,0.61c0.41,0.417,0.631,0.985,0.61,1.57c-0.002,0.212-0.025,0.423-0.07,0.63
	c-0.039,0.219-0.186,0.403-0.39,0.49c-0.255,0.111-0.524,0.189-0.8,0.23l-0.84,0.12L95.2,105.833z M98.95,107.933
	c0.012,0.487-0.101,0.97-0.33,1.4c-0.212,0.397-0.514,0.739-0.88,1c-0.383,0.248-0.806,0.427-1.25,0.53
	c-0.476,0.127-0.967,0.191-1.46,0.19c-0.29,0.001-0.579-0.036-0.86-0.11c-0.27-0.067-0.525-0.186-0.75-0.35
	c-0.216-0.159-0.393-0.364-0.52-0.6c-0.138-0.265-0.207-0.561-0.2-0.86c-0.04-0.649,0.264-1.272,0.8-1.64
	c0.678-0.406,1.45-0.63,2.24-0.65c0.54-0.047,1.08-0.103,1.62-0.17c0.551-0.073,1.089-0.221,1.6-0.44L98.95,107.933z
	 M112.35,104.543c-0.1-1.084-0.62-2.086-1.45-2.79c-0.873-0.623-1.929-0.936-3-0.89c-0.737-0.014-1.467,0.147-2.13,0.47
	c-0.582,0.298-1.094,0.717-1.5,1.23c-0.426,0.543-0.748,1.16-0.95,1.82c-0.216,0.696-0.324,1.421-0.32,2.15
	c0.007,0.741,0.115,1.478,0.32,2.19c0.172,0.654,0.475,1.266,0.89,1.8c0.412,0.515,0.94,0.927,1.54,1.2
	c1.274,0.52,2.691,0.573,4,0.15c0.504-0.18,0.966-0.459,1.36-0.82c0.389-0.362,0.708-0.792,0.94-1.27
	c0.25-0.517,0.429-1.065,0.53-1.63h-1.33c-0.105,0.808-0.483,1.556-1.07,2.12c-0.62,0.535-1.422,0.81-2.24,0.77
	c-0.578,0.015-1.149-0.119-1.66-0.39c-0.446-0.242-0.83-0.584-1.12-1c-0.296-0.445-0.509-0.939-0.63-1.46
	c-0.133-0.543-0.2-1.101-0.2-1.66c0.001-0.557,0.072-1.111,0.21-1.65c0.127-0.526,0.344-1.027,0.64-1.48
	c0.286-0.432,0.67-0.792,1.12-1.05c0.502-0.276,1.068-0.414,1.64-0.4c0.746-0.028,1.477,0.213,2.06,0.68
	c0.554,0.498,0.942,1.154,1.11,1.88L112.35,104.543z M118.84,110.973l-0.51,0.1c-0.17,0.014-0.34,0.014-0.51,0
	c-0.206,0.009-0.413-0.018-0.61-0.08c-0.136-0.048-0.25-0.144-0.32-0.27c-0.074-0.149-0.115-0.313-0.12-0.48c0-0.193,0-0.423,0-0.69
	v-7.39h2v-1h-2v-3h-1.25v3H114v1h1.48v7.88c-0.067,0.603,0.11,1.207,0.49,1.68c0.397,0.337,0.91,0.506,1.43,0.47
	c0.28,0.004,0.559-0.029,0.83-0.1l0.6-0.15L118.84,110.973z M122.32,98.773v-1.85h-1.37v1.85L122.32,98.773z M122.25,111.903v-10.78
	H121v10.78H122.25z M128.43,111.903h1.39l4.18-10.78h-1.35l-3.5,9.24l-3.5-9.24h-1.35L128.43,111.903z M145,106.803
	c0.125-1.592-0.322-3.177-1.26-4.47c-0.864-1.029-2.158-1.595-3.5-1.53c-0.719-0.012-1.43,0.142-2.08,0.45
	c-0.601,0.291-1.13,0.711-1.55,1.23c-0.443,0.54-0.782,1.157-1,1.82c-0.232,0.72-0.347,1.473-0.34,2.23
	c-0.006,0.75,0.091,1.497,0.29,2.22c0.176,0.651,0.482,1.26,0.9,1.79c0.412,0.515,0.94,0.927,1.54,1.2
	c0.702,0.308,1.463,0.459,2.23,0.44c0.608,0.012,1.213-0.08,1.79-0.27c0.488-0.165,0.94-0.423,1.33-0.76
	c0.376-0.324,0.693-0.71,0.94-1.14c0.255-0.452,0.456-0.932,0.6-1.43h-1.39c-0.155,0.729-0.566,1.379-1.16,1.83
	c-0.61,0.441-1.348,0.669-2.1,0.65c-0.546,0.012-1.087-0.105-1.58-0.34c-0.441-0.218-0.827-0.532-1.13-0.92
	c-0.31-0.403-0.541-0.861-0.68-1.35c-0.155-0.536-0.232-1.092-0.23-1.65H145z M136.65,105.793c0.05-0.5,0.168-0.991,0.35-1.46
	c0.149-0.433,0.369-0.838,0.65-1.2c0.3-0.348,0.667-0.63,1.08-0.83c0.459-0.216,0.963-0.322,1.47-0.31
	c0.509-0.011,1.015,0.092,1.48,0.3c0.412,0.188,0.777,0.464,1.07,0.81c0.295,0.356,0.516,0.767,0.65,1.21
	c0.148,0.483,0.222,0.985,0.22,1.49L136.65,105.793z"
        />
        <circle
          id="circle01"
          mask="url(#eye-mask)"
          cx="72.248"
          cy="50.965"
          r="28.187"
        />
        <mask id="eye-mask">
          <rect width="144.98" height="150" fill="#fff" />
          <path
            id="eye"
            fill="#000"
            d="M98.26,51.691c-4.64,8.39-11.55,13.74-21,15.45c-12.706,2.278-25.393-4.096-31.15-15.65
	c-0.152-0.347-0.152-0.743,0-1.09c4.66-8.55,11.68-13.83,21.3-15.55c1.59-0.28,3.19-0.37,4.59-0.52
	c11.78,0.51,20.46,5.7,26.26,15.77C98.579,50.583,98.579,51.209,98.26,51.691z"
          />
        </mask>
        <circle id="circle02" cx="72.2" cy="50.961" r="15.13" />
      </svg>
    )
  }
}
