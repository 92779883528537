import React, { Fragment } from 'react'
import { Space, Stack } from 'spaced-components'

export const tilesSpacing = { mobileSm: 65, laptopLg: 'xxl' }

const TilesContainer = ({ children }) => (
  <Fragment>
    <Space y={{ mobileSm: -40, laptopSm: -64, _1200: -100, laptopLg: -120 }} />
    <Stack {...tilesSpacing} overflow="hidden">
      {children}
    </Stack>
    <Space {...tilesSpacing} />
  </Fragment>
)

export default TilesContainer
