import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Inset, Break, Flex, Stack, On, Space } from 'spaced-components'
import {
  ParallaxProvider,
  Parallax as ParallaxObject,
} from 'react-scroll-parallax'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import Parallax from '../../components/Parallax'
import { AutoFade, Fade, AutoSlide } from '../../components/Animated'
import BeforeAfterSlider from 'react-before-after-slider'
import Img from '../../components/Img'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import { keyframes } from 'emotion'

import CaseStudyLayout, {
  boxShadow,
  dropShadow,
  Title,
  Box,
  Detail,
  DetailValue,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'
import webpage from './images/webpage.jpg'
import webpageAfter from './images/webpage-after.jpg'
import webpageBefore from './images/webpage-before.jpg'

import corporateLogos from './images/corporate-logos.png'
import facebookImage from './images/facebook-image.png'
import facebook from './images/facebook.jpg'
import footballParallax from './images/football-parallax.png'
import instagramComments from './images/instagram-comments.jpg'
import instagramTop from './images/instagram-top.jpg'
import iphone from './images/iphone.png'
import macbook from './images/macbook.png'
import parallaxBg from './images/parallax-bg.jpg'
import socialImage from './images/social-image.jpg'
import stackCenter from './images/stack-center.jpg'
import stackLeft from './images/stack-left.jpg'
import stackRight from './images/stack-right.jpg'

const rotateBall = keyframes({
  to: {
    transform: 'rotate(360deg)',
  },
})

const currentProject = getProject('turnkey')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <ParallaxProvider>
      <CaseStudyLayout
        currentProjectKey={currentProject.key}
        logo={<Logo play width={360} />}
      >
        <Box css={{ backgroundColor: currentProject.color }}>
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <InfoSection
              text={
                <Fragment>
                  <Detail muted>CHALLENGE:</Detail>
                  <Space md />
                  <Title is="h1">
                    Fresh off an acquisition with seconds on the clock, we
                    helped the experts in sports and entertainment research
                    score big by improving their digital game.
                  </Title>
                </Fragment>
              }
              details={
                <Fragment>
                  <Space tabletMd={50} />
                  <Stack is="ul" rowGap={15}>
                    <li>
                      <Detail muted>Client:</Detail>
                      <Break />
                      <Space xs />
                      <DetailValue>MarketCast Group Inc.</DetailValue>
                    </li>
                    <li>
                      <Detail muted>Industry:</Detail>
                      <Break />
                      <Space xs />
                      <DetailValue>Sports & Entertaiment</DetailValue>
                    </li>
                    <li>
                      <Detail muted>Services:</Detail>
                      <Break />
                      <Space xs />
                      <DetailValue>
                        Creative Direction
                        <Break />
                        Website Design
                        <Break />
                        WordPress Development
                        <Break />
                        Social Media Strategy
                      </DetailValue>
                    </li>
                  </Stack>
                </Fragment>
              }
            />
            <Box
              css={{
                position: 'relative',
                width: { tablet: '80%' },
                margin: 'auto',
                marginBottom: '-30vmin',
              }}
            >
              <Box css={{ width: '89%' }}>
                <AutoSlide up style={dropShadow()}>
                  <Img src={macbook} ratio={1102 / 1919} />
                </AutoSlide>
              </Box>
              <Box
                css={{
                  width: '19%',
                  position: 'absolute',
                  top: '26%',
                  right: '8%',
                }}
              >
                <AutoSlide up delay={1 * 300} style={dropShadow()}>
                  <Img src={iphone} ratio={1064 / 532} />
                </AutoSlide>
              </Box>
            </Box>
          </Inset>
        </Box>

        <Box css={{ backgroundColor: '#0a0a0a' }}>
          <Space y="40vmin" />
          <Space xl />
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <Box
              css={{
                position: 'relative',
                width: { tablet: '80%' },
                margin: 'auto',
              }}
            >
              <Box css={{ width: '93%' }}>
                <AutoFade>
                  <Img src={corporateLogos} ratio={708 / 2455} />
                </AutoFade>
              </Box>
              <Box
                css={{
                  width: '21%',
                  position: 'absolute',
                  bottom: '0%',
                  right: '-3.5%',
                }}
              >
                <WhenVisible>
                  {inView => (
                    <Fade inView={inView} delay={300}>
                      <Logo
                        play={inView}
                        delay={300}
                        iconColor={currentProject.color}
                        textColor="#fff"
                        style={{ display: 'block' }}
                      />
                    </Fade>
                  )}
                </WhenVisible>
              </Box>
            </Box>
            <Space xxl tabletMd={200} />
            <AutoSlide
              up
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <BeforeAfterSlider
                before={webpageBefore}
                after={webpageAfter}
                width={window.innerWidth * 0.61}
                height={window.innerWidth * 0.61 * (1566 / 2199)}
              />
            </AutoSlide>
            <Space xl tabletMd="xxl" />
          </Inset>
          <Box
            css={{
              display: { mobile: 'none', tabletMd: 'block' },
              position: 'relative',
              backgroundColor: '#f3f3f3',
              // overflow: 'hidden',
            }}
          >
            <Box
              css={{
                width: { mobile: '80%', tabletMd: '66.666%' },
                margin: 'auto',
              }}
            >
              <Box
                css={{
                  width: '50%',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <Img src={parallaxBg} ratio={1702 / 1813} />
              </Box>
              <Box
                css={{
                  width: '7%',
                  position: 'absolute',
                  top: '12%',
                  right: '33%',
                }}
              >
                <ParallaxObject
                  offsetXMin={30}
                  offsetXMax={-140}
                  offsetYMin={-150}
                  offsetYMax={30}
                  slowerScrollRate
                >
                  <Box css={{ animation: `${rotateBall} 30s infinite linear` }}>
                    <Img src={footballParallax} ratio={230 / 229} />
                  </Box>
                </ParallaxObject>
              </Box>
              <Space xxl tabletMd={250} />
              <Title
                css={{
                  maxWidth: '13.6em',
                  fontSize: { mobile: 30, laptop: '2.7vw' },
                  color: '#000',
                  opacity: 0.3,
                }}
              >
                We utilized key visual elements from the brand identity to
                create depth and movement throughout the new website design such
                as the “journey line” motifs.
              </Title>
              <Space xxl tabletMd={250} />
            </Box>
          </Box>
          <Space xl tabletMd="xxl" />
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <Box
              css={{
                width: { mobile: '80%', tabletMd: '66.666%' },
                margin: 'auto',
              }}
            >
              <WhenVisible>
                {inView => (
                  <Fragment>
                    <On min="tabletMd">
                      <Space x={{ tabletMd: '-5.55vmax' }}>
                        <BackgroundText
                          is="h4"
                          animate={inView}
                          style={{ textAlign: 'left' }}
                        >
                          DESIGN
                        </BackgroundText>
                      </Space>
                    </On>
                    <Space y={{ tabletMd: '-7vw' }} />
                  </Fragment>
                )}
              </WhenVisible>
            </Box>
            <Box
              css={{
                width: { mobile: '80%', tabletMd: '66.666%' },
                margin: 'auto',
              }}
            >
              <WhenVisible>
                {inView => (
                  <Box
                    css={{
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                      transition: '1s ease-out',
                      opacity: inView ? 1 : 0,
                      transform: inView ? 'none' : 'translateY(80px)',
                    }}
                  >
                    <Box
                      css={{
                        width: '85%',
                        position: 'relative',
                        zIndex: 1,
                        ...boxShadow(),
                      }}
                    >
                      <Img src={stackCenter} ratio={1300 / 1794} />
                    </Box>
                    <Box
                      css={{
                        width: '65%',
                        position: 'absolute',
                        left: '-10%',
                        top: '12%',
                        transition: '1s .5s ease-out',
                        transform: inView ? '' : 'translateX(30%)',
                      }}
                    >
                      <Img src={stackLeft} ratio={1016 / 1402} />
                    </Box>
                    <Box
                      css={{
                        width: '65%',
                        position: 'absolute',
                        right: '-10%',
                        top: '12%',
                        transition: '1s .5s ease-out',
                        transform: inView ? '' : 'translateX(-30%)',
                      }}
                    >
                      <Img src={stackRight} ratio={1016 / 1402} />
                    </Box>
                  </Box>
                )}
              </WhenVisible>
            </Box>
            <Space xl tabletMd="xxl" />
            <Box
              css={{
                width: { tablet: '66.666%' },
                margin: 'auto',
              }}
            >
              <AutoSlide up>
                <Img src={webpage} ratio={8067 / 2268} />
              </AutoSlide>
            </Box>
            <Space xl tabletMd="xxl" />
            <Box
              css={{
                width: { mobile: '80%', tabletMd: '66.666%' },
                margin: 'auto',
              }}
            >
              <WhenVisible>
                {inView => (
                  <Fragment>
                    <On min="tabletMd">
                      <Space x={{ tabletMd: '-5.55vmax' }}>
                        <BackgroundText
                          is="h4"
                          animate={inView}
                          style={{ textAlign: 'left' }}
                        >
                          SOCIAL
                        </BackgroundText>
                      </Space>
                    </On>
                    <Space y={{ tabletMd: '-7vw' }} />
                  </Fragment>
                )}
              </WhenVisible>
              <Box
                css={{
                  width: { mobile: '100%', tabletMd: '80%' },
                  position: 'relative',
                }}
              >
                <AutoSlide
                  up
                  style={{
                    width: '72%',
                    position: 'relative',
                    zIndex: 1,
                    left: 0,
                    ...boxShadow(),
                  }}
                >
                  <Img src={instagramTop} ratio={1200 / 1200} />
                </AutoSlide>
                <AutoSlide
                  up
                  delay={100}
                  style={{
                    width: '38.5%',
                    position: 'absolute',
                    left: '68%',
                    top: '5%',
                    ...boxShadow(),
                  }}
                >
                  <Img src={instagramComments} ratio={1199 / 639} />
                </AutoSlide>
              </Box>
              <Space xl tabletMd="xxl" />
              <Box
                css={{
                  width: '80%',
                  position: 'relative',
                }}
              >
                <AutoSlide
                  up
                  style={{
                    width: '72%',
                    position: 'relative',
                    marginBottom: '40%',
                    marginLeft: '11%',
                    ...boxShadow(),
                  }}
                >
                  <Img src={socialImage} ratio={1200 / 1200} />
                </AutoSlide>
                <AutoSlide
                  up
                  delay={1 * 200}
                  style={{
                    width: '60%',
                    position: 'absolute',
                    left: '72%',
                    top: '55%',
                    ...boxShadow(),
                  }}
                >
                  <Img src={facebook} ratio={1124 / 952} />
                </AutoSlide>
                <AutoFade
                  delay={3 * 200}
                  style={{
                    width: '64.15%',
                    position: 'absolute',
                    left: '70%',
                    top: '74.2%',
                    ...dropShadow(0.8),
                  }}
                >
                  <Img src={facebookImage} ratio={517 / 800} />
                </AutoFade>
              </Box>
            </Box>
            <Space xl tabletMd="xxl" />
          </Inset>
        </Box>

        <Parallax direction="both" height="65vh" maxHeight={600}>
          <Parallax.Background height="150%">
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                backgroundColor: currentProject.color,
              }}
            >
              <Box
                css={{
                  width: { mobile: '200%', _1200: '60%' },
                  position: 'relative',
                  top: '1%',
                }}
              >
                <svg viewBox="0 0 58.2 36" fill={currentProject.colors[0]}>
                  <path d="M49.8 0L33.3 20.2a11.2 11.2 0 0 0-3.8-5L10.9 0H0v36h6v-.2l.2.2 5.8-7.2C13.8 33 17.9 36 22.6 36c3.2 0 6.2-1.4 8.3-3.6v.1l21.3-26V36h6V0h-8.4zm-37 18.4L6 26.7V6h2.9v-.3.3l8.4 7-2.5 3c-.8.7-1.4 1.5-2 2.4zM22.6 30c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5 5.5 2.5 5.5 5.5-2.4 5.5-5.5 5.5z" />
                </svg>
              </Box>
            </Box>
          </Parallax.Background>
          <Parallax.Content>
            <Flex height="100%" center middle>
              <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
                <Title
                  is="blockquote"
                  css={{ maxWidth: '21em', textAlign: 'center' }}
                >
                  The new Turnkey identity and website debuted at Sports
                  Business Week 2018, the launch was celebrated as a success by
                  all stakeholders of the MarketCast Group.
                </Title>
              </Inset>
            </Flex>
          </Parallax.Content>
        </Parallax>
      </CaseStudyLayout>
    </ParallaxProvider>
  </Fragment>
)
