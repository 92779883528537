import React from "react"
import styled from "react-emotion"
import GSAPComponent from "./GSAPComponent"

function shuffle(o) {
  for (
    var j, x, i = o.length;
    i;
    j = parseInt(Math.random() * i, 10), x = o[--i], o[i] = o[j], o[j] = x
  );
  return o
}

const splitAndShuffle = text => {
  /* eslint-disable */
  const newText = new SplitText(text, { type: "words, chars" })
  shuffle(newText.chars)

  return newText
  /* eslint-enable */
}

const TextRotatorWrapper = styled("span")`
  display: inline-block;
  position: relative;

  > span:not(:last-child) {
    position: absolute;
    top: 0;
    left: 0;
  }
`

class TextRotator extends GSAPComponent {
  animate() {
    /* eslint-disable */
    const tl = new TimelineMax({ repeat: -1 })

    Array.prototype.forEach.call(this.el.childNodes, textEl => {
      const text = splitAndShuffle(textEl)

      TweenMax.set(text.chars, {
        opacity: 0,
        x: 40,
        transformOrigin: "center",
        willChange: "transform, opacity",
        ease: Power4.easeInOut
      })

      tl.staggerTo(text.chars, 0.8, { opacity: 1, x: 0 }, 0.05)
      tl.staggerTo(text.chars, 0.8, { opacity: 0 }, 0.05, "+=3")
    })

    return tl
    /* eslint-enable */
  }

  render() {
    const { text, ...props } = this.props

    return (
      <TextRotatorWrapper innerRef={ref => (this.el = ref)} {...props}>
        {this.props.text.map((text, i) => <span key={i}>{text}</span>)}
      </TextRotatorWrapper>
    )
  }
}

export default TextRotator
