import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Inset, Break, Stack, Space, On } from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'

import greenPopsicle from './assets/green-popsicle.png'
import greenPopsicle2 from './assets/green-popsicle2.png'
import popsicleTop from './assets/popsicle-top.png'
import redPopsicle from './assets/red-popsicle.png'
import yellowPopsicle from './assets/yellow-popsicle.png'

import apple from './assets/apple.png'
import farmBg from './assets/farm-bg.png'
import handCrafted from './assets/hand-crafted.png'
import instagram1 from './assets/instagram1.png'
import instagram2 from './assets/instagram2.png'
import iPhone from './assets/iPhone.png'
import macbook from './assets/macbook.png'
import ppPoster from './assets/pp-poster.png'
import pressPopsLogo from './assets/press-pops-logo.png'
import twoBoxesIntro from './assets/two-boxes-intro.png'
import twoBoxesTop from './assets/two-boxes-top.png'

const currentProject = getProject('press-pops')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#626429' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  True to our roots.
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  Press Brothers Juicery, a Los Angeles-based brand famous for
                  their unique blend of cold-pressed juices tapped us to help
                  them launch their very own brand of frozen popsicles, made
                  with, you guessed it certified organic, cold-pressed, juice. A
                  big part of Press Pop’s uniqueness is their process; using
                  raw, unpasteurized, organic fruits and vegetables devoid of
                  preservatives or additives.
                </Paragraph>
                <Space md />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  We knew rational benefits were simply not enough to break from
                  the crowd of a growing segment. Our goal was to create a brand
                  with refined positioning - differentiated and aspirational.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Press Brothers Juicery</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Food & Beverage</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Brand Strategy
                    <Break />
                    Brand Identity
                    <Break />
                    Packaging Design
                    <Break />
                    Web Design + Development
                    <Break />
                    Brand Collateral
                  </DetailValue>
                </li>
              </Stack>
            }
          />
          <Box
            css={{
              width: { tabletMd: '80%' },
              margin: '-2vw auto -22vw',
              position: 'relative',
              left: '12vw',
            }}
          >
            <Img src={twoBoxesIntro} ratio={845 / 1370} />
          </Box>
        </Inset>
      </Box>
      <Box
        css={{
          background: `url(${farmBg}) center bottom / 100% auto no-repeat #0a0a0a`,
        }}
      >
        <Space y="22vw" />
        <Space xxl />
        <AutoSlide up>
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
            }}
          >
            <Title is="h2">A story on every stick.</Title>
            <Space md />
            <Paragraph css={{ maxWidth: '31em' }}>
              Like most new brands, there’s always the struggle to find its
              differentiator amongst the established competition. We implemented
              a strategy that helped drive the authenticity of Press Pops. Tying
              the brand to its family history and Midwestern roots to help
              further that feeling of personality.
            </Paragraph>
          </Box>
        </AutoSlide>
        <Space xxl />
        <Space tabletMd="xxl" />
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Box
          css={{
            display: 'flex',
            width: '90%',
            margin: 'auto',
          }}
        >
          <AutoFade delay={0 * 100} style={{ flex: 1 }}>
            <Img src={redPopsicle} ratio={1111 / 405} />
          </AutoFade>
          <AutoFade delay={1 * 100} style={{ flex: 1 }}>
            <Img src={greenPopsicle} ratio={966 / 409} />
          </AutoFade>
          <AutoFade delay={2 * 100} style={{ flex: 1.8, marginLeft: '-24%' }}>
            <Img src={yellowPopsicle} ratio={1108 / 730} />
          </AutoFade>
          <AutoFade delay={3 * 100} style={{ flex: 0.89 }}>
            <Img src={greenPopsicle2} ratio={1036 / 393} />
          </AutoFade>
        </Box>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '40%' },
            margin: 'auto',
          }}
        >
          <Img src={pressPopsLogo} ratio={406 / 588} />
        </Box>
        <Space xl tabletMd="xxl" />
        <WhenVisible>
          {inView => (
            <Fragment>
              <On min="tabletSm">
                <Space x={{ tabletSm: '-5.55vmax' }}>
                  <Box css={{ width: { tabletMd: '66.666%' }, margin: 'auto' }}>
                    <BackgroundText
                      is="h4"
                      animate={inView}
                      size="12vw"
                      style={{ textAlign: 'left' }}
                    >
                      PACKAGING
                    </BackgroundText>
                  </Box>
                </Space>
              </On>
              <Space y={{ tabletSm: '-7vw' }} />
              <Box
                css={{
                  width: '80%',
                  margin: 'auto',
                }}
              >
                <AutoSlide up style={{ position: 'relative', right: '-8.5vw' }}>
                  <Img src={twoBoxesTop} ratio={1041 / 1497} />
                </AutoSlide>
              </Box>
            </Fragment>
          )}
        </WhenVisible>
        <Space xl tabletMd="xxl" />
        <WhenVisible>
          {inView => (
            <Fragment>
              <On min="tabletSm">
                <Space x={{ tabletSm: '-5.55vmax' }}>
                  <Box css={{ width: { tabletMd: '66.666%' }, margin: 'auto' }}>
                    <BackgroundText
                      is="h4"
                      animate={inView}
                      size="12vw"
                      style={{ textAlign: 'left' }}
                    >
                      WEBSITE
                    </BackgroundText>
                  </Box>
                </Space>
              </On>
              <Space y={{ tabletSm: '-7vw' }} />
              <Box
                css={{
                  width: '80%',
                  position: 'relative',
                  margin: 'auto',
                }}
              >
                <AutoSlide
                  up
                  style={{
                    position: 'relative',
                    left: '-3.1vw',
                  }}
                >
                  <Img src={macbook} ratio={801 / 1308} />
                </AutoSlide>
                <AutoSlide
                  up
                  delay={1 * 150}
                  style={{
                    width: '27%',
                    position: 'absolute',
                    right: '-1%',
                    top: '26%',
                  }}
                >
                  <Img src={iPhone} ratio={626 / 360} />
                </AutoSlide>
                <AutoSlide
                  up
                  delay={2 * 150}
                  style={{
                    width: '25.6%',
                    position: 'absolute',
                    left: '36%',
                    top: '-13%',
                  }}
                >
                  <Img src={popsicleTop} ratio={890 / 344} />
                </AutoSlide>
                <AutoSlide
                  up
                  delay={3 * 150}
                  style={{
                    width: '50%',
                    position: 'absolute',
                    left: '23%',
                    top: '42%',
                  }}
                >
                  <Img src={handCrafted} ratio={64 / 649} />
                </AutoSlide>
                <AutoSlide
                  up
                  delay={4 * 150}
                  style={{
                    width: '15%',
                    position: 'absolute',
                    left: '47%',
                    top: '53%',
                  }}
                >
                  <Img src={apple} ratio={209 / 198} />
                </AutoSlide>
              </Box>
            </Fragment>
          )}
        </WhenVisible>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <AutoSlide up>
            <Img src={ppPoster} ratio={1073 / 780} />
          </AutoSlide>
        </Box>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            position: 'relative',
            paddingBottom: '10%',
            margin: 'auto',
          }}
        >
          <AutoSlide up style={{ width: '60%' }}>
            <Img src={instagram1} ratio={1} />
          </AutoSlide>
          <AutoSlide
            up
            delay={150}
            style={{
              width: '60%',
              position: 'absolute',
              left: '40.5%',
              top: '24%',
            }}
          >
            <Img src={instagram2} ratio={1} />
          </AutoSlide>
        </Box>
        <Space xl tabletMd="xxl" />
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
