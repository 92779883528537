import styled from "react-emotion"
import { rem } from "spaced-components"

const VerticalLine = styled("div")`
  width: 2px;
  height: ${rem(100)};
  background-color: #fff;
  transition: 1s transform ease-out;
  transform-origin: bottom;
  transform: scaleY(0);
  ${p => p.animateToTop && `transform: scaleY(1)`};
`

export default VerticalLine
