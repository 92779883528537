import React, { Fragment } from "react"
import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import styled from "../../styles/styled"
import { NavbarThemeSwitch } from "../../components/NavbarTheme"
import { Space, Board, Inset, Columns, Flex, On } from "spaced-components"
import LinesPattern from "../../components/LinesPattern"
import DarkWrapper from "../../components/DarkWrapper"
import Layout from "../../Layout"
import ShowCells from "../../components/ShowCells"
import LogoCell from "../../components/LogoCell"
import TextLink from "../../components/TextLink"
import ScrollToLink from "../../components/ScrollToLink"
import BackgroundText from "../../components/BackgroundText"
import { Footer } from "../../components/Footer"
import ChallengeUsFooter from "../../components/ChallengeUsFooter"
import Headline from "../../components/Headline"
import HelloFab from "../../components/HelloFab"
import AwardCell from "../../components/AwardCell"
import VerticalLine from "../../components/VerticalLine"
import WhenVisible from "../../components/WhenVisible"

const Paragraph = styled("p")(() => ({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: 1.55
}))

export default () => (
  <Fragment>
    <Helmet>
      <title>Awards</title>
      <meta name="description" content="Awards description" />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <Layout.Shell>
      <Footer>
        <DarkWrapper>
          <LinesPattern />
          <Board.Item
            offset={{ mobileSm: 1 / 10, tabletMd: 1 / 6 }}
            width={{ mobileSm: 8 / 10, tabletMd: 4 / 6 }}
          >
            <Space y={100} />
            <Space x={{ tabletMd: "-5.55vmax" }}>
              <BackgroundText animate>AWARDS</BackgroundText>
            </Space>
            <On min="tabletSm" style={{ position: "relative" }}>
              <Space y={{ tabletMd: "-6.35vmax" }} />
              <Space x={{ tabletMd: -5 }}>
                <Headline>Victories along the way</Headline>
              </Space>
            </On>
            <Space tabletMd="lg" />
            <Board.Item width={{ tabletMd: 3 / 4 }}>
              <On min="tabletMd">
                <Paragraph>
                  Sure we like shiny things, but the truth is winning awards is
                  a testament to the dedication and passion of our craft.
                </Paragraph>
              </On>
            </Board.Item>
            <Space xl />
            <ShowCells defaultActive>
              <Inset mobileSm={5}>
                <Columns columnCount={{ mobileSm: 2, tabletSm: 4 }} gap={10}>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={1}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/webby-awards.svg`}
                          alt="Webby Awards logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={1}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/addy-awards.svg`}
                          alt="Addy Awards logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={1}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/how.svg`}
                          alt="How Awards logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={10}>
                        <img
                          width="37%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/W3-awards.svg`}
                          alt="W3 Awards logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={1}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/ava.svg`}
                          alt="AVA logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={1}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/commarts.svg`}
                          alt="Commarts logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={10}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/communicator.svg`}
                          alt="communicator logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={5}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/davey.svg`}
                          alt="davey logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={3}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/hermes.svg`}
                          alt="hermes logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={5}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/horizon.svg`}
                          alt="horizon logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={2}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/marcom.svg`}
                          alt="marcom logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={2}>
                        <img
                          width="40%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/webaward.svg`}
                          alt="webaward logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={1}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/iac.svg`}
                          alt="iac logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={3}>
                        <img
                          width="60%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/sca.svg`}
                          alt="sca logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={1}>
                        <img
                          width="37%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/gdusa.svg`}
                          alt="gdusa logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                  <Columns.Item>
                    <LogoCell>
                      <AwardCell count={7}>
                        <img
                          width="53%"
                          src={`${
                            process.env.PUBLIC_URL
                          }/assets/awards/ima.svg`}
                          alt="ima logo"
                        />
                      </AwardCell>
                    </LogoCell>
                  </Columns.Item>
                </Columns>
              </Inset>
            </ShowCells>
            <Space xl />
            <Flex column center>
              <Link to="/our-work">
                <TextLink is="span">SEE OUR WORK</TextLink>
              </Link>
              <Space y={60} />
              <ScrollToLink href="#">
                <Flex column center>
                  <svg style={{ width: 36, height: 36 }} viewBox="0 0 24 24">
                    <path
                      fill="#fff"
                      d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
                    />
                  </svg>
                  <Space y={20} />
                  <WhenVisible triggerOnce={false}>
                    {inView => <VerticalLine animateToTop={inView} />}
                  </WhenVisible>
                </Flex>
              </ScrollToLink>
            </Flex>
          </Board.Item>
        </DarkWrapper>
      </Footer>
      <ChallengeUsFooter />
      <HelloFab />
    </Layout.Shell>
  </Fragment>
)
