import React from 'react'
import { Relative, Flex, Board, Absolute } from 'spaced-components'
import WhenVisible from '../components/WhenVisible'
import TileTitle from '../components/TileTitle'
import Img from '../components/Img'
import { Slide, Reveal } from '../components/Animated'
import { TileTransitionLink } from '../components/TileTransition'
import { projectURL, projectImageURL } from '../projects'

const MobileTile = ({ data: { key, title, color, colors }, ...props }) => (
  <div>
    <Board.Item
      offset={{ mobileSm: 1 / 10 }}
      width={{ mobileSm: 8 / 10 }}
      {...props}
    >
      <TileTransitionLink to={projectURL(key)} imgSrc={projectImageURL(key)}>
        <WhenVisible>
          {inView => (
            <Relative>
              <Flex middle>
                <Absolute left zIndex={1}>
                  <Slide left inView={inView} translate={80}>
                    <TileTitle underlineColors={colors} active={inView}>
                      <span>{title}</span>
                    </TileTitle>
                  </Slide>
                </Absolute>
                <Board.Item
                  offset={{ mobileSm: 1 / 8, mobileLg: 1 / 4 }}
                  width={{ mobileSm: 7 / 8, mobileLg: 3 / 4 }}
                >
                  <Reveal
                    left
                    className="TileImg"
                    inView={inView}
                    color={color}
                  >
                    <Img src={projectImageURL(key)} alt={title} color={color} />
                  </Reveal>
                </Board.Item>
              </Flex>
            </Relative>
          )}
        </WhenVisible>
      </TileTransitionLink>
    </Board.Item>
  </div>
)

export default MobileTile
