import React from "react"
import GSAPComponent from "../../components/GSAPComponent"

export default class extends GSAPComponent {
  animate() {
    /* eslint-disable */
    const tl = new TimelineMax()
    const rectAmc = this.svg.querySelector("#rect-amc")
    const lettersAmc = this.svg.querySelector("#letters-amc")

    tl.from(rectAmc, 1, { opacity: 0, ease: Power2.easeOut })
    tl.to(lettersAmc, 0.5, {
      scale: 0.6,
      transformOrigin: "center",
      ease: Power2.easeInOut
    })
    tl.to(
      rectAmc,
      0.5,
      { scale: 0.6, transformOrigin: "center", ease: Power2.easeInOut },
      "-=.4"
    )

    return tl
    /* eslint-enable */
  }

  render() {
    return (
      <svg
        ref={ref => (this.svg = ref)}
        viewBox="0 0 52.2799988 24.1000004"
        width={300}
      >
        <defs>
          <mask id="amc-mask">
            <rect
              id="rect-amc"
              width="52.2799988"
              height="24.1000004"
              fill="#fff"
            />
            <path
              id="letters-amc"
              d="M11.8499756 5.3600101c-4.8699951 0-5.6199951 3.1699829-5.6199951 4.1699829h2.9000244c.210022-.7800293.7700195-1.6600342 2.7700195-1.6600342.0801392-.0078735.1607056-.0115967.241272-.0112305 1.2978516.0062256 2.3449097 1.0634155 2.3386841 2.3612671v.2999878h-.8800049c-4.3099976 0-7.8699951.8400269-7.8699951 4.2800293.2467041 2.3221436 2.2681274 4.0447388 4.6000366 3.9199829 1.5022583-.0002441 2.9707642-.4456177 4.2199707-1.2800293l.0700073 1h2.7600098v-6.9400024c0-3.9199828-1.4199829-6.1399536-5.5300293-6.1399536zm2.6600342 8.2299805c-.0108643.3936157-.1099854.7797852-.2900391 1.1300049-.7171631 1.0326538-1.9259033 1.6104126-3.1799927 1.5200195-1.3399658 0-2.3399658-.4200439-2.3399658-1.4200439 0-1.3299561 1.5199585-1.7099609 2.6400146-1.8200073.7607422-.0794067 1.5250854-.1194458 2.289978-.1199951h.8800049v.7100219zm29.1499633-.0900268h2.8900146c-.4338989 2.9874268-2.9813843 5.2121582-6 5.2400513-3.6199951 0-6.1499634-2.8800049-6.1499634-6.6900024 0-4 2.5499878-6.6900024 6.1499634-6.6900024 2.9909668.0166626 5.5300903 2.196106 6 5.1499634h-2.8999634c-.3596191-1.4333496-1.62323-2.4564819-3.1000366-2.5100098-1.9099731 0-3.3399658 1.7300415-3.3399658 4 0 2.2700195 1.4299927 4 3.3399658 4 1.4764405-.0512086 2.7426759-1.0690309 3.1099854-2.5000001zm-15.25-7.8299561H33v12.7699585h-2.9799805V9.0799809l-2.6000366 9.3599854h-2.7600098l-2.5999756-9.3599854v9.3599854h-3V5.6700077h4.5900269s2.1599731 8.1699829 2.3800049 9.1799927l2.3799438-9.1799927z"
            />
          </mask>
        </defs>
        <rect
          mask="url(#amc-mask)"
          width="52.2799988"
          height="24.1000004"
          fill="#fff"
        />
      </svg>
    )
  }
}
