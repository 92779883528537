import styled from '../styles/styled'

const TextUnderline = styled('span')(
  ({
    active = true,
    color,
    colors,
    top = 0.7,
    left = 0,
    height = [0.3, 1],
  }) => ({
    display: 'inline',
    backgroundImage: colors
      ? `linear-gradient(to right, ${colors[0]}, ${colors[1]})`
      : `linear-gradient(to right, ${color}, ${color})`,
    backgroundPosition: `${left}em ${top}em`,
    backgroundSize: `100% ${height[0]}em`,
    backgroundRepeat: 'no-repeat',
    boxDecorationBreak: 'clone',
    opacity: active ? 1 : 0,
    transitionProperty: 'opacity, background',
    transitionDuration: '.2s',
    transitionTimingFunction: 'ease-out',
    '&:hover': {
      backgroundPosition: `${left}em ${top + height[0] - height[1]}em`,
      backgroundSize: `100% ${height[1]}em`,
    },
  })
)

export default TextUnderline
