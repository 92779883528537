import React, { useRef, useEffect } from 'react'
import { Power4, Elastic } from '../../lib/gsap'
import { useControlledTimeline } from '../../hooks/animation'

const Logo = ({ play, delay = 0, ...props }) => {
  const tl = useControlledTimeline({ delay: delay / 1000 }, play)

  useEffect(() => {
    tl.from(signet.current, 0.5, {
      transformOrigin: 'center',
      scale: 0.5,
      opacity: 0,
      ease: Power4.easeInOut,
    })

    tl.staggerFrom(
      [l1.current, l2.current, l3.current, l4.current, l5.current],
      0.5,
      {
        transformOrigin: 'center',
        scale: 0.5,
        x: 20,
        opacity: 0,
        ease: Power4.easeOut,
      },
      0.05,
      '-=.3'
    )

    tl.from(
      playBtn.current,
      0.7,
      {
        transformOrigin: 'center',
        scale: 0.5,
        opacity: 0,
        ease: Elastic.easeOut.config(2),
      },
      '-=.5'
    )
  }, [])

  const l1 = useRef()
  const l2 = useRef()
  const l3 = useRef()
  const l4 = useRef()
  const l5 = useRef()
  const playBtn = useRef()
  const signet = useRef()

  return (
    <svg viewBox="-20 0 144.56 25.23" fill="currentColor" {...props}>
      <path
        ref={l1}
        d="M42.13 7.92a3 3 0 0 1 3.27 3.14c0 .34-.04.67-.1 1l-1.36 6.48a2.72 2.72 0 0 1-3 2.47h-6.87c-1.93 0-3-1.26-3-3.21 0-.5.05-1 .12-1.49.42-2.49 1.78-3.48 4.13-3.48h6l.13-.57v-.45c0-.74-.5-1.14-1.39-1.14h-6.61l.6-2.77 8.08.02zm-5.54 7.6a1.25 1.25 0 0 0-1.43 1.09c-.08.27-.12.56-.13.84a.82.82 0 0 0 .92.91h3.29a1 1 0 0 0 1.06-.86l.42-2-4.13.02z"
      />
      <path
        ref={l2}
        d="M46.83 21a1.47 1.47 0 0 1-1.58-1.63 3.33 3.33 0 0 1 1.56-3l7.37-5.24c.1-.08.25-.15.25-.27s0-.15-.2-.15h-6.75l.59-2.85h9.82c1.31 0 1.68.75 1.68 1.59.07 1.18-.5 2.3-1.48 2.94l-7.37 5.32c-.13.1-.25.22-.25.34s.05.08.2.08h6.95L57 21H46.83z"
      />
      <path
        ref={l3}
        d="M72.28 18.56c-.37 1.73-1.16 2.47-3 2.47H64c-2.67 0-4.5-1.18-4.5-3.53 0-.4.04-.79.13-1.17l1.78-8.41h3.86l-1.59 7.42c-.07.35-.11.71-.12 1.07 0 1.23.69 1.8 2.27 1.8h1.81a.92.92 0 0 0 1-.86l2-9.43h3.89l-2.25 10.64z"
      />
      <path
        ref={l4}
        d="M76.29 21a1.75 1.75 0 0 1-2-1.83c0-.22.02-.43.07-.64L77.5 3.72h3.86l-.89 4.2H84c3.36 0 4.48 2 4.48 4.78 0 .77-.08 1.54-.24 2.3-.84 4-2.08 6.06-6.78 6.06L76.29 21zm2.2-3.83v.39c0 .45.25.6.84.6H81c2.3 0 2.89-1.68 3.34-3.71.13-.59.2-1.18.22-1.78 0-1.12-.42-1.93-1.93-1.93h-2.75l-1.39 6.43z"
      />
      <path
        ref={l5}
        d="M102.31 18.56a2.65 2.65 0 0 1-3 2.47H94c-2.67 0-4.5-1.18-4.5-3.53 0-.4.04-.79.12-1.17l1.78-8.41h3.86l-1.58 7.42c-.07.35-.11.71-.12 1.07 0 1.23.69 1.8 2.27 1.8h1.81a.92.92 0 0 0 1-.86l2-9.43h3.88l-2.21 10.64z"
      />
      <path
        ref={playBtn}
        d="M17 13.09a.52.52 0 0 0 0-1l-6.17-3.54C10.37 8.28 10 8.5 10 9v7.12c0 .54.39.76.85.49L17 13.09z"
      />
      <path
        ref={signet}
        d="M5.46 23a12 12 0 0 1-1.76-1.37l-.06-.06-.15-.15a1.59 1.59 0 0 0-.13-.13l-.07-.08-.27-.3-.12-.15-.14-.16-.14-.18-.08-.09-.32-.44-.05-.07-.17-.25v-.08a15 15 0 0 1-.83-1.41v-.06l-.09-.2-.08-.17v-.08a12.63 12.63 0 0 1-1-4.68v-.55c.03-1.52.33-3.02.9-4.43l.1-.24.14-.23.06-.13.11-.24c.24-.5.51-.98.82-1.44l.18-.27c.11-.17.23-.32.35-.48l.06-.08.15-.18.13-.16.17-.16.27-.3v-.05l.2-.2.08-.08.12-.12c.05-.05.16-.1.16-.16.45-.43.94-.81 1.46-1.16V23zM24.32 7.92a1.6 1.6 0 0 1-.1-.23v-.12l-.06-.13-.19-.41a.83.83 0 0 1-.07-.13l-.18-.34-.11-.2-.06-.09c-.1-.2-.22-.38-.35-.56l-.19-.27a.44.44 0 0 0-.08-.11L22.66 5l-.07-.09-.33-.4-.17-.2-.09-.17-.33-.35-.07-.07-.13-.13-.16-.15h-.05l-.31-.28-.19-.16-.16-.14-.18-.15h-.06a5.6 5.6 0 0 0-.49-.36l-.27-.18c-.47-.3-.95-.57-1.45-.81l-.25-.12-.13-.06-.21-.18-.23-.08C15.93.35 14.42.03 12.9 0h-.56c-1.6.04-3.2.38-4.68 1h-.09l-.16.07-.21.1 18 10.39c-.1-1.27-.4-2.51-.87-3.69M15.8 18.12c-3 1.74-4.93 4.43-5.24 6.94.59.1 1.18.15 1.78.17h.55c1.52-.03 3.01-.34 4.42-.91l.22-.09.14-.06.12-.05.37-.18.16-.08.31-.16.22-.12h.05c.28-.17.56-.34.83-.53l.07-.05.25-.18a.39.39 0 0 0 .1-.08l.35-.26.19-.16.14-.12.33-.3.21-.2.08-.07.12-.13.16-.16v-.05l.29-.33.13-.14.15-.19.26-.33.09-.12.17-.23.06-.1c.18-.26.35-.53.51-.8v-.12l.12-.21.17-.31c0-.06.05-.11.07-.16l.18-.37a.61.61 0 0 0 .05-.12l.06-.14.09-.22.1-.25c-2.32-1-5.62-.67-8.62 1.06"
      />
    </svg>
  )
}

export default Logo
