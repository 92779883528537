import styled from 'react-emotion'
import { Box } from 'spaced-components'
import { typo, duration } from '../styles/helpers'

const TileTitle = styled(Box.is('h2'))`
  ${typo('headline')};
  display: inline;
  letter-spacing: -1.4px;

  > span {
    position: relative;
    z-index: 0;
    left: -0.07em;
  }

  > span::after {
    content: '';
    display: block;
    height: 40%;
    position: absolute;
    z-index: -1;
    bottom: 11%;
    left: 0.05em;
    right: 0.05em;
    transform-origin: bottom;
    transform: scaleY(0.45);
    will-change: transform, opacity;
    opacity: 1;
    ${p =>
      p.underlineColors &&
      `background-image: linear-gradient(to right, ${p.underlineColors[0]}, ${
        p.underlineColors[1]
      })`};
    ${p => p.color && `background-color: ${p.color}`};
    transition: ${duration('slow')} opacity, ${duration('fast')} transform;
  }

  &:hover > span::after {
    transform: none;
  }
`

export default TileTitle
