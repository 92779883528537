import React from "react"
import Toggle from "react-toggled"
import emotionStyled from "react-emotion"
import styled from "../../styles/styled"
import {
  Absolute,
  Flex,
  Inset,
  min,
  On,
  rule,
  Space,
  zIndex
} from "spaced-components"
import { color, duration } from "../../styles/helpers"
import WhenTabIsInactive from "../WhenTabIsInactive"
import TwitterLink from "../TwitterLink"
import CloseButton from "../CloseButton"
import backgroundImage from "./quote-bg-ogilvy.jpg"

import quotes from "../../quotes"

const Overlay = emotionStyled("div")`
  position: fixed;
  z-index: ${zIndex("quote")};
  top: 0;
  right: 0;
  bottom: 0;
  ${p => rule("left", p.theme.barWidth)(p)};
  background-color: ${color("light", 400)};
  // background-image: url(${backgroundImage});
  // background-position: right bottom;
  // background-size: auto 70%;
  // background-repeat: no-repeat;
  visibility: hidden;
  opacity: 0;
  transition: ${duration("slow")};
  overflow-y: auto;
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAXCAYAAAAP6L+eAAAACXBIWXMAAAsSAAALEgHS3X78AAAAwUlEQVQ4y7XV0Q2DIBDG8T83QTcQYwdhhI7sCB3ABEZwA305H9pSOUB5A8KPy+UjuGnwL2BeUly5YDz9+ACCmwb/1rXQiys6AwgQdH3WjW4UCKJVduHf6JLiKgA9eA49WkEr/g/9gGvxM/QHtuIlNAuXcAsK4LZtM/fwuMiS+1M4g2N9TEVYcQ9EnY5Liql05raKpSanNTmXmpzW5Fxqw2/FpSX8FlxqUSsuLagFl1a0hLtp8M3o6Q9yBZqr3N31S+9BCsAN6wYnRgAAAABJRU5ErkJggg=="),
    auto;

  ${p =>
    p.active &&
    `visibility: visible;
     opacity: 1;
     transition: 0s;`};
`

const BlockQuote = emotionStyled("blockquote")`
  position: relative;
  margin: 0;
  ${p => p.maxWidth && `max-width: ${p.maxWidth}`};
  font-weight: 400;
  ${rule("font-size", { mobileSm: 33, mobileLg: 40 })};
  font-family: "Source Serif Pro", serif;
  ${min("laptopSm")} {
    font-size: 2.8vw;
    font-size: 2.8vmax;
  }
  line-height: 1.2;
  color: #000;

  > span:first-child::before {
    font-weight: 800;
    font-family: RidleyGrotesk, sans-serif;
    content: "“";
    ${min("laptopSm")} {
      position: absolute;
      top: -0.38em;
      left: -0.7em;
      font-size: 300%;
    }
  }

  > span:first-child::after {
    font-weight: 800;
    font-family: RidleyGrotesk, sans-serif;
    content: "”";
  }
`

const QuoteAuthor = styled("footer")(() => ({
  fontWeight: 800,
  fontSize: 24,
  fontFamily: "RidleyGrotesk, sans-serif"
}))

const getRandomQuote = () => quotes[Math.floor(Math.random() * quotes.length)]

let lastQuote

const QuoteOverlay = () => (
  <WhenTabIsInactive showOnce showAfter={2 * 60 * 1000}>
    {visible =>
      visible && (
        <Toggle defaultOn>
          {({ on, setOff, getTogglerProps }) => {
            const quote = on ? getRandomQuote() : lastQuote
            lastQuote = quote
            const tweetText = encodeURIComponent(
              `"${quote.text}" - ${
                quote.author
              } via @Ad_Victorem #designquotes #daretowin`
            )

            return (
              <Overlay active={on} onClick={setOff}>
                <On max="tablet">
                  <Absolute top="lg" right="lg">
                    <CloseButton {...getTogglerProps()} />
                  </Absolute>
                </On>
                <Flex center middle minHeight="100vh">
                  <Flex.Item width={{ mobileSm: "100%", laptopSm: "70%" }}>
                    <Inset x="lg" y="xl">
                      <BlockQuote maxWidth="20em">
                        <span>{quote.text}</span>
                        <Space lg />
                        <QuoteAuthor>
                          {quote.author}
                          <Space lg />
                          <TwitterLink
                            href={`https://twitter.com/intent/tweet?text=${tweetText}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Tweet this
                          </TwitterLink>
                        </QuoteAuthor>
                      </BlockQuote>
                    </Inset>
                  </Flex.Item>
                </Flex>
              </Overlay>
            )
          }}
        </Toggle>
      )
    }
  </WhenTabIsInactive>
)

export default QuoteOverlay
