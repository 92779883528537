import React from "react"
import styled from "react-emotion"
import { On, Box, Grid } from "spaced-components"

import { typo, duration } from "../styles/helpers"
import useScrolledBelow from "../hooks/useScrolledBelow"

import Play from "../components/Play"

const basename = process.env.PUBLIC_URL

const SocialLink = styled(Box.is("a"))`
  ${typo("linkLg")};
  color: #777;
  transition: color ${duration("fast")};

  ${p =>
    p.disabled
      ? "color: #343334"
      : `&:hover { color: #fff }
         &:active { color: #333; transition: 0s }`};
`

const Copyright = styled(props => <span {...props}>&copy;</span>)({
  display: "inline-block",
  marginTop: -7,
  transformOrigin: "40%",
  transform: "rotate(90deg)"
})

const Dot = styled(({ color, ...rest }) => <span {...rest}>.</span>)(
  ({ color }) => ({
    display: "inline-block",
    transform: "scale(1.1) translate(.02em)",
    color
  })
)

const SocialLinks = props => {
  const isBelow = useScrolledBelow(100)

  return (
    <On minHeight={500} dynamic>
      <Play sounds={{ playOnClick: `${basename}/assets/sounds/click_18.wav` }}>
        {({ playOnClick }) => (
          <div style={{ overflow: "hidden" }}>
            <Grid
              is="ul"
              template={`auto / repeat(6, max-content)`}
              style={{
                writingMode: "vertical-lr",
                transition: "transform .5s cubic-bezier(0.8, 0, 0.2, 1)",
                transform: `rotate(180deg) ${
                  isBelow ? "translateY(-140px)" : ""
                }`
              }}
              {...props}
            >
              <li
                style={{
                  transition: "opacity .5s cubic-bezier(0.8, 0, 0.2, 1)",
                  opacity: isBelow ? 0 : 1
                }}
              >
                <SocialLink is="span" disabled>
                  1 COR 15:57 <Copyright />
                  2018
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  href="https://twitter.com/Ad_Victorem"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={playOnClick}
                >
                  Tw
                  <Dot color="#40bfec" />
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  href="https://www.linkedin.com/company/ad-victorem/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={playOnClick}
                >
                  Ln
                  <Dot color="#003471" />
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  href="https://instagram.com/ad_victorem/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={playOnClick}
                >
                  Ig
                  <Dot color="#92278f" />
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  href="https://www.behance.net/ad_victorem"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={playOnClick}
                >
                  Be
                  <Dot color="#0072bc" />
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  href="https://medium.com/advictorem"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={playOnClick}
                >
                  M
                  <Dot color="#1AA77D" />
                </SocialLink>
              </li>
            </Grid>
          </div>
        )}
      </Play>
    </On>
  )
}

export default SocialLinks
