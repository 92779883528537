import React, { Fragment } from 'react'

const basename = process.env.PUBLIC_URL

export const projects = [
  {
    listed: true,
    key: 'turnkey',
    title: 'Turnkey Intelligence',
    titleMarkup: (
      <Fragment>
        <span>Keeping the sports</span>
        <br />
        <span>experts in the</span>
        <br />
        <span>digital game</span>
      </Fragment>
    ),
    tags: [
      'Creative Direction',
      'Website Design',
      'Development',
      'Social Content',
    ],
    color: '#ee434a',
    colors: ['#c1424c', '#ee434a'],
  },
  {
    listed: true,
    key: 'motiv-ring',
    title: 'Motiv Ring',
    titleMarkup: (
      <Fragment>
        <span>Launching</span>
        <br />
        <span>a tech startup</span>
      </Fragment>
    ),
    summary: 'Launching a tech startup',
    tags: [
      'Brand Identity',
      'Website Design',
      'Content Development',
      'Digital Advertising',
    ],
    color: '#2b2e2e',
    colors: ['#9099a9', '#546073'],
  },
  {
    listed: true,
    key: 'tennis-channel',
    title: 'Tennis Channel',
    titleMarkup: (
      <Fragment>
        <span>Upgrading tennis'</span>
        <br />
        <span>premiere website</span>
      </Fragment>
    ),
    summary: "Upgrading tennis' premiere website",
    tags: ['UX Design', 'Visual Design'],
    color: '#008568',
    colors: ['#16cc9e', '#038c6a'],
  },
  {
    listed: true,
    key: 'fox-digital',
    title: 'FOX Digital',
    titleMarkup: (
      <Fragment>
        <span>Extending the</span>
        <br />
        <span>story into social</span>
      </Fragment>
    ),
    summary: 'Extending the story into social',
    tags: ['Social Content', 'Brand Guidelines'],
    color: '#e6e147',
    colors: ['#4b6fc5', '#213F87'],
  },
  {
    listed: true,
    key: 'amc-preacher',
    title: 'AMC’s Preacher',
    titleMarkup: (
      <Fragment>
        <span>Activating fans</span>
        <br />
        <span>of a cult classic</span>
      </Fragment>
    ),
    summary: 'Activating fans of a cult classic',
    tags: ['Social Content', 'Digital Advertising'],
    color: '#ffcd38',
    colors: ['#debb28', '#904000'],
  },
  {
    listed: true,
    key: 'azubu',
    title: 'Azubu',
    titleMarkup: (
      <Fragment>
        <span>Leveling up a</span>
        <br />
        <span>global eSports</span>
        <br />
        <span>brand</span>
      </Fragment>
    ),
    tags: [
      'Brand Strategy',
      'Creative Direction',
      'Website Design',
      'Social Content',
    ],
    color: '#077dd5',
    colors: ['#077dd5', '#4d1739'],
  },
  {
    listed: true,
    key: 'espn',
    title: 'ESPN',
    titleMarkup: (
      <Fragment>
        <span>Hyping up the</span>
        <br />
        <span>return of college</span>
        <br />
        <span>football</span>
      </Fragment>
    ),
    summary: 'Hyping up the return of college football',
    tags: ['Visual Design', 'Digital Advertising'],
    color: '#941608',
    colors: ['#781307', '#a8370d'],
  },
  {
    listed: true,
    key: 'lafc',
    title: 'LAFC',
    titleMarkup: (
      <Fragment>
        <span>Going Metro with</span>
        <br />
        <span>Latif Blessing</span>
      </Fragment>
    ),
    summary: 'Going Metro with Latif Blessing',
    tags: ['Video Production', 'Creative Direction'],
    color: '#111',
    colors: ['#333', '#222'],
  },
  {
    listed: true,
    key: 'nestea',
    title: 'Nestea',
    titleMarkup: (
      <Fragment>
        <span>Adding flavor</span>
        <br />
        <span>to summertime</span>
      </Fragment>
    ),
    summary: 'Adding flavor to summertime',
    tags: ['Brand Architecture', 'Visual Design'],
    color: '#07b0f3',
    colors: ['#047fcd', '#27aad6'],
  },
  {
    listed: true,
    key: 'ac-hotels',
    title: 'AC Hotels',
    titleMarkup: (
      <Fragment>
        <span>A hotel for</span>
        <br />
        <span>the modern</span>
        <br />
        <span>entrepreneur</span>
      </Fragment>
    ),
    summary: 'Revitalizing Wilshire Blvd for the modern entrepreneur',
    tags: ['Creative Direction', 'Website Design'],
    color: '#c6c4b9',
    colors: ['#c6c4b9', '#c6c4b9'],
  },
  {
    listed: true,
    key: 'dignity-health',
    title: 'Dignity Health',
    titleMarkup: (
      <Fragment>
        <span>Empowering</span>
        <br />
        <span>Humankindness</span>
      </Fragment>
    ),
    summary: 'Empowering Humankindness',
    tags: ['Website Design', 'Development'],
    color: '#2e2942',
    colors: ['rgba(239, 116, 24, .9)', 'rgba(255, 132, 40, 0.9)'],
  },
  {
    listed: true,
    key: 'paramount-equity-mortgage',
    title: 'Paramount Equity Mortgage',
    tags: [
      'Brand Strategy',
      'Brand Identity',
      'Website Design',
      'Digital Advertising',
    ],
    color: '#013990',
    colors: ['#43adff', '#013990'],
  },
  {
    listed: true,
    key: 'burbank',
    title: 'iHeartBurbank',
    tags: ['Visual Design', 'Website Design', 'Video Production'],
    color: '#901862',
    colors: ['#00c0ed', '#901862'],
  },
  {
    listed: true,
    key: 'aliso-viejo',
    title: 'Aliso Viejo',
    tags: ['Visual Design', 'Website Design', 'Video Production'],
    color: '#00597f',
    colors: ['#00c5bb', '#00597f'],
  },
  {
    listed: true,
    key: 'press-pops',
    title: 'Press Pops',
    tags: [
      'Brand Messaging',
      'Brand Identity',
      'Packaging Design',
      'Website Design',
    ],
    color: '#626429',
    colors: ['#c2c824', '#626429'],
  },
  {
    listed: true,
    key: 'cbs',
    title: 'CBS TV App',
    tags: ['UX Design', 'Visual Design'],
    color: '#0f3b6c',
    colors: ['#2d8be5', '#0f3b6c'],
  },
  {
    listed: false,
    key: 'sungreen-systems',
    title: 'Sungreen Systems',
    tags: ['Brand Strategy', 'Website Design'],
    color: '#88b301',
    colors: ['#88b301', '#5f2b03'],
  },
  {
    listed: true,
    key: 'plum-lending',
    title: 'Plum Lending',
    titleMarkup: (
      <Fragment>
        <span>Marketing to</span>
        <br />
        <span>the new adventurers</span>
      </Fragment>
    ),
    tags: [
      'Brand Strategy',
      'Brand Identity',
      'Web Design',
      'Marketing Planning',
    ],
    color: '#421690',
    colors: ['#009A00', '#72DB00'],
  },
  {
    listed: false,
    key: 'rams',
    title: 'Los Angeles Rams',
    tags: ['Art Direction', 'Digital Advertising'],
    color: '#001865',
    colors: ['#001865', '#001865'],
  },
]

export const listedProjects = projects.filter(p => p.listed)

export const projectURL = key => `/case-studies/${key}`

export const projectImageURL = key => `${basename}/assets/projects/${key}.jpg`

export const getProject = key => projects.filter(p => p.key === key)[0]

export const getPrevProject = key => {
  const currentProjectIndex = projects.indexOf(getProject(key))
  return currentProjectIndex - 1 >= 0
    ? projects[currentProjectIndex - 1]
    : projects[projects.length - 1]
}

export const getNextProject = key => {
  const currentProjectIndex = projects.indexOf(getProject(key))
  return currentProjectIndex + 1 < projects.length
    ? projects[currentProjectIndex + 1]
    : projects[0]
}
