import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import {
  Inset,
  Board,
  Break,
  Flex,
  Stack,
  On,
  Grid,
  Space,
} from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import Parallax from '../../components/Parallax'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'
import VideoPlayer from '../../components/VideoPlayer'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'

import cfbLogo from './assets/cfb-logo.png'
import endframe01 from './assets/endframe-01.png'
import endframe02 from './assets/endframe-02.png'
import endframe03 from './assets/endframe-03.png'
import facebook from './assets/facebook.png'
import footballField from './assets/football-field.png'
import humanAla from './assets/human-ala.png'
import humanFsu from './assets/human-fsu.png'
import leaderboardAd01 from './assets/leaderboard-ad-01.png'
import leaderboardAd02 from './assets/leaderboard-ad-02.png'
import logo01 from './assets/logo-01.png'
import logo02 from './assets/logo-02.png'
import logo03 from './assets/logo-03.png'
import logo04 from './assets/logo-04.png'
import logo05 from './assets/logo-05.png'
import logo06 from './assets/logo-06.png'
import twitter from './assets/twitter.png'
import wireframe from './assets/wireframe.png'
import video from './assets/video.mp4'
import webroll from './assets/webroll.mp4'

const currentProject = getProject('espn')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#8a0f08' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  Hyping up the return of College Football
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  No brand says "sports" quite like ESPN. But one of the media
                  giant's biggest challenges of the last few years has been
                  keeping up with all of the new ways to interact with fans
                  however and wherever they connect with their favorite teams.
                  So when RED Interactive brought us on to execute the creative
                  strategy on ESPN's new College Football digital advertising
                  campaign, we knew we would have to combine ESPN's storied
                  legacy with an innovative approach to connecting with their
                  fans. Putting our design and animation touch on world-class
                  sports brands made this an incredibly exciting project to work
                  on — to say we’re passionate about sports is an
                  understatement.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>ESPN</DetailValue>
                </li>
                <li>
                  <Detail muted>Agency:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>RED Interactive</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Entertainment</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Visual Design
                    <Break />
                    Digital Advertising
                  </DetailValue>
                </li>
              </Stack>
            }
          />
          <Space y={-30} />
          <AutoSlide
            up
            style={{
              maxWidth: 400,
              minWidth: 200,
              width: '30vw',
              margin: 'auto',
              position: 'relative',
              zIndex: 2,
            }}
          >
            <Img src={cfbLogo} ratio={527 / 500} />
          </AutoSlide>
          <Space y="-14.5vw" />
        </Inset>
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Grid template="1fr / repeat(6, 1fr)" alignItems="center">
          <Grid.Item row="1 / 2" column="1 / -1">
            <AutoFade>
              <Img src={footballField} ratio={861 / 1739} />
            </AutoFade>
          </Grid.Item>
          <Grid.Item row="1 / 2" column="2 / -1" zIndex={1}>
            <On min="tabletMd">
              <AutoSlide up>
                <Space y={40} />
                <Title is="h2">Our Challenge</Title>
                <Space md />
                <Paragraph css={{ width: '40%' }}>
                  The college football season is back and ESPN wanted to focus
                  on driving viewership to their marquee games. In the past, the
                  approach would drive to a slew of priority and secondary games
                  but this year we focused on a digital campaign that elevated
                  the storylines surrounding main matchups.
                </Paragraph>
              </AutoSlide>
            </On>
          </Grid.Item>
        </Grid>
        <Grid templateColumns="repeat(6, 1fr)" alignItems="center">
          <Grid.Item>
            <AutoFade delay={0 * 100}>
              <Img
                src={logo01}
                ratio={1}
                style={{ width: '80%', margin: 'auto' }}
              />
            </AutoFade>
          </Grid.Item>
          <Grid.Item>
            <AutoFade delay={1 * 100}>
              <Img
                src={logo02}
                ratio={1}
                style={{ width: '80%', margin: 'auto' }}
              />
            </AutoFade>
          </Grid.Item>
          <Grid.Item>
            <AutoFade delay={2 * 100}>
              <Img
                src={logo03}
                ratio={217 / 226}
                style={{ width: '80%', margin: 'auto' }}
              />
            </AutoFade>
          </Grid.Item>
          <Grid.Item>
            <AutoFade delay={3 * 100}>
              <Img
                src={logo04}
                ratio={211 / 217}
                style={{ width: '80%', margin: 'auto' }}
              />
            </AutoFade>
          </Grid.Item>
          <Grid.Item>
            <AutoFade delay={4 * 100}>
              <Img
                src={logo05}
                ratio={188 / 244}
                style={{ width: '80%', margin: 'auto' }}
              />
            </AutoFade>
          </Grid.Item>
          <Grid.Item>
            <AutoFade delay={5 * 100}>
              <Img
                src={logo06}
                ratio={185 / 224}
                style={{ width: '80%', margin: 'auto' }}
              />
            </AutoFade>
          </Grid.Item>
        </Grid>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <Space xl />
          <div style={{ textAlign: 'center' }}>
            <AutoFade>
              <Title is="h4">A story in every game</Title>
            </AutoFade>
            <Space lg />
            <AutoFade>
              <Paragraph css={{ maxWidth: '41em', margin: 'auto' }}>
                Based on the customer experience research we were tasked to
                create a campaign that would understand and accommodate the
                fan’s experience of CFB, beyond just “tuning in”.
              </Paragraph>
            </AutoFade>
          </div>
          <Space xxl />
          <Board.Item
            offset={{ tabletMd: 1 / 6 }}
            width={{ mobile: 1, tabletMd: 4 / 6 }}
          >
            <Space y={{ tabletSm: -40 }} />
            <Space x={{ tabletSm: '-5.55vmax' }}>
              <WhenVisible>
                {inView => (
                  <BackgroundText is="h4" animate={inView}>
                    CREATIVE
                  </BackgroundText>
                )}
              </WhenVisible>
            </Space>
            <Space y={{ tabletSm: '-6.35vmax' }} />
            <Grid
              template={{
                mobile: 'repeat(2, 1fr) / 1fr',
                tabletLg: 'repeat(3, 1fr) / repeat(3, 1fr)',
              }}
            >
              <Grid.Item
                area={{ mobile: 'auto', tabletLg: '1 / 1 / 3 / 3' }}
                zIndex={1}
              >
                <AutoFade>
                  <Img src={humanAla} ratio={594 / 694} />
                </AutoFade>
              </Grid.Item>
              <Grid.Item area={{ mobile: 'auto', tabletLg: '2 / 2 / 4 / 4' }}>
                <AutoFade>
                  <Img src={humanFsu} ratio={594 / 694} />
                </AutoFade>
              </Grid.Item>
            </Grid>
            <Space xl />
            <Grid
              template={{
                mobile: 'repeat(2, 1fr) / 1fr',
                tabletLg: 'repeat(3, 1fr) / repeat(3, 1fr)',
              }}
            >
              <Grid.Item area={{ mobile: 'auto', tabletLg: '1 / 1 / 3 / 3' }}>
                <AutoFade>
                  <Img src={twitter} ratio={594 / 694} />
                </AutoFade>
              </Grid.Item>
              <Grid.Item area={{ mobile: 'auto', tabletLg: '2 / 2 / 4 / 4' }}>
                <AutoFade>
                  <Img src={facebook} ratio={594 / 694} />
                </AutoFade>
              </Grid.Item>
            </Grid>
          </Board.Item>
          <On min="tabletLg">
            <Space xl />
            <WhenVisible>
              {inView => (
                <Box
                  css={{ width: '88%', margin: 'auto', position: 'relative' }}
                >
                  <AutoFade style={{ width: '82%', margin: 'auto' }}>
                    <Img src={wireframe} ratio={993 / 1361} />
                  </AutoFade>
                  <div
                    style={{
                      width: '71.5%',
                      position: 'absolute',
                      top: '5.5%',
                      left: '14.8%',
                      transition: 'opacity 1s .3s, transform 1s 1s',
                      opacity: inView ? 1 : 0,
                      transform: inView ? 'none' : 'scale(1.4)',
                    }}
                  >
                    <Img src={leaderboardAd01} ratio={205 / 1585} />
                  </div>
                  <div
                    style={{
                      width: '19.9%',
                      position: 'absolute',
                      top: '39.2%',
                      left: '64.9%',
                      transition: 'opacity 1s .3s, transform 1s 1s',
                      opacity: inView ? 1 : 0,
                      transform: inView ? 'none' : 'scale(1.4)',
                    }}
                  >
                    <Img src={leaderboardAd02} ratio={381 / 441} />
                  </div>
                </Box>
              )}
            </WhenVisible>
          </On>
          <Space xl />

          <Board.Item
            offset={{ tabletMd: 1 / 6 }}
            width={{ mobile: 1, tabletMd: 4 / 6 }}
          >
            <Grid
              template={{
                mobile: 'repeat(2, 1fr) / 1fr',
                tabletLg: 'repeat(4, 1fr) / repeat(4, 1fr)',
              }}
            >
              <Grid.Item area={{ mobile: 'auto', tabletLg: '1 / 1 / 3 / 3' }}>
                <AutoFade>
                  <Img src={endframe01} ratio={594 / 694} />
                </AutoFade>
              </Grid.Item>
              <Grid.Item area={{ mobile: 'auto', tabletLg: '2 / 2 / 4 / 4' }}>
                <AutoFade>
                  <Img src={endframe02} ratio={594 / 694} />
                </AutoFade>
              </Grid.Item>
              <Grid.Item area={{ mobile: 'auto', tabletLg: '3 / 3 / 5 / 5' }}>
                <AutoFade>
                  <Img src={endframe03} ratio={594 / 694} />
                </AutoFade>
              </Grid.Item>
            </Grid>
          </Board.Item>
          <Space xl />
          <AutoSlide up>
            <Box
              css={{
                width: { tablet: '80%' },
                margin: 'auto',
                boxShadow: '0 20px 50px rgba(0, 0, 0, .3)',
              }}
            >
              <VideoPlayer
                webroll={webroll}
                video={video}
                ratio={1080 / 1920}
              />
            </Box>
          </AutoSlide>
          <Space xxl />
        </Inset>
      </Box>
      <Parallax direction="both" height="55vh" maxHeight={600}>
        <Parallax.Background height="150%">
          <Box css={{ height: '100%', backgroundColor: '#8a0f08' }}>
            <Box
              css={{
                display: 'flex',
                alignItems: 'center',
                width: { mobile: '200%', tabletLg: '110%' },
                height: '100%',
                position: 'relative',
                left: '-5%',
              }}
            >
              <svg viewBox="0 0 51.23 12.78" fill="#770c06" width="100%">
                <path d="M15.52,0c-1,0-2.69,1.11-2.69,3.61H38.14C38.48-.1,35.87,0,35.87,0ZM12.61,4.93V6.1a1.94,1.94,0,0,0,2,1.75h6.13l-.19,1.5H12.24l-.44,3.42h9.73a3.25,3.25,0,0,0,3-2.61S25,7,25,6.85a1.83,1.83,0,0,0-2-1.92Zm13.24,0H38l-.26,1.36a2,2,0,0,1-2.15,1.56H29.63l-.56,4.93H25l.9-7.85ZM38.63,3.61H51.09C51.65,1.2,50.48,0,49.26,0H39.09l-.46,3.61Zm3.07,9.17H37.58l.9-7.85h4.17l-.95,7.85Zm8.29,0H45.87l.9-7.85h4.17L50,12.78ZM.93,4.93H12.07l-.36,2.92H4.85L4.63,9.36h6.88l-.41,3.42H0L.93,4.93ZM1.49,0H12.63l-.47,3.61H1.1L1.49,0Z" />
              </svg>
            </Box>
          </Box>
        </Parallax.Background>
        <Parallax.Content>
          <Flex height="100%" center middle>
            <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
              <Title
                is="blockquote"
                css={{ maxWidth: '18em', textAlign: 'center' }}
              >
                We executed a visual campaign that evolved throughout the season
                using the most relevant, interesting and compelling storylines.
              </Title>
            </Inset>
          </Flex>
        </Parallax.Content>
      </Parallax>
    </CaseStudyLayout>
  </Fragment>
)
