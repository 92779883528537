import React from 'react'

import { Stack, Break } from 'spaced-components'
import { Detail, DetailValue } from '../projects/CaseStudyLayout'
import TextUnderline from './TextUnderline'

const FooterData = props => (
  <Stack is="ul" rowGap={15} {...props}>
    <li>
      <Detail muted>WHO WE ARE:</Detail>
      <Break />
      <DetailValue style={{ display: 'inline-block', maxWidth: '13em' }}>
        Based in Los Angeles, Ad&nbsp;Victorem is a&nbsp;competitive branding
        and marketing agency that combines award-winning creativity and business
        strategy for those who dare to win.
      </DetailValue>
    </li>
    <li>
      <Detail muted>GET IN TOUCH:</Detail>
      <Break />
      <a href="tel:+03234573899">
        <TextUnderline color="rgba(255, 255, 255, .25)">
          <DetailValue>323.457.3899</DetailValue>
        </TextUnderline>
      </a>
    </li>
    <li>
      <Detail muted>Business Inquiries:</Detail>
      <Break />
      <a href="mailto:hello@advictorem.agency">
        <TextUnderline color="rgba(255, 255, 255, .25)">
          <DetailValue>hello@advictorem.agency</DetailValue>
        </TextUnderline>
      </a>
    </li>
    <li>
      <Detail muted>JOIN OUR TEAM:</Detail>
      <Break />
      <a href="mailto:jobs@advictorem.agency">
        <TextUnderline color="rgba(255, 255, 255, .25)">
          <DetailValue>jobs@advictorem.agency</DetailValue>
        </TextUnderline>
      </a>
    </li>
  </Stack>
)

export default FooterData
