import React from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'emotion-theming'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { NavbarThemeProvider } from './components/NavbarTheme'
import { BusinessTypeProvider } from './components/BusinessTypeContext'
import { TileTransitionProvider } from './components/TileTransition'
import theme from './styles/theme'
import './styles/global'

import QuoteOverlay from './components/QuoteOverlay'
import ScrollToTop from './components/ScrollToTop'

import Layout from './Layout'
import Welcome from './pages/Welcome'
import OurClients from './pages/OurClients'
import Awards from './pages/Awards'
import OurWork from './pages/OurWork'
//import OurAgency from './pages/OurAgency'
import OurPlaybook from './pages/OurPlaybook'
import OurBlog from './pages/OurBlog'
import YourTurn from './pages/YourTurn'
import Affiliations from './pages/Affiliations'
import Indigo from './pages/Indigo'
import PageNotFound from './pages/PageNotFound'

import MotivRing from './projects/MotivRing'
import TennisChannel from './projects/TennisChannel'
import FoxDigital from './projects/FoxDigital'
import AmcPreacher from './projects/AmcPreacher'
import Espn from './projects/Espn'
import Lafc from './projects/Lafc'
import Nestea from './projects/Nestea'
import DignityHealth from './projects/DignityHealth'
import Azubu from './projects/Azubu'
import Rams from './projects/Rams'
import ParamountEquityMortgage from './projects/ParamountEquityMortgage'
import IHeartBurbank from './projects/IHeartBurbank'
import AcHotels from './projects/AcHotels'
import AlisoViejo from './projects/AlisoViejo'
import PressPops from './projects/PressPops'
import Cbs from './projects/Cbs'
import SungreenSystems from './projects/SungreenSystems'
import PlumLending from './projects/PlumLending'
import Turnkey from './projects/Turnkey'

import { importGSAP } from './components/Timeline'

importGSAP(() => import(/* webpackChunkName: "gsap" */ './lib/gsap'))

export default () => (
  <ThemeProvider theme={theme}>
    <TileTransitionProvider>
      <BusinessTypeProvider>
        <NavbarThemeProvider>
          <Router>
            <Layout>
              <Helmet
                defaultTitle="Ad Victorem - For Those Who Dare To Win | Brand Strategy + Design"
                titleTemplate="%s | Ad Victorem - For Those Who Dare To Win | Brand Strategy + Design"
              />
              <ScrollToTop />
              <QuoteOverlay />

              <Switch>
                <Route exact path="/" component={Welcome} />
                <Route path="/clients" component={OurClients} />
                <Route path="/awards" component={Awards} />
                <Route path="/our-work" component={OurWork} />
                {/*<Route path="/our-agency" component={OurAgency} /> */}
                <Redirect from="/our-agency" to="/" />
                <Route path="/our-playbook" component={OurPlaybook} />
                <Route path="/our-blog" component={OurBlog} />
                <Route path="/contact" component={YourTurn} />
                <Route path="/affiliations" component={Affiliations} />
                <Route path="/indigo" component={Indigo} />

                <Route
                  path="/case-studies/tennis-channel"
                  component={TennisChannel}
                />
                <Route path="/case-studies/ac-hotels" component={AcHotels} />
                <Route path="/case-studies/motiv-ring" component={MotivRing} />
                <Route
                  path="/case-studies/fox-digital"
                  component={FoxDigital}
                />
                <Route
                  path="/case-studies/amc-preacher"
                  component={AmcPreacher}
                />
                <Route path="/case-studies/espn" component={Espn} />
                <Route path="/case-studies/lafc" component={Lafc} />
                <Route path="/case-studies/nestea" component={Nestea} />
                <Route
                  path="/case-studies/dignity-health"
                  component={DignityHealth}
                />
                <Route path="/case-studies/azubu" component={Azubu} />
                <Route path="/case-studies/rams" component={Rams} />
                <Route
                  path="/case-studies/paramount-equity-mortgage"
                  component={ParamountEquityMortgage}
                />
                <Route path="/case-studies/burbank" component={IHeartBurbank} />
                <Route
                  path="/case-studies/aliso-viejo"
                  component={AlisoViejo}
                />
                <Route path="/case-studies/press-pops" component={PressPops} />
                <Route path="/case-studies/cbs" component={Cbs} />
                <Route
                  path="/case-studies/sungreen-systems"
                  component={SungreenSystems}
                />
                <Route
                  path="/case-studies/plum-lending"
                  component={PlumLending}
                />
                <Route path="/case-studies/turnkey" component={Turnkey} />

                <Route component={PageNotFound} />
              </Switch>
            </Layout>
          </Router>
        </NavbarThemeProvider>
      </BusinessTypeProvider>
    </TileTransitionProvider>
  </ThemeProvider>
)
