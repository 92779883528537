import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Component from '@reach/component-component'
import Swiper from '../../lib/swiper4'
import {
  Inset,
  Columns,
  Relative,
  Absolute,
  On,
  Break,
  Flex,
  Stack,
  Grid,
  Space,
  rule,
  min,
} from 'spaced-components'
import emotionStyled from 'react-emotion'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import Parallax from '../../components/Parallax'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'
import VideoPlayer from '../../components/VideoPlayer'
import ShowCells from '../../components/ShowCells'
import LogoCell from '../../components/LogoCell'

import CaseStudyLayout, {
  boxShadow,
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'
import logo from './logo.svg'

import webroll01 from './assets/webroll01.mp4'
import video01 from './assets/video01.mp4'
import webroll02 from './assets/webroll02.mp4'
import video02 from './assets/video02.mp4'

import grid from './assets/grid.svg'
import user1 from './assets/user1.svg'
import user2 from './assets/user2.svg'
import user3 from './assets/user3.svg'
import uxJourneyMap from './assets/ux-journey-map.svg'
import devices from './assets/devices.png'
// import info01Image from "./assets/info-01.png"
// import info02Image from "./assets/info-02.png"
import info03Image from './assets/info-03.png'
import info04Image from './assets/info-04.png'
import screen01 from './assets/app-screen-01.jpg'
import screen02 from './assets/app-screen-02.jpg'
import screen03 from './assets/app-screen-03.jpg'
import screen04 from './assets/app-screen-04.jpg'
import screen05 from './assets/app-screen-05.jpg'
// import skew01 from "./assets/skew-01.png"
// import skew02 from "./assets/skew-02.png"
// import skew03 from "./assets/skew-03.png"
// import skew04 from "./assets/skew-04.png"
import webpage from './assets/webpage.png'
import appIcon from './assets/app-icon.png'
// import sketch from "./assets/sketch.png"
import wsj from './assets/wall-street-journal-logo.svg'
import techCrunch from './assets/tech-crunch-logo.svg'
import forbes from './assets/forbes.svg'
import iPhone from './assets/iPhone.png'
import parallax01 from './assets/parallax-bg-01.jpg'
import parallax02 from './assets/parallax-bg-02.jpg'

import storyboard1 from './assets/storyboard1.png'
import storyboard2 from './assets/storyboard2.png'
import storyboard3 from './assets/storyboard3.png'
import storyboard4 from './assets/storyboard4.png'
import photos from './assets/photos.png'
import roseGoldRing from './assets/rose-gold-ring.png'
import slateRing from './assets/slate-ring.png'
import webbyStatue from './assets/advictorem-webby-statue.png'

const currentProject = getProject('motiv-ring')

const SliderContainer = emotionStyled('div')`
  ${rule('width', { mobileSm: 'calc(100vw - 80px)', tabletSm: '14.5vw' })};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  /* Fix of Webkit flickering */
  z-index: 1;
  user-select: none;

  .swiper-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    will-change: transform;
  }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    position: relative;
    opacity: 0.3;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .3);
    transition: opacity .5s;
  }

  .swiper-slide.swiper-slide-active {
    opacity: 1;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  ${min('tablet')} {
    .swiper-pagination {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
    margin: 0 8px;
    box-shadow: inset 0 0 0 1px;
    transition: background-color .5s;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
`

const SliderFadeContainer = emotionStyled('div')`
  ${rule('width', { mobileSm: '75vw', tabletSm: '48vw' })};
  position: relative;
  /* Fix of Webkit flickering */
  z-index: 1;
  user-select: none;
  overflow: hidden;

  .swiper-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    will-change: transform;
  }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    position: relative;
    opacity: 0.3;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .3);
    transition: opacity .5s;
  }

  .swiper-slide.swiper-slide-active {
    opacity: 1;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  ${min('tablet')} {
    .swiper-pagination {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50%;
    margin: 0 8px;
    box-shadow: inset 0 0 0 1px;
    transition: background-color .5s;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
`

// const TallText = styled("span")(({ fontSize = "11.2vw", color = "#000" }) => ({
//   letterSpacing: "0.1em",
//   fontFamily: "TungstenMedium, sans-serif",
//   textTransform: "uppercase",
//   fontSize,
//   color
// }))

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout
      currentProjectKey={currentProject.key}
      logo={<img src={logo} alt="" style={{ maxWidth: 300 }} />}
    >
      <Box css={{ backgroundColor: '#1C1D1F' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1">Launching a tech startup</Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  In 2017, at the height of the wearable market, wristbands and
                  watches became somewhat of a tired form factor. Motiv is a
                  Silicon Valley venture that designed and engineered an
                  innovative fitness tracker ring, something that has never been
                  done before. We were tasked with conceptualizing and executing
                  the creative strategy to bring this stylish wearable to
                  market.
                </Paragraph>
                <Space xl />
                <Box
                  css={{
                    width: '25vmax',
                    height: '16vmax',
                    position: 'relative',
                    marginLeft: '6vmax',
                    'max:tablet': {
                      margin: 'auto',
                      transform: 'scale(1.4)',
                    },
                  }}
                >
                  <Absolute width="16vmax" left top="1vw">
                    <AutoFade>
                      <Img src={roseGoldRing} ratio={285 / 270} />
                    </AutoFade>
                  </Absolute>
                  <Absolute width="19vmax" left="7vmax">
                    <AutoFade delay={200}>
                      <Img src={slateRing} ratio={317 / 296} />
                    </AutoFade>
                  </Absolute>
                </Box>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Motiv Inc.</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Consumer Technology</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Brand Identity
                    <Break />
                    Website Design
                    <Break />
                    Content Development
                    <Break />
                    Digital Advertising
                  </DetailValue>
                </li>
                <li>
                  <Detail muted>Awards:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    American Advertising Awards
                    <Break />
                    The Communicator Awards
                    <Break />
                    dotComm Awards
                    <Break />
                    W3 Awards
                    <Break />
                    Webby Awards
                  </DetailValue>
                </li>
              </Stack>
            }
          />
          <AutoSlide up>
            <Box
              css={{
                margin: { mobile: '0 auto -32vw', tablet: '-30px auto -21vw' },
                width: { tablet: '70%' },
              }}
            >
              <Img src={devices} ratio={949 / 1254} />
            </Box>
          </AutoSlide>
        </Inset>
      </Box>
      <Box
        css={{
          textAlign: 'center',
          backgroundColor: '#0a0a0a',
          overflowX: 'hidden',
        }}
      >
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <Space y={{ mobile: '32vw', tablet: '21vw' }} />
          <Space mobile="xl" tablet={0} />
          <WhenVisible>
            {inView => (
              <Box css={{ width: { tabletMd: '66.666%' }, margin: 'auto' }}>
                <On min="tabletSm">
                  <Space x={{ tabletMd: '-5.55vmax' }}>
                    <BackgroundText
                      is="h4"
                      animate={inView}
                      style={{ textAlign: 'left' }}
                    >
                      STRATEGY
                    </BackgroundText>
                  </Space>
                </On>
                <Space y={{ tabletMd: '-7vw' }} />
                <Grid templateColumns="repeat(4, 1fr)" templateRows="3fr 9fr">
                  <Grid.Item
                    area={{
                      mobile: '1 / 1 / 3 / 5',
                      tablet: '1 / 1 / 3 / 4',
                    }}
                  >
                    <AutoSlide up>
                      <Img src={info03Image} ratio={432 / 686} />
                    </AutoSlide>
                  </Grid.Item>
                  <Grid.Item area="2 / 3 / 3 / 5">
                    <On min="tablet">
                      <AutoSlide up delay={150}>
                        <Img src={info04Image} ratio={432 / 426} />
                      </AutoSlide>
                    </On>
                  </Grid.Item>
                </Grid>
                <Space xl tabletMd="xxl" />
                <AutoFade>
                  <Title is="h4" css={{ maxWidth: '16.2em' }}>
                    Positioning Motiv as a <Break />
                    fashionable lifestyle product
                  </Title>
                </AutoFade>
              </Box>
            )}
          </WhenVisible>
          <Space lg tabletMd="xl" />
          <On min="tabletLg">
            <Grid
              width="70%"
              style={{ margin: 'auto' }}
              gap={40}
              templateColumns={{
                mobile: 'repeat(1, 1fr)',
                tablet: 'repeat(2, 1fr)',
                laptop: 'repeat(3, 1fr)',
              }}
            >
              <Grid.Item>
                <Grid template="1fr 3fr 1fr / 1fr 3fr 1fr">
                  <Grid.Item area="1 / 1 / -1 / -1">
                    <AutoFade>
                      <Img src={grid} ratio={1} />
                    </AutoFade>
                  </Grid.Item>
                  <Grid.Item area="2 / 2 / -2 / -2">
                    <AutoFade delay={400}>
                      <Img src={user1} ratio={1} />
                    </AutoFade>
                  </Grid.Item>
                </Grid>
                <Space y={20} />
                <Flex column>
                  <Detail muted>PERSONA:</Detail>
                  <DetailValue>The Fitness Junkie</DetailValue>
                </Flex>
              </Grid.Item>
              <Grid.Item>
                <Grid template="1fr 3fr 1fr / 1fr 3fr 1fr">
                  <Grid.Item area="1 / 1 / -1 / -1">
                    <AutoFade>
                      <Img src={grid} ratio={1} />
                    </AutoFade>
                  </Grid.Item>
                  <Grid.Item area="2 / 2 / -2 / -2">
                    <AutoFade delay={400}>
                      <Img src={user2} ratio={1} />
                    </AutoFade>
                  </Grid.Item>
                </Grid>
                <Space y={20} />
                <Flex column>
                  <Detail muted>PERSONA:</Detail>
                  <DetailValue>The Professional</DetailValue>
                </Flex>
              </Grid.Item>
              <Grid.Item>
                <Grid template="1fr 3fr 1fr / 1fr 3fr 1fr">
                  <Grid.Item area="1 / 1 / -1 / -1">
                    <AutoFade>
                      <Img src={grid} ratio={1} />
                    </AutoFade>
                  </Grid.Item>
                  <Grid.Item area="2 / 2 / -2 / -2">
                    <AutoFade delay={400}>
                      <Img src={user3} ratio={1} />
                    </AutoFade>
                  </Grid.Item>
                </Grid>
                <Space y={20} />
                <Flex column>
                  <Detail muted>PERSONA:</Detail>
                  <DetailValue>The Early Adopter</DetailValue>
                </Flex>
              </Grid.Item>
            </Grid>
          </On>
          <Space xl tabletMd="xxl" />
          <WhenVisible>
            {inView => (
              <Fragment>
                <On min="tabletSm">
                  <Space x={{ tabletMd: '-5.55vmax' }}>
                    <Box
                      css={{ width: { tabletMd: '66.666%' }, margin: 'auto' }}
                    >
                      <BackgroundText
                        is="h4"
                        animate={inView}
                        size="12vw"
                        style={{ textAlign: 'left' }}
                      >
                        PHOTOGRAPHY
                      </BackgroundText>
                    </Box>
                  </Space>
                </On>
                <Space y={{ tabletMd: '-7vw' }} />
                <AutoFade>
                  <Img src={photos} ratio={1188 / 1839} />
                </AutoFade>
              </Fragment>
            )}
          </WhenVisible>
          <Space xl tabletMd="xxl" />
          <AutoFade>
            <Title is="h4" css={{ maxWidth: '16em' }}>
              We created a frictionless customer journey that increased
              pre-order sales
            </Title>
          </AutoFade>
          <Space lg />
          <AutoFade>
            <Paragraph css={{ maxWidth: '35em', margin: 'auto' }}>
              Unlike typical consumer products, Motiv Ring had many variables
              that needed to be communicated clearly such as how sizing, color,
              and location worked. We also had to anticipate a user framework to
              transition the checkout experience from ‘Pre-Order’ to ‘Buy Now’
            </Paragraph>
          </AutoFade>
          <On min="tabletMd">
            <Space xxl />
            <Img
              src={uxJourneyMap}
              ratio={660.1 / 1242.8}
              style={{ width: '66.666%', margin: 'auto' }}
            />
          </On>
          <Space xl />
        </Inset>
        <Box
          css={{
            width: { tablet: '66.666%' },
            margin: 'auto',
          }}
        >
          <Inset
            x={{ mobile: 'lg', tabletMd: 0 }}
            style={{ textAlign: 'left' }}
          >
            <Space x={{ tabletMd: '-5.55vmax' }}>
              <WhenVisible>
                {inView => (
                  <On min="tabletMd">
                    <BackgroundText is="h4" animate={inView}>
                      DESIGN
                    </BackgroundText>
                  </On>
                )}
              </WhenVisible>
            </Space>
            <Space y={{ tabletMd: '-6.35vmax' }} />
            <AutoFade>
              <Img src={webpage} ratio={4143 / 1227} />
            </AutoFade>
            <Space xl />
          </Inset>
        </Box>

        <Parallax direction="both" height="65vh" maxHeight={700}>
          <Parallax.Background height="150%">
            <Img src={parallax01} width="100%" height="100%" />
          </Parallax.Background>
          <Parallax.Content> </Parallax.Content>
        </Parallax>

        <Box css={{ backgroundColor: '#451f33' }}>
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <Space y={{ mobile: 70, tablet: 150 }} />
            <AutoFade>
              <Img
                src={appIcon}
                ratio={1}
                width={254}
                height={254}
                style={{ margin: 'auto' }}
              />
            </AutoFade>
            <Space y={{ mobile: 40, tabletMd: 80 }} />
            <Relative>
              <Component
                refs={{ root: null }}
                initialState={{ slider: null }}
                didMount={({ refs: { root }, setState }) => {
                  const slider = new Swiper(root, {
                    initialSlide: 2,
                    centeredSlides: true,
                    grabCursor: true,
                    spaceBetween: 20,
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                  })

                  setState({ slider })
                }}
                shouldUpdate={() => false}
                willUnmount={({ state: { slider } }) => slider.destroy()}
              >
                {({ refs }) => (
                  <SliderContainer innerRef={ref => (refs.root = ref)}>
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <Img src={screen01} ratio={660 / 372} />
                      </div>
                      <div className="swiper-slide">
                        <Img src={screen02} ratio={660 / 372} />
                      </div>
                      <div className="swiper-slide">
                        <Img src={screen03} ratio={660 / 372} />
                      </div>
                      <div className="swiper-slide">
                        <Img src={screen04} ratio={660 / 372} />
                      </div>
                      <div className="swiper-slide">
                        <Img src={screen05} ratio={660 / 372} />
                      </div>
                    </div>
                    <div className="swiper-pagination" />
                  </SliderContainer>
                )}
              </Component>
              <On
                min="tablet"
                style={{
                  width: '26vw',
                  margin: 'auto',
                  position: 'absolute',
                  zIndex: 1,
                  top: '-5.1vw',
                  left: 'calc(50% - 12.4vw)',
                  pointerEvents: 'none',
                  userSelect: 'none',
                }}
              >
                <Img src={iPhone} ratio={661 / 414} />
              </On>
            </Relative>
            <Space y={{ mobile: 150, tablet: 250 }} />
            <AutoSlide up>
              <Box
                css={{
                  width: { tablet: '80%' },
                  margin: 'auto',
                  marginBottom: '-25vw',
                  ...boxShadow(0.3, '0 20px 50px'),
                }}
              >
                <VideoPlayer
                  webroll={webroll01}
                  video={video01}
                  ratio={1080 / 1920}
                />
              </Box>
            </AutoSlide>
          </Inset>
        </Box>
        <Box css={{ backgroundColor: '#0a0a0a' }}>
          <Space mobile="25vw" />
          <Space xl />
          <Box
            css={{
              width: '66.666%',
              height: '47vw',
              position: 'relative',
              margin: 'auto',
            }}
          >
            <Absolute width="36vw" left="2vw" top="1vw" zIndex={1}>
              <AutoFade delay={0 * 150}>
                <Img src={storyboard1} ratio={366 / 629} />
              </AutoFade>
            </Absolute>
            <Absolute width="36vw" left="32.6vw" top="10.5vw" zIndex={2}>
              <AutoFade delay={1 * 150}>
                <Img src={storyboard2} ratio={366 / 629} />
              </AutoFade>
            </Absolute>
            <Absolute width="36vw" left="-5.5vw" top="18.3vw">
              <AutoFade delay={2 * 150}>
                <Img src={storyboard3} ratio={366 / 629} />
              </AutoFade>
            </Absolute>
            <Absolute width="36vw" left="20.7vw" top="27vw">
              <AutoFade delay={3 * 150}>
                <Img src={storyboard4} ratio={366 / 629} />
              </AutoFade>
            </Absolute>
          </Box>
        </Box>
        <Box css={{ backgroundColor: '#0a0a0a', textAlign: 'left' }}>
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <Space y={80} />
            <WhenVisible>
              {inView => (
                <Box css={{ width: { tabletMd: '66.666%' }, margin: 'auto' }}>
                  <On min="tabletSm">
                    <Space x={{ tabletMd: '-5.55vmax' }}>
                      <BackgroundText is="h4" animate={inView}>
                        PRESS
                      </BackgroundText>
                    </Space>
                  </On>
                  <Box
                    css={{
                      width: { mobileSm: '75vw', tabletMd: '48vw' },
                      margin: 'auto',
                      textAlign: 'center',
                    }}
                  >
                    <Space y={{ tabletMd: '-6.35vmax' }} />
                    <Component
                      refs={{ root: null }}
                      initialState={{ slider: null }}
                      didMount={({ refs: { root }, setState }) => {
                        const slider = new Swiper(root, {
                          speed: 1000,
                          autoplay: { delay: 5000 },
                          effect: 'fade',
                          fadeEffect: { crossFade: true },
                        })

                        setState({ slider })
                      }}
                      shouldUpdate={() => false}
                      willUnmount={({ state: { slider } }) => slider.destroy()}
                    >
                      {({ refs }) => (
                        <SliderFadeContainer
                          innerRef={ref => (refs.root = ref)}
                        >
                          <div className="swiper-wrapper">
                            <div className="swiper-slide">
                              <Title is="blockquote" italic>
                                “An engineering marvel…the first wearable tech
                                that just blends in.”
                              </Title>
                              <Space lg />
                              <img src={wsj} width={230} alt="" />
                            </div>
                            <div className="swiper-slide">
                              <Title is="blockquote" italic>
                                “Liberation from the tyranny of the wrist… Motiv
                                succeeds in its goal surprisingly well.”
                              </Title>
                              <Space lg />
                              <img src={techCrunch} width={170} alt="" />
                            </div>
                            <div className="swiper-slide">
                              <Title is="blockquote" italic>
                                “Motiv is absolutely disrupting the wearable
                                space and fast.”
                              </Title>
                              <Space lg />
                              <img src={forbes} width={120} alt="" />
                            </div>
                          </div>
                          <div className="swiper-pagination" />
                        </SliderFadeContainer>
                      )}
                    </Component>
                  </Box>
                </Box>
              )}
            </WhenVisible>

            <Space xl />
            <WhenVisible>
              {inView => (
                <Box
                  css={{
                    width: { tabletMd: '66.666%' },
                    position: 'relative',
                    margin: 'auto',
                  }}
                >
                  <AutoFade
                    style={{
                      width: '25%',
                      position: 'absolute',
                      top: '-7%',
                      right: '-10%',
                    }}
                  >
                    <Img src={webbyStatue} ratio={1258 / 636} />
                  </AutoFade>
                  <On min="tabletSm">
                    <Space x={{ tabletMd: '-5.55vmax' }}>
                      <BackgroundText is="h4" animate={inView}>
                        AWARDS
                      </BackgroundText>
                    </Space>
                    <Space y={{ tabletMd: '-6.35vmax' }} />
                  </On>
                  <ShowCells active={inView}>
                    <Inset mobileSm={5}>
                      <Columns
                        columnCount={{ mobileSm: 2, tabletSm: 4 }}
                        gap={10}
                      >
                        <Columns.Item>
                          <LogoCell solid>
                            <img
                              width="70%"
                              src={`${
                                process.env.PUBLIC_URL
                              }/assets/awards/addy-awards.svg`}
                              alt="Addy Awards logo"
                            />
                          </LogoCell>
                        </Columns.Item>
                        <Columns.Item>
                          <LogoCell solid>
                            <img
                              width="70%"
                              src={`${
                                process.env.PUBLIC_URL
                              }/assets/awards/commarts.svg`}
                              alt="Commarts logo"
                            />
                          </LogoCell>
                        </Columns.Item>
                        <Columns.Item>
                          <LogoCell solid>
                            <img
                              width="70%"
                              src={`${
                                process.env.PUBLIC_URL
                              }/assets/awards/communicator.svg`}
                              alt="communicator logo"
                            />
                          </LogoCell>
                        </Columns.Item>
                        <Columns.Item>
                          <LogoCell solid>
                            <img
                              width="60%"
                              src={`${
                                process.env.PUBLIC_URL
                              }/assets/awards/webby-awards.svg`}
                              alt="Webby Awards logo"
                            />
                          </LogoCell>
                        </Columns.Item>
                      </Columns>
                    </Inset>
                  </ShowCells>
                </Box>
              )}
            </WhenVisible>
            <Space y={{ mobile: 100, tabletMd: 200 }} />

            <AutoSlide up>
              <Box
                css={{
                  width: { tablet: '80%' },
                  margin: 'auto',
                  boxShadow: '0 20px 50px rgba(0, 0, 0, .3)',
                }}
              >
                <VideoPlayer
                  webroll={webroll02}
                  video={video02}
                  ratio={1080 / 1920}
                />
              </Box>
            </AutoSlide>
          </Inset>
        </Box>
        <Space xxl />

        <Parallax direction="both" height="75vh" maxHeight={700}>
          <Parallax.Background height="150%">
            <Img src={parallax02} width="100%" height="100%" />
          </Parallax.Background>
          <Parallax.Content>
            <Flex height="100%" middle>
              <Inset x="11%">
                <AutoFade>
                  <Title
                    is="p"
                    css={{
                      maxWidth: '13.8em',
                      textAlign: 'left',
                      lineHeight: 1.2,
                    }}
                  >
                    We took Motiv from a stealth startup to a stylish technology
                    brand.
                  </Title>
                </AutoFade>
              </Inset>
            </Flex>
          </Parallax.Content>
        </Parallax>
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
