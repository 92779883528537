import React from "react"
import GSAPComponent from "../../components/GSAPComponent"

export default class extends GSAPComponent {
  animate() {
    /* eslint-disable */
    const sign = this.svg.querySelector("#plum-sign")
    const letters = this.svg.querySelectorAll("#plum-text path")

    const tl = new TimelineMax({ delay: 0.2 })

    tl.from(sign, 0.3, {
      opacity: 0
    })

    tl.from(
      sign,
      1,
      {
        transformOrigin: "center",
        scale: 0.5,
        x: 350,
        ease: Back.easeInOut
      },
      "-=.3"
    )

    tl.staggerFrom(
      letters,
      0.3,
      {
        transformOrigin: "center",
        scale: 0.5,
        opacity: 0,
        ease: Back.easeOut
      },
      0.06,
      "-=.5"
    )

    return tl
    /* eslint-enable */
  }

  render() {
    return (
      <svg
        ref={ref => (this.svg = ref)}
        viewBox="0 0 700.9 157.8"
        width={300}
        fill="#fff"
      >
        <path
          id="plum-sign"
          d="M236.3 30.1v54.7c0 16.6-13.5 30.1-30.1 30.1h-41.3l-43.5 43.5V63.6l35.7 15.6h43.5V35.7h-43.5v43.5l-35.7-35.7V0l8.9 8.8c5.4-5.4 12.9-8.8 21.2-8.8h54.7c16.6 0 30.1 13.5 30.1 30.1z"
        />
        <g id="plum-text">
          <path d="M504.7 38.1v62.2h-18.8v-1.2-67.5c0-5.2 1.9-9.2 6.6-11.6 1.9-1 4.1-1.7 6.2-2 3.8-.6 7.6-.5 11.3.8 4.6 1.6 7.6 4.9 8.9 9.4 2.2 7.3 4.1 14.6 6.1 21.9 2.4 8.6 4.7 17.2 7 25.8.1.2.2.5.4 1.1.2-.8.4-1.3.5-1.8 4.2-14.8 8.5-29.7 12.7-44.5.9-3.1 2.1-6 4.4-8.4 2.6-2.7 5.9-4.1 9.5-4.4 4.1-.4 8.2-.3 12.2 1.3 4.7 1.8 7.5 5.7 7.7 10.7v70.5h-18.8V38.6h-.3l-1.8 6.3c-4.4 15.2-8.8 30.5-13.3 45.7-.7 2.4-1.5 4.7-3.2 6.6-2.4 2.8-5.7 3.6-9.2 3.6-3.7 0-7.1-.8-9.7-3.7-1.9-2.2-2.7-4.9-3.5-7.6-4.9-17-9.7-33.9-14.6-50.9 0 0-.1-.1-.3-.5z" />
          <path d="M453.9 18.8h21v62.6c0 3.1-.2 6.1-1.3 9-2.1 5.4-6.4 8.1-11.8 9.2-2.3.5-4.7.8-7.1.8-9.2.1-18.5.1-27.7 0-3.5 0-7-.6-10.3-1.9-5.6-2.3-8.3-6.6-9-12.4-.2-1.8-.3-3.7-.3-5.6v-60-1.6h20.9v58.9c0 1.1.1 2.2.2 3.3.3 2 1.5 3.2 3.5 3.4 1.8.2 3.6.3 5.5.3 4.1 0 8.3 0 12.4-.2 2.7-.2 3.8-1.5 3.9-4.2.1-.9.1-1.8.1-2.7V20.5v-1.7z" />
          <path d="M400 100.5H364.9c-3.6 0-7.1-.3-10.6-1.4-6.4-2.1-9.8-6.6-10.7-13.1-.2-1.5-.3-3-.3-4.6V20.2v-1.3h21.1v57.4c0 1.5.1 3 .3 4.4.3 2.2 1.6 3.6 3.8 4 1.8.3 3.6.4 5.3.4h26.1c.1 5.1.1 10.2.1 15.4z" />
          <path d="M334.8 32.8c-.2-5.1-2.5-9.5-7.3-12.1-3-1.6-6.3-2.3-9.6-2.3-17.1-.1-29 0-46 0v81.7h21V70.5h20.4c2.3 0 6.1-.2 8.2-.5 7.9-1.1 12.9-6.1 13.3-14 .5-7.8.4-15.5 0-23.2zM314 55.2h-20.9V34.3H314v20.9z" />
        </g>
      </svg>
    )
  }
}
