import React, { PureComponent } from 'react'
import debounce from 'lodash.debounce'

class Parallax extends PureComponent {
  static defaultProps = {
    direction: 'both',
    distance: 300,
  }

  static Background = props => props.children
  static Content = props => props.children

  componentDidMount() {
    this.initObserver()
  }

  componentWillUnmount() {
    this.destroyObserver()
  }

  initObserver = () => {
    this.observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        isIntersecting ? this.initParallax() : this.destroyParallax()
      },
      { rootMargin: '50% 0px' }
    )
    this.observer.observe(this.wrapper)
  }

  destroyObserver = () => {
    this.observer && this.observer.disconnect()
    this.destroyParallax()
  }

  initParallax = () => {
    this.calculateAndTransform()
    window.addEventListener('scroll', this.transform)
    window.addEventListener('resize', this.calculateAndTransform)
  }

  destroyParallax = () => {
    window.removeEventListener('scroll', this.transform)
    window.removeEventListener('resize', this.calculateAndTransform)
  }

  calculateAndTransform = debounce(() => {
    this.calculate()
    this.transform()
  }, 300)

  calculate = () => {
    this.windowHeight = window.innerHeight
    this.bgHeight = this.bg.offsetHeight
    this.wrapperTop =
      this.wrapper.getBoundingClientRect().top + window.pageYOffset
    this.wrapperHeight = this.wrapper.offsetHeight
  }

  parseProgress = value => Math.max(0, Math.min(1, Math.abs(value)))

  transform = () => {
    const currentWrapperTop = this.wrapperTop - window.pageYOffset
    const bgDistance = this.bgHeight - this.wrapperHeight
    const userDistance = this.props.distance
    let currentPos
    let scrollDistance
    let progress
    let posY

    if (this.props.direction === 'top') {
      currentPos = currentWrapperTop
      scrollDistance = this.wrapperHeight
      progress = this.parseProgress(currentPos / scrollDistance)
      posY = userDistance * progress
    }

    if (this.props.direction === 'bottom') {
      currentPos = currentWrapperTop - this.windowHeight
      scrollDistance = this.wrapperHeight
      progress = this.parseProgress(currentPos / scrollDistance)
      posY = -bgDistance + bgDistance * progress
    }

    if (this.props.direction === 'both') {
      currentPos = currentWrapperTop - this.windowHeight
      scrollDistance = this.windowHeight + this.wrapperHeight
      progress = this.parseProgress(currentPos / scrollDistance)
      posY = -userDistance + userDistance * progress
    }

    this.bg.style.transform = `translateY(${posY}px)`
  }

  render() {
    const [background, content] = this.props.children

    return (
      <div
        ref={ref => (this.wrapper = ref)}
        style={{
          height: this.props.height,
          maxHeight: this.props.maxHeight,
          position: 'relative',
          zIndex: 1,
          overflow: 'hidden',
        }}
      >
        <div
          ref={ref => (this.bg = ref)}
          style={{
            height: background.props.height || '100%',
            backgroundColor: '#000',
            willChange: 'transform',
          }}
        >
          {background}
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          {content}
        </div>
      </div>
    )
  }
}

export default Parallax
