import React from 'react'
import Timeline from '../../components/Timeline'

// const Logo = () => {
//   const box = useRef()
//   const tennis = useRef()
//   const channel = useRef()
//   const tm = useRef()
//   const { TimelineMax, Power3 } = useGSAP()

//   useEffect(() => {
//     const dur = 2.5
//     const ease = Power3.easeInOut
//     const boxNewPath =
//       "M40.4,49.4l20.8,2.8v33h-31l0,0l0,0L40.4,49.4z M4.6,44.8l23.8,3.1L17.8,85.2H4.6V44.8z M61.2,28.6v11.5L4.6,32.7v-4.1H61.2z"

//     new TimelineMax()
//       .from(box.current, dur, { y: -20, opacity: 0, ease }, 0)
//       .to(box.current, dur, { morphSVG: boxNewPath, ease }, 0)
//       .from(tm.current, dur, { y: -20, opacity: 0, ease }, 0)
//       .from(tennis.current, dur, { opacity: 0, ease }, 0)
//       .from(channel.current, dur, { y: 20, opacity: 0, ease }, 0)
//   }, [])

//   return (
//     <svg viewBox="0 0 65.8 141.8" width="150" fill="#fff">
//       <path
//         ref={box}
//         d="M39.1,52.2h22.1v33H39.1l0,0l0,0V52.2z M4.6,52.2h22.1v33H4.6V52.2z M61.2,28.6v11.5H4.6V28.6H61.2z"
//       />
//       <path
//         ref={tennis}
//         d="M13.5 89v2.4h-3.1v11.9H7.5V91.4H4.4V89h9.1zm9.2 2.4v-2.3h-8v14.3h8.2V101h-5.3v-3.9h4.8v-2.3h-4.8v-3.4h5.1zm5-2.4h-3.3v14.3H27v-10l3.5 10h3.3V89h-2.6v9.8L27.7 89zm11.4 0h-3.3v14.3h2.6v-10l3.5 10h3.3V89h-2.6v9.8L39.1 89zm8.3 14.3h2.9V89.1h-2.9v14.2zm7.4-4V99h-2.9v.6c0 1.3.4 2.3 1.1 3.1.7.7 1.8 1.1 3.5 1.1 1.6 0 2.8-.3 3.7-1 .8-.7 1.3-1.8 1.3-3.3 0-.5 0-.8-.1-1.2-.1-.4-.2-.7-.4-1s-.5-.6-.8-.8c-.3-.3-.8-.5-1.3-.8l-2.2-1c-.6-.2-1-.5-1.2-.8s-.3-.7-.3-1.1c0-.2 0-.4.1-.6s.2-.4.3-.5c.2-.2.3-.3.5-.4-.1-.3.2-.3.5-.3.6 0 1 .2 1.2.5s.4.9.4 1.6H61v-.4c0-.7-.1-1.3-.3-1.8s-.5-.9-.9-1.2c-.4-.3-.8-.5-1.4-.7-.5-.1-1.1-.2-1.8-.2-1.4 0-2.4.3-3.2 1s-1.2 1.8-1.2 3.2c0 .6.1 1.1.2 1.5.2.5.4.8.7 1.2.3.3.7.6 1.2.9.5.2 1 .5 1.7.7.5.2.9.3 1.2.5s.6.3.8.5.3.4.4.6c.1.2.1.5.1.8 0 .6-.2 1.1-.5 1.3-.3.3-.7.4-1.2.4-.4 0-.7 0-.9-.2-.3-.1-.5-.2-.6-.5-.2-.2-.2-.4-.3-.7-.2-.1-.2-.4-.2-.7z"
//       />
//       <path
//         ref={channel}
//         d="M8.7 107.2c-.1-.2-.4-.4-.7-.4-.2 0-.3.1-.5.2-.1.1-.2.3-.3.5-.1.2-.1.5-.2.8v1.3c0 .5 0 1 .1 1.3 0 .4.1.6.2.8.1.2.2.3.3.4.1.1.3.1.4.1.1 0 .3 0 .4-.1.1-.1.2-.1.3-.3.1-.1.2-.3.2-.5s.1-.5.1-.9h1.5c0 .4 0 .8-.1 1.1-.1.4-.2.7-.3.9-.2.3-.4.5-.7.6-.3.2-.7.2-1.2.2-.6 0-1-.1-1.3-.3-.3-.2-.6-.5-.8-.8-.2-.3-.3-.7-.3-1.1-.1-.5-.1-1-.1-1.5 0-.6 0-1.1.1-1.5.1-.5.2-.8.3-1.2.2-.3.4-.6.8-.8.3-.2.8-.3 1.3-.3s.9.1 1.2.3c.3.2.5.4.7.6.2.3.2.5.3.8 0 .3.1.6.1.8H8.9c0-.4-.1-.7-.2-1m5.6-1.3v7.3h1.5V110h1.7v3.2H19v-7.3h-1.5v2.8h-1.7v-2.8h-1.5zm10.2 4.5l.7-3.3.7 3.3h-1.4zm-.1-4.5l-1.9 7.3H24l.3-1.5h1.9l.3 1.5H28l-1.9-7.3h-1.7zm8.9 0h-1.7v7.3H33v-5.1l1.8 5.1h1.7v-7.3h-1.3v5l-1.9-5zm8.9 0h-1.7v7.3h1.3v-5.1l1.8 5.1h1.7v-7.3H44v5l-1.8-5zm11.2 0h-4v7.3h4.1V112h-2.7v-2h2.4v-1.2h-2.4v-1.7h2.6v-1.2zm3.9 7.3h4V112h-2.6v-6h-1.4v7.2z"
//       />
//       <path
//         ref={tm}
//         d="M62.8 29.1h-.5v-.3h1.4v.3h-.5V31h-.4v-1.9zm1.1-.3V31h.3v-1.8l.5 1.8h.3l.5-1.8V31h.3v-2.2h-.5l-.4 1.6-.4-1.6h-.6z"
//       />
//     </svg>
//   )
// }

const Logo = () => (
  <Timeline
    didMount={({
      timeline,
      refs: { box, tm, tennis, channel },
      gsap: { Power3 },
    }) => {
      const dur = 2.5
      const ease = Power3.easeInOut
      const boxNewPath =
        'M40.4,49.4l20.8,2.8v33h-31l0,0l0,0L40.4,49.4z M4.6,44.8l23.8,3.1L17.8,85.2H4.6V44.8z M61.2,28.6v11.5L4.6,32.7v-4.1H61.2z'

      timeline
        .from(box, dur, { y: -20, opacity: 0, ease }, 0)
        .to(box, dur, { morphSVG: boxNewPath, ease }, 0)
        .from(tm, dur, { y: -20, opacity: 0, ease }, 0)
        .from(tennis, dur, { opacity: 0, ease }, 0)
        .from(channel, dur, { y: 20, opacity: 0, ease }, 0)
    }}
  >
    {({ ref }) => (
      <svg viewBox="0 0 65.8 141.8" width="150" fill="#fff">
        <path
          ref={ref('box')}
          d="M39.1,52.2h22.1v33H39.1l0,0l0,0V52.2z M4.6,52.2h22.1v33H4.6V52.2z M61.2,28.6v11.5H4.6V28.6H61.2z"
        />
        <path
          ref={ref('tennis')}
          d="M13.5 89v2.4h-3.1v11.9H7.5V91.4H4.4V89h9.1zm9.2 2.4v-2.3h-8v14.3h8.2V101h-5.3v-3.9h4.8v-2.3h-4.8v-3.4h5.1zm5-2.4h-3.3v14.3H27v-10l3.5 10h3.3V89h-2.6v9.8L27.7 89zm11.4 0h-3.3v14.3h2.6v-10l3.5 10h3.3V89h-2.6v9.8L39.1 89zm8.3 14.3h2.9V89.1h-2.9v14.2zm7.4-4V99h-2.9v.6c0 1.3.4 2.3 1.1 3.1.7.7 1.8 1.1 3.5 1.1 1.6 0 2.8-.3 3.7-1 .8-.7 1.3-1.8 1.3-3.3 0-.5 0-.8-.1-1.2-.1-.4-.2-.7-.4-1s-.5-.6-.8-.8c-.3-.3-.8-.5-1.3-.8l-2.2-1c-.6-.2-1-.5-1.2-.8s-.3-.7-.3-1.1c0-.2 0-.4.1-.6s.2-.4.3-.5c.2-.2.3-.3.5-.4-.1-.3.2-.3.5-.3.6 0 1 .2 1.2.5s.4.9.4 1.6H61v-.4c0-.7-.1-1.3-.3-1.8s-.5-.9-.9-1.2c-.4-.3-.8-.5-1.4-.7-.5-.1-1.1-.2-1.8-.2-1.4 0-2.4.3-3.2 1s-1.2 1.8-1.2 3.2c0 .6.1 1.1.2 1.5.2.5.4.8.7 1.2.3.3.7.6 1.2.9.5.2 1 .5 1.7.7.5.2.9.3 1.2.5s.6.3.8.5.3.4.4.6c.1.2.1.5.1.8 0 .6-.2 1.1-.5 1.3-.3.3-.7.4-1.2.4-.4 0-.7 0-.9-.2-.3-.1-.5-.2-.6-.5-.2-.2-.2-.4-.3-.7-.2-.1-.2-.4-.2-.7z"
        />
        <path
          ref={ref('channel')}
          d="M8.7 107.2c-.1-.2-.4-.4-.7-.4-.2 0-.3.1-.5.2-.1.1-.2.3-.3.5-.1.2-.1.5-.2.8v1.3c0 .5 0 1 .1 1.3 0 .4.1.6.2.8.1.2.2.3.3.4.1.1.3.1.4.1.1 0 .3 0 .4-.1.1-.1.2-.1.3-.3.1-.1.2-.3.2-.5s.1-.5.1-.9h1.5c0 .4 0 .8-.1 1.1-.1.4-.2.7-.3.9-.2.3-.4.5-.7.6-.3.2-.7.2-1.2.2-.6 0-1-.1-1.3-.3-.3-.2-.6-.5-.8-.8-.2-.3-.3-.7-.3-1.1-.1-.5-.1-1-.1-1.5 0-.6 0-1.1.1-1.5.1-.5.2-.8.3-1.2.2-.3.4-.6.8-.8.3-.2.8-.3 1.3-.3s.9.1 1.2.3c.3.2.5.4.7.6.2.3.2.5.3.8 0 .3.1.6.1.8H8.9c0-.4-.1-.7-.2-1m5.6-1.3v7.3h1.5V110h1.7v3.2H19v-7.3h-1.5v2.8h-1.7v-2.8h-1.5zm10.2 4.5l.7-3.3.7 3.3h-1.4zm-.1-4.5l-1.9 7.3H24l.3-1.5h1.9l.3 1.5H28l-1.9-7.3h-1.7zm8.9 0h-1.7v7.3H33v-5.1l1.8 5.1h1.7v-7.3h-1.3v5l-1.9-5zm8.9 0h-1.7v7.3h1.3v-5.1l1.8 5.1h1.7v-7.3H44v5l-1.8-5zm11.2 0h-4v7.3h4.1V112h-2.7v-2h2.4v-1.2h-2.4v-1.7h2.6v-1.2zm3.9 7.3h4V112h-2.6v-6h-1.4v7.2z"
        />
        <path
          ref={ref('tm')}
          d="M62.8 29.1h-.5v-.3h1.4v.3h-.5V31h-.4v-1.9zm1.1-.3V31h.3v-1.8l.5 1.8h.3l.5-1.8V31h.3v-2.2h-.5l-.4 1.6-.4-1.6h-.6z"
        />
      </svg>
    )}
  </Timeline>
)

export default Logo
