import React, { Fragment } from 'react'
import Component from '@reach/component-component'
import Helmet from 'react-helmet'
import emotionStyled from 'react-emotion'
import {
  Inset,
  rule,
  min,
  Flex,
  Break,
  Relative,
  Stack,
  On,
  Space,
} from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import Parallax from '../../components/Parallax'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import { AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'
import VideoPlayer from '../../components/VideoPlayer'
import Swiper from '../../lib/swiper4'

import CaseStudyLayout, {
  dropShadow,
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'
import webroll from './assets/webroll.mp4'
import video from './assets/video.mp4'

import ahiPhone from './assets/ah-iPhone.png'
import ahPost1 from './assets/ah-post1.png'
import ahPost2 from './assets/ah-post2.png'
import ahPost3 from './assets/ah-post3.png'
import ahscreen1 from './assets/ah-screen-1.png'
import ahscreen2 from './assets/ah-screen-2.png'
import ahscreen3 from './assets/ah-screen-3.png'
import ahscreen4 from './assets/ah-screen-4.png'
import ahscreen5 from './assets/ah-screen-5.png'
import appIconFb from './assets/app-icon-fb.png'
import appIconIg from './assets/app-icon-ig.png'
import b99BottomPhoto from './assets/b99-bottom-photo.jpg'
import b99Facebook from './assets/b99-facebook.png'
import b99FbComments from './assets/b99-fb-comments.png'
import b99FbPost from './assets/b99-fb-post.png'
import b99IPhone from './assets/b99-iPhone.png'
import b99PostBot from './assets/b99-post-bot.png'
import b99PostMid from './assets/b99-post-mid.png'
import b99PostTop from './assets/b99-post-top.png'
import horseman from './assets/horseman.png'
import liveTweet from './assets/live-tweet.png'
import ngInstagramComments from './assets/ng-instagram-comments.png'
import ngInstagram from './assets/ng-instagram.png'
import ngPostBot from './assets/ng-post-bot.png'
import ngPostTop from './assets/ng-post-top.png'
import parallaxTwitterBg from './assets/parallax-twitter-bg.jpg'
import shareables from './assets/shareables.png'
import sleepyFacebook from './assets/sleepy-facebook.png'
import sleepyPostBot from './assets/sleepy-post-bot.png'
import sleepyPostTop from './assets/sleepy-post-top.png'
import styleguide from './assets/styleguide.png'
import zoey from './assets/zoey.gif'
import twitterLogo from './assets/twitter-logo.png'

const SliderContainer = emotionStyled('div')`
${rule('width', { mobileSm: 'calc(100vw - 80px)', tabletSm: '14.5vw' })};
margin-left: auto;
margin-right: auto;
position: relative;
/* Fix of Webkit flickering */
z-index: 1;
user-select: none;

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  will-change: transform;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  position: relative;
  opacity: 0.3;
  box-shadow: 0 10px 30px rgba(0, 0, 0, .3);
  transition: opacity .5s;
}

.swiper-slide.swiper-slide-active {
  opacity: 1;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

${min('tablet')} {
  .swiper-pagination {
    display: none;
  }
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 8px;
  box-shadow: inset 0 0 0 1px;
  transition: background-color .5s;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
}
`

const currentProject = getProject('fox-digital')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#e6e147', color: '#274ba1' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  Engaging TV fans with contagious content
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  Social media isn’t just a second screen for TV watchers; it’s
                  the first screen for people who want to dive deeper into their
                  favorite FOX TV series. Our challenge was to create emotive
                  buzz content outside the confines of a 30- or 60-minute
                  episode – where fans could discover exclusive content and
                  engage in real-time conversations with the goal of increasing
                  views of FOX’s newest hit shows.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>FOX Networks Group</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Entertainment</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Social Content
                    <Break />
                    Digital Advertising
                  </DetailValue>
                </li>
              </Stack>
            }
          />
          <Box
            css={{
              width: { tabletMd: '80%' },
              margin: '-2vw auto -22vw',
              position: 'relative',
            }}
          >
            <AutoSlide
              up
              style={{
                width: '54%',
                position: 'relative',
                left: '20%',
                zIndex: 1,
              }}
            >
              <Img src={b99FbPost} ratio={642 / 640} />
            </AutoSlide>
            <AutoSlide
              up
              delay={1 * 150}
              style={{
                width: '30%',
                position: 'absolute',
                left: '5%',
                top: '20%',
                zIndex: 1,
              }}
            >
              <Img src={b99IPhone} ratio={626 / 360} />
            </AutoSlide>
            <AutoSlide
              up
              delay={2 * 150}
              style={{
                width: '31%',
                position: 'absolute',
                right: '5%',
                top: '5%',
              }}
            >
              <Img src={b99FbComments} ratio={642 / 375} />
            </AutoSlide>
            <AutoSlide
              up
              style={{
                width: '10%',
                position: 'absolute',
                left: '57%',
                top: '96%',
              }}
            >
              <Img src={appIconFb} ratio={1} />
            </AutoSlide>
            <AutoSlide
              up
              style={{
                width: '10%',
                position: 'absolute',
                left: '15%',
                top: '109%',
              }}
            >
              <Img src={appIconIg} ratio={1} />
            </AutoSlide>
          </Box>
        </Inset>
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a', overflow: 'hidden' }}>
        <Space y="22vw" />
        <Space xl tabletMd="xxl" />
        <Space xl />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <WhenVisible>
            {inView => (
              <Fragment>
                <On min="tabletMd">
                  <Space x={{ tabletMd: '-5.55vmax' }}>
                    <BackgroundText
                      is="h4"
                      animate={inView}
                      size="12vw"
                      style={{ textAlign: 'left' }}
                    >
                      STYLEGUIDE
                    </BackgroundText>
                  </Space>
                </On>
                <Space y={{ tabletMd: '-7vw' }} />
                <Box
                  css={{
                    position: 'relative',
                  }}
                >
                  <Box
                    css={{
                      width: { 1200: '55%' },
                      // margin: 'auto',
                    }}
                  >
                    <Title is="h2">
                      Branding a new girl, a robot and a headless horseman.
                    </Title>
                    <Space md />
                    <Paragraph css={{ maxWidth: '31em' }}>
                      While creating a volume of various content for a roster of
                      shows, we first established a unique and consistent look
                      and feel for each show. We developed branded style guides
                      inspired by each show ensuring each piece of show content
                      was ‘ownable’ regardless of the platform it was shared on.
                    </Paragraph>
                    <Space xl />
                  </Box>
                  <Box
                    css={{
                      width: { mobile: '52%', 1200: '62%' },
                      position: 'absolute',
                      zIndex: 0,
                      top: { mobile: 'auto', 1200: '-5%' },
                      right: { mobile: '-7%', 1200: '-17%' },
                      marginTop: { mobile: '-11%', 1200: 'auto' },
                    }}
                  >
                    <AutoSlide left>
                      <Img src={horseman} ratio={967 / 652} />
                    </AutoSlide>
                  </Box>
                  <Box
                    css={{
                      width: { mobile: '80%', tabletMd: '70%' },
                      position: 'relative',
                      left: '5%',
                    }}
                  >
                    <AutoSlide up>
                      <Img src={styleguide} ratio={565 / 722} />
                    </AutoSlide>
                    <AutoSlide
                      up
                      delay={150}
                      style={{
                        width: '50%',
                        position: 'absolute',
                        left: '-20%',
                        bottom: '-14%',
                      }}
                    >
                      <Img src={shareables} ratio={536 / 375} />
                    </AutoSlide>
                  </Box>
                </Box>
              </Fragment>
            )}
          </WhenVisible>
        </Box>
        <Space xl tabletMd="xxl" />
        <Box css={{ backgroundColor: '#1e0e0c' }}>
          <Space xl tabletMd="xxl" />
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
            }}
          >
            <WhenVisible>
              {inView => (
                <Fragment>
                  <On min="tabletSm">
                    <Space x={{ tabletSm: '-5.55vmax' }}>
                      <BackgroundText
                        is="h4"
                        animate={inView}
                        size="12vw"
                        color="#100706"
                        style={{ textAlign: 'left', lineHeight: 0.8 }}
                      >
                        SLEEPY
                        <Break />
                        HOLLOW
                      </BackgroundText>
                    </Space>
                  </On>
                  <Space y={{ tabletSm: '-7vw' }} />
                  <Box css={{ position: 'relative' }}>
                    <AutoSlide
                      up
                      style={{ width: '60%', position: 'relative', zIndex: 1 }}
                    >
                      <Img src={sleepyPostTop} ratio={716 / 615} />
                    </AutoSlide>
                    <AutoSlide
                      up
                      delay={150}
                      style={{
                        width: '60%',
                        position: 'absolute',
                        left: '45%',
                        top: '30%',
                      }}
                    >
                      <Img src={sleepyPostBot} ratio={716 / 615} />
                    </AutoSlide>
                  </Box>
                </Fragment>
              )}
            </WhenVisible>
            <Space xl tabletMd="xxl" />
            <Space xl tabletMd="xxl" />
            <AutoSlide up>
              <Img src={sleepyFacebook} ratio={1545 / 1227} />
            </AutoSlide>
          </Box>
          <Space xl tabletMd="xxl" />
        </Box>
        <Box css={{ backgroundColor: '#27323c', overflow: 'hidden' }}>
          <Space xl tabletMd="xxl" />
          <Space xl tabletSm={0} />
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
            }}
          >
            <WhenVisible>
              {inView => (
                <Fragment>
                  <On min="tabletSm">
                    <Space x={{ tabletSm: '-5.55vmax' }}>
                      <BackgroundText
                        is="h4"
                        animate={inView}
                        size="12vw"
                        color="#1c242c"
                        style={{ textAlign: 'left', lineHeight: 0.8 }}
                      >
                        ALMOST
                        <Break />
                        HUMAN
                      </BackgroundText>
                    </Space>
                  </On>
                  <Space y={{ tabletSm: '-7vw' }} />
                  <Box css={{ position: 'relative' }}>
                    <AutoSlide
                      up
                      style={{
                        width: '54%',
                        position: 'relative',
                        zIndex: 1,
                        left: '-3.6%',
                      }}
                    >
                      <Img src={ahPost2} ratio={1} />
                    </AutoSlide>
                    <AutoSlide
                      up
                      delay={150}
                      style={{
                        width: '52%',
                        position: 'absolute',
                        zIndex: 2,
                        left: '24%',
                        top: '46%',
                      }}
                    >
                      <Img src={ahPost1} ratio={1} />
                    </AutoSlide>
                    <AutoSlide
                      up
                      delay={150}
                      style={{
                        width: '54%',
                        position: 'absolute',
                        zIndex: 3,
                        left: '50.6%',
                        top: '-23%',
                      }}
                    >
                      <Img src={ahPost3} ratio={1} />
                    </AutoSlide>
                  </Box>
                </Fragment>
              )}
            </WhenVisible>
            <Space xl tabletMd="xxl" />
            <Space xl tabletMd="xxl" />
            <Space lg tabletMd="xl" />
            <AutoSlide up>
              <Relative>
                <Component
                  initialState={{ root: React.createRef(), slider: null }}
                  didMount={({ state: { root }, setState }) => {
                    const slider = new Swiper(root.current, {
                      initialSlide: 2,
                      centeredSlides: true,
                      grabCursor: true,
                      spaceBetween: 20,
                      pagination: '.swiper-pagination',
                      paginationClickable: true,
                    })

                    setState({ slider })
                  }}
                  shouldUpdate={() => false}
                  willUnmount={({ state: { slider } }) => slider.destroy()}
                >
                  {({ state: { root } }) => (
                    <SliderContainer innerRef={root}>
                      <div className="swiper-wrapper">
                        <div className="swiper-slide">
                          <Img src={ahscreen1} ratio={419 / 235} />
                        </div>
                        <div className="swiper-slide">
                          <Img src={ahscreen2} ratio={419 / 235} />
                        </div>
                        <div className="swiper-slide">
                          <Img src={ahscreen3} ratio={419 / 235} />
                        </div>
                        <div className="swiper-slide">
                          <Img src={ahscreen4} ratio={419 / 235} />
                        </div>
                        <div className="swiper-slide">
                          <Img src={ahscreen5} ratio={419 / 235} />
                        </div>
                      </div>
                      <div className="swiper-pagination" />
                    </SliderContainer>
                  )}
                </Component>
                <On
                  min="tablet"
                  style={{
                    width: '22.9vw',
                    margin: 'auto',
                    position: 'absolute',
                    zIndex: 1,
                    top: '-5.1vw',
                    left: 'calc(50% - 11.2vw)',
                    pointerEvents: 'none',
                    userSelect: 'none',
                  }}
                >
                  <Img src={ahiPhone} ratio={644 / 369} />
                </On>
              </Relative>
            </AutoSlide>
            <Space lg tabletMd="xl" />
            <Space xl tabletMd="xxl" />
            <AutoSlide up>
              <Box css={{ boxShadow: '0 20px 50px rgba(0, 0, 0, .3)' }}>
                <VideoPlayer
                  webroll={webroll}
                  video={video}
                  ratio={1080 / 1920}
                />
              </Box>
            </AutoSlide>
          </Box>
          <Space xl tabletMd="xxl" />
        </Box>
        <Box css={{ backgroundColor: '#59898e' }}>
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
            }}
          >
            <Space xl tabletMd="xxl" />
            <WhenVisible>
              {inView => (
                <Fragment>
                  <On min="tabletSm">
                    <Space x={{ tabletSm: '-5.55vmax' }}>
                      <BackgroundText
                        is="h4"
                        animate={inView}
                        size="12vw"
                        color="#507b80"
                        style={{ textAlign: 'left' }}
                      >
                        NEW GIRL
                      </BackgroundText>
                    </Space>
                  </On>
                  <Space y={{ tabletSm: '-7vw' }} />
                  <Box
                    css={{
                      position: 'relative',
                    }}
                  >
                    <AutoSlide
                      up
                      style={{ width: '60%', position: 'relative' }}
                    >
                      <Img src={ngPostBot} ratio={716 / 615} />
                    </AutoSlide>
                    <AutoSlide
                      up
                      style={{
                        width: '60%',
                        position: 'absolute',
                        zIndex: 1,
                        left: '41%',
                        top: '40%',
                      }}
                    >
                      <Img src={ngPostTop} ratio={716 / 615} />
                    </AutoSlide>
                  </Box>
                </Fragment>
              )}
            </WhenVisible>
            <Space xl tabletMd="xxl" />
            <Space xl tabletMd={150} />
            <Box css={{ position: 'relative' }}>
              <AutoSlide
                up
                style={{
                  width: '72%',
                  position: 'relative',
                  zIndex: 1,
                  left: '-2%',
                }}
              >
                <Img src={ngInstagram} ratio={1} />
              </AutoSlide>
              <AutoSlide
                up
                style={{
                  width: '42%',
                  position: 'absolute',
                  left: '60.8%',
                  top: '5%',
                }}
              >
                <Img src={ngInstagramComments} ratio={829 / 485} />
              </AutoSlide>
            </Box>
          </Box>
          <Space xl tabletMd="xxl" />
        </Box>
        <Parallax direction="both" height="90vh">
          <Parallax.Background height="150%">
            <Img src={parallaxTwitterBg} width="100%" height="100%" />
          </Parallax.Background>
          <Parallax.Content>
            <Flex height="100%" middle>
              <Box
                css={{
                  width: '80%',
                  margin: 'auto',
                  textAlign: { mobile: 'center', tabletMd: 'left' },
                }}
              >
                <Img
                  src={twitterLogo}
                  ratio={34 / 41}
                  width="41px"
                  css={{ display: 'inline-block' }}
                />
                <Space y={20} />
                <Title is="blockquote" css={{ maxWidth: '15em' }}>
                  We engaged superfans on Twitter to join live dialogues with
                  cast members to drive audience excitement and loyalty.
                </Title>
              </Box>
            </Flex>
          </Parallax.Content>
        </Parallax>
        <Box
          css={{
            backgroundColor: '#d8d8d8',
          }}
        >
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
              position: 'relative',
              '.twitter-tweet': {
                width: '340px !important',
                height: '630px !important',
                position: 'relative !important',
                zIndex: 1,
                margin: {
                  mobile: '-20vw 0 0 !important',
                  tabletMd: '-6vw 0 0 !important',
                },
                ...dropShadow(0.3, '0 20px 20px'),
              },
            }}
          >
            <AutoSlide up style={{ position: 'relative', zIndex: 1 }}>
              <blockquote className="twitter-tweet" data-lang="en">
                <p lang="en" dir="ltr">
                  Live tweeting in full effect with{' '}
                  <a href="https://twitter.com/HannahSimone?ref_src=twsrc%5Etfw">
                    @HannahSimone
                  </a>{' '}
                  <a href="https://twitter.com/MrJakeJohnson?ref_src=twsrc%5Etfw">
                    @MrJakeJohnson
                  </a>{' '}
                  <a href="https://twitter.com/iamgreenfield?ref_src=twsrc%5Etfw">
                    @iamgreenfield
                  </a>{' '}
                  and{' '}
                  <a href="https://twitter.com/LamorneMorris?ref_src=twsrc%5Etfw">
                    @LamorneMorris
                  </a>
                  !{' '}
                  <a href="https://twitter.com/hashtag/NewGirlFinale?src=hash&amp;ref_src=twsrc%5Etfw">
                    #NewGirlFinale
                  </a>{' '}
                  <a href="http://t.co/6ZqqO1kLTz">
                    pic.twitter.com/6ZqqO1kLTz
                  </a>
                </p>
                &mdash; New Girl (@NewGirlonFOX){' '}
                <a href="https://twitter.com/NewGirlonFOX/status/595758364283510784?ref_src=twsrc%5Etfw">
                  May 6, 2015
                </a>
              </blockquote>
            </AutoSlide>
            <Component
              didMount={() => {
                const script = document.createElement('script')
                script.src = 'https://platform.twitter.com/widgets.js'
                script.setAttribute('async', true)
                document.body.appendChild(script)
              }}
            />
            <Box
              css={{
                marginTop: { mobile: '80px', tabletMd: 0 },
                width: { tabletMd: '60%' },
                position: { tabletMd: 'absolute' },
                right: '4%',
                top: '44%',
              }}
            >
              <AutoSlide up delay={200}>
                <Img src={liveTweet} ratio={1} />
              </AutoSlide>
            </Box>
            <Box
              css={{
                width: '45%',
                position: 'absolute',
                right: '-4%',
                top: '23%',
              }}
            >
              <On min="tabletMd">
                <AutoSlide up>
                  <Img src={zoey} ratio={256 / 500} />
                </AutoSlide>
              </On>
            </Box>
          </Box>
          <Space xl tabletMd="xxl" />
          <Space xl tabletMd="xxl" />
        </Box>
        <Box css={{ backgroundColor: '#E5E247', overflow: 'hidden' }}>
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
            }}
          >
            <Space xl tabletMd="xxl" />
            <WhenVisible>
              {inView => (
                <Fragment>
                  <On min="tabletSm">
                    <Space x={{ tabletSm: '-5.55vmax' }}>
                      <BackgroundText
                        is="h4"
                        animate={inView}
                        size="12vw"
                        color="rgba(0, 0, 0, .1)"
                        style={{ textAlign: 'left' }}
                      >
                        BROOKLYN99
                      </BackgroundText>
                    </Space>
                  </On>
                  <Space y={{ tabletSm: '-7vw' }} />
                  <Box
                    css={{
                      position: 'relative',
                      paddingBottom: '40%',
                    }}
                  >
                    <AutoSlide
                      up
                      style={{ width: '60%', position: 'relative', zIndex: 2 }}
                    >
                      <Img src={b99PostTop} ratio={716 / 615} />
                    </AutoSlide>
                    <AutoSlide
                      up
                      style={{
                        width: '60%',
                        position: 'absolute',
                        zIndex: 1,
                        left: '12%',
                        top: '46%',
                      }}
                    >
                      <Img src={b99PostMid} ratio={716 / 615} />
                    </AutoSlide>
                    <AutoSlide
                      up
                      style={{
                        width: '60%',
                        position: 'absolute',
                        left: '43.5%',
                        top: '19%',
                      }}
                    >
                      <Img src={b99PostBot} ratio={716 / 615} />
                    </AutoSlide>
                  </Box>
                </Fragment>
              )}
            </WhenVisible>
            <Space xl tabletMd="xxl" />
            <AutoSlide up>
              <Img src={b99Facebook} ratio={1521 / 1227} />
            </AutoSlide>
            <Space xl tabletMd="xxl" />
            <AutoSlide up>
              <Img src={b99BottomPhoto} ratio={523 / 1024} />
            </AutoSlide>
          </Box>
        </Box>
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
