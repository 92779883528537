import React, { Fragment } from 'react'
import Component from '@reach/component-component'
import Helmet from 'react-helmet'
import styled from '../../styles/styled'
import { On, Relative, Grid, Space, Stack, Break, rem } from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import { Detail, DetailValue } from '../../projects/CaseStudyLayout'
import Layout from '../../Layout'
import LinesPattern from '../../components/LinesPattern'
import DarkWrapper from '../../components/DarkWrapper'
import TextLink from '../../components/TextLink'
import Headline from '../../components/Headline'
import Select from '../../components/Select'
import BackgroundText from '../../components/BackgroundText'
import HelloFab from '../../components/HelloFab'
import Box from '../../components/Box'
import ScrollToLink from '../../components/ScrollToLink'
import WhenVisible from '../../components/WhenVisible'
import { Fade, Slide, Reveal } from '../../components/Animated'
import VideoWrapper from '../../components/VideoWrapper'
import TextUnderline from '../../components/TextUnderline'
import ScrollToIntended from '../../components/ScrollToIntended'
import { BusinessTypeConsumer } from '../../components/BusinessTypeContext'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import timelapse from './timelapse.mp4'
import poster from './poster.jpg'

const Text = styled('p')(() => ({
  fontWeight: 700,
  fontSize: { mobile: 18, laptop: '1.75vw' },
  lineHeight: 1.7,
}))

const Input = styled('input')(({ width = 230, isTouched, isInvalid }) => ({
  width,
  fontWeight: 700,
  fontSize: { mobile: 18, laptop: '1.75vw' },
  lineHeight: 1.4,
  fontFamily: 'inherit',
  color: isInvalid ? '#f44336' : isTouched ? '#fff' : 'rgba(255, 255, 255, .3)',
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
  borderBottom: '1px solid',
  borderColor: isInvalid && '#f44336',
  transition: '.3s',
  '::placeholder': {
    color: isInvalid ? '#f44336' : 'rgba(255, 255, 255, .3)',
  },
  ':focus': {
    color: '#fff',
  },
}))

class Weather extends Component {
  state = {
    temp: undefined,
    weatherId: undefined,
  }

  componentDidMount() {
    fetch(
      'https://api.openweathermap.org/data/2.5/weather?zip=90028,us&units=imperial&appid=59f0103b97049b544c63a9af44ffcc6d'
    )
      .then(res => res.json())
      .then(res => {
        if (res && res.main) {
          this.setState({
            temp: Math.round(res.main.temp),
            weatherId: res.weather[0].id,
          })
        }
      })
  }

  render() {
    return this.state.temp ? (
      <Box css={{ display: 'flex', alignItems: 'center', fontSize: 20 }}>
        <i
          className={`wi wi-owm-${this.state.weatherId}`}
          style={{ fontSize: '130%', marginRight: 10 }}
        />
        <span>{this.state.temp} °</span>
      </Box>
    ) : null
  }
}

export default () => (
  <Fragment>
    <Helmet>
      <title>Contact Us</title>
      <meta name="description" content="Your turn description" />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />
    <ScrollToIntended delay={0.7} />

    <Layout.Shell>
      <Relative>
        <LinesPattern />
        <DarkWrapper>
          <Space y={{ mobile: 100, tabletMd: 180 }} />
          <WhenVisible>
            {inView => (
              <Grid
                template={{
                  mobile: `
                    auto
                    auto
                    / 10% 1fr 10%
                  `,
                  tabletMd: `
                    8.8vw
                    auto
                    auto
                    auto
                    / repeat(6, 1fr)
                  `,
                }}
              >
                <Grid.Item
                  area={{
                    mobile: '1 / 2 / 2 / 3',
                    tabletMd: '2 / 2 / 3 / 6',
                  }}
                  align={{ mobile: 'center', tabletMd: 'initial' }}
                  zIndex={1}
                >
                  <On min="tabletMd">
                    <Slide left inView={inView}>
                      <Headline is="p">
                        How can we help
                        <Break />
                        your brand succeed?
                      </Headline>
                    </Slide>
                    <Space y={30} />
                    <Slide left inView={inView} delay={250}>
                      <ScrollToLink
                        href="#form"
                        style={{
                          paddingBottom: rem(50),
                          cursor: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.57' height='42.65' viewBox='0 0 18.57 42.65'%3E%3Cpath fill='%23fff' d='M17.7 32.5l-7.8 7.8V0H8.67v40.3l-7.8-7.8-.87.87 8.41 8.42.87.86.87-.86 8.42-8.42z'/%3E%3C/svg%3E"), auto`,
                        }}
                      >
                        <TextLink is="span" sm>
                          Tell us about your challenge
                        </TextLink>
                      </ScrollToLink>
                    </Slide>
                  </On>
                  <On max="tabletMd">
                    <Headline is="p" css={{ paddingLeft: 30 }}>
                      Talk to us
                    </Headline>
                  </On>
                </Grid.Item>
                <Grid.Item
                  area={{
                    mobile: '1 / 2 / 2 / 3',
                    tabletMd: '1 / 3 / 4 / 6',
                  }}
                >
                  <Box css={{ position: 'relative' }}>
                    <Box
                      css={{
                        position: 'absolute',
                        zIndex: 1,
                        top: 20,
                        right: 20,
                      }}
                    >
                      <Weather />
                    </Box>
                    <Reveal left inView={inView}>
                      <VideoWrapper>
                        <video
                          src={timelapse}
                          poster={poster}
                          autoPlay
                          loop
                          playsInline
                          muted
                        />
                      </VideoWrapper>
                    </Reveal>
                  </Box>
                </Grid.Item>
                <Box
                  css={{
                    gridArea: '2 / 2 / 3 / 3',
                    'min:tabletMd': {
                      gridArea: '3 / 4 / 5 / 6',
                      justifySelf: 'end',
                      alignSelf: 'start',
                      zIndex: 1,
                      marginRight: 20,
                      'li > span': {
                        opacity: 0.5,
                      },
                    },
                  }}
                >
                  <Slide
                    up
                    inView={inView}
                    delay={500}
                    style={{
                      background: '#fff',
                      color: '#000',
                      padding: 32,
                    }}
                  >
                    <Stack is="ul" rowGap={15}>
                      <li>
                        <Detail muted>GET IN TOUCH:</Detail>
                        <Break />
                        <a href="tel:+03234573899">
                          <TextUnderline color="#ddd">
                            <DetailValue>323.457.3899</DetailValue>
                          </TextUnderline>
                        </a>
                      </li>
                      <li>
                        <Detail muted>Business Inquiries:</Detail>
                        <Break />
                        <a href="mailto:hello@advictorem.agency">
                          <TextUnderline color="#ddd">
                            <DetailValue>hello@advictorem.agency</DetailValue>
                          </TextUnderline>
                        </a>
                      </li>
                      <li>
                        <Detail muted>OUR OFFICE:</Detail>
                        <Break />
                        <a
                          href="https://www.google.com/maps/place/7083+Hollywood+Blvd,+Los+Angeles,+CA+90028,+Stany+Zjednoczone/@34.1018147,-118.346036,17.25z/data=!4m5!3m4!1s0x80c2bf2058cc2007:0xbd5996e038472564!8m2!3d34.1018232!4d-118.3443526"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TextUnderline color="#ddd">
                            <DetailValue>
                              7083 Hollywood Blvd.
                              <Break />
                              Los Angeles, CA 90028
                            </DetailValue>
                          </TextUnderline>
                        </a>
                      </li>
                    </Stack>
                  </Slide>
                </Box>
              </Grid>
            )}
          </WhenVisible>
          <Space y={50} />

          <WhenVisible>
            {inView => (
              <Formik
                initialValues={{
                  fullName: '',
                  organizationName: '',
                  businessType: 'foreign brand coming to the 🇺🇸',
                  businessChallenge: '',
                  dateOrTimeframe: '',
                  budget: '$100,000 - 200,000',
                  email: '',
                }}
                validationSchema={Yup.object().shape({
                  fullName: Yup.string().required(),
                  organizationName: Yup.string().required(),
                  businessType: Yup.string().required(),
                  businessChallenge: Yup.string().required(),
                  dateOrTimeframe: Yup.string().required(),
                  budget: Yup.string().required(),
                  email: Yup.string()
                    .email()
                    .required(),
                })}
                onSubmit={(formData, { setStatus }) => {
                  fetch(
                    'https://us-central1-advictorem-c6b09.cloudfunctions.net/processAvForm',
                    {
                      body: JSON.stringify(formData),
                      credentials: 'same-origin',
                      headers: { 'content-type': 'application/json' },
                      method: 'POST',
                      mode: 'cors',
                    }
                  )
                    .then(response => response.json())
                    .then(({ success }) => {
                      // if (success) {
                      //   TweenMax.to(window, 0.6, {
                      //     scrollTo: { y: '#form', offsetY: 40 },
                      //     ease: Power4.easeInOut,
                      //   })
                      // }
                      setStatus({ sent: success })
                    })
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  status: { sent } = {},
                  isSubmitting,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                }) => (
                  <Form id="form">
                    <Grid
                      template={{
                        mobile: "'. details .' / 1fr 8fr 1fr",
                        tabletMd: "'. form . details' / 16.666% 2.5fr 2rem 1fr",
                      }}
                    >
                      {sent ? (
                        <Grid.Item area="form">
                          <On
                            min="tabletMd"
                            style={{ position: 'relative', zIndex: 0 }}
                          >
                            <Space x={{ tabletMd: '-5.55vmax' }}>
                              <BackgroundText animate size="11vw">
                                THANK YOU
                              </BackgroundText>
                            </Space>
                            <Space y={{ tabletMd: '-6.35vmax' }} />
                            <Headline
                              is="p"
                              style={{ maxWidth: '9em', position: 'relative' }}
                            >
                              Your message has been sent.
                            </Headline>
                            <Space y={30} />
                            <Text style={{ maxWidth: '23em' }}>
                              If you're as excited to get started as we are,
                              call us at{' '}
                              <a href="tel:+03234573899">
                                <TextUnderline color="#777">
                                  323.457.3899
                                </TextUnderline>
                              </a>
                              .
                            </Text>
                          </On>
                        </Grid.Item>
                      ) : (
                        <Grid.Item area="form">
                          <On min="tabletMd">
                            <Space x={{ tabletMd: '-5.55vmax' }}>
                              <BackgroundText animate={inView}>
                                CONTACT
                              </BackgroundText>
                            </Space>
                            <Space y={{ tabletMd: '-6.35vmax' }} />
                            <Fade
                              inView={inView}
                              delay={300}
                              style={{ position: 'relative' }}
                            >
                              <Text is="div">
                                My name is{' '}
                                <Input
                                  width="9.4em"
                                  name="fullName"
                                  placeholder="Full Name"
                                  isTouched={touched.fullName}
                                  isInvalid={
                                    touched.fullName && errors.fullName
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.fullName}
                                />{' '}
                                and I'm&nbsp;with{' '}
                                <Input
                                  width="9.8em"
                                  name="organizationName"
                                  placeholder="Organization Name"
                                  isTouched={touched.organizationName}
                                  isInvalid={
                                    touched.organizationName &&
                                    errors.organizationName
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.organizationName}
                                />
                                .
                              </Text>
                              <Space y={35} />
                              <Text is="div">
                                We are a{' '}
                                <BusinessTypeConsumer>
                                  {({ selected, setSelected }) => {
                                    const options = [
                                      'foreign brand coming to the 🇺🇸',
                                      'startup disrupting the market',
                                      'company that needs to rebrand',
                                    ]

                                    if (
                                      values.businessType !== options[selected]
                                    ) {
                                      setFieldValue(
                                        'businessType',
                                        options[selected]
                                      )
                                    }

                                    return (
                                      <Select
                                        selected={selected}
                                        onChange={({ value, selected }) => {
                                          setFieldValue('businessType', value)
                                          setSelected(selected)
                                        }}
                                        options={options}
                                        minWidth="13em"
                                        color="#757575"
                                        whiteSelected
                                        border
                                      />
                                    )
                                  }}
                                </BusinessTypeConsumer>
                                <Break />
                                and in need of an expert team to help me with{' '}
                                <Input
                                  width="17em"
                                  name="businessChallenge"
                                  placeholder="Business Challenge in a Snapshot"
                                  isTouched={touched.businessChallenge}
                                  isInvalid={
                                    touched.businessChallenge &&
                                    errors.businessChallenge
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.businessChallenge}
                                />
                                , <Break />
                                to be completed on or near{' '}
                                <Input
                                  width="9.6em"
                                  name="dateOrTimeframe"
                                  placeholder="Date or Timeframe"
                                  isTouched={touched.dateOrTimeframe}
                                  isInvalid={
                                    touched.dateOrTimeframe &&
                                    errors.dateOrTimeframe
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.dateOrTimeframe}
                                />
                                .
                              </Text>
                              <Space y={30} />
                              <Text is="div">
                                I&nbsp;have a&nbsp;budget between{' '}
                                <Select
                                  selected={4}
                                  onChange={({ value }) =>
                                    setFieldValue('budget', value)
                                  }
                                  options={[
                                    '$10,000 - 20,000',
                                    '$20,000 - 30,000',
                                    '$30,000 - 50,000',
                                    '$50,000 - 100,000',
                                    '$100,000 - 200,000',
                                    '$250,000 and up',
                                  ]}
                                  minWidth="13em"
                                  color="#757575"
                                  whiteSelected
                                  border
                                />
                                <Break />
                                You can reach me at{' '}
                                <Input
                                  width="9.6em"
                                  name="email"
                                  placeholder="Email Address"
                                  isTouched={touched.email}
                                  isInvalid={touched.email && errors.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                />{' '}
                                to get the conversation started.
                              </Text>
                              <Space y={30} />
                              <TextLink
                                is="button"
                                type="submit"
                                disabled={isSubmitting}
                                sm
                              >
                                SUBMIT YOUR MESSAGE
                              </TextLink>
                            </Fade>
                          </On>
                        </Grid.Item>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </WhenVisible>
          <Space y={{ mobile: 50, tabletMd: 150 }} />
        </DarkWrapper>
      </Relative>
      <HelloFab />
    </Layout.Shell>
  </Fragment>
)
