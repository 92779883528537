import React, { Fragment } from 'react'
import Component from '@reach/component-component'
import styled from 'react-emotion'
import Toggle from 'react-toggled'
import { Stack } from 'spaced-components'
import { duration } from '../styles/helpers'
import { rem } from 'spaced-components'

const SelectWrapper = styled('span')`
  display: inline-block;
  position: relative;
  z-index: 1;
`

const SelectOptionsWrapper = styled('div')`
  min-width: ${p => p.minWidth};
  width: 100%;
  position: absolute;
  top: 1.5em;
  left: 0;
  padding: ${rem(20)};
  background: rgba(0, 0, 0, 0.95);
  border: solid 1px;
  transition-property: opacity, visibility;
  transition-duration: ${duration('normal')};
  opacity: 0;
  visibility: hidden;

  ${p => p.open && `opacity: 1; visibility: visible`};
`

const SelectOption = styled('span')`
  display: inline-block;
  font-weight: 800;
  line-height: 1.4;
  ${p => p.color && p.whiteSelected && !p.selected && `color: ${p.color}`};
  ${p => p.color && !p.whiteSelected && `color: ${p.color}`};
  cursor: pointer;
  white-space: nowrap;
  transition: color 0.3s;

  &:hover {
    ${p => !p.selected && 'color: #fff'};
  }

  ${p =>
    p.selected &&
    p.border &&
    `
      padding: 0 ${rem(20)};
      border-bottom: 1px solid;
    `};
  ${p => p.selected && !p.border && `text-decoration: underline`};
`

const SelectArrowWrapper = styled('span')`
  display: inline-block;
  width: 0.8em;
  margin-left: 0.4em;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.1);
  outline: none;

  > svg {
    display: block;
    width: 100%;
    height: 100%;
    transition-property: transform, fill;
    transition: ${duration('normal')};
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 1;
  }

  ${p => (p.active ? `> svg { transform: rotateX(180deg) }` : '')};
`

const SelectArrow = props => (
  <SelectArrowWrapper {...props}>
    <svg viewBox="14 18 22 13">
      <path d="M34.9,19.9c-0.2-0.2-0.4-0.2-0.5,0L25,29.3l-9.3-9.3c-0.2-0.2-0.4-0.2-0.5,0C15,20,15,20.1,15,20.2c0,0.1,0,0.2,0.1,0.3l9.6,9.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l9.6-9.6C35,20.3,35,20.1,34.9,19.9" />
    </svg>
  </SelectArrowWrapper>
)

const Select = ({ selected, ...rest }) => (
  <Component initialState={{ selected }} {...rest}>
    {({
      state: { selected },
      setState,
      props: { options, onChange, color, whiteSelected, border, minWidth },
    }) => (
      <SelectWrapper>
        <Toggle>
          {({ on, getTogglerProps, setOn, setOff }) => (
            <Fragment>
              <SelectOption
                selected
                color={color}
                whiteSelected={whiteSelected}
                border={border}
                {...getTogglerProps()}
              >
                {options[selected]}
                <SelectArrow active={on} />
              </SelectOption>
              <SelectOptionsWrapper open={on} minWidth={minWidth}>
                <Stack is="ul" rowGap={5} onClick={setOff}>
                  {options
                    .map((option, index) => (
                      <li
                        key={option}
                        onClick={() =>
                          setState(
                            { selected: index },
                            () =>
                              onChange &&
                              onChange({
                                value: options[index],
                                selected: index,
                              })
                          )
                        }
                      >
                        <SelectOption
                          color={color}
                          whiteSelected={whiteSelected}
                        >
                          {option}
                        </SelectOption>
                      </li>
                    ))
                    .filter((option, index) => index !== selected)}
                </Stack>
              </SelectOptionsWrapper>
            </Fragment>
          )}
        </Toggle>
      </SelectWrapper>
    )}
  </Component>
)

export default Select
