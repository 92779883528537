import { Component } from 'react'
import { TweenMax, Power4 } from 'gsap'

class ScrollToIntended extends Component {
  static defaultProps = {
    root: [document.body, document.documentElement],
    selector: null,
    duration: 1,
    delay: 0,
    offset: 0,
  }

  scrollToTween = null

  componentDidMount() {
    if (typeof window === 'undefined') return

    if (this.props.selector) {
      this.scrollTo(this.props.selector)
      return
    }

    const hash = window.location.hash

    if (!hash || hash === '#') return

    this.scrollTo(hash)
  }

  handleClick = e => {
    this.href = e.currentTarget.getAttribute('href')

    if (this.href[0] !== '#') return
    e.preventDefault()

    if (this.href === '#') this.scrollTo(0)
    else this.scrollTo(this.href)
  }

  scrollTo = selectorOrNumber => {
    let scrollTop = 0

    if (typeof selectorOrNumber === 'number') {
      scrollTop = selectorOrNumber
    } else {
      const element = document.querySelector(selectorOrNumber)

      if (!element) return

      scrollTop = window.pageYOffset + element.getBoundingClientRect().top
    }

    scrollTop =
      scrollTop - this.props.offset >= this.props.offset
        ? scrollTop - this.props.offset
        : 0

    this.scrollToTween = TweenMax.to(this.props.root, this.props.duration, {
      scrollTop,
      delay: this.props.delay,
      ease: Power4.easeInOut,
      onComplete: () => {
        window.history.pushState(null, null, this.href)
        window.removeEventListener('wheel', this.cancelScrollToTween)
        window.removeEventListener('touchstart', this.cancelScrollToTween)
      },
    })
    window.addEventListener('wheel', this.cancelScrollToTween)
    window.addEventListener('touchstart', this.cancelScrollToTween)
  }

  cancelScrollToTween = () => {
    this.scrollToTween.pause()
    window.removeEventListener('wheel', this.cancelScrollToTween)
    window.removeEventListener('touchstart', this.cancelScrollToTween)
  }

  render() {
    return null
  }
}

export default ScrollToIntended
