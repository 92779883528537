import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Autolinker from 'react-autolinker'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import {
  Absolute,
  Break,
  Fixed,
  Flex,
  Grid,
  Inset,
  On,
  Relative,
  Space,
  Stack,
} from 'spaced-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import styled from '../../styles/styled'
import theme from '../../styles/theme'
import Layout from '../../Layout'
import LinesPattern from '../../components/LinesPattern'
import DarkWrapper from '../../components/DarkWrapper'
import WhenVisible from '../../components/WhenVisible'
import Img from '../../components/Img'
import TextLink from '../../components/TextLink'
import { AutoFade, Slide, Reveal } from '../../components/Animated'
import BackgroundText from '../../components/BackgroundText'
import Headline from '../../components/Headline'
import FlagVideo from '../../components/FlagVideo'
import HelloFab from '../../components/HelloFab'
import TextUnderline from '../../components/TextUnderline'
import { DecideWhenToShowFooter } from '../../components/Footer'
import FooterData from '../../components/FooterData'

import post01 from './images/post01.jpg'
import post02 from './images/post02.jpg'
import post03 from './images/post03.jpg'
import post04 from './images/post04.jpg'
import post05 from './images/post05.jpg'
import post06 from './images/post06.jpg'
import post07 from './images/post07.jpg'

const MediumIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg
    viewBox="0 0 195 195"
    style={{
      display: 'block',
      width: size,
      height: size,
      fill: color,
    }}
  >
    <path d="M0,0v195h195V0H0z M162,46.2l-10.5,10c-0.9,0.7-1.3,1.8-1.2,2.9v73.7c-0.2,1.1,0.3,2.2,1.2,2.9l10.2,10v2.2h-51.4v-2.2l10.6-10.3c1-1,1-1.3,1-2.9V73l-29.4,74.7h-4L54.4,73v50.1c-0.3,2.1,0.4,4.2,1.9,5.7L70,145.6v2.2H31v-2.2l13.8-16.7c1.5-1.5,2.1-3.7,1.8-5.7v-58h-0.1c0.2-1.6-0.5-3.2-1.7-4.3L32.7,46.2V44h38l29.4,64.4L125.8,44H162V46.2z" />
  </svg>
)

const LinkedInIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg
    viewBox="0 0 24 24"
    style={{
      display: 'block',
      width: size,
      height: size,
      fill: color,
    }}
  >
    <path d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M18.5,18.5V13.2A3.26,3.26 0 0,0 15.24,9.94C14.39,9.94 13.4,10.46 12.92,11.24V10.13H10.13V18.5H12.92V13.57C12.92,12.8 13.54,12.17 14.31,12.17A1.4,1.4 0 0,1 15.71,13.57V18.5H18.5M6.88,8.56A1.68,1.68 0 0,0 8.56,6.88C8.56,5.95 7.81,5.19 6.88,5.19A1.69,1.69 0 0,0 5.19,6.88C5.19,7.81 5.95,8.56 6.88,8.56M8.27,18.5V10.13H5.5V18.5H8.27Z" />
  </svg>
)

const PostTitle = styled('h3')(({ maxWidth = 'auto' }, { scale }) => ({
  maxWidth,
  fontWeight: 700,
  fontSize: scale({ mobile: 18 }, { laptopMd: 22, desktopMd: 26 }),
  textShadow: { mobile: '0 0 10px rgba(0, 0, 0, 0.42)', tabletLg: 'none' },
  zIndex: 1,
}))

const PostDescription = styled('p')((props, { scale }) => ({
  fontSize: scale({ mobile: 14 }, { laptopMd: 14, desktopLg: 16 }),
  fontFamily: '"Source Serif Pro", serif',
  lineHeight: 1.8,
  opacity: 0.8,
}))

const Post = ({ direction, title, summary, img, url, service }) => (
  <WhenVisible rootMargin="-25% 0px">
    {inView => (
      <Grid
        templateColumns={{ mobile: '10% 1fr', tabletLg: 'repeat(4, 1fr)' }}
        alignItems="center"
      >
        <Grid.Item
          column={{
            mobile: '2 / 3',
            tabletLg: direction === 'right' ? '1 / 4' : '2 / 5',
          }}
          row="1"
        >
          <Relative>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <Reveal
                inView={inView}
                direction={
                  window.matchMedia('(min-width: 900px)').matches
                    ? direction
                    : 'left'
                }
              >
                <Img src={img} ratio={70 / 100} />
              </Reveal>
              <On max="tabletLg">
                <Absolute right={24} bottom={24}>
                  {
                    {
                      medium: <MediumIcon color="#fff" />,
                      linkedIn: <LinkedInIcon color="#fff" size={30} />,
                    }[service]
                  }
                </Absolute>
              </On>
            </a>
          </Relative>
        </Grid.Item>
        <Grid.Item
          column={{
            mobile: '1 / 3',
            tabletLg: direction === 'right' ? '3 / 5' : '1 / 3',
          }}
          row="1"
          zIndex={1}
        >
          <a href={url} target="_blank" rel="noopener noreferrer">
            <On max="tabletLg">
              <Slide left inView={inView} delay={400} translate={80}>
                <PostTitle maxWidth="80%">
                  <TextUnderline top={0.74} color="rgba(255, 255, 255, .3)">
                    {title}
                  </TextUnderline>
                </PostTitle>
              </Slide>
            </On>
            <On min="tabletLg">
              <Slide direction={direction} delay={400} inView={inView}>
                <Relative>
                  <Inset lg style={{ color: '#000', backgroundColor: '#fff' }}>
                    <AutoFade delay={800} inView={inView}>
                      <Stack md left>
                        <PostTitle>
                          <TextUnderline top={0.65} color="#ddd">
                            {title}
                          </TextUnderline>
                        </PostTitle>
                        <PostDescription>{summary}</PostDescription>
                        <TextLink is="span">GO TO ARTICLE</TextLink>
                      </Stack>
                      <Absolute right={27} bottom={27}>
                        {
                          {
                            medium: <MediumIcon color="#000" />,
                            linkedIn: <LinkedInIcon color="#000" size={28} />,
                          }[service]
                        }
                      </Absolute>
                    </AutoFade>
                  </Inset>
                </Relative>
              </Slide>
            </On>
          </a>
        </Grid.Item>
      </Grid>
    )}
  </WhenVisible>
)

const TweetTitle = styled('h3')((props, { scale }) => ({
  fontWeight: 700,
  fontSize: scale(
    { mobile: 18 },
    { tablet: 18, laptopMd: 24 },
    { laptopMd: 24, desktopMd: 30 }
  ),
  'max:tablet': {
    '& span': {
      wordBreak: 'break-all',
    },
  },
}))

const TweetDate = styled('span')(() => ({
  fontWeight: 700,
  fontSize: 13,
}))

const TweetWrapper = styled('div')(() => ({
  backgroundColor: '#111',
  transition: '.3s',
  ':hover': {
    backgroundColor: '#018abd',
  },
}))

const Tweet = ({ text, timestamp, url }) => (
  <AutoFade>
    <Link to={url}>
      <Relative>
        <TweetWrapper>
          <Absolute
            width={20}
            height={20}
            left={{ mobile: '10vw', tabletMd: 50 }}
            top={{ mobile: '8vw', tabletMd: 40 }}
          >
            <svg viewBox="0 0 24 24">
              <path
                fill="#00aced"
                d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z"
              />
            </svg>
          </Absolute>
          <Space mobile="11vw" tabletMd={0} />
          <Inset
            x={{ mobile: '10vw', laptop: 124 }}
            y={{ mobile: '10vw', laptop: 100 }}
          >
            <TweetTitle is="div">
              <Autolinker
                text={text}
                options={{ stripPrefix: false, newWindow: true }}
                renderLink={tag => (
                  <TextUnderline key="tag" color="#018abd">
                    {tag.innerHtml}
                  </TextUnderline>
                )}
              />
            </TweetTitle>
            <Space lg />
            <TweetDate>{moment(timestamp).format('MMM DD, YYYY')}</TweetDate>
          </Inset>
        </TweetWrapper>
      </Relative>
    </Link>
  </AutoFade>
)

const posts = [
  {
    type: 'article',
    service: 'medium',
    url:
      'https://medium.com/advictorem/make-that-two-awards-for-l-a-s-newest-branding-agency-e464d056ab9d',
    title: 'Make That Two Awards for L.A.’s Newest Branding Agency',
    summary: 'Ad Victorem Adds Muse Award To HOW Award 🏆 Honors',
    img: post01,
    timestamp: 1522768809253,
  },
  {
    type: 'article',
    service: 'medium',
    url:
      'https://medium.com/advictorem/ask-the-professor-how-do-i-create-a-concept-for-a-fashion-social-media-campaign-2022372da4de',
    title:
      'Ask The Professor: How Do I Create A Marketing Plan For My New Business?',
    summary:
      'Q: Dear Freddy: How should I prepare my marketing plan and conduct local research?',
    img: post02,
    timestamp: 1522768809253,
  },
  {
    type: 'article',
    service: 'medium',
    url:
      'https://medium.com/advictorem/use-your-delusion-customer-centricity-as-the-trend-du-jour-70dbabb54301',
    title: 'Customer Centricity and the Marketing Delusion',
    summary:
      '7 Reasons why customer-centricity in not an effective business strategy',
    img: post03,
    timestamp: 1522768809253,
  },
  {
    type: 'tweet',
    service: 'tweet',
    url: '#',
    text:
      'Success and failure are often separated by a small distance: one inch, one second, one single point... https://t.co/DQuDAssaY',
    timestamp: 1522768809253,
  },
  {
    type: 'article',
    service: 'medium',
    url:
      'https://medium.com/advictorem/designing-a-new-website-problem-seek-before-you-problem-solve-84a926bd9b77',
    title: 'Designing a New Website? Problem Seek Before You Problem Solve',
    summary: 'How the discovery phase saves time and money.',
    img: post04,
    timestamp: 1522768809253,
  },
  {
    type: 'article',
    service: 'medium',
    url:
      'https://medium.com/advictorem/competitive-strategy-a-sweet-demonstration-149dbd9643e7',
    title: 'Competitive Strategy (A Sweet Demonstration)',
    summary: 'Never shy from competition.',
    img: post05,
    timestamp: 1522768809253,
  },
  {
    type: 'article',
    service: 'medium',
    url:
      'https://medium.com/advictorem/sometimes-the-product-is-just-an-excuse-when-brands-come-first-2485d44f3ceb',
    title: 'Sometimes The Product Is Just An Excuse: When Brands Come First',
    summary:
      'In the marketing version of chicken-and-egg, we marketers love to endlessly debate which should come first',
    img: post06,
    timestamp: 1522768809253,
  },
  {
    type: 'article',
    service: 'medium',
    url:
      'https://medium.com/advictorem/ask-the-professor-how-do-i-create-a-concept-for-a-fashion-social-media-campaign-2022372da4de',
    title:
      'Ask The Professor: How Do I Create A Marketing Plan For My New Business?',
    summary:
      'Q: Dear Freddy: How should I prepare my marketing plan and conduct local research?',
    img: post02,
    timestamp: 1522768809253,
  },
  {
    type: 'article',
    service: 'medium',
    url:
      'https://medium.com/advictorem/have-creative-agencies-killed-creative-humans-66cecce94f1a',
    title: 'Have Creative Agencies Killed Creative Humans?',
    summary:
      'When most agencies and clients settle for cliches and cookie-cutter campaigns, we knock them off-balance with daring creativity and strategy.',
    img: post07,
    timestamp: 1522768809253,
  },
]

const Posts = ({ posts }) => {
  let tweetTracker = 0

  return (
    <Stack
      mobile={65}
      laptop="xxl"
      overflowX={{ mobile: 'hidden', laptop: 'visible' }}
    >
      {posts.map((post, i) => {
        switch (post.type) {
          case 'tweet':
            tweetTracker++
            return <Tweet key={i} {...post} />
          case 'article':
          default:
            return (
              <Post
                key={i}
                direction={(i + 1 + tweetTracker) % 2 ? 'right' : 'left'}
                {...post}
              />
            )
        }
      })}
    </Stack>
  )
}

const Text = styled('p')(() => ({
  fontWeight: 700,
  fontSize: { mobile: 18, laptop: '1.45vw' },
  lineHeight: 1.6,
}))

const Field = styled('div')(() => ({
  flex: 1,
  position: 'relative',
  '::after': {
    content: '"*"',
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 22,
    color: '#f44336',
  },
}))

const Input = styled('input')(({ isTouched, isInvalid }) => ({
  width: '100%',
  fontWeight: 700,
  fontSize: { mobile: 18, laptop: '1.45vw' },
  lineHeight: 1.6,
  fontFamily: 'inherit',
  color: isInvalid ? '#f44336' : isTouched ? '#fff' : 'rgba(255, 255, 255, .6)',
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
  borderBottom: '1px solid',
  borderColor: isInvalid && '#f44336',
  transition: '.3s',
  '::placeholder': {
    color: isInvalid && '#f44336',
  },
  ':focus': {
    color: '#fff',
  },
}))

const SignUpForm = () => (
  <MailchimpSubscribe
    url="https://design.us18.list-manage.com/subscribe/post?u=47ac7c370c3a2b56244dac91f&id=f1a2a12baa"
    render={({ subscribe, status, message }) => (
      <Formik
        initialValues={{
          fullname: '',
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required(),
          fullname: Yup.string().required(),
        })}
        onSubmit={(formData, { resetForm, setStatus, setSubmitting }) => {
          const [, FNAME, LNAME] = /(.+?)\s(.+)/.exec(formData.fullname)
          const data = {
            FNAME,
            LNAME,
            EMAIL: formData.email,
          }
          subscribe(data)
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            {status === 'success' ? (
              <Text>
                Thank you for signing up for our newsletter.{' '}
                <Break min="laptop" />
                We promise no spam, just good stuff! 👏👏
              </Text>
            ) : status === 'error' ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            ) : (
              <Fragment>
                <Headline is="h2">Want more?</Headline>
                <Space mobileSm={20} />
                <Text css={{ maxWidth: '28em' }}>
                  Never miss insights and thoughts from Ad Victorem, when you
                  sign up for our blog and receive our monthly newsletter on
                  business, marketing, and design.
                </Text>
                <Space mobileSm={30} />
                <Flex columnGap={20} middle width="95%">
                  <Field>
                    <Input
                      placeholder="Full Name"
                      isTouched={touched.fullname}
                      isInvalid={touched.fullname && errors.fullname}
                      name="fullname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullname}
                    />
                  </Field>
                  <Field>
                    <Input
                      placeholder="Email Address"
                      isTouched={touched.email}
                      isInvalid={touched.email && errors.email}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </Field>
                  <TextLink is="button" type="submit" disabled={isSubmitting}>
                    Subscribe
                  </TextLink>
                </Flex>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    )}
  />
)

export default () => (
  <Fragment>
    <Helmet>
      <title>Our Blog</title>
      <meta name="description" content="Our Blog description" />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <Layout.Shell>
      <Relative zIndex={1} style={{ marginBottom: '70vh' }}>
        <DarkWrapper>
          <LinesPattern />
          <Space xxl />
          <Relative>
            <Grid
              templateAreas="'. content .'"
              templateColumns={{
                mobile: '1fr 8fr 1fr',
                tabletMd: '1fr 4fr 1fr',
              }}
            >
              <Grid.Item area="content">
                <WhenVisible>
                  {inView => (
                    <Fragment>
                      <Space x={{ tabletMd: '-5.55vmax' }}>
                        <BackgroundText animate={inView}>
                          OUR BLOG
                        </BackgroundText>
                      </Space>
                      <On min="tabletMd">
                        <Space y={{ tabletMd: '-6.35vmax' }} />
                        <Space x={{ tabletMd: -5 }}>
                          <Slide left inView={inView}>
                            <Headline>News. Views. Articles.</Headline>
                          </Slide>
                        </Space>
                      </On>
                    </Fragment>
                  )}
                </WhenVisible>
                <Space mobile={65} laptop="xxl" />
                <Posts posts={posts} />
              </Grid.Item>
            </Grid>
          </Relative>
          <Space xxl />
        </DarkWrapper>
      </Relative>

      <DecideWhenToShowFooter>
        <Fixed is="footer" bottom left={theme.barWidth} right={theme.barWidth}>
          <Flex middle height="70vh">
            <Absolute top left height="100%">
              <FlagVideo />
            </Absolute>

            <Relative width="100%">
              <Grid
                template={{
                  mobile: "'. details .' / 1fr 8fr 1fr",
                  laptop: "'. form . details' / 1fr 3fr 2rem 2fr",
                }}
                alignItems="center"
              >
                <Grid.Item area="form">
                  <On min="laptop">
                    <SignUpForm />
                  </On>
                </Grid.Item>
                <Grid.Item area="details">
                  <FooterData />
                </Grid.Item>
              </Grid>
            </Relative>
          </Flex>
        </Fixed>
      </DecideWhenToShowFooter>
      <HelloFab />
    </Layout.Shell>
  </Fragment>
)
