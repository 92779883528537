import React, { Component } from 'react'
import styled, { keyframes } from 'react-emotion'
import debounce from 'lodash.debounce'

import ScrollToLink from './ScrollToLink'

import { passive } from '../utils'

const animation = keyframes({
  from: { transform: 'translateY(-15%)' },
  to: { transform: 'translateY(10%)' },
})

const StyledSvg = styled('svg')(
  {
    display: 'block',
    width: 50,
    fill: 'none',
    stroke: '#fff',
    transformOrigin: 'center',
    animation: `${animation} .6s ease-in-out infinite alternate`,
  },
  ({ isAbove }) => ({
    animationPlayState: isAbove ? 'play' : 'paused',
  })
)

class ScrollIndicator extends Component {
  state = {
    isAbove: false,
  }

  componentDidMount() {
    this.updateProgress()
    window.addEventListener('scroll', this.updateProgress, passive)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateProgress)
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = debounce(() => {
    this.updateProgress()
  }, 300)

  updateProgress = () => {
    if (!this.circle) return

    if (window.pageYOffset <= 50) {
      this.setState({ isAbove: true })
    } else if (this.state.isAbove) {
      this.setState({ isAbove: false })
    }

    const distance =
      (document.documentElement.scrollHeight || document.body.scrollHeight) -
      window.innerHeight
    let progress = window.pageYOffset / distance
    progress = Math.max(0, progress)
    progress = Math.min(1, progress)

    this.circle.style.strokeDashoffset = (1 - progress) * 502
  }

  render() {
    return (
      <ScrollToLink
        href={this.state.isAbove ? '#case-study-content' : '#'}
        {...this.props}
      >
        <StyledSvg viewBox="0 0 200 200" isAbove={this.state.isAbove}>
          <g
            style={{
              transition: 'transform .3s',
              transformOrigin: 'center',
              transform: this.state.isAbove ? 'rotate(180deg)' : '',
            }}
          >
            <line
              strokeWidth="7"
              strokeMiterlimit="10"
              x1="100"
              y1="126"
              x2="100"
              y2="76"
            />
            <line
              strokeWidth="7"
              strokeLinecap="square"
              strokeMiterlimit="10"
              x1="85"
              y1="91"
              x2="100"
              y2="76"
            />
            <line
              strokeWidth="7"
              strokeLinecap="square"
              strokeMiterlimit="10"
              x1="100"
              y1="76"
              x2="115"
              y2="91"
            />
            <circle cx="100" cy="100" r="80" opacity=".2" strokeWidth="7" />
            <circle
              ref={ref => (this.circle = ref)}
              cx="100"
              cy="100"
              r="80"
              strokeWidth="7"
              strokeDasharray="502"
              style={{
                transformOrigin: 'center',
                transform: 'rotate(-90deg)',
              }}
            />
          </g>
        </StyledSvg>
      </ScrollToLink>
    )
  }
}

export default ScrollIndicator
