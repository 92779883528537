import React from "react"
import GSAPComponent from "../../components/GSAPComponent"

export default class extends GSAPComponent {
  animate() {
    /* eslint-disable */
    const paths = this.svg.querySelectorAll("#pp-mask path")
    const tl = new TimelineMax({ delay: 0.2 })
    const ease = Power1.easeInOut

    tl.from(paths[0], 0.5, { drawSVG: "100% 100%", ease })
    tl.from(paths[1], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[2], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[3], 0.5, { drawSVG: "100% 100%", ease }, "-=.2")
    tl.from(paths[4], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[5], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[6], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[7], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[8], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[9], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[10], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[11], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[12], 0.5, { drawSVG: "0%", ease }, "-=.2")
    tl.from(paths[13], 0.5, { drawSVG: "0%", ease }, "-=.2")

    return tl
    /* eslint-enable */
  }

  render() {
    return (
      <svg ref={ref => (this.svg = ref)} viewBox="0 0 955 319" width={340}>
        <defs>
          <mask id="pp-mask">
            <rect x="0" y="0" width="955" height="319" fill="#000" />
            <g fill="none" stroke="#fff" strokeWidth="26">
              <path
                id="path-01"
                d="M100.7186737,124.1826782c0,0-22.3784409-26.1747131,14.6146622-42.59935
            c36.9251022-16.394455,75.2803574-27.8056335,96.1398087,2.8213348C232.6618805,115.5150909,189.4091492,152.4503937,172.25,160.25
            c-17.1591492,7.7995911-30.9733582,16.9700317-45.0126495,7.6105042"
              />
              <path
                id="path-02"
                d="M160.7756653,94.5441437c-31.967804,48.797287-69.1108398,107.6977997-81.8959045,137.2731781"
              />
              <path
                id="path-03"
                strokeWidth="30"
                d="M190.3333282,208.1666565c0,0,31.9510651-49.3378754,40.5306396-57.1374969
            c10.0650635-10.5576782,21.5258636-15.8058167,30.7639008-5.1536255
            C270.8659058,156.5277405,248.0773468,176.8730621,240.3333282,188
            c-7.7440186,11.1269226-20.4044189,46.4315796,24.8333282,11.3333435
            C297.5,171.8183746,306.628418,156.161087,306.628418,156.161087"
              />
              <path
                id="path-04"
                d="M375.2649841,155.3811188c0,0-42.1179199,58.4970551-70.9765015,56.9371796
            c-28.858551-1.5599365-15.5992126-28.8585815-10.9194641-36.6581879s31.9783936-44.4577942,45.2377319-32.7583771
            c13.2593689,12.4793854-6.2396545,25.7387085-14.0392761,31.1984406
            c-7.0196533,3.8998108-14.0393066,5.4597473-17.9391174,3.1198578"
              />
              <path
                id="path-05"
                d="M345.6264648,190.4793396c0,0,36.6131287-53.7200317,49.8724976-51.3801422
            c13.2593384,2.3398743-13.9942932,72.4391022-31.1534424,73.9990387
            C347.1864014,214.6581726,341.7266541,195.9390869,345.6264648,190.4793396"
              />
              <path
                id="path-06"
                d="M376.8248901,211.5382996c30.4185181-8.5795593,71.1957092-78.753067,83.6751099-72.513382
            c12.4793701,6.2396851-13.4786072,56.9141693-18.9382935,64.7137909
            c-5.4597168,7.7995911-22.6188965,21.0589294-34.3182983-10.1394958"
              />
              <path
                id="path-07"
                d="M444.6815186,204.5186768c0,0,27.298645-9.1273499,56.9371643-49.6853485"
              />
              <path
                id="path-08"
                d="M525.0175171,124.9626312c0,0-19.2937622-28.2377472,17.7324829-44.5876312
            c36.9457397-16.3143311,72.2142334-26.4272461,93.0220337,4.0296631
            c21.05896,31.1984253-22.6188965,67.0766449-39.7780151,74.8762665
            c-17.1591187,7.7995911-30.4185181,17.9391022-44.4577637,8.5795746"
              />
              <path
                id="path-09"
                d="M585.0744629,95.3241119c-33.5383301,47.5776215-70.1964722,106.85466-81.8959045,137.2731476"
              />
              <path
                id="path-10"
                d="M658.3908691,149.9213867c0,0-44.4577637,59.2770386-19.4990234,62.3969116
            c24.9586792,2.3398743,60.8369751-62.3968964,49.1375122-73.3163452
            c-11.6994019-10.9194641-24.178772,3.1198425-26.5186768,14.8192444
            c-1.5598755,11.6994171,9.3594971,63.9568176,60.0570068,10.1394958"
              />
              <path
                id="path-11_1_"
                d="M759.0058594,107.0235291c0,0-98.2750854,169.2515564-106.074707,194.9902954"
              />
              <path
                id="path-12"
                d="M728.5873413,171.7602997c0,0,32.7583618-34.3182831,42.8978882-28.8585663
            c10.1394653,5.4597168-21.05896,73.3163452-40.5579834,68.6365662s-17.9391479-17.9390869-17.9391479-17.9390869"
              />
              <path
                id="path-13"
                d="M743.4066162,211.5382996c35.8781738-3.8998108,80.4473267-78.1452026,92.9266968-71.9055023
            c12.4793701,6.2396851-14.930603,54.7463531-20.3903198,62.5459747
            c-5.4596558,7.7996216-22.6188354,21.8388977-34.3182983-10.1394958"
              />
              <path
                id="path-14"
                d="M819.0628052,203.7387085c0,0,28.9830933-8.4272461,58.6216431-48.9851837"
              />
            </g>
          </mask>
        </defs>

        <g mask="url(#pp-mask)" fill="#fff">
          <path
            id="letter-01"
            d="M77.3198395,224.7976379c-1.5599213-1.559906-3.1198425-3.8997803-3.1198425-6.2396545
          c0.7799683-5.4597473,3.1198425-10.1394958,5.4597321-14.8192749c2.3398819-3.8998108,3.8998032-7.7996216,6.2396851-12.4793701
          c1.5599213-3.8998108,3.8998108-7.7996216,6.2396927-10.9194794c0.7799683-0.7799377,1.5599213-1.5599213,2.3398819-2.3398743
          c0.7799683-0.779953,1.5599213-3.1198578,2.3398895-5.4597321c0.7799606-1.5599213,1.5599213-2.3398743,2.3398819-3.1198578
          c0.7799683-1.5599213,1.5599213-3.1198578,2.3398895-3.8998108c0.7799606-2.3398743,1.5599213-3.8997955,3.1198349-6.2396698
          c0.7799683-1.5599365,1.5599213-2.3398895,2.3398895-3.1198425c0.7799606-1.5599365,0.7799606-2.3398743,1.5599289-3.1198425
          c0.7799606-0.7799683,1.5599289,0,2.3398743-0.7799683c0.7799683-0.7799683,2.3398819-3.1198425,2.3398819-3.8998108
          c0.7799606-1.5599213,1.5599213-3.1198425,2.3398743-4.6797638c1.5599289-3.1198425,4.6797714-6.2396851,6.2396851-9.3595428
          c1.5599213-3.1198425,3.1198425-6.2396851,5.4597321-9.3595276c2.3398895-3.1198578,5.4597321-6.2396851,6.2396851-7.7996216
          c0-0.779953,0.779953-1.5599213,0.779953-2.3398743c0-0.7799683,0.7799683-0.7799683,1.5599365-0.7799683
          c0,0,0-1.5599213,0.7799683-2.3398743c0.779953-0.779953,2.3398743-2.3398743,3.8997955-3.8998108
          c0.7799683-1.5599213,0-1.5599213,0.7799683-2.3398743s2.3398743-0.779953,3.1198425-2.3398743
          c1.5599213-3.1198425,3.8998108-5.4597321,6.2396851-7.7996063c0.7799683-0.7799683,2.3398743-1.5599365,3.8997955-1.5599365
          c3.1198425,0,3.1198425,2.3398743,7.0196533,6.2396851c1.5599365,1.5599365,2.3398743,3.8998108,3.1198425,6.2396851
          c0,2.3398743-0.7799683,4.6797638-2.3398743,6.2396851c-2.3398743,3.8998108-5.4597321,8.5795746-7.7996216,12.4793854
          c-4.6797638,7.0196381-9.3595276,14.0392914-13.2593384,21.8388977c-1.5599213,2.3398743-3.1198425,4.6797638-4.6797638,7.7996063
          c-0.7799683,1.5599365-3.1198425,3.8998108-3.1198425,5.4597321c0,1.5599365,0.779953,1.5599365,2.3398743,1.5599365
          c0.7799683,0,2.3398743,0,3.1198425,0c7.7996063-0.7799683,14.8192596-3.1198425,21.05896-7.7996063
          c1.5599213-1.5599365,3.8997955-2.3398895,5.4597321-3.1198425c1.5599213-0.7799683,3.1198425-1.5599365,4.6797638-2.3398895
          l10.1394958-7.0196381c7.0196381-4.6797791,13.2593384-10.9194489,17.9390869-17.9391022
          c1.5599365-2.3398743,3.1198425-5.4597321,3.8998108-7.7996063c1.5599213-2.3398743,2.3398743-3.8998108,3.1198425-6.2396851
          c1.5599213-3.1198425,2.3398743-6.2396851,2.3398743-10.1394958c0-3.8997955-0.7799683-7.0196381-2.3398743-10.9194489
          c-2.3398743-3.1198425-5.4597321-6.2396851-9.3595428-8.5795746c-3.1198425-1.5599213-7.0196381-2.3398743-10.9194489-2.3398743
          c-3.1198273-0.7799835-7.0196381-0.7799835-10.9194336-0.7799835c-2.3398743,0-4.6797791,0-7.0196533,0.7799683
          c-1.5599213,0.7799683-3.8997955,1.5599213-5.4597321,1.5599213c-4.6797638,0.7799683-8.5795746,0.7799683-12.4793854,2.3398895
          c-9.3595276,3.1198425-18.7190552,6.2396698-28.078598,10.9194489c-2.3398743,1.5599213-4.6797714,3.1198425-7.0196457,4.6797638
          c-3.8998032,2.3398743-7.799614,4.6797791-11.6994171,7.7996063c-1.5599213,1.5599365-4.6797714,4.6797791-4.6797714,7.7996216
          s3.8998108,4.6797638,3.8998108,7.0196381c0,1.5599365-0.7799683,3.1198425-2.3398895,3.1198425
          s-4.6797638-2.3398743-5.4597244-3.1198425c-3.8998032-2.3398743-7.0196457-5.4597321-9.3595276-9.3595276
          c-0.7799683-1.5599365-0.7799683-3.1198425-0.7799683-5.4597321c0.7799683-5.4597473,3.1198425-11.6994171,6.2396927-16.3791962
          c0.7799606-1.5599213,2.3398819-2.3398743,3.8998032-3.1198425c1.5599213-0.779953,1.5599213-1.5599213,3.1198425-2.3398743
          c0.7799606-0.7799683,2.3398819-1.5599213,3.1198425-2.3398743c0.779953-0.779953,0.7799606-2.3398743,2.3398819-3.1198425
          s2.3398895-0.7799683,3.8998032-0.7799683c1.5599213-0.7799683,3.1198502-1.5599213,4.6797638-2.3398743
          c1.5599289-0.7799683,2.3398819-1.5599213,3.8998108-2.3398743c1.5599213-0.7799683,3.1198349-1.5599365,4.6797638-3.1198425
          c1.5599289-0.7799683,0.7799683-2.3398743,1.5599289-3.1198425c0.7799683-1.5599213,3.1198425-1.5599213,4.6797638-1.5599213
          c3.8998108-0.7799683,7.0196533-2.3398895,10.9194641-3.1198425c3.8997955-0.7799683,7.7996063-1.5599365,11.6994171-3.1198425
          c3.1198425-0.7799683,7.0196381-1.5599365,10.1394806-2.3398895c2.3398743-0.779953,4.6797791-0.779953,7.0196533-1.5599213
          c1.5599213,0,2.3398743,0.7799683,4.6797638,0.7799683s3.1198425-0.7799683,4.6797638-0.7799683
          c2.3398895,0,1.5599365,0.7799683,3.1198425,0.7799683l0,0c1.5599365,0,2.3398895-0.7799683,3.8998108-0.7799683
          c0.7799683,0.7799683,2.3398743,1.5599213,3.1198425,2.3398743c0.7799683,0.7799683,2.3398743,0.7799683,3.8998108,0.7799683
          c1.5599213,0,3.8997955,0.7799683,5.4597321,2.3398743c3.1198425,2.3398743,7.0196381,3.1198425,10.1394806,5.4597321
          c3.1198425,2.3398743,6.2396851,4.6797791,8.5795746,7.7996216c6.2396851,6.2396698,9.3595428,14.0392914,10.9194641,21.8388977
          c0.779953,2.3398743,0.779953,5.4597321,0.779953,7.7996063c0,7.0196533-1.5599213,14.0392914-3.8997955,21.05896
          c-2.3398895,7.0196533-6.2396851,14.0392914-10.9194641,19.4990234
          c-4.6797638,6.2396851-10.1394806,12.4793854-17.1591492,17.1591492
          c-7.0196381,4.6797791-14.0392914,9.3595276-21.8388977,14.0393066c-3.8998108,2.3398895-7.7996063,3.8998108-11.6994171,4.6797638
          c-5.4597321,1.5599213-10.9194489,3.1198578-17.1591492,3.1198578c-3.1198425,0-6.2396851-0.779953-9.3595428-1.5599365
          c-3.1198425-1.5599213-5.4597321-3.1198578-8.5795746-3.8997955c-1.5599289,0-2.3398743,2.3398743-4.6797714,5.4597321
          l-3.8997726,4.6797333c-2.3398743,3.1198425-3.8998032,7.0196533-5.4597321,10.1394653
          c-1.5599289,3.119873-3.1198425,3.119873-4.6797714,6.2396851c-1.5599289,3.1198425-5.4597244,10.9194641-7.0196457,15.5992432
          c-1.5599213,2.3398743-3.1198425,4.6797485-4.6797714,7.0196533c-1.5599213,3.1198425-2.3398819,6.2396851-6.2396927,6.2396851
          C84.3394928,231.8173218,79.6597214,227.1375427,77.3198395,224.7976379z"
          />
          <path
            id="letter-02"
            d="M238.7717743,220.8978577c-4.6797485,0-8.5795746-2.3398743-11.6994171-5.4597473
          c-2.3398743-3.1198425-3.8997955-7.0196533-3.1198425-10.9194641c0-1.5599365,0-3.8998108,0-5.4597473
          c0-1.559906,0-3.1198425,0.7799683-4.6797485s2.3398743-3.1198425,3.8998108-4.6797485
          c0.7799683-1.5599365,2.3398743-2.3398743,3.8998108-3.8998108c0.7799377-1.559906,2.3398743-2.3398743,2.3398743-3.1198425
          c0-0.7799835-0.779953-0.779953-0.779953-2.3398895c0-1.5599213,0.779953-1.5599213,1.5599213-3.1198578
          c2.3398895-3.8997955,4.6797638-7.0196533,7.7996216-10.1394806c1.5599213-1.5599365,3.1198578-2.3398743,4.6797485-3.8998108
          c1.559906-1.5599213,0.779953-2.3398743,2.3398743-3.1198425c0.7799683-1.5599213,1.5599365-1.5599213,1.5599365-1.5599213
          s-0.7799377-0.7799683-1.5599365-0.7799683c-0.7799377,0-1.5599213,0-2.3398743,0c-1.5599213,0-3.1198578,0-4.6797485,0
          c-2.3398743,0-2.3398743,0.7799683-3.8998108,2.3398743c-0.779953,1.5599365-2.3398743,3.8998108-3.8998108,5.4597321
          c-1.5599213,2.3398895-2.3398743,3.8998108-3.1198425,3.8998108c-1.5599365,2.3398743-4.6797791,5.4597321-7.0196533,7.7996063
          c-1.5599365,2.3398895-3.8998108,3.8998108-4.6797638,6.2396851c-1.5599365,2.3398743-2.3398895,3.8998108-4.6797791,5.4597473
          c-2.3398743,2.3398743-3.8997955,5.4597168-6.2396851,7.7995911c-2.3398743,2.3398743-3.1198425,3.8998108-4.6797638,5.4597473
          c-1.5599365,1.5599365-3.8998108,3.8998108-6.2396851,5.4597168c-2.3398743,1.5599365-1.5599213,1.5599365-2.3398895,1.5599365
          s-0.7799683,0-0.7799683,0s-0.779953,0-1.559906,0c-0.7799683,0-1.5599365,0-1.5599365-0.7799377
          c-1.5599213-0.7799683-1.5599213-0.7799683-2.3398743-1.5599365s0-2.3398743-0.7799683-3.8998108
          c-0.779953-0.7799377-1.5599213-0.7799377-1.5599213-1.559906s0.7799683-1.5599365,1.5599213-3.119873
          c0.7799683-0.7799377,0.7799683-2.3398743,1.5599365-3.1198425s0.7799683-0.7799683,1.5599213-0.7799683
          c1.5599365-1.559906,2.3398743-2.3398743,3.1198425-3.1198425c0.7799683-0.7799988,3.8998108-3.8998108,5.4597321-6.2396851
          c1.5599365-1.5599365,3.1198425-3.8998108,4.6797791-6.2396851c1.5599213-2.3398743,3.1198425-3.8998108,4.6797638-7.0196686
          c1.5599213-2.3398743,3.8998108-5.4597321,6.2396851-7.7996063c2.3398743-3.1198425,3.8998108-5.4597321,5.4597321-7.7996063
          c0.7799683-0.7799683,1.5599213-2.3398743,2.3398743-3.1198425c0-0.7799683,0.7799683-1.5599365,0.7799683-3.1198425
          c0-0.7799683,0.7799683-1.5599365,0.7799683-3.1198425c0.779953-1.5599213,0.779953-3.1198425,0.779953-4.6797638
          c0-0.7799683,0-2.3398895,0-3.8998108c0-0.7799683,0-1.5599365,0.7799683-2.3398743
          c0-0.7799683,1.5599365-0.7799683,1.5599365-1.5599365c0-0.779953,0.779953-1.5599213,1.5599213-2.3398743
          c0,0,0.7799683-0.7799683,1.559906-0.7799683c0.779953,0,0.779953,0,1.5599365,0.7799683c0.779953,0,1.5599213,0,1.5599213,0
          c0.779953,0,0.779953,0.7799683,1.5599365,1.5599365c1.5599213,1.5599213,3.8997955,3.8997955,6.2396851,3.8997955
          c1.5599213-0.7799683,3.8997955-0.7799683,5.4597321-1.5599213c1.5599213,0,2.339859,0,4.6797638-0.7799683
          c1.559906-0.7799683,1.559906-0.7799683,2.3398743-1.5599213c0.7799377,0,1.559906,0,2.3398743,0
          c2.3398743,0,3.8998108-1.5599365,6.2396851-1.5599365s4.6797485,3.1198425,6.2396851,5.4597321
          c3.1198425,3.8998108,7.0196533,7.7996216,7.0196533,12.4793854c0,3.1198425-1.5599365,6.2396851-3.119873,8.5795746
          c-4.6797485,7.0196381-11.6994019,12.4793854-17.1591492,19.4990387
          c-2.3398743,3.8998108-5.4597168,7.0196686-7.7995911,10.9194794c-0.779953,2.3398743-2.3398895,3.8998108-3.1198578,6.2396851
          c-1.5599365,1.559906-2.3398895,3.8997803-2.3398895,5.4597168s0.779953,1.5599365,1.5599365,1.5599365
          c4.6797485,0,16.3791809-9.3595276,17.9390869-10.1394958c7.0196838-5.4597168,13.2593689-11.6994171,19.4990234-18.7190704
          c3.8998108-3.8998108,6.2396851-7.7996216,9.3595581-11.6994171c1.5599365-1.5599365,3.1198425-3.1198425,3.1198425-3.8998108
          c0.7799683-0.7799683,0.7799683-1.5599213,1.5599365-1.5599213c0,0,0.7799377,0.779953,0.7799377,2.3398743v3.8998108
          c0.7799683,3.8997955,0,7.7996063-1.559906,11.6994019c-3.119873,4.6797638-6.2396851,8.5795746-10.1394958,12.4793854
          c-7.0196533,7.7995911-14.8192749,15.5992126-23.3988342,22.618866c-3.1198425,2.3398743-5.4597473,4.6797485-8.5795593,6.2396851
          C249.6912537,219.3379211,244.2315063,220.8978577,238.7717743,220.8978577L238.7717743,220.8978577z"
          />
          <path
            id="letter-03"
            d="M301.9486389,220.8978577c-2.3398743,0-5.4597168,0-7.7995911-0.7799683
          c-3.119873-0.7799377-5.4597473-2.3398743-7.7996216-4.6797485c-0.7799377-0.7799377-0.7799377-2.3398743-1.5599365-3.8998108
          c-0.7799377-1.559906-2.3398743-1.559906-3.1198425-2.3398743c-0.7799683-1.5599365-0.7799683-3.1198425-0.7799683-3.8998108
          c0-0.7799377,0-1.559906-0.7799377-2.3398743c-0.7799377-0.7799377-1.5599365-1.559906-1.5599365-2.3398743
          c0-0.7799377,0.7799683-2.3398743,0.7799683-3.8998108c0-1.559906,0-3.1198425,0-4.6797485c0-0.7799377,0-0.7799377,0-1.5599365
          c0.7799377-7.0196533,3.1198425-13.2593384,6.2396851-18.7190704c2.3398743-3.1198578,4.6797485-7.0196533,6.2396851-10.1394806
          c2.3398743-3.1198425,4.6797485-6.2396851,7.7995911-9.3595428c1.5599365-1.5599213,2.3398743-3.1198425,3.8998108-3.8998108
          c1.5599365-1.5599213,3.1198425-2.3398743,4.6797485-3.8997955c1.559906-1.5599365,1.5599365-2.3398895,3.119873-3.8998108
          c0.7799377-0.7799683,2.3398743-2.3398743,3.8997803-3.1198425c1.5599365-0.7799683,3.119873-0.7799683,4.6797791-0.7799683
          c1.559906-0.779953,3.1198425-0.779953,4.6797485-2.3398743c2.3398743-1.5599213,4.6797485-2.3398743,7.0196533-2.3398743
          c1.5599365,0,3.119873,0,4.6797485,0.7799683c3.8998108,0.779953,7.0196838,3.1198425,9.3595581,6.2396851
          c1.559906,3.1198273,2.3398743,7.0196381,3.1198425,10.9194489c-0.7799377,7.7996063-3.8998108,14.8192749-8.5795593,20.2789764
          c-4.6797485,5.4597321-10.9194641,10.1394806-17.9390869,12.4793701c-3.119873,1.559906-6.2396851,3.1198425-10.1394958,3.1198425
          c-1.5599365,0-3.1198425-0.7799377-4.6797485-1.5599365c-1.5599365-0.7799377-3.119873-2.339859-4.6797485-2.339859
          c-1.559906,0-4.6797791,4.6797638-5.4597473,7.0196686c-0.7799377,2.3398743-1.5599365,4.6797485-1.5599365,7.7995911
          c0,1.5599365,0.7799683,3.119873,1.5599365,4.6797791c0.7799377,2.3398743,3.1198425,3.1198425,5.4597473,3.1198425
          c4.6797485,0,8.5795593-1.5599365,12.4793396-3.8998108c6.2396851-3.1198425,12.4793701-7.0196533,18.7190857-10.9194641
          c3.119873-2.3398743,6.2396851-4.6797485,9.3595581-7.7996063c3.1198425-3.1198578,5.4597168-5.4597321,7.7995911-7.7996216
          c2.3398743-2.3398743,5.4597473-4.6797485,7.7996216-7.7996063c1.5599365-0.779953,2.3398743-3.1198578,3.8998108-3.8998108
          c1.559906-0.7799377,2.3398743-3.8997955,4.6797485-3.8997955s1.5599365,1.5599213,1.5599365,3.1198425
          c0,1.559906-0.7799683,3.8997803-0.7799683,4.6797638c0,3.1198578-0.7799377,7.0196686-3.1198425,9.3595428
          c-3.8998108,5.4597321-8.5795593,10.9194794-14.0393066,16.3791656
          c-5.4597168,5.4597473-10.9194641,10.1394958-17.1591492,14.8192749
          c-5.4597168,4.6797485-12.4793701,8.5795593-19.4990234,11.6994324
          C311.3081665,220.1178894,306.6283875,220.8978577,301.9486389,220.8978577L301.9486389,220.8978577z M312.0881348,172.5402527
          c3.1198425-0.779953,6.2396851-1.5599213,8.5795593-3.8998108c2.3398743-1.5599213,4.6797485-3.1198578,6.2396851-6.2396698
          c1.5599365-2.3398895,3.1198425-5.4597473,4.6797485-8.5795746c0.7799683-1.5599365,1.5599365-3.1198425,2.3398743-5.4597473
          c0-0.779953,0-1.5599213-0.7799377-1.5599213l0,0c-2.3398743,0-5.4597473,3.1198425-7.0196533,4.6797638
          c-1.5599365,2.3398895-3.8998108,4.6797791-6.2396851,7.0196533c-2.3398743,1.5599213-3.8998108,3.8998108-5.4597473,6.2397003
          c-0.7799377,1.5599213-2.3398743,3.1198578-2.3398743,5.4597321
          C310.5281982,172.5402527,310.5281982,173.3202362,312.0881348,172.5402527L312.0881348,172.5402527z"
          />
          <path
            id="letter-04"
            d="M363.5655518,221.6777954c-2.3398743,0-3.8998108,0-6.2396851-0.7799377
          c-3.1198425-1.5599365-5.4597168-3.8998108-7.7995911-6.2396851c-3.119873-3.119873-5.4597473-7.7996216-6.2396851-11.6994324
          c0-1.559906-0.7799683-3.1198425-0.7799683-4.6797485c0-0.7799377,0-2.3398743-0.7799377-3.1198425
          c-1.5599365-1.5599365-3.8998108-2.3398743-3.8998108-4.6797791c0-2.3398743,2.3398743-4.6797485,4.6797485-7.0196533
          c2.3398743-1.5599213,4.6797485-4.6797485,7.0196838-7.0196533c4.6797485-4.6797638,8.5795593-10.1394958,12.4793396-15.5992279
          c3.8998108-5.4597321,7.7996216-10.1394806,12.4793701-14.8192596c3.119873-3.1198425,6.2396851-7.0196381,10.1394958-10.9194489
          c1.5599365-1.5599213,3.1198425-2.3398743,4.6797485-3.8998108c1.5599365-1.5599213,3.8998108-3.8997955,5.4597473-3.8997955
          c1.559906,0,2.3398743,0.779953,3.8998108,1.5599213c1.559906,0.7799683,2.3398743,0.7799683,3.8997803,0.7799683
          c3.8998108,1.5599213,5.4597473,5.4597321,5.4597473,9.3595428c0,0.779953,0,1.5599213,0,2.3398743
          c-0.7799377,6.2396851-1.5599365,11.6994171-3.119873,17.9391022c-1.559906,6.2396851-3.1198425,12.4793701-5.4597168,17.9391022
          c-0.7799377,3.1198578-2.3398743,6.2396851-3.119873,9.3595581c-0.7799377,0.7799377-0.7799377,2.3398743-0.7799377,3.1198425
          c0,0.7799377,0,0.7799377,0.7799377,0.7799377c0.7799683,0,1.5599365-0.7799377,2.3399048-0.7799377
          c5.4597168-3.8998108,10.1394653-8.5795593,14.8192444-13.2593384c2.3399048-2.3398895,4.6797791-5.4597321,7.0196838-8.5795593
          s3.8998108-4.6797638,6.2396851-7.0196533c0.7799377-0.7799683,2.3398743-2.3398743,3.1198425-3.1198425
          c0.7799683-1.5599213,2.3398743-3.1198425,3.119873-3.8998108h0.7799377c0.7799377,0,1.5599365,0.7799683,2.3398743,2.3398895
          c0,1.5599213,0,2.3398743,0,3.8997955c0,8.5795593-11.6994019,21.05896-20.2789612,29.6385193
          c-5.4597473,5.4597473-10.9194946,10.9194641-17.1591492,15.5992126c-4.6797791,3.119873-8.5795593,5.4597473-14.0393066,7.7996216
          c-3.1198425,1.5599365-6.2396851,3.1198425-9.3595276,3.8998108
          C370.5852051,221.6777954,366.6854248,222.4577637,363.5655518,221.6777954L363.5655518,221.6777954z M363.5655518,202.178772
          c1.5599365-1.5599365,3.8998108-3.119873,5.4597473-5.4597473c1.559906-1.559906,2.3398743-3.8997803,3.8997803-6.2396851
          c0-0.7799377,0.7799683-0.7799377,1.5599365-1.559906c0.7799683,0,1.5599365-0.7799683,2.3398743-2.3398743
          c0-0.7799683,0-1.5599365,0-1.5599365c0-1.5599365,3.119873-2.3398743,3.119873-3.8998108
          c0-1.5599213-0.7799683-0.779953-0.7799683-1.5599213c0-0.779953,0.7799683-1.5599365,0.7799683-2.3398895
          c0-0.7799377-0.7799683-0.7799377-0.7799683-2.3398743c0-1.5599213,0.7799683-3.1198578,1.5599365-4.6797485
          c0.7799377-1.5599365,0.7799377-3.1198578,1.5599365-5.4597321c0-1.5599365,0.7799377-3.1198425,1.5599365-4.6797791
          c0-0.779953,0.7799377-0.779953,0.7799377-1.5599213s0-0.7799683-0.7799377-0.7799683
          c-1.5599365,0-3.8998108,2.3398743-6.2396851,3.8998108c-1.5599365,1.5599213-3.119873,3.1198578-3.8998108,4.6797485
          c-3.119873,3.8998108-6.2396851,7.7996216-9.3595581,11.6994171c-3.1198425,3.8998108-8.5795593,7.0196686-8.5795593,11.6994171
          c0,3.8998108,0.7799683,7.0196533,1.5599365,10.9194641c0.7799683,1.5599365,3.119873,2.3398743,4.6797485,2.3398743
          C361.2256775,203.7387085,362.0056152,202.9587402,363.5655518,202.178772z"
          />
          <path
            id="letter-05"
            d="M427.5223694,221.6777954c-2.3398743,0-3.8998108,0-6.2396851-0.7799377
          c-3.1198425-1.5599365-5.4597168-3.119873-7.7996216-6.2396851c-3.1198425-3.119873-5.4597168-7.7996216-6.2396545-11.6994324
          c0-1.559906-0.7799683-3.1198425-0.7799683-4.6797485c0-0.7799377,0-2.3398743-0.7799377-3.1198425
          c-1.5599365-1.5599365-3.8998108-2.3398743-3.8998108-4.6797791c0-2.3398743,2.3398743-4.6797485,4.6797485-7.0196533
          c2.3398743-1.5599213,4.6797485-4.6797485,7.0196533-7.0196533c4.6797791-4.6797638,8.5795593-10.1394958,12.4793701-15.5992279
          s7.7996216-10.1394806,12.4793701-14.8192596c3.119873-3.1198425,6.2396851-7.0196381,10.1394958-10.9194489
          c1.5599365-1.5599213,3.1198425-2.3398743,4.6797485-3.8998108c1.5599365-1.5599213,3.8998108-3.8997955,5.4597473-3.8997955
          c1.559906,0,2.3398743,0.779953,3.8997803,1.5599213c1.5599365,0.7799683,2.3399048,0.7799683,3.8998108,0.7799683
          c3.8998108,1.5599213,5.4597473,5.4597321,5.4597473,9.3595428c0,0.779953,0,1.5599213,0,2.3398743
          c-0.7799683,6.2396851-1.5599365,11.6994171-3.119873,17.9391022c-1.559906,6.2396851-3.1198425,12.4793701-5.4597168,17.9391022
          c-1.5599365,3.1198578-2.3398743,6.2396851-3.119873,9.3595581c-0.7799377,0.7799377-0.7799377,2.3398743-0.7799377,3.1198425
          c0,0.7799377,0,0.7799377,0.7799377,0.7799377c0.7799683,0,1.5599365-0.7799377,2.3398743-0.7799377
          c5.4597473-3.8998108,10.1394958-8.5795593,14.8192749-13.2593384c2.3399048-2.3398895,4.6797791-5.4597321,7.0196533-8.5795593
          c2.3399048-2.3398895,3.8998108-4.6797638,6.2397156-7.0196533c0.7799377-0.7799683,2.3399048-2.3398743,3.1198425-3.1198425
          c0.7799377-1.5599213,2.3399048-2.3398743,3.119873-3.8998108h0.7799377c0.7799377,0,1.559906,0.7799683,1.559906,2.3398895
          s0,2.3398743,0,3.8997955c0,8.5795593-11.6994324,21.05896-20.2789917,29.6385193
          c-5.4597168,5.4597473-11.6994019,10.9194641-17.9390869,15.5992126c-4.6797485,3.119873-8.5795593,5.4597473-13.2593384,7.7996216
          c-3.119873,1.5599365-6.2396851,3.1198425-9.3595581,3.8998108
          C434.5420227,221.6777954,431.4221802,222.4577637,427.5223694,221.6777954L427.5223694,221.6777954z M427.5223694,202.9587402
          c1.5599365-1.559906,3.8998108-3.1198425,5.4597473-5.4597168c1.559906-1.5599365,2.3398743-3.8998108,3.8997803-6.2396851
          c0-0.7799683,0.7799683-0.7799683,1.5599365-1.5599365c0.7799683,0,1.5599365-0.7799377,2.3398743-2.3398743
          c0-0.7799377,0-1.5599365,0-1.5599365c0-1.559906,3.119873-2.3398743,3.119873-3.8997955
          c0-1.5599365-0.7799683-0.779953-0.7799683-1.5599365c0-0.7799683,0.7799683-1.5599213,0.7799683-2.3398743
          s-0.7799683-0.779953-0.7799683-2.3398743c0-1.5599365,0.7799683-3.1198578,1.5599365-4.6797485
          c0.7799377-1.5599365,0.7799377-3.1198578,1.5599365-4.6797638c0-1.5599213,0.7799377-3.1198578,1.559906-4.6797638
          c0-0.7799683,0.7799683-0.7799683,0.7799683-1.5599365c0-0.779953,0-0.779953-0.7799683-0.779953
          c-1.559906,0-3.8997803,2.3398743-6.2396545,3.8997955c-1.5599365,1.5599365-3.119873,3.1198578-3.8998108,4.6797638
          c-3.119873,3.8997955-6.2396851,7.7996063-9.3595581,11.6994171c-3.1198425,3.8997955-8.5795593,7.0196686-8.5795593,11.6994171
          c0,3.8998108,0.7799683,7.0196533,2.3398743,10.9194641c0.7799683,1.5599365,3.119873,2.3398743,4.6797791,2.3398743
          C425.9624329,203.7387085,426.7424316,202.9587402,427.5223694,202.9587402L427.5223694,202.9587402z"
          />
          <path
            id="letter-06"
            d="M502.3986206,224.7976379c-1.559906-1.559906-3.1198425-3.8997803-3.1198425-6.2396545
          c0.7799377-5.4597473,3.1198425-10.1394958,5.4596863-14.8192749c2.3399048-3.8998108,3.8998413-7.7996216,6.2397461-12.4793701
          c1.5598755-3.8998108,3.8997803-7.7996216,6.2396851-10.9194794c0.7799683-0.7799377,1.5598755-1.5599213,2.3399048-2.3398743
          c1.5598755-1.5599213,1.5598755-3.1198578,1.5598755-4.6797485c0.7799683-1.5599365,1.5599365-2.3398743,2.3399048-3.1198578
          c0.7799683-1.5599365,1.5599365-3.1198578,2.3399658-3.8998108c0.7799072-2.3398743,1.5598755-3.8997955,3.119812-6.2396851
          c1.5599976-2.3398743,1.5599365-2.3398743,2.3399048-3.1198425c0.7799683-1.5599213,0.7799683-2.3398743,1.5599365-3.1198425
          c0.7799072-0.779953,1.5598755,0,2.3399048-0.779953c0.7799072-1.5599365,1.5598755-2.3398743,2.3399048-3.8998108
          c0.7799072-1.5599213,1.5598755-3.1198425,2.3399048-4.6797638c1.5598755-3.1198425,4.6797485-6.2396851,6.2396851-9.3595428
          c1.5599976-3.1198578,3.119873-6.2396851,5.4597168-9.3595428s5.4597168-6.2396851,6.2396851-7.7996063
          c0.7799683-0.7799683,0-1.5599365,0.7799683-2.3398743c0.7799683-0.7799683,0.7799683-0.7799683,0.7799683-0.7799683
          s0-1.5599213,0.7799683-2.3398743c0.7799072-0.779953,2.3399048-2.3398743,3.8997803-3.8998108
          c1.5598755-1.5599213,0-1.5599213,0.7799683-2.3398743c0.7799072-0.779953,2.3399048-0.7799683,3.119812-2.3398743
          c1.5599365-3.1198425,3.8998413-5.4597321,6.2397461-7.7996216c0.7799072-0.779953,2.3399048-1.5599213,3.8997803-1.5599213
          c3.119873,0,3.119873,2.3398743,7.0196533,6.2396851c1.5599365,1.5599213,2.3399048,3.8997955,3.119873,6.2396851
          c0,2.3398743-0.7799683,4.6797638-2.3399048,6.2396851c-2.3399048,3.8997955-5.4597168,8.5795746-7.7996216,12.4793701
          c-4.6797485,7.0196533-9.3594971,14.0392914-13.2592773,21.838913c-1.5599365,2.3398743-3.119873,4.6797638-4.6798096,7.7996063
          c-1.5598755,3.1198425-3.119812,3.8998108-3.119812,5.4597321c0,1.5599365,0.7799072,1.5599365,2.3399048,1.5599365
          c0.7799072,0,2.3399048,0,3.119873,0c7.7995605-0.7799683,14.8192139-3.1198425,21.0588989-7.7996216
          c1.5598755-1.5599213,3.8997803-2.3398743,5.4597168-3.1198425c1.5598755-0.779953,3.119812-1.5599213,4.6797485-2.3398743
          l10.1395264-7.0196381c7.0196533-4.6797791,13.2592773-10.9194641,17.9390869-17.9391022
          c1.5599365-2.3398743,3.119873-5.4597321,3.8998413-7.7996216c1.5598755-2.3398743,2.3399048-3.8997955,3.8997803-6.2396698
          c0.7799683-3.1198425,1.5599365-6.2396851,1.5599365-9.3595428c0-3.8998108-0.7799683-7.0196533-2.3399048-10.9194489
          c-2.3399048-3.1198425-5.4597168-6.2396851-9.3595581-8.5795746c-3.119812-1.5599365-7.0196533-2.3398895-10.1395264-2.3398895
          c-3.1199341-2.3399048-7.0197754-2.3399048-10.1395874-2.3399048c-2.3399048,0-4.6797485,0-7.0196533,0.7799683
          C590.5342407,78.164978,587.8098755,78.5625,586.25,78.5625c-3.8998413,0-8.1951294,1.1623993-12.0949097,2.7223206
          c-9.3594971,3.1198425-18.7191162,6.2396698-28.0786133,10.9194489c-2.3399048,1.5599213-4.6797485,3.1198425-7.0196533,4.6797638
          c-3.8997803,2.3398743-7.7996216,4.6797791-11.6994019,7.7996063c-2.3399048,2.3398895-4.6797485,4.6797791-4.6797485,7.7996216
          s3.8997803,4.6797638,3.8997803,7.0196381c0,1.5599365-0.7799072,3.1198425-2.3399048,3.1198425
          c-1.5598755,0-4.6797485-2.3398743-5.4597168-3.1198425c-3.8997803-2.3398743-7.0196533-5.4597321-8.5795288-9.3595276
          c-0.7799683-1.5599365-1.5599365-3.1198425-1.5599365-5.4597321c0.7799683-6.2396851,3.119873-11.6994171,7.0197144-16.3791962
          c0.7799072-1.5599213,2.3399048-2.3398743,3.8997803-3.1198425c1.5598755-0.779953,1.5598755-1.5599213,3.119873-2.3398743
          c0.7799072-0.7799683,2.3399048-1.5599213,3.119812-2.3398743c0.7799683-0.7799683,0.7799683-2.3398743,2.3399658-3.1198425
          c1.5599365-0.7799683,2.3399048-0.7799683,3.8997803-0.7799683c1.5598755-0.7799683,3.119873-1.5599213,3.8997803-2.3398743
          c1.5599365-0.7799683,2.3399048-1.5599213,3.8998413-2.3398743c1.5598755-0.7799683,3.119812-1.5599365,4.6797485-3.1198425
          c1.5598755-0.7799683,0.7799683-2.3398743,1.5598755-3.1198425c0.7799683-1.5599213,3.119873-1.5599213,4.6797485-1.5599213
          c3.8998413-0.7799683,7.0196533-2.3398895,10.9194946-3.1198425c3.8997803-0.7799683,7.7996216-1.5599365,11.6994019-3.1198425
          c3.119873-0.7799683,7.0196533-1.5599365,10.1395264-2.3398895c2.3399048-0.779953,4.6797485-1.5599213,7.0196533-1.5599213
          c1.5598755,0,2.3399048,0.7799683,4.6797485,0.7799683s3.119873-0.7799683,4.6797485-0.7799683
          c1.5599365,0,1.5599365,0.7799683,2.3399048,0.7799683h0.7799683c1.5598755,0,2.3399048-0.7799683,3.8997803-0.7799683
          c0.7799683,0.7799683,2.3399048,0.7799683,3.119873,1.5599213c0.7799683,0.7799683,2.3399048,0.7799683,3.8998413,0.7799683
          c2.3399048,0,3.8997803,0.7799683,5.4596558,2.3398743c3.119873,2.3398895,7.0197144,3.1198425,10.1395264,5.4597321
          c3.119873,2.3398895,6.2397461,4.6797791,8.5795898,7.7996216c5.4597168,6.2396851,9.3594971,13.2593384,10.1395264,21.8388977
          c0.7799072,2.3398743,0.7799072,5.4597321,0.7799072,7.7996216c0,7.0196381-1.5598755,14.8192444-3.8997803,21.8388977
          s-6.2397461,14.0392914-10.9194946,19.4990234c-4.6797485,6.2396851-10.9194336,12.4793854-17.1591187,17.1591492
          c-7.0196533,4.6797791-14.0393066,9.3595276-21.8389282,13.2593384c-3.8997803,2.3398743-7.7996216,3.8997955-11.6994019,4.6797485
          c-5.4597168,1.5599365-10.9194946,3.1198578-17.1591187,3.1198578c-3.119873,0-6.2396851-0.779953-9.3594971-1.5599213
          c-2.3399048-1.5599365-5.4597168-3.1198578-8.5795898-3.8998108c-1.5598755,0-2.3399048,2.3398743-4.6797485,4.6797485
          l-3.119873,5.4597321c-1.5598755,3.119873-3.119812,7.0196838-4.6797485,10.1394958
          c-1.5598755,3.1198425-3.119812,3.1198425-4.6797485,6.2396851c-1.5598755,3.1198425-5.4597168,10.9194641-7.0196533,15.5992126
          c-1.5598755,2.3398743-3.119873,4.6797485-4.6797485,7.0196533c-1.5598755,3.119873-2.3399048,6.2396851-6.2396851,6.2396851
          C508.6383667,231.8173218,503.9585876,227.1375427,502.3986206,224.7976379z"
          />
          <path
            id="letter-07"
            d="M645.1315308,221.6777954c-6.2396851,0-12.4793701-2.3398743-16.3791504-7.7996216
          c-1.5599365-2.3398743-3.119873-4.6797485-3.8998413-7.7995911c0-0.7799683-0.7799072-1.5599365-0.7799072-1.5599365
          c0-0.7799377,0.7799072-1.5599365,0.7799072-2.3398743s-0.7799072-3.8998108-0.7799072-6.2396851
          c0-1.5599365,0.7799072-2.3398743,0.7799072-3.1198425c0-0.7799988-0.7799072-2.3399048-0.7799072-3.119873
          c0-0.7799377,0-1.5599365,0.7799072-2.3398743c0.7799683-1.5599365,0.7799683-2.3398743,1.5599365-3.8998108
          c0.7799072-0.7799377,1.5598755-0.7799377,1.5598755-1.5599213c0.7799683-1.5599365,1.5598755-3.1198578,2.3399048-5.4597321
          c0-1.5599365,0.7799683-2.3398743,0.7799683-3.8998108c0.7799072-0.779953,1.5598755-1.5599213,2.3399048-2.3398743
          c0.7799683-0.779953,0-3.8998108,2.3399048-5.4597321c0.7799072-0.779953,1.5598755-1.5599365,2.3399048-2.3398743
          c0.7799072-1.5599365,1.5598755-2.3398895,2.3399048-3.8998108c1.5598755-1.5599365,2.3399048-1.5599365,3.119873-3.1198425
          c0.7799072-0.7799683,1.5598755-1.5599213,2.3399048-2.3398743c0-0.7799683,0-0.7799683,0-1.5599365
          c0-0.779953,2.3399048-0.779953,2.3399048-1.5599213s-0.7799683-0.7799683-0.7799683-1.5599365
          c0-0.779953,1.5598755-1.5599213,2.3399048-2.3398743c0.7800293-0.7799377,1.5598755-1.5599213,2.3399048-2.3398743
          s2.3399048-0.7799683,3.119873-1.5599213c0.7799072-0.7799683,0.7799072-0.7799683,0.7799072-0.7799683
          s1.5599365,0,2.3399048-0.7799683c0.7800293-0.7799683,0.7799683-0.7799683,0.7799683-2.3398743
          c0.7799683-1.5599213,1.5599365-2.3398743,3.119873-3.1198425c1.5598755-0.7799683,3.8997803-1.5599213,5.4597168-2.3398743
          c2.3399048-1.5599365,5.4596558-2.3398743,8.5795288-2.3398743c3.8998413,0,7.7996216,0,10.9194946,1.5599213
          c2.3399048,1.5599365,4.6797485,3.1198425,6.2396851,5.4597321c2.3399048,2.3398743,3.8998413,5.4597321,4.6797485,8.5795746
          c0,1.5599365,0,2.3398743,0,3.8998108c0,1.5599213,0,3.8998108-0.7799072,5.4597321
          c-0.7799683,3.1198425-2.3399048,6.2396851-2.3399048,9.3595276c-0.7799683,3.1198578-0.7799683,6.2396851-2.3399048,8.5795593
          c-0.7799683,3.1198578-3.8998413,6.2396851-3.8998413,7.0196533c0,1.5599365,0.7799683,1.5599365,1.5598755,1.5599365
          c1.5599365,0,3.8998413-0.779953,5.4597168-1.5599365c3.119873-1.5599213,5.4597168-3.1198578,7.7996216-5.4597321
          c4.6797485-3.8997955,9.3594971-8.5795593,13.2593384-13.2593384c0.7799072-0.7799683,1.5598755-2.3398743,2.3399048-3.1198425
          c0.7799072-1.5599365,1.5598755-2.3398743,2.3399048-2.3398743c0.7799683,0,1.5598755,1.5599213,1.5598755,3.8997955
          c0,3.1198425-0.7799683,6.2397003-1.5598755,9.3595276c-1.5599365,4.6797638-3.8998413,8.5795593-7.7996216,12.4793701
          c-4.6797485,5.4597321-10.9194946,10.1394806-17.9391479,12.4793549
          c-3.8997803,1.5599365-7.0196533,1.5599365-10.9194336,1.5599365c-1.5599365,0-2.3399048-0.7799377-3.8998413-0.7799377
          c-1.5598755,0-2.3399048,1.559906-3.119812,3.1198425c-3.8998413,6.2396851-8.5795898,11.6994324-14.0393677,16.3791809
          C659.9508057,218.5579834,652.1511841,220.8978577,645.1315308,221.6777954L645.1315308,221.6777954z M649.8112793,203.7387085
          c0.7799683-0.7799683,2.3399048-2.3398743,3.119873-3.119873c3.119873-1.5599365,5.4597168-3.8998108,7.7996216-7.0196533
          c1.5598755-2.3398743,4.6797485-3.8998108,4.6797485-6.2396851c0-0.7799377,0-1.5599365-0.7799683-2.3398743
          c-0.7799683-1.5599365-2.3399048-2.3398743-3.8997803-3.8998108c-0.7799683-1.5599213-1.5599365-3.1198578-2.3399048-5.4597321
          c-0.7799683-0.779953-0.7799683-3.1198578-2.3399048-3.1198578c-1.5599976,0-3.119873,2.3398743-3.8998413,3.8998108
          c-2.3399048,3.1198578-3.8997803,6.2396851-6.2396851,10.1394958c-2.3399048,3.8997803-3.119873,8.5795593-3.8998413,13.2593384
          c0,3.1198425,0.7799683,4.6797485,2.3399048,4.6797485C646.6914063,205.2986145,649.031311,204.5186768,649.8112793,203.7387085z
           M677.1099243,168.6404419c2.3399048-4.6797485,4.6797485-10.1394806,6.2396851-15.5992126
          c0.7799683-0.7799683,0.7799683-2.3398743,0.7799683-3.1198425s-0.7799683-2.3398743-2.3399048-2.3398743
          c-2.3399048,0-3.8997803,1.5599213-5.4597168,3.1198425c-3.119812,3.8997955-4.6797485,9.3595276-4.6797485,14.8192596
          c0,3.1198578,0,7.7996216,3.119873,7.7996216S676.3299561,170.2003784,677.1099243,168.6404419z"
          />
          <path
            id="letter-08"
            d="M659.9508057,302.0138245c-5.4597168-0.7799683-10.1395264-4.6797791-10.9194946-10.1394958
          c0-0.7799683,0-1.5599365,0-3.119873c0.7799683-4.6797485,2.3399048-9.3595276,5.4597168-13.2593384
          c0.7799683-0.7799377,0.7799683-1.5599365,1.5599365-3.1198425v-0.7799683c0-0.7799377,0-1.559906,0-2.3398743
          c0.7799072-0.7799377,1.5598755-1.559906,1.5598755-2.3398743c0-0.7799377,0-0.7799377,0-0.7799377
          s0.7799683-0.7799683,1.5598755-1.5599365c0.7799683-0.7799683,2.3399048-1.5599365,2.3399048-2.3398743s0-0.7799377,0-1.5599365
          c0-0.7799377,0.7799683-2.3398743,1.5599365-3.1198425c0.7799072-0.7799683,0.7799072,0,1.5598755-0.7799683
          c0.7799683-0.7799377,0.7799683-1.559906,1.5598755-2.3398743c0.7799683-0.7799377,14.8192749-27.2986145,15.5992432-28.0785828
          c0.7799683-2.3398743,1.5598755-4.6797485,2.3399048-6.2396851c0.7799683-0.7799377,1.5599365-2.3398743,2.3399048-3.8998108
          c0.7799683-0.7799377,0.7799683-2.3398743,0.7799683-3.1198425c0.7799683-1.5599365,2.3399048-3.8998108,3.8997803-5.4597473
          c0.7799683-0.7799377,1.5599365-1.559906,1.5599365-2.3398743c0-0.7799377,0.7799072-1.559906,0.7799072-2.3398743
          c0-0.7799377,1.5599365-1.559906,2.3399048-3.1198425c0.7799683-0.7799683,0-0.7799683,0.7799683-2.3398743
          c0.7799683-3.119873,2.3399048-5.4597473,3.8997803-7.7996216c1.5599365-2.3398743,3.119873-3.8998108,4.6798096-6.2396851
          c1.5598755-2.3398743,1.5598755-3.8997955,2.3399048-6.2396851c0.7799072-2.3398743,3.119812-3.8997955,3.8997803-6.2396851
          c0.7799683-2.3398743,3.119873-2.3398743,3.8997803-4.6797485c0.7799683-0.779953,0.7799683-1.5599213,0.7799683-2.3398743
          s0.7799683-0.779953,0.7799683-0.779953c0.7799072-0.779953,0.7799072-1.5599213,1.5598755-2.3398743
          c0.7799683-2.3398743,2.3399048-4.6797638,3.119873-7.0196533c2.3399048-4.6797638,4.6797485-9.3595276,7.0196533-13.2593384
          c0.7799683-0.7799683,1.5598755-1.5599213,2.3399048-2.3398743c0.7799683-0.7799683,1.5598755-2.3398743,1.5598755-3.1198425
          s0.7799683-2.3398743,1.5599365-3.1198425c0.7799683,0,0.7799683,0,1.5598755,0
          c1.5599365-0.7799683,4.6797485-3.8998108,5.4597168-7.0196533c0.7799683-2.3398743,2.3399048-4.6797638,4.6797485-7.0196381
          c0.7799683-0.7799683,2.3399048-1.5599365,2.3399048-3.1198425c0-1.5599213,0-0.7799683,0-1.5599365
          c0-2.3398743,3.119873-4.6797638,5.4597168-4.6797638s3.8997803,2.3398743,5.4597168,3.8998108
          c1.5598755,1.5599213,2.3399048,3.1198425,2.3399048,4.6797638v1.5599213
          c-0.7799683,5.4597473-3.119873,10.9194641-7.0196533,15.5992279l-4.6797485,7.7996063
          c-0.7799683,1.5599365-1.5599365,3.1198425-2.3399658,4.6797791c0,0.779953,0,0.779953,0.7799683,0.779953
          c1.5599365,0,4.6797485-3.8997955,6.2397461-6.2396698c2.3399048-2.3398895,4.6797485-4.6797791,7.0196533-6.2396851
          c3.119812-2.3398743,7.0196533-3.1198425,10.1395264-3.8998108c2.3399048,0,3.8997803,0.7799683,5.4596558,1.5599365
          c1.5599365,1.5599213,3.119873,3.1198425,3.8998413,5.4597321c1.5598755,2.3398743,2.3399048,3.1198425,2.3399048,4.6797638
          c0,1.5599365-0.7799683,3.1198425-0.7799683,4.6797638c0,1.5599365,0,2.3398895,0,3.8998108
          c0,3.8998108-0.7799683,7.7996063-2.3399048,10.9194641c-2.3399048,6.2396851-4.6797485,11.6994171-7.7996216,17.1591492
          c-1.5598755,2.3398895-3.119812,4.6797485-4.6797485,7.0196533l-2.3399048,3.8998108
          c-0.7799683,0.7799683-3.119873,3.119873-3.119873,3.8998108s0,0.7799377,0.7799683,0.7799377l0,0
          c1.5599365,0,3.119873-0.7799377,3.8998413-2.3398743c2.3399048-1.559906,4.6797485-3.8997803,7.7995605-6.2396851
          c4.6797485-3.8997803,9.3595581-7.7995911,13.2593384-12.4793549c3.8997803-4.6797485,7.7996216-9.3595428,12.4793701-13.2593384
          c1.5598755-1.5599365,3.119873-3.8998108,4.6797485-6.2396851c0.7799683-0.7799683,2.3399048-3.1198425,3.119873-3.1198425
          c0.7799072,0,1.5598755,1.5599213,1.5598755,3.1198425c0,2.3398743-0.7799683,3.8998108-1.5598755,6.2396851
          c0,3.1198578-0.7799683,6.2396851-2.3399048,9.3595428c-3.119873,4.6797485-7.0196533,9.3595276-10.9194946,14.0392761
          c-7.7996216,9.3595581-17.9391479,17.9391174-28.8585205,24.1788025
          c-5.4597168,3.1198425-10.9194946,6.2396851-17.1591187,7.7995911c-2.3399048,0.7799683-5.4597168,1.5599365-7.7996216,1.5599365
          h-3.8997803c-1.5599365,0-3.8998413,0.7799377-5.4597168,0.7799377c-2.3399048-0.7799377-4.6797485-0.7799377-7.0196533-2.3398743
          c-3.119873-0.7799377-5.4597168-1.559906-7.7996216-3.8997803c-2.3399048-1.5599365-3.8998413-3.8998108-5.4597168-6.2396851
          c-0.7799683-1.5599365-0.7799683-2.3399048-1.5598755-2.3399048c-0.7799683,0-1.5599365,1.5599365-1.5599365,2.3399048
          c-0.7799072,2.3398743-1.5598755,5.4597168-3.119812,7.7995911s-3.119873,4.6797485-4.6798096,7.0196838
          c-0.7799072,2.3398743-15.5991821,29.6384888-17.1591187,31.978363c-1.5598755,2.3399048-3.119812,4.6797791-3.8997803,7.0196838
          c-0.7799683,3.1198425-2.3399048,6.2396851-3.119873,8.5795593c-0.7799072,2.3398743-2.3399048,4.6797485-3.8997803,6.2396851
          c-1.5598755,2.3398743-3.119873,4.6797485-3.8997803,7.0196533c-0.7799683,2.3398743-3.119873,4.6797485-3.8998413,7.0196533
          c-0.7799683,1.5599365,0,2.3398743-0.7799683,3.119873c-0.7799072,0.7799683-1.5598755,0.7799377-1.5598755,2.3398743
          c-0.7799683,1.5599365-1.5598755,3.8998108-3.8997803,3.8998108L659.9508057,302.0138245z M740.2867432,195.9390869
          c3.119873-3.8998108,6.2397461-7.7996216,8.5795898-12.4793701c3.119812-4.6797485,5.4597168-8.5795593,7.7996216-13.2593384
          c2.3399048-4.6797638,4.6797485-8.5795746,7.0196533-13.2593536c0.7799072-1.5599213,1.5598755-3.1198273,1.5598755-4.6797638
          c0-1.5599213-0.7799683-3.1198425-2.3399048-3.1198425c-0.7799683,0-1.5598755,0.7799683-2.3399048,1.5599365
          c-3.8997803,3.1198425-6.2396851,7.0196381-10.1395264,10.1394806c-3.8997803,3.1198578-7.0196533,6.2396851-10.1395264,10.1394958
          c-3.119812,3.8997955-7.7996216,7.7996063-10.9194336,11.6994171c-3.119873,3.8998108-7.0196533,7.7995911-7.0196533,11.6994019
          c0,2.3398743,1.5598755,3.8998108,2.3399048,4.6797485c0.7799072,1.5599365,1.5598755,5.4597473,4.6797485,5.4597473
          C732.4869995,204.5186462,737.9468994,198.2789612,740.2867432,195.9390869z"
          />
          <path
            id="letter-09"
            d="M803.463623,221.6777954c-2.3399658,0-3.8998413,0-6.2397461-0.7799377
          c-3.119873-1.5599365-5.4597168-3.8998108-7.7996216-6.2396851c-3.119812-3.119873-5.4597168-7.7996216-6.2396851-11.6994324
          c0-1.559906-0.7799683-3.1198425-0.7799683-4.6797485c0-0.7799377,0-2.3398743-0.7799683-3.1198425
          c-1.5598755-1.5599365-3.8997803-2.3398743-3.8997803-4.6797791c0-2.3398743,2.3399048-4.6797485,4.6797485-7.0196533
          c2.3399048-1.5599213,4.6797485-4.6797485,7.0196533-7.0196533c4.6797485-4.6797638,8.5795898-10.1394958,12.4793701-15.5992279
          c3.8998413-5.4597321,7.7996216-10.1394806,12.4793701-14.8192596c3.119873-3.1198425,6.2397461-7.0196381,10.1395264-10.9194489
          c1.5598755-1.5599213,3.119873-2.3398743,4.6797485-3.8998108c1.5598755-1.5599213,3.8997803-3.8997955,5.4597168-3.8997955
          c1.5598755,0,2.3399048,0.779953,3.8997803,1.5599213c1.5599365,0.7799683,2.3399048,0.7799683,3.8998413,0.7799683
          c3.8997803,1.5599213,5.4596558,5.4597321,5.4596558,9.3595428c0,0.779953,0,1.5599213,0,2.3398743
          c-0.7799072,6.2396851-1.5598755,11.6994171-3.119812,17.9391022s-3.119873,12.4793701-5.4597168,17.9391022
          c-0.7799683,3.1198578-2.3399048,6.2396851-3.119873,9.3595581c-0.7799072,0.7799377-0.7799072,2.3398743-0.7799072,3.1198425
          c0,0.7799377,0,0.7799377,0.7799072,0.7799377c0.7799683,0,1.5599365-0.7799377,2.3399048-0.7799377
          c5.4597168-3.8998108,10.1395264-8.5795593,14.8192749-13.2593384c2.3399048-2.3398895,4.6797485-5.4597321,7.0196533-8.5795593
          s3.8998413-4.6797638,6.2397461-7.0196533c0.7799683-0.7799683,2.3399048-2.3398743,3.119873-3.1198425
          c0.7799072-1.5599213,2.3399048-3.1198425,3.119812-3.8998108h0.7799683c0.7799683,0,1.5598755,0.7799683,2.3399048,2.3398895
          c0,1.5599213,0,2.3398743,0,3.8997955c0,8.5795593-10.9194946,21.05896-20.2789917,29.6385193
          c-5.4597168,5.4597473-10.9194336,10.9194641-17.1591187,15.5992126c-4.6797485,3.119873-8.5795288,5.4597473-14.0393066,7.7996216
          c-3.119873,1.5599365-6.2397461,3.1198425-9.3594971,3.8998108
          C810.4832764,221.6777954,807.3634033,222.4577637,803.463623,221.6777954L803.463623,221.6777954z M803.463623,202.9587402
          c1.5598755-1.559906,3.8997803-3.1198425,5.4596558-5.4597168c1.5599365-1.5599365,2.3399048-3.8998108,3.119873-6.2396851
          c0-0.7799683,0.7799683-0.7799683,1.5598755-1.5599365c0.7799683,0,1.5599365-0.7799377,2.3399048-2.3398743
          c0-0.7799377,0-0.7799377,0-1.5599365c0-1.559906,3.119873-2.3398743,3.119873-3.8997955
          c0-1.5599365-0.7799683-0.779953-0.7799683-1.5599365c0-0.7799377,0.7799683-1.5599213,0.7799683-2.3398743
          s-1.5598755,0-1.5598755-1.5599213c0-1.5599365,0.7799072-3.1198578,1.5598755-4.6797638
          c0.7799683-1.5599213,0.7799683-3.1198578,1.5599365-4.6797485c0-1.5599365,0.7799072-3.1198578,1.5598755-4.6797638
          c0-0.779953,0.7799683-0.779953,0.7799683-1.5599213s0-0.7799683-0.7799683-0.7799683
          c-1.5598755,0-3.8997803,2.3398895-6.2397461,3.899826c-1.5598755,1.5599213-3.119812,3.1198578-3.8997803,4.6797485
          c-3.119873,3.8998108-6.2396851,7.7996216-9.3594971,11.6994171c-3.119873,3.8997955-8.5795898,7.0196686-8.5795898,11.6994171
          c0,3.8998108,0.7799683,7.0196533,2.3399048,10.9194641c0.7799683,1.5599365,3.119873,2.3398743,4.6797485,2.3398743
          C801.9036865,203.7387085,802.6836548,202.9587402,803.463623,202.9587402L803.463623,202.9587402z"
          />
        </g>
      </svg>
    )
  }
}
