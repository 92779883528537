import theme from './theme'

export const color = (key, weight) => p => p.theme.colors[key][weight]

export const typo = key => p => p.theme.typo[key](p)

export const duration = key => p => p.theme.durations[key]

export const zIndex = key => theme.zIndex.indexOf(key) + 1

export const globalSelection = (bg, fg) => ({
  '::-moz-selection': {
    backgroundColor: bg,
    color: fg,
  },
  '::selection': {
    backgroundColor: bg,
    color: fg,
  },
})

export const isTouchDevice = () => {
  if (typeof window !== 'undefined') {
    return window.matchMedia('(pointer: coarse)').matches
  }
  return false
}

export const supportsHover = `@media (hover: hover)`
