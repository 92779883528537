import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { NavbarThemeSwitch } from '../components/NavbarTheme'
import { Space, Board } from 'spaced-components'
import LinesPattern from '../components/LinesPattern'
import DarkWrapper from '../components/DarkWrapper'
import Layout from '../Layout'
import { Footer } from '../components/Footer'
import ChallengeUsFooter from '../components/ChallengeUsFooter'
import HelloFab from '../components/HelloFab'

export default () => (
  <Fragment>
    <Helmet>
      <title>Indigo</title>
      <meta name='description' content='' />
    </Helmet>
    <NavbarThemeSwitch theme='dark' />

    <Layout.Shell>
      <Footer>
        <DarkWrapper>
          <LinesPattern />
          <Board.Item
            offset={{ mobileSm: 1 / 10, tabletMd: 1 / 6 }}
            width={{ mobileSm: 8 / 10, tabletMd: 4 / 6 }}
          >
            <Space y={150} />
            <video
              src={`${process.env.PUBLIC_URL}/assets/videos/indigo.mp4`}
              style={{ width: '100%' }}
              controls
            />
          </Board.Item>
        </DarkWrapper>
      </Footer>
      <ChallengeUsFooter />
      <HelloFab />
    </Layout.Shell>
  </Fragment>
)
