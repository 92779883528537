import styled from "react-emotion"
import { color } from "../styles/helpers"
import { prop } from "spaced-components"

const DarkWrapper = styled("div")`
  min-height: 100vh;
  ${prop("height")};
  background-color: ${color("dark", 700)};
`

export default DarkWrapper
