import React from 'react'

import VideoWrapper from './VideoWrapper'

const StaticVideo = ({ ratio, ...props }) => (
  <VideoWrapper ratio={ratio}>
    <video autoPlay loop playsInline muted {...props} />
  </VideoWrapper>
)

export default StaticVideo
