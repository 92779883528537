import React from "react"
import styled from "../styles/styled"

const AwardCell = styled(({ count, children, ...rest }) => (
  <div {...rest}>
    <div className="count">{count}</div>
    <div className="logo">{children}</div>
  </div>
))(() => ({
  display: "grid",
  gridTemplate: "auto / auto",
  "> div": { gridArea: "1 / 1 / 1 /1" },
  width: "100%",
  height: "100%",
  overflow: "hidden",
  ".count": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 90,
    fontFamily: "TungstenMedium, sans-serif",
    transform: "scale(1.5)",
    color: "#fff",
    opacity: 0,
    transition: ".4s"
  },
  ".logo": { transition: ".4s" },
  "&:hover .logo": { opacity: 0 },
  "&:hover .count": { transform: "scale(1)", opacity: 1 }
}))

export default AwardCell
