import React, { useRef, useEffect } from 'react'
import { TweenMax, TimelineMax, Power1, Linear } from '../../lib/gsap'
import { useControlledTimeline } from '../../hooks/animation'

const Logo = ({
  play,
  delay = 0,
  iconColor,
  textColor,
  color = 'currentColor',
  ...props
}) => {
  const tl = useControlledTimeline({ delay: delay / 1000 }, play)

  useEffect(() => {
    TweenMax.set(path3.current, {
      transformOrigin: 'center',
      rotation: -90,
    })

    const dur = el => 0.01 * el.getTotalLength()
    const ease = Linear.easeNone
    const pathsTL = new TimelineMax({ paused: true })

    pathsTL.fromTo(
      path1.current,
      dur(path1.current),
      { drawSVG: '100% 100%' },
      { drawSVG: '0% 100%', ease }
    )
    pathsTL.from(path2.current, dur(path2.current), { drawSVG: '0%', ease })
    pathsTL.from(path3.current, dur(path3.current), { drawSVG: '0%', ease })
    pathsTL.addLabel('path3')
    pathsTL.from(path4.current, dur(path4.current), { drawSVG: '0%', ease })
    pathsTL.addLabel('end')
    pathsTL.from(path5.current, 0.7, { drawSVG: '0%', ease }, 'path3')
    pathsTL.from(
      path6.current,
      dur(path6.current),
      { drawSVG: '0%', ease },
      'end'
    )

    tl.to(pathsTL, 2.5, { progress: 1, ease: Power1.easeInOut })
    tl.from(type.current, 2.5, { opacity: 0, ease: Power1.easeInOut }, 0)
  }, [])

  const type = useRef()
  const path1 = useRef()
  const path2 = useRef()
  const path3 = useRef()
  const path4 = useRef()
  const path5 = useRef()
  const path6 = useRef()

  return (
    <svg viewBox="0 0 311.8 36" {...props}>
      <g fill="none" stroke={iconColor || color} strokeWidth={6}>
        <path ref={path4} d="M3,3h6.8l17.8,14.7" />
        <path ref={path1} d="M3,36V0" />
        <path ref={path6} d="M29.1,30L51.2,3h4v33" />
        <path ref={path2} d="M3.7,34.1l12.2-14.8" />
        <path ref={path5} d="M15.9,19.3l5.6-6.8" />
        <path
          ref={path3}
          d="M27.7,17.7c3.8,2.8,4.6,8.1,1.8,11.9c-2.8,3.8-8.1,4.6-11.9,1.8s-4.6-8.1-1.8-11.9S23.9,14.9,27.7,17.7z"
        />
      </g>
      <path
        ref={type}
        fill={textColor || color}
        d="M79.6,12v14.6h-2.4V12h-5.3V9.7h13V12H79.6z M88.5,20.9c0,2.2,1.3,3.7,3.2,3.7c2,0,3.3-1.5,3.3-3.7
    V14h2.3v7.1c0,3.4-2.2,5.7-5.6,5.7s-5.6-2.3-5.6-5.7V14h2.4V20.9z M104,26.5h-2.3V14h2.3v1.6c0.7-1.2,1.8-1.9,3.1-1.9
    c0.8,0,1.5,0.2,1.9,0.6l-1,2.2c-0.4-0.3-1-0.5-1.6-0.5c-1.5,0-2.5,1.3-2.5,3.4L104,26.5L104,26.5z M114.2,26.5h-2.3V14h2.3v1.5
    c0.8-1.2,2.1-1.9,3.8-1.9c3,0,4.9,2,4.9,5.2v7.7h-2.4v-7.3c0-2.1-1.2-3.4-3.2-3.4s-3.1,1.3-3.1,3.4
    C114.2,19.2,114.2,26.5,114.2,26.5z M127.1,8.5h2.3v10.4h2l3.7-4.9h2.8l-4.5,5.8l4.7,6.7h-2.9l-3.9-5.5h-2v5.5H127L127.1,8.5
    L127.1,8.5z M139.2,20.2c0-3.6,2.6-6.6,6.2-6.6c3.5,0,6.2,2.8,6.2,6.3c0,0.3,0,0.7-0.1,0.8h-10c0.3,2.3,1.9,3.9,4.3,3.9
    c1.4,0,2.7-0.5,3.5-1.4l1.2,1.6c-1,1.1-2.8,1.9-4.8,1.9C141.9,26.8,139.2,23.9,139.2,20.2z M141.6,19.3h7.6
    c-0.4-2.3-1.8-3.6-3.8-3.6C143.4,15.6,141.9,17.2,141.6,19.3z M159.3,23.8l3.6-9.8h2.4l-5.4,14.6c-0.8,2.3-2.1,3.4-4.1,3.4
    c-1.1,0-2.2-0.4-3-1.2l1.1-1.8c0.6,0.5,1.3,0.8,2,0.8c0.9,0,1.5-0.5,2.1-2l0.4-1.1L152.6,14h2.5L159.3,23.8z M174.1,9.7h2.4v16.8
    h-2.4V9.7z M183.5,26.5h-2.3V14h2.3v1.5c0.8-1.2,2.1-1.9,3.8-1.9c3,0,4.9,2,4.9,5.2v7.7h-2.3v-7.3c0-2.1-1.2-3.4-3.2-3.4
    s-3.1,1.3-3.1,3.4L183.5,26.5L183.5,26.5z M195,14h2.1v-3.1h2.3V14h3.6v2h-3.5v6.6c0,1.3,0.6,2,1.7,2c0.6,0,1.3-0.2,1.6-0.7l1.1,1.8
    c-0.6,0.6-1.6,1.1-3,1.1c-2.4,0-3.8-1.4-3.8-4.1V16H195L195,14L195,14z M205.1,20.2c0-3.6,2.6-6.6,6.2-6.6c3.5,0,6.2,2.8,6.2,6.3
    c0,0.3,0,0.7-0.1,0.8h-10c0.3,2.3,1.9,3.9,4.3,3.9c1.4,0,2.7-0.5,3.5-1.4l1.3,1.8c-1,1.1-2.8,1.9-4.8,1.9
    C207.8,26.8,205.1,23.9,205.1,20.2z M207.5,19.3h7.6c-0.4-2.3-1.8-3.6-3.8-3.6C209.2,15.6,207.8,17.2,207.5,19.3z M220.8,8.5h2.3v18
    h-2.3V8.5z M227.6,8.5h2.3v18h-2.3V8.5z M234.1,10.4c0-0.8,0.7-1.4,1.4-1.4c0.8,0,1.4,0.7,1.4,1.4c0,0.8-0.7,1.4-1.4,1.4
    C234.8,11.8,234.1,11.1,234.1,10.4z M236.8,26.5h-2.3V14h2.3V26.5z M246.5,29.9c2.6,0,4.2-1.4,4.2-4.1v-1.5c-1,1.4-2.5,2.2-4.4,2.2
    c-3.4,0-6-2.7-6-6.4c0-3.6,2.6-6.4,6-6.4c1.9,0,3.4,0.8,4.4,2.1V14h2.3v11.7c0,4.6-2.9,6.3-6.3,6.3c-2.3,0-4.1-0.6-5.3-1.6l1.2-1.8
    C243.3,29.2,244.7,29.9,246.5,29.9z M250.7,20.1c0-2.3-1.7-4.2-4-4.2s-4,1.9-4,4.2c0,2.4,1.7,4.3,4,4.3S250.7,22.4,250.7,20.1z
     M256.6,20.2c0-3.6,2.6-6.6,6.2-6.6c3.5,0,6.2,2.8,6.2,6.3c0,0.3,0,0.7-0.1,0.8h-10c0.3,2.3,1.9,3.9,4.3,3.9c1.4,0,2.7-0.5,3.5-1.4
    l1.2,1.6c-1,1.1-2.8,1.9-4.8,1.9C259.4,26.8,256.6,23.9,256.6,20.2z M259,19.3h7.6c-0.4-2.3-1.8-3.6-3.8-3.6
    C260.8,15.6,259.4,17.2,259,19.3z M274.7,26.5h-2.3V14h2.3v1.5c0.8-1.2,2.1-1.9,3.8-1.9c3,0,4.9,2,4.9,5.2v7.7h-2.3v-7.3
    c0-2.1-1.2-3.4-3.2-3.4s-3.1,1.3-3.1,3.4L274.7,26.5L274.7,26.5z M293.2,15.8c-2.5,0-4.2,1.9-4.2,4.4c0,2.4,1.7,4.4,4.2,4.4
    c1.4,0,2.5-0.4,3.4-1.3l1.2,1.7c-1,1.1-2.8,1.8-4.7,1.8c-3.7,0-6.4-3-6.4-6.6s2.8-6.6,6.5-6.6c2,0,3.7,0.7,4.7,1.8l-1.3,1.7
    C295.7,16.3,294.6,15.8,293.2,15.8z M299.4,20.2c0-3.6,2.6-6.6,6.2-6.6c3.5,0,6.2,2.8,6.2,6.3c0,0.3,0,0.7-0.1,0.8h-10
    c0.3,2.3,1.9,3.9,4.3,3.9c1.4,0,2.7-0.5,3.5-1.4l1.2,1.6c-1,1.1-2.8,1.9-4.8,1.9C302.2,26.8,299.4,23.9,299.4,20.2z M301.8,19.3h7.6
    c-0.4-2.3-1.8-3.6-3.8-3.6C303.6,15.6,302.2,17.2,301.8,19.3z"
      />
    </svg>
  )
}

export default Logo
