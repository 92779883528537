import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { zIndex } from 'spaced-components'
import styled from 'react-emotion'
import GSAPComponent from './GSAPComponent'

const TileTransitionContext = React.createContext({})

export const TileTransitionConsumer = TileTransitionContext.Consumer

export class TileTransitionProvider extends Component {
  startTransition = thumbData =>
    this.setState({ status: 'transitioning', thumbData })

  endTransition = () => this.setState({ status: 'ready', thumbData: {} })

  state = {
    status: 'ready',
    thumbData: {},
    startTransition: this.startTransition,
    endTransition: this.endTransition,
  }

  render() {
    return (
      <TileTransitionContext.Provider value={this.state}>
        {this.props.children}
      </TileTransitionContext.Provider>
    )
  }
}

export const TileTransitionLink = ({ to, imgSrc, children, ...rest }) => (
  <TileTransitionConsumer>
    {({ startTransition }) => (
      <Route>
        {({ history }) => (
          <a
            href={to}
            onClick={e => {
              e.preventDefault()
              startTransition({
                imgSrc,
                thumb: e.currentTarget,
                onComplete: () => history.push(to),
              })
            }}
            {...rest}
          >
            {children}
          </a>
        )}
      </Route>
    )}
  </TileTransitionConsumer>
)

const Box = styled('div')(p => ({
  display: 'none',
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: zIndex('tileTransition')(p),
  top: 0,
  left: 0,
  overflow: 'hidden',
}))

const Img = styled('img')({
  display: 'block',
  width: '100%',
  height: '100%',
})

const getBarWidth = () =>
  window.matchMedia('(min-width: 64em)').matches ? 70 : 0

class TileTransitionAnimation extends GSAPComponent {
  animate() {
    /* eslint-disable */
    let tl
    const thumb = this.props.thumbData.thumb.querySelector('.TileImg')
    const box = this.box
    const img = this.img

    img.addEventListener('load', () => {
      tl = new TimelineMax({ onComplete: this.props.onComplete })
      const thumbBCR = thumb.getBoundingClientRect()
      const thumbLeft = thumbBCR.left
      const thumbTop = thumbBCR.top
      const thumbWidth = thumbBCR.width
      const thumbHeight = thumbBCR.height
      const endWidth = document.body.offsetWidth - 2 * getBarWidth()
      const endHeight = window.innerHeight

      const imgRatio = img.naturalWidth / img.naturalHeight
      const thumbRatio = thumbWidth / thumbHeight
      const endRatio = endWidth / endHeight

      const boxStartScaleX = thumbWidth / endWidth
      const boxStartScaleY = thumbHeight / endHeight
      const boxEndScaleX = 1
      const boxEndScaleY = 1

      let imgStartScaleX
      let imgStartScaleY
      let imgEndScaleX
      let imgEndScaleY

      // Thumbnail is wider than image
      if (imgRatio <= thumbRatio) {
        const imgStartHeight = thumbWidth / imgRatio
        imgStartScaleX = 1
        imgStartScaleY = imgStartHeight / thumbHeight
        // Image is wider than thumbnail
      } else {
        const imgStartWidth = thumbHeight * imgRatio
        imgStartScaleX = imgStartWidth / thumbWidth
        imgStartScaleY = 1
      }

      // End rect is wider than image
      if (imgRatio <= endRatio) {
        const imgEndHeight = endWidth / imgRatio
        imgEndScaleX = 1
        imgEndScaleY = imgEndHeight / endHeight
        // Image is wider than end rect
      } else {
        const imgEndWidth = endHeight * imgRatio
        imgEndScaleX = imgEndWidth / endWidth
        imgEndScaleY = 1
      }

      TweenMax.set(box, {
        display: 'block',
        transformOrigin: 'left top',
        width: endWidth,
        height: endHeight,
        x: thumbLeft,
        y: thumbTop,
        scaleX: boxStartScaleX,
        scaleY: boxStartScaleY,
      })
      TweenMax.set(img, {
        scaleX: imgStartScaleX,
        scaleY: imgStartScaleY,
      })

      const duration = 0.8
      const baseEase = Power3.easeInOut
      // tl.pause()
      tl.from(box, duration / 2, {
        opacity: 0,
      })
      tl.to(
        box,
        duration,
        {
          x: getBarWidth(),
          y: 0,
          scaleX: boxEndScaleX,
          ease: ExpoScaleEase.config(boxStartScaleX, boxEndScaleX, baseEase),
        },
        duration / 2
      )
      tl.to(
        box,
        duration,
        {
          x: getBarWidth(),
          y: 0,
          scaleX: boxEndScaleX,
          ease: ExpoScaleEase.config(boxStartScaleX, boxEndScaleX, baseEase),
        },
        duration / 2
      )
      tl.to(
        box,
        duration,
        {
          scaleY: boxEndScaleY,
          ease: ExpoScaleEase.config(boxStartScaleY, boxEndScaleY, baseEase),
        },
        duration / 2
      )

      tl.to(
        img,
        duration,
        {
          scaleX: imgEndScaleX,
          scaleY: imgEndScaleY,
          ease: baseEase,
        },
        duration / 2
      )
    })

    return tl
    /* esling-enable */
  }

  render() {
    return (
      <Box innerRef={ref => (this.box = ref)}>
        <Img
          innerRef={ref => (this.img = ref)}
          src={this.props.thumbData.imgSrc}
          alt=""
        />
      </Box>
    )
  }
}

export const TileTransitionObject = () => (
  <TileTransitionConsumer>
    {({ status, thumbData, endTransition }) => {
      if (status === 'transitioning') {
        return (
          <TileTransitionAnimation
            thumbData={thumbData}
            onComplete={() => {
              endTransition()
              thumbData.onComplete()
            }}
          />
        )
      }

      return null
    }}
  </TileTransitionConsumer>
)
