import { css } from 'react-emotion'
import { min, rule } from 'spaced-components'
import { color } from './helpers'

const theme = {}
theme.typo = {}

theme.barWidth = { mobileSm: 0, laptopSm: 70 }
theme.navbarHeight = { mobile: 60, laptop: 'auto' }
// theme.barWidth = { mobileSm: 0, laptopSm: 70, _1400: 80, _1600: 100 }

theme.zIndex = [
  'content',
  'tileTransition',
  'desktopNavbar',
  'modal',
  'mobileNavbar',
  'quote',
  'bar',
  'intro',
]

theme.colors = {
  light: {
    100: 'rgba(255, 255, 255, 0.1)',
    400: '#fff',
  },
  dark: {
    500: '#333',
    600: '#171717',
    700: '#0a0a0a',
    900: '#020202',
    1000: '#000000',
  },
}

theme.durations = {
  fast: '0.25s',
  normal: '0.5s',
  slow: '1s',
}

theme.typo.link = p => css`
  display: inline-block;
  letter-spacing: 0.2em;
  font-weight: normal;
  font-family: TungstenMedium, sans-serif;
  text-transform: uppercase;
  -webkit-tap-highlight-color: ${color('light', 100)(p)};
`

theme.typo.linkLg = p => css`
  ${theme.typo.link(p)};
  ${rule('font-size', { mobileSm: 24, tabletMd: 16 })(p)};
`

theme.typo.linkSm = p => css`
  ${theme.typo.link(p)};
  ${rule('font-size', { mobileSm: 13, tabletMd: 16 })(p)};
`

theme.typo.headline = p => css`
  font-weight: 800;
  ${rule('font-size', { mobileSm: 33, mobileLg: 40 })(p)};
  ${min('laptopSm')(p)} {
    font-size: ${p.fontSize || '4.05'}vw;
    font-size: ${p.fontSize || '4.05'}vmax;
  }
  line-height: 1.1;
`

export default theme
