import React from 'react'
import styled from 'react-emotion'
import { duration } from '../styles/helpers'

const Wrapper = styled('div')(props => ({
  ...(props.rotated && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 27,
    height: 180,
    overflow: 'hidden',
  }),

  svg: {
    display: 'block',
    width: 180,
    height: 27,
    fill: '#fff',
    transform: props.rotated && 'rotate(90deg)',
  },

  path: {
    transition: duration('normal')(props),
  },

  'svg:hover path:nth-child(odd)': {
    transform: 'translateY(10px)',
  },

  'svg:hover path:nth-child(even)': {
    transform: 'translateY(-10px)',
  },
}))

const AdVictoremLogo = props => (
  <Wrapper {...props}>
    <div>
      <svg viewBox="0 -5 273.2 27.7">
        <path d="M11.8,13.9H5.4l-1.7,3.4H0L8.6,0l8.6,17.2h-3.7L11.8,13.9z M10.3,10.9L8.6,7.3l-1.8,3.5H10.3z" />
        <path d="M30,0.4h6.1c4.7,0,8.1,3.7,8.1,8.5s-3.3,8.3-8.1,8.3H30V0.4z M40.8,9c0-3.1-2.2-5.5-5.3-5.5h-2.2 v10.7h2.2C38.6,14.2,40.8,12.1,40.8,9z" />
        <path d="M80.6,17.7L72,0.4h3.7l4.9,9.9l4.9-9.9h3.7L80.6,17.7z" />
        <path d="M105.1,0.4h3.3v16.8h-3.3V0.4z" />
        <path d="M137.5,13.9c-1.5,2.1-4.2,3.5-6.8,3.5c-4.7,0-8.6-3.8-8.6-8.6c0-4.7,3.8-8.6,8.6-8.6 c2.4,0,4.7,1,6.4,2.7l-2.2,2.1c-1.1-1-2.7-1.7-4.2-1.7c-3.1,0-5.2,2.5-5.2,5.6c0,3.1,2.1,5.5,5.2,5.5c1.9,0,3.8-1.1,4.8-2.4 L137.5,13.9z" />
        <path d="M149.7,0.4h12.1v3h-4.4v13.7h-3.3V3.5h-4.4V0.4z" />
        <path d="M191,8.8c0,4.7-3.8,8.6-8.6,8.6c-4.7,0-8.6-3.8-8.6-8.6c0-4.7,3.8-8.6,8.6-8.6 C187.1,0.2,191,4.1,191,8.8z M187.6,8.9c0-3.1-2.2-5.6-5.3-5.6c-3.1,0-5.2,2.5-5.2,5.6c0,3.1,2.1,5.5,5.2,5.5 C185.4,14.4,187.6,12,187.6,8.9z" />
        <path d="M210.9,11.1H208v6.1h-3.3V0.4h7.9c2.8,0,5,2.2,5,5.3c0,2.3-1.3,4.1-3.1,4.9l3.3,6.5H214L210.9,11.1z M214.3,5.8c0-1.4-0.9-2.3-1.9-2.3H208V8h4.3C213.5,8,214.3,7.2,214.3,5.8z" />
        <path d="M234.7,14.2h8.3v3h-11.7V0.4H243v3h-8.3v4.1h6.7v3h-6.7V14.2z" />
        <path d="M273.2,0v17.2h-3.3V8.4l-4.7,4.7l-4.7-4.7v8.8H257V0l8.1,8.3L273.2,0z" />
      </svg>
    </div>
  </Wrapper>
)

export default AdVictoremLogo
