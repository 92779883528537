import React from "react"
import styled from "react-emotion"
import { withTheme } from "emotion-theming"
import { On, Position } from "spaced-components"
import { color, duration } from "../styles/helpers"

const LinesCurtainWrapper = styled("div")`
  display: flex;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  > div {
    ${p =>
      p.active &&
      `transform: scaleX(1);
       transition-delay: 0s`};
  }
`

const Column = styled("div")`
  ${p => (p.width ? `width: ${p.width}` : "flex: 1")};
  height: 100%;
  background-color: ${color("dark", 900)};
  transform-origin: left center;
  transform: scaleX(0);
  transition: ${duration("normal")} ${duration("normal")} transform;
`

const LinesCurtain = ({ theme: { barWidth }, active, ...props }) => (
  <Position position="absolute" top right bottom left {...props}>
    <LinesCurtainWrapper active={active}>
      <On max="tabletMd" dynamic>
        <Column width="10%" />
        <Column />
        <Column />
        <Column width="10%" />
      </On>
      <On min="tabletMd" dynamic>
        <Column />
        <Column />
        <Column />
        <Column />
        <Column />
        <Column />
      </On>
    </LinesCurtainWrapper>
  </Position>
)

export default withTheme(LinesCurtain)
