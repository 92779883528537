import React from "react"
import styled from "react-emotion"

import Timeline from "./Timeline"
import { Box, min } from "spaced-components"
import { color } from "../styles/helpers"

export const BackgroundTextWrapper = styled(Box)`
  letter-spacing: 0.1em;
  font-size: ${p => p.size || "14vw"};
  font-family: TungstenMedium, sans-serif;
  text-transform: uppercase;
  color: ${p => p.color || "rgba(255, 255, 255, .4)"};
  ${min("tabletMd")} {
    color: ${p => p.color || color("dark", 1000)(p)};
  }
`

const BackgroundText = ({ animate = false, ...rest }) => (
  <Timeline
    paused={!animate}
    didMount={({ timeline, refs: { textEl }, gsap: { SplitText, Power3 } }) => {
      const text = new SplitText(textEl, { type: "words, chars" })

      timeline.staggerFrom(
        text.chars,
        0.8,
        { opacity: 0, x: -100, ease: Power3.easeOut },
        -0.05
      )
    }}
  >
    {({ ref }) => (
      <div ref={ref("textEl")}>
        <BackgroundTextWrapper {...rest} />
      </div>
    )}
  </Timeline>
)

export default BackgroundText
