import React from 'react'
import { Link } from 'react-router-dom'
import {
  On,
  Inset,
  Absolute,
  rem,
  Grid,
  Break,
  Stack,
  Board,
  Fixed,
  Relative,
  Flex,
  Columns,
  Space,
} from 'spaced-components'
import Layout from '../Layout'
import styled from '../styles/styled'
import theme from '../styles/theme'
import LinesPattern from '../components/LinesPattern'
import Img from '../components/Img'
import Parallax from '../components/Parallax'
import ScrollIndicator from '../components/ScrollIndicator'
import Headline from '../components/Headline'
import TextLink from '../components/TextLink'
import NextArrow from '../components/NextArrow'
import FlagVideo from '../components/FlagVideo'
import TextUnderline from '../components/TextUnderline'
import { DecideWhenToShowFooter } from '../components/Footer'

import {
  getProject,
  getPrevProject,
  getNextProject,
  projectURL,
  projectImageURL,
} from '../projects'

export const boxShadow = (alpha = 0.5, data = '7px 7px 70px') => ({
  boxShadow: `${data} rgba(0, 0, 0, ${alpha})`,
})

export const dropShadow = (alpha = 0.5, data = '10px 10px 30px') => ({
  filter: `drop-shadow(${data} rgba(0, 0, 0, ${alpha}))`,
})

export const Title = styled('h1')(({ colors, color, italic }, { scale }) => ({
  display: 'inline-block',
  position: 'relative',
  zIndex: 0,
  fontStyle: italic && 'italic',
  fontWeight: 800,
  fontSize: scale(
    { mobile: 28 },
    { mobileMd: 28, tablet: 40 },
    { tablet: 40 },
    { laptopMd: 40, desktopLg: 60 }
  ),
  lineHeight: 1.1,
  letterSpacing: -1.4,

  '&::after': {
    content: '""',
    display: 'block',
    height: '40%',
    position: 'absolute',
    zIndex: -1,
    bottom: '11%',
    left: '0.05em',
    right: '0.05em',
    transformOrigin: 'bottom',
    transform: 'scaleY(0.45)',
    willChange: 'transform',
    ...(!!colors
      ? {
          backgroundImage: `linear-gradient(to right, ${colors[0]}, ${
            colors[1]
          })`,
        }
      : {}),
    backgroundColor: color,
    transition: '1s 0.3s opacity, 0.25s transform',
  },
  '&:hover::after': {
    transform: 'none',
  },
}))

export const Box = styled('div')({})

export const Detail = styled('span')(({ block, muted }) => ({
  display: block ? 'block' : 'inline-block',
  letterSpacing: '0.2em',
  fontWeight: 'normal',
  fontSize: 15,
  fontFamily: 'TungstenMedium, sans-serif',
  textTransform: 'uppercase',
  WebkitTapHighlightColor: 'rgba(255, 255, 255, 0.1)',
  opacity: muted && 0.15,
}))

export const Paragraph = styled('p')((props, { scale }) => ({
  fontSize: scale({ mobile: 16 }, { laptopMd: 16, desktopLg: 20 }),
  fontFamily: '"Source Serif Pro", serif',
  lineHeight: 1.8,
  opacity: 0.8,
}))

export const DetailValue = styled('span')({
  fontWeight: 700,
  fontSize: 15,
  lineHeight: 1.5,
})

export const QuoteAuthor = styled('p')((props, { scale }) => ({
  fontWeight: 700,
  fontSize: scale({ mobile: 18 }, { laptopMd: 18, desktopLg: 20 }),
}))

const Text = styled('p')(() => ({
  fontWeight: 700,
  fontSize: { mobile: 18, laptop: '1.45vw' },
  lineHeight: 1.6,
}))

export const InfoSection = ({ text, details }) => (
  <Inset y={{ mobile: 60, tablet: 100 }}>
    <Board rowGap="lg">
      <Board.Item
        offset={{ tabletMd: 1 / 6 }}
        width={{ mobile: 1, tabletMd: 3 / 6 }}
        style={{ paddingRight: rem(20) }}
      >
        {text}
      </Board.Item>
      <Board.Item width={{ mobile: 1, tabletMd: 2 / 6 }}>
        <On min="tabletMd">{details}</On>
      </Board.Item>
    </Board>
  </Inset>
)

const ImageZoomer = styled(({ children, ...rest }) => (
  <div {...rest}>
    <div className="ImageZoomerImage" />
    <div className="ImageZoomerContent">{children}</div>
  </div>
))(({ color, image }) => ({
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: color,
  '.ImageZoomerContent': {
    position: 'relative',
  },
  '.ImageZoomerImage': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    opacity: 0,
    transition: ' opacity 1s, transform 5s',
  },
  ':hover .ImageZoomerImage': {
    opacity: 1,
    transform: 'scale(1.5)',
  },
}))

const CaseStudyLayout = ({ children, currentProjectKey, logo }) => {
  const currentProject = getProject(currentProjectKey)
  const prevProject = getPrevProject(currentProjectKey)
  const nextProject = getNextProject(currentProjectKey)

  return (
    <Layout.Shell>
      <Relative zIndex={1}>
        <On min="tabletMd">
          <LinesPattern zIndex={2} color="rgba(255, 255, 255, .2)" />
        </On>
        <Parallax
          direction="top"
          distance={300}
          height="100vh"
          style={{ zIndex: 1 }}
        >
          <Parallax.Background>
            <Img
              src={projectImageURL(currentProject.key)}
              alt=""
              height="100%"
              color={currentProject.color}
            />
          </Parallax.Background>
          <Parallax.Content>
            <Inset x={30} height="100%">
              <Flex height="100%" center middle>
                {logo}
              </Flex>
            </Inset>
          </Parallax.Content>
        </Parallax>

        <Relative
          id="case-study-content"
          zIndex={1}
          style={{ marginBottom: 'calc(100vh - 80px)' }}
        >
          {children}
        </Relative>

        <DecideWhenToShowFooter>
          <Fixed
            is="footer"
            bottom
            left={theme.barWidth}
            right={theme.barWidth}
          >
            <Columns
              columnCount={{ tablet: 2 }}
              style={{ textAlign: 'center' }}
            >
              <ImageZoomer
                color="#161616"
                image={projectImageURL(prevProject.key)}
              >
                <Flex
                  middle
                  center
                  height={{
                    mobile: 'calc(20vh - 20px)',
                    tablet: 'calc(40vh - 40px)',
                  }}
                >
                  <Flex.Item>
                    <Detail block>Previous project</Detail>
                    <Link to={projectURL(prevProject.key)}>
                      <Title colors={prevProject.colors}>
                        {prevProject.title}
                      </Title>
                    </Link>
                  </Flex.Item>
                </Flex>
              </ImageZoomer>
              <ImageZoomer
                color="#0d0d0d"
                image={projectImageURL(nextProject.key)}
              >
                <Flex
                  middle
                  center
                  height={{
                    mobile: 'calc(20vh - 20px)',
                    tablet: 'calc(40vh - 40px)',
                  }}
                >
                  <Flex.Item>
                    <Detail block>Next project</Detail>
                    <Link to={projectURL(nextProject.key)}>
                      <Title colors={nextProject.colors}>
                        {nextProject.title}
                      </Title>
                    </Link>
                  </Flex.Item>
                </Flex>
              </ImageZoomer>
            </Columns>
            <Relative>
              <Flex middle height="calc(60vh - 40px)" overflow="hidden">
                <Absolute top left height="100%">
                  <FlagVideo />
                </Absolute>

                <Relative width="100%">
                  <Grid
                    template={{
                      mobile: "'. details .' / 1fr 8fr 1fr",
                      tabletMd: "'. form . details' / 1fr 3fr 2rem 2fr",
                    }}
                  >
                    <Grid.Item area="form">
                      <On min="tabletMd">
                        <Headline is="h2" css={{ fontSize: '3.5vmax' }}>
                          Like what you see?
                        </Headline>
                        <Space mobileSm={20} />
                        <Text css={{ maxWidth: '27em' }}>
                          Our clients hire us to take risks and inject
                          creativity that differentiates their brand.
                        </Text>
                        <Space mobileSm={30} />
                        <Link to="/contact#form">
                          <TextLink is="span" sm>
                            TELL US ABOUT YOUR PROJECT{' '}
                            <NextArrow width="1.9em" />
                          </TextLink>
                        </Link>
                      </On>
                    </Grid.Item>
                    <Grid.Item area="details">
                      <Space md />
                      <Stack is="ul" rowGap={15}>
                        <li>
                          <Detail muted>GET IN TOUCH:</Detail>
                          <Break />
                          <a href="tel:+03234573899">
                            <TextUnderline color="rgba(255, 255, 255, .25)">
                              <DetailValue>323.457.3899</DetailValue>
                            </TextUnderline>
                          </a>
                        </li>
                        <li>
                          <Detail muted>Business Inquiries:</Detail>
                          <Break />
                          <a href="mailto:hello@advictorem.agency">
                            <TextUnderline color="rgba(255, 255, 255, .25)">
                              <DetailValue>hello@advictorem.agency</DetailValue>
                            </TextUnderline>
                          </a>
                        </li>
                        <li>
                          <Detail muted>JOIN OUR TEAM:</Detail>
                          <Break />
                          <a href="mailto:jobs@advictorem.agency">
                            <TextUnderline color="rgba(255, 255, 255, .25)">
                              <DetailValue>jobs@advictorem.agency</DetailValue>
                            </TextUnderline>
                          </a>
                        </li>
                      </Stack>
                    </Grid.Item>
                  </Grid>
                </Relative>
              </Flex>
            </Relative>
          </Fixed>
        </DecideWhenToShowFooter>

        <Fixed right={theme.barWidth} zIndex={1} bottom={24}>
          <ScrollIndicator />
          <Space inline x={20} />
        </Fixed>
      </Relative>
    </Layout.Shell>
  )
}

export default CaseStudyLayout
