import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import { Space, Board, On } from 'spaced-components'
import LinesPattern from '../../components/LinesPattern'
import DarkWrapper from '../../components/DarkWrapper'
import Layout from '../../Layout'
import BackgroundText from '../../components/BackgroundText'
import { Footer } from '../../components/Footer'
import ChallengeUsFooter from '../../components/ChallengeUsFooter'
import HelloFab from '../../components/HelloFab'

export default () => (
  <Fragment>
    <Helmet>
      <title>Affiliations</title>
      <meta name="description" content="" />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <Layout.Shell>
      <Footer>
        <DarkWrapper>
          <LinesPattern />
          <Board.Item
            offset={{ mobileSm: 1 / 10, tabletMd: 1 / 6 }}
            width={{ mobileSm: 8 / 10, tabletMd: 4 / 6 }}
          >
            <Space y={100} />
            <On min="tabletSm">
              <Space x={{ tabletMd: '-5.55vmax' }}>
                <BackgroundText animate>AFFILIATIONS</BackgroundText>
              </Space>
            </On>
            <a href="https://upcity.com/local-marketing-agencies/profiles/ad-victorem">
              <img
                src="https://app.upcity.com/images/badges/featured.png"
                alt=""
                width="300px"
                height="70px"
              />
            </a>
          </Board.Item>
        </DarkWrapper>
      </Footer>
      <ChallengeUsFooter />
      <HelloFab />
    </Layout.Shell>
  </Fragment>
)
