import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import {
  Grid,
  Board,
  Flex,
  Inset,
  Break,
  Stack,
  Space,
  Relative,
} from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText from '../../components/BackgroundText'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'
import Parallax from '../../components/Parallax'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  QuoteAuthor,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import ramsVintage from './images/rams-vintage.png'
import player1 from './images/player1.png'
import player2 from './images/player2.png'
import trees from './images/trees.png'
import ramsWomensHoodie from './images/rams-womens-hoodie.png'
import ramsPennant from './images/rams-pennant.png'
import ramsMensTshirt from './images/rams-mens-tshirt.png'
import ramsPoster from './images/rams-poster.png'
import ramsLogo from './images/rams-logo.svg'
import ramsCampaignBillboard from './images/rams-campaign-billboard.png'
import ramsHollywoodBillboard from './images/rams-hollywood-billboard.png'
import ramsBillboards from './images/rams-billboards.png'
import ramsDigitalMedia from './images/rams-digital-media.png'
import ticket from './images/ticket.png'
import Logo from './Logo'

const currentProject = getProject('rams')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#001865' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em' }}>
                  Call it a comeback.
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  Our city has a storied history of athletics; from the Dodgers
                  to the Lakers to the 1984 Olympics, LA is home to some of the
                  greatest sports memories in modern history. Following the
                  announcement of the Rams’ return, we created a conceptual
                  out-of-home and digital media campaign celebrating the
                  comeback of LA’s original football team.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Los Angeles Rams</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Sports, Entertainment</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Art Direction
                    <Break />
                    Digital Advertising
                  </DetailValue>
                </li>
              </Stack>
            }
          />
          <Space mobile="13vw" tabletMd="22vw" />
        </Inset>
      </Box>

      <Img
        src={ramsVintage}
        style={{
          minHeight: '60vh',
          marginTop: '-30vw',
          pointerEvents: 'none',
        }}
      />

      <Box
        css={{
          marginTop: { mobile: -200, 600: '-30vw' },
          color: '#000',
          backgroundColor: '#fff',
        }}
      >
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <Board.Item
            offset={{ tabletMd: 1 / 6 }}
            width={{ mobile: 1, tabletMd: 4 / 6 }}
            style={{ textAlign: 'center' }}
          >
            <Space mobile={200} _600={'25vw'} />
            <AutoFade>
              <Title is="h4">The art of the game</Title>
            </AutoFade>
            <Space lg />
            <AutoFade>
              <Paragraph css={{ maxWidth: '41em', margin: 'auto' }}>
                Often times, traditional ad campaigns do not translate well
                across other branding media such as marketing collateral,
                merchandise, and digital. Our focus was to create a strong piece
                of creative art that would serve as a the foundation for a
                versatile campaign.
              </Paragraph>
            </AutoFade>
          </Board.Item>
        </Inset>
        <Space mobile="-17vw" tabletMd="-10vw" />
        <Flex bottom overflow="hidden">
          <Box
            css={{
              width: { mobile: '60%', tabletMd: '42%' },
              position: 'relative',
              zIndex: 2,
              marginLeft: { mobile: '-12%', tabletMd: 0 },
              pointerEvents: 'none',
              userSelect: 'none',
            }}
          >
            <AutoSlide up>
              <Img src={player1} alt="" ratio={841 / 719} />
            </AutoSlide>
          </Box>
          <Box
            css={{
              width: { mobile: '75%', tabletMd: '52%' },
              position: 'relative',
              zIndex: 1,
              marginLeft: { mobile: '-40%', tabletMd: '-30%' },
              pointerEvents: 'none',
              userSelect: 'none',
            }}
          >
            <AutoSlide up delay={300}>
              <Img src={player2} alt="" ratio={938 / 900} />
            </AutoSlide>
          </Box>
          <Box
            css={{
              width: { mobile: '62%', tabletMd: '31%' },
              position: 'relative',
              left: { mobile: '18%', tabletMd: 0 },
              marginLeft: { mobile: '-32%', tabletMd: 'auto' },
              pointerEvents: 'none',
              userSelect: 'none',
            }}
          >
            <Img src={trees} alt="" ratio={1224 / 492} />
          </Box>
        </Flex>
      </Box>

      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }} y="xxl">
          <Grid
            templateColumns="repeat(100, 1fr)"
            templateRows="repeat(100, 1fr)"
            maxWidth={1100}
            style={{ margin: 'auto' }}
          >
            <Grid.Item column="6 / 78" row="1 / 100">
              <AutoSlide up>
                <Img
                  src={ramsWomensHoodie}
                  alt=""
                  ratio={957 / 936}
                  style={{ width: '100%' }}
                />
              </AutoSlide>
            </Grid.Item>
            <Grid.Item column="1 / 70" row="57 / 100" zIndex={1}>
              <AutoSlide up>
                <Img
                  src={ramsPennant}
                  alt=""
                  ratio={428 / 846}
                  style={{ width: '100%' }}
                />
              </AutoSlide>
            </Grid.Item>
            <Grid.Item column="45 / 100" row="41 / 100">
              <AutoSlide up>
                <Img
                  src={ramsMensTshirt}
                  alt=""
                  ratio={730 / 662}
                  style={{ width: '100%' }}
                />
              </AutoSlide>
            </Grid.Item>
          </Grid>
        </Inset>
      </Box>

      <Parallax direction="both" height="55vh" maxHeight={600}>
        <Parallax.Background height="150%">
          <Box css={{ height: '100%', backgroundColor: '#001967' }}>
            <Box
              is="img"
              src={ramsLogo}
              alt=""
              css={{
                width: { mobile: '150%', laptop: '100%' },
                height: '100%',
                margin: { laptop: '5vw 0 0 -10vw' },
                objectFit: { mobile: 'cover', laptop: 'initial' },
              }}
            />
          </Box>
        </Parallax.Background>
        <Parallax.Content>
          <Flex height="100%" center middle>
            <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
              <Box css={{ textAlign: 'center' }}>
                <Title is="blockquote" css={{ maxWidth: '25em' }}>
                  “What they did really got me excited about the return of
                  football to my home, L.A.”
                </Title>
                <Space lg />
                <QuoteAuthor>Ralph Brown</QuoteAuthor>
                <Space xs />
                <Paragraph is="span">
                  10-year NFL Veteran, Los Angeles Native
                </Paragraph>
              </Box>
            </Inset>
          </Flex>
        </Parallax.Content>
      </Parallax>

      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Space xxl />
        <Img
          src={ramsPoster}
          alt=""
          ratio={1067 / 783}
          style={{
            maxWidth: 600,
            width: '100%',
            margin: 'auto',
          }}
        />
        <Space xl />
        <Img
          src={ramsCampaignBillboard}
          alt=""
          ratio={1183 / 1754}
          style={{ width: '100%' }}
        />
        <Space xxl />
        <Img
          src={ramsHollywoodBillboard}
          alt=""
          ratio={1270 / 1490}
          style={{
            maxWidth: 1000,
            width: '100%',
            margin: 'auto',
          }}
        />
        <Img
          src={ramsBillboards}
          alt=""
          ratio={1873 / 1838}
          style={{ width: '100%' }}
        />
      </Box>

      <Box css={{ textAlign: 'center', backgroundColor: '#001967' }}>
        <Space xxl />
        <WhenVisible>
          {inView => (
            <BackgroundText animate={inView} color="#031f77">
              #MYRAMSRETURN
            </BackgroundText>
          )}
        </WhenVisible>
        <Space y={{ tabletSm: '-5vmax' }} />
        <Relative>
          <Img
            src={ramsDigitalMedia}
            alt=""
            ratio={1006 / 1183}
            style={{
              display: 'block',
              width: '70%',
              margin: 'auto',
            }}
          />
        </Relative>
        <Img
          src={ticket}
          alt=""
          ratio={1028 / 1126}
          style={{
            display: 'block',
            width: '70%',
            margin: 'auto',
          }}
        />
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
