import { Component } from 'react'
import debounce from 'lodash.debounce'

import { passive } from '../utils'

class ScrollPosition extends Component {
  state = {
    matches: false,
  }

  below = null

  componentDidMount() {
    this.below =
      typeof this.props.below === 'function'
        ? this.props.below()
        : this.props.below

    this.update()

    window.addEventListener('scroll', this.update, passive)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.update)
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = debounce(() => {
    this.below =
      typeof this.props.below === 'function'
        ? this.props.below()
        : this.props.below

    this.update()
  }, 300)

  update = () => {
    if (window.pageYOffset >= this.below) {
      !this.state.matches && this.setState({ matches: true })
    } else {
      this.state.matches && this.setState({ matches: false })
    }
  }

  render() {
    return this.props.children(this.state.matches)
  }
}

export default ScrollPosition
