import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'
import 'intersection-observer'

{
  let latestTitle
  let restoreTitleTimeout

  window.addEventListener('blur', e => {
    latestTitle = document.title
    document.title = 'Come back, we miss you!'
    restoreTitleTimeout = setTimeout(() => (document.title = latestTitle), 5000)
  })

  window.addEventListener('focus', e => {
    if (latestTitle) document.title = latestTitle
    clearTimeout(restoreTitleTimeout)
  })
}

const rootEl = document.getElementById('root')
const renderApp = () => ReactDOM.render(<Root />, rootEl)
renderApp()

if (module.hot) {
  module.hot.accept(['./Root'], () => {
    renderApp()
  })
}
