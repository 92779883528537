import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { Inset, Break, Stack, Space } from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import { AutoFade, AutoSlide } from '../../components/Animated'
import VideoPlayer from '../../components/VideoPlayer'
import Parallax from '../../components/Parallax'
import Img from '../../components/Img'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'

import webroll01 from './assets/webroll.mp4'
import video01 from './assets/video.mp4'
import webroll02 from './assets/webroll02.mp4'
import video02 from './assets/video02.mp4'

import stationBillboard from './assets/station-billboard.jpg'
import latifPhotos from './assets/lafc-latif-photos.png'
import latifBlessingGoMetro from './assets/latif-blessing-go-metro.png'
import scoreboard1 from './assets/scoreboard1.png'
import scoreboard2 from './assets/scoreboard2.png'

const currentProject = getProject('lafc')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
      <Box css={{ backgroundColor: '#000' }}>
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <InfoSection
            text={
              <Fragment>
                <Title is="h1" css={{ maxWidth: '11em', color: '#f3d066' }}>
                  Going Metro with Latif Blessing
                </Title>
                <Space lg />
                <Paragraph
                  css={{
                    width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                  }}
                >
                  We were thrilled to collaborate with Los Angeles’ newest
                  sports team, LAFC and the talented crew at Friendly Filmworks
                  to tell fans how to get to the new Banc of California Stadium
                  in Exposition Park via the Los Angeles Metro Expo Line. With
                  LAFC star winger Latif Blessing, we created an ad showing
                  Latif using his skills to shortcut the heavy LA traffic and
                  opting for the Metro instead. The video can be seen playing on
                  screens at the Banc of California Stadium promoting the Metro
                  + LAFC partnership throughout the 2018 season.
                </Paragraph>
              </Fragment>
            }
            details={
              <Stack is="ul" rowGap={15}>
                <li>
                  <Detail muted>Client:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Metro Los Angeles</DetailValue>
                </li>
                <li>
                  <Detail muted>Agency:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Friendly Filmworks</DetailValue>
                </li>
                <li>
                  <Detail muted>Industry:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>Transportation</DetailValue>
                </li>
                <li>
                  <Detail muted>Services:</Detail>
                  <Break />
                  <Space xs />
                  <DetailValue>
                    Video Production
                    <Break />
                    Creative Direction
                    <Break />
                  </DetailValue>
                </li>
              </Stack>
            }
          />
          <Space y={{ tablet: -40 }} />
          <AutoSlide up>
            <Box
              css={{
                width: { tablet: '80%' },
                margin: 'auto',
                marginBottom: '-25vw',
                boxShadow: '0 20px 50px rgba(0, 0, 0, .3)',
              }}
            >
              <VideoPlayer
                webroll={webroll01}
                video={video01}
                ratio={1080 / 1920}
              />
            </Box>
          </AutoSlide>
        </Inset>
      </Box>
      <Box css={{ backgroundColor: '#0a0a0a' }}>
        <Space y="25vw" />
        <Space xl tabletMd="xxl" />
        <AutoFade>
          <Img src={latifPhotos} ratio={1480 / 1839} />
        </AutoFade>
        <Space xl tabletMd="xxl" />
        <AutoFade style={{ width: '70%', margin: 'auto' }}>
          <Img src={latifBlessingGoMetro} ratio={843 / 1239} />
        </AutoFade>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: '80%',
            height: '53vw',
            position: 'relative',
            margin: 'auto',
          }}
        >
          <AutoFade style={{ width: '80%' }}>
            <Img src={scoreboard1} ratio={746 / 1074} />
          </AutoFade>
          <Box
            css={{
              width: '70%',
              position: 'absolute',
              left: '32%',
              top: '48%',
            }}
          >
            <AutoFade>
              <Img src={scoreboard2} ratio={626 / 1074} />
            </AutoFade>
          </Box>
        </Box>
        <Space xl tabletMd="xxl" />
        <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
          <AutoFade style={{ textAlign: 'center' }}>
            <Title is="h3" css={{ color: '#f3d066' }}>
              Go Behind The Scenes
            </Title>
          </AutoFade>
          <Space lg tabletMd="xl" />
          <AutoSlide up>
            <Box
              css={{
                width: { tablet: '80%' },
                margin: 'auto',
                boxShadow: '0 20px 50px rgba(0, 0, 0, .3)',
              }}
            >
              <VideoPlayer
                webroll={webroll02}
                video={video02}
                ratio={1080 / 1920}
              />
            </Box>
          </AutoSlide>
        </Inset>
        <Space xl tabletMd="xxl" />
        <Parallax direction="both" height="95vh" maxHeight={800}>
          <Parallax.Background height="150%">
            <Img src={stationBillboard} width="100%" height="100%" />
          </Parallax.Background>
          <Parallax.Content> </Parallax.Content>
        </Parallax>
      </Box>
    </CaseStudyLayout>
  </Fragment>
)
