import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import {
  Inset,
  Board,
  Break,
  Flex,
  Stack,
  Grid,
  Space,
} from 'spaced-components'
import {
  ParallaxProvider,
  Parallax as ParallaxObject,
} from 'react-scroll-parallax'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import Parallax from '../../components/Parallax'
import { AutoFade, AutoSlide } from '../../components/Animated'
import BeforeAfterSlider from 'react-before-after-slider'
import Img from '../../components/Img'

import CaseStudyLayout, {
  Title,
  Box,
  Detail,
  DetailValue,
  Paragraph,
  InfoSection,
} from '../CaseStudyLayout'

import { getProject } from '../../projects'

import Logo from './Logo'
import desktopAndPhone from './images/desktop-and-phone.png'
import homepage from './images/homepage.png'
import ipad from './images/ipad.png'
import sitemap from './images/sitemap.png'
import webpageAfter from './images/webpage-after.png'
import webpageBefore from './images/webpage-before.png'
import wireframe from './images/wireframe.png'
import tennisBall from './images/tennis-ball.png'

const currentProject = getProject('tennis-channel')

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <ParallaxProvider>
      <CaseStudyLayout currentProjectKey={currentProject.key} logo={<Logo />}>
        <Box css={{ backgroundColor: '#008568' }}>
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <InfoSection
              text={
                <Fragment>
                  <Title is="h1" css={{ maxWidth: '11em' }}>
                    Smashing the old, serving up a new look.
                  </Title>
                  <Space lg />
                  <Paragraph
                    css={{
                      width: { mobile: '100%', tabletMd: '95%', laptop: '90%' },
                    }}
                  >
                    We served up Tennis Channel’s website with a much-needed
                    update — a responsive web experience that would look great
                    on any device and bring their dynamic video content to life
                    to create an immersive digital fan experience.
                  </Paragraph>
                </Fragment>
              }
              details={
                <Fragment>
                  <Space tabletMd={50} />
                  <Stack is="ul" rowGap={15}>
                    <li>
                      <Detail muted>Client:</Detail>
                      <Break />
                      <Space xs />
                      <DetailValue>The Tennis Channel</DetailValue>
                    </li>
                    <li>
                      <Detail muted>Agency:</Detail>
                      <Break />
                      <Space xs />
                      <DetailValue>Something Massive</DetailValue>
                    </li>
                    <li>
                      <Detail muted>Industry:</Detail>
                      <Break />
                      <Space xs />
                      <DetailValue>Sports, Entertainment</DetailValue>
                    </li>
                    <li>
                      <Detail muted>Services:</Detail>
                      <Break />
                      <Space xs />
                      <DetailValue>Website Design</DetailValue>
                    </li>
                  </Stack>
                </Fragment>
              }
            />
            <AutoSlide up>
              <Box css={{ width: { tablet: '80%' }, margin: 'auto' }}>
                <Img
                  src={desktopAndPhone}
                  ratio={949 / 1254}
                  style={{ marginBottom: '-40vmin' }}
                />
              </Box>
            </AutoSlide>
          </Inset>
        </Box>

        <Box css={{ backgroundColor: '#0a0a0a' }}>
          <Space y="40vmin" />
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <Space md />
            <div style={{ textAlign: 'center' }}>
              <AutoFade>
                <Title is="h4">Uncovering the journey</Title>
              </AutoFade>
              <Space lg />
              <AutoFade>
                <Paragraph css={{ maxWidth: '41em', margin: 'auto' }}>
                  Our user-centered design approach started by creating the
                  journey typical TennisChannel.com users take and the flows
                  they follow to complete their objectives.
                </Paragraph>
              </AutoFade>
            </div>
            <Space xl />
            <AutoFade>
              <Box css={{ width: { tablet: '80%' }, margin: 'auto' }}>
                <Img src={sitemap} ratio={952 / 1410} />
              </Box>
            </AutoFade>
            <Space lg />
            <div style={{ textAlign: 'center' }}>
              <AutoFade>
                <Title is="h4">Visualizing the journey</Title>
              </AutoFade>
              <Space lg />
              <AutoFade>
                <Paragraph css={{ maxWidth: '41em', margin: 'auto' }}>
                  Our visual redesign consisted of translating dozens of
                  wireframes into a living, breathing, mobile-friendly website
                  with a scalable video player and easy to browse editorial
                  content.
                </Paragraph>
              </AutoFade>
            </div>
            <Space xl />
            <Grid templateColumns={'repeat(11, 1fr)'}>
              <Grid.Item column="3 / 4" row="1" zIndex={1}>
                <AutoFade duration={2000}>
                  <ParallaxObject
                    offsetYMax={350}
                    offsetYMin={-50}
                    slowerScrollRate
                  >
                    <div>
                      <Img
                        src={tennisBall}
                        ratio={1}
                        style={{
                          transform: 'scale(1.3) translate(-18%, -10%)',
                        }}
                      />
                    </div>
                  </ParallaxObject>
                </AutoFade>
              </Grid.Item>
              <Grid.Item column="2 / 6" row="1">
                <AutoSlide up delay={200}>
                  <Space tablet="xl" />
                  <Img src={wireframe} ratio={1229 / 521} />
                </AutoSlide>
              </Grid.Item>
              <Grid.Item column="4 / 11" row="1">
                <AutoSlide up>
                  <Img src={homepage} ratio={1386 / 943} />
                </AutoSlide>
              </Grid.Item>
            </Grid>
            <Space xl />
            <Board.Item
              offset={{ tabletMd: 1 / 6 }}
              width={{ mobile: 1, tabletMd: 4 / 6 }}
            >
              <AutoFade style={{ display: 'flex', justifyContent: 'center' }}>
                <BeforeAfterSlider
                  before={webpageBefore}
                  after={webpageAfter}
                  width={window.innerWidth * 0.6}
                  height={window.innerWidth * 0.6 * (901 / 1227)}
                />
              </AutoFade>
            </Board.Item>
            <Space xl />

            <AutoFade>
              <Box css={{ width: { tablet: '70%' }, margin: 'auto' }}>
                <Img src={ipad} ratio={740 / 1019} />
              </Box>
            </AutoFade>
            <Space xl />
          </Inset>
        </Box>

        <Parallax direction="both" height="65vh" maxHeight={600}>
          <Parallax.Background height="150%">
            <Box css={{ height: '100%', backgroundColor: '#008568' }}>
              <Box
                css={{
                  width: { mobile: '200%', tabletLg: '80%' },
                  'max:tabletLg': {
                    position: 'relative',
                    top: '-50%',
                    left: '-50%',
                  },
                }}
              >
                <svg viewBox="0 18 65.8 141.8" fill="#00654f">
                  <path d="M40.4,49.4l18.8,2.5l2,0.3v33h-31l0,0l0,0L40.4,49.4z" />
                  <path d="M4.6,44.8l21.7,2.8l2.1,0.3L17.8,85.2H4.6V44.8z" />
                  <path d="M61.2,28.6v11.5L4.6,32.7v-4.1H61.2z" />
                </svg>
              </Box>
            </Box>
          </Parallax.Background>
          <Parallax.Content>
            <Flex height="100%" center middle>
              <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
                <Title
                  is="blockquote"
                  css={{ maxWidth: '18em', textAlign: 'center' }}
                >
                  Our responsive design provided a content-rich viewing
                  experience for Tennis fans everywhere.
                </Title>
              </Inset>
            </Flex>
          </Parallax.Content>
        </Parallax>
      </CaseStudyLayout>
    </ParallaxProvider>
  </Fragment>
)
