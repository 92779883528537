import React, { Fragment } from 'react'
import Component from '@reach/component-component'
import Helmet from 'react-helmet'
import emotionStyled from 'react-emotion'
import BeforeAfterSlider from 'react-before-after-slider'
import {
  ParallaxProvider,
  Parallax as ParallaxObject,
} from 'react-scroll-parallax'
import {
  rule,
  min,
  Inset,
  Break,
  On,
  Flex,
  Relative,
  Absolute,
  Stack,
  Space,
} from 'spaced-components'
import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import WhenVisible from '../../components/WhenVisible'
import BackgroundText, {
  BackgroundTextWrapper,
} from '../../components/BackgroundText'
import VideoPlayer from '../../components/VideoPlayer'
import Parallax from '../../components/Parallax'
import { AutoFade, AutoSlide } from '../../components/Animated'
import Img from '../../components/Img'
import Swiper from '../../lib/swiper4'

import CaseStudyLayout, {
  boxShadow,
  dropShadow,
  Title,
  Box,
  Detail,
  DetailValue,
  InfoSection,
} from '../CaseStudyLayout'

import azubuE3Preview from './assets/azubu-e3-preview.mp4'
import azubuE3 from './assets/azubu-e3.mp4'
import e3Logo from './assets/e3logo.svg'
import azubuBooth from './assets/azubu-booth.png'
import azubuIcon from './assets/azubu-icon.png'
import azubuLogoVideo from './assets/azubu-logo.mp4'
import azubuLogo from './assets/azubu-logo.png'
import cartoonFigure from './assets/cartoon-figure.png'
import device1 from './assets/device1.png'
import device2 from './assets/device2.png'
import device3 from './assets/device3.png'
import fakerPoster from './assets/faker-poster.jpg'
import fortuneLogo from './assets/fortune-logo.svg'
import gamescomLogo from './assets/gamescom-logo.png'
import gamescom3 from './assets/gamescom.jpg'
import gamescom1 from './assets/gamescom1.jpg'
import gamescom2 from './assets/gamescom2.jpg'
import gbmPoster from './assets/gbm-poster.jpg'
import hoodie from './assets/hoodie.png'
import iphone from './assets/iphone.png'
import newLogo from './assets/new-logo.png'
import oldLogo from './assets/old-logo.png'
import parallax1 from './assets/parallax1.jpg'
import parallax2 from './assets/parallax2.jpg'
import parallax3 from './assets/parallax3.jpg'
import polygonLogo from './assets/polygon-logo.svg'
import poster1 from './assets/poster1.png'
import poster2 from './assets/poster2.png'
import poster3 from './assets/poster3.png'
import poster4 from './assets/poster4.png'
import rokuIcon from './assets/roku-icon.png'
import soda from './assets/soda.png'
import storyboard1 from './assets/storyboard1.gif'
import storyboard2 from './assets/storyboard2.gif'
import storyboard3 from './assets/storyboard3.jpg'
import storyboard4 from './assets/storyboard4.jpg'
import teamImpulseHypePreviewVideo from './assets/team-impulse-hype-preview.mp4'
import teamImpulseHypeVideo from './assets/team-impulse-hype.mp4'
import team from './assets/team.png'
import tvDisplay from './assets/tv-display.png'
import ventureBeatLogo from './assets/venture-beat-logo.svg'
import webpage from './assets/webpage.jpg'
import webpage1 from './assets/webpage1.jpg'
import webpage2 from './assets/webpage2.jpg'
import x from './assets/x-close.svg'
import xpekePoster from './assets/xpeke-poster.jpg'
import screen1 from './assets/screen1.jpg'
import screen2 from './assets/screen2.jpg'
import screen3 from './assets/screen3.jpg'
import screen4 from './assets/screen4.jpg'
import screen5 from './assets/screen5.jpg'
import webpageBefore from './assets/webpage-before.jpg'
import webpageAfter from './assets/webpage-after.jpg'
import text1 from './assets/text1.png'
import text2 from './assets/text2.png'
import text3 from './assets/text3.png'

import { getProject } from '../../projects'

import Logo from './Logo'

const currentProject = getProject('azubu')

const SliderContainer = emotionStyled('div')`
${rule('width', { mobileSm: 'calc(100vw - 80px)', tabletSm: '14.5vw' })};
margin-left: auto;
margin-right: auto;
position: relative;
/* Fix of Webkit flickering */
z-index: 1;
user-select: none;

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
  will-change: transform;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  position: relative;
  opacity: 0.3;
  box-shadow: 0 10px 30px rgba(0, 0, 0, .3);
  transition: opacity .5s;
}

.swiper-slide.swiper-slide-active {
  opacity: 1;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

${min('tablet')} {
  .swiper-pagination {
    display: none;
  }
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  margin: 0 8px;
  box-shadow: inset 0 0 0 1px;
  transition: background-color .5s;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
}
`

export default () => (
  <Fragment>
    <Helmet>
      <title>{currentProject.title}</title>
      <meta name="description" content={currentProject.summary} />
    </Helmet>
    <NavbarThemeSwitch theme="dark" />

    <ParallaxProvider>
      <CaseStudyLayout
        currentProjectKey={currentProject.key}
        logo={<Logo play delay={500} width={330} />}
      >
        <Box css={{ backgroundColor: currentProject.color }}>
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <InfoSection
              text={
                <Fragment>
                  <Detail muted>CHALLENGE:</Detail>
                  <Space md />
                  <Title is="h1">
                    Turning a rising eSports platform into a popular global
                    entertainment network that rivals the competition.
                  </Title>
                </Fragment>
              }
              details={
                <Stack is="ul" rowGap={15}>
                  <li>
                    <Detail muted>Client:</Detail>
                    <Break />
                    <Space xs />
                    <DetailValue>Azubu North America, Inc.</DetailValue>
                  </li>
                  <li>
                    <Detail muted>Industry:</Detail>
                    <Break />
                    <Space xs />
                    <DetailValue>Sports & Entertainment</DetailValue>
                  </li>
                  <li>
                    <Detail muted>Services:</Detail>
                    <Break />
                    <Space xs />
                    <DetailValue>
                      Brand Strategy
                      <Break />
                      Brand Identity
                      <Break />
                      Mobile App Design
                      <Break />
                      Web Design
                      <Break />
                      Content Development
                      <Break />
                      Digital Advertising
                      <Break />
                      Event Marketing
                    </DetailValue>
                  </li>
                  <li>
                    <Detail muted>Awards:</Detail>
                    <Break />
                    <Space xs />
                    <DetailValue>
                      Best Mobile App Awards
                      <Break />
                      [app] design awards
                    </DetailValue>
                  </li>
                </Stack>
              }
            />
            <Box
              css={{
                position: 'relative',
                width: { tablet: '80%' },
                margin: 'auto',
                marginBottom: '-30vmin',
              }}
            >
              <Box
                css={{
                  width: '82%',
                  margin: 'auto',
                  // ...dropShadow(),
                }}
              >
                <AutoSlide up>
                  <Img src={device1} ratio={803 / 1113} />
                </AutoSlide>
              </Box>
              <Box
                css={{
                  width: '36%',
                  position: 'absolute',
                  top: '72.2%',
                  right: '20%',
                  // ...dropShadow(),
                }}
              >
                <AutoSlide left delay={1 * 200} translate={50}>
                  <Img src={device2} ratio={266 / 532} />
                </AutoSlide>
              </Box>
              <Box
                css={{
                  width: '19%',
                  position: 'absolute',
                  top: '40%',
                  right: '5.6%',
                  // ...dropShadow(),
                }}
              >
                <AutoSlide up delay={2 * 200}>
                  <Img src={device3} ratio={532 / 266} />
                </AutoSlide>
              </Box>
              <Box
                css={{
                  width: '30%',
                  position: 'absolute',
                  top: '29%',
                  left: '-2.6%',
                  // ...dropShadow(),
                }}
              >
                <ParallaxObject
                  offsetYMin={-65}
                  offsetYMax={35}
                  slowerScrollRate
                >
                  <AutoFade>
                    <Img src={cartoonFigure} ratio={748 / 420} />
                  </AutoFade>
                </ParallaxObject>
              </Box>
            </Box>
          </Inset>
        </Box>
        <Box css={{ backgroundColor: '#0a0a0a', overflow: 'hidden' }}>
          <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
            <Space y="40vmin" />
            <Space xl />
            <WhenVisible>
              {inView => (
                <Box css={{ width: { tabletMd: '66.666%' }, margin: 'auto' }}>
                  <On min="tabletMd">
                    <Space x={{ tabletMd: '-5.55vmax' }}>
                      <BackgroundText is="h4" animate={inView}>
                        PRESS
                      </BackgroundText>
                    </Space>
                  </On>
                  <Space y={{ tabletMd: '-6.35vmax' }} />
                </Box>
              )}
            </WhenVisible>

            <Box css={{ width: { tabletMd: '66.666%' }, margin: 'auto' }}>
              <AutoSlide left>
                <Title is="blockquote" italic css={{ maxWidth: '17em' }}>
                  “This Startup Plans to Take on Twitch and YouTube in ESports”
                </Title>
                <Space lg />
                <Box css={{ position: 'relative' }}>
                  <img
                    src={fortuneLogo}
                    style={{ width: 210, maxWidth: '50%' }}
                    alt=""
                  />
                  <img
                    src={text1}
                    alt=""
                    style={{
                      position: 'absolute',
                      top: '-171%',
                      left: '3%',
                      pointerEvents: 'none',
                    }}
                  />
                </Box>
              </AutoSlide>

              <Space xl tabletMd="xxl" />
              <AutoSlide right style={{ textAlign: 'right' }}>
                <Title is="blockquote" italic css={{ maxWidth: '17em' }}>
                  “Azubu raises $59M in debt to create an esports rival to
                  Twitch”
                </Title>
                <Space lg />
                <Box css={{ position: 'relative' }}>
                  <img
                    src={ventureBeatLogo}
                    style={{ width: 230, maxWidth: '50%' }}
                    alt=""
                  />
                  <img
                    src={text2}
                    alt=""
                    style={{
                      position: 'absolute',
                      top: '-470%',
                      right: '5%',
                      pointerEvents: 'none',
                    }}
                  />
                </Box>
              </AutoSlide>

              <Space xl tabletMd="xxl" />

              <AutoSlide left>
                <Title is="blockquote" italic css={{ maxWidth: '19em' }}>
                  “Azubu goes after Twitch by focusing on eSports and customer
                  service”
                </Title>
                <Space md />
                <Box css={{ position: 'relative' }}>
                  <img
                    src={polygonLogo}
                    style={{ width: 230, maxWidth: '50%' }}
                    alt=""
                  />
                  <img
                    src={text3}
                    alt=""
                    style={{
                      position: 'absolute',
                      top: '-50%',
                      left: '-1%',
                      pointerEvents: 'none',
                    }}
                  />
                </Box>
              </AutoSlide>
            </Box>

            <Space xxl tabletMd={200} />
          </Inset>
        </Box>

        <On min="tabletMd">
          <Parallax direction="both" height="80vh">
            <Parallax.Background
              height="130%"
              style={{ backgroundColor: '#111' }}
            >
              <img
                src={parallax1}
                alt=""
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Parallax.Background>
            <Parallax.Content>
              <Box
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  paddingLeft: { mobile: '35%', _1200: '45%' },
                }}
              >
                <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
                  <Space y={{ tabletMd: '-2.4vmax' }} />
                  <On min="tabletMd">
                    <Space x={{ tabletMd: '-5.7vmax' }}>
                      <AutoSlide up>
                        <BackgroundTextWrapper
                          is="h4"
                          size="14.6vw"
                          color="rgba(0, 0, 0, .15)"
                        >
                          THE&nbsp;BRAND
                        </BackgroundTextWrapper>
                      </AutoSlide>
                    </Space>
                  </On>
                  <Space y={{ tabletMd: '-9vmax' }} />
                  <AutoSlide up delay={200}>
                    <Title is="blockquote" css={{ maxWidth: '15em' }}>
                      We modernized Azubu's brand identity to reflect a change
                      in the company's new vision and direction.
                    </Title>
                  </AutoSlide>
                </Inset>
              </Box>
            </Parallax.Content>
          </Parallax>
        </On>

        <Box
          css={{
            display: 'flex',
            flexDirection: { mobile: 'column', tablet: 'row' },
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '13% 10%',
            margin: 'auto',
            'max:tablet': {
              '> *': {
                width: '100%',
                margin: '8% 0 !important',
                flex: 'initial !important',
              },
            },
          }}
        >
          <AutoFade style={{ flex: 1, transform: 'scale(0.75)' }}>
            <Img src={newLogo} ratio={375 / 1389} />
          </AutoFade>
          <On min="tablet" style={{ flex: 0.3, margin: '0 7%' }}>
            <AutoFade delay={1 * 150}>
              <svg viewBox="0 0 94.728 21.309" fill="#bbb">
                <path d="M94.605,10.99c0.028-0.036,0.053-0.073,0.071-0.113c0.012-0.026,0.021-0.051,0.029-0.079c0.009-0.031,0.013-0.061,0.016-0.091c0.003-0.032,0.008-0.062,0.006-0.093c-0.002-0.023-0.01-0.045-0.015-0.069c-0.013-0.061-0.036-0.119-0.07-0.173c-0.016-0.025-0.031-0.05-0.05-0.071c-0.009-0.011-0.014-0.025-0.025-0.035L84.095,0.149c-0.214-0.203-0.554-0.198-0.758,0.015c-0.203,0.214-0.199,0.554,0.015,0.758l9.524,9.196H0.534C0.239,10.118,0,10.357,0,10.652c0,0.296,0.239,0.535,0.534,0.54h92.335l-9.517,9.196c-0.214,0.203-0.219,0.544-0.015,0.758c0.107,0.107,0.244,0.163,0.387,0.163c0.132,0,0.269-0.051,0.371-0.153l10.473-10.117c0.003-0.003,0.005-0.007,0.008-0.009c0.003-0.003,0.005-0.003,0.008-0.006C94.593,11.014,94.597,11.001,94.605,10.99" />
              </svg>
            </AutoFade>
          </On>
          <AutoFade
            delay={2 * 150}
            style={{ flex: 1, transform: 'scale(0.75)' }}
          >
            <Img src={oldLogo} ratio={387 / 1596} />
          </AutoFade>
        </Box>
        <On min="tabletMd">
          <AutoSlide up>
            <Box
              css={{
                width: { mobile: '80%', tabletMd: '66.666%' },
                margin: 'auto',
                boxShadow: '0 20px 50px rgba(0, 0, 0, .3)',
              }}
            >
              <VideoPlayer
                webroll={azubuLogoVideo}
                video={azubuLogoVideo}
                ratio={1080 / 1920}
              />
            </Box>
          </AutoSlide>
        </On>
        <Space xl tabletMd="xxl" />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <WhenVisible>
            {inView => (
              <Box
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                  transition: '1s ease-out',
                  opacity: inView ? 1 : 0,
                  transform: inView ? 'none' : 'translateY(80px)',
                }}
              >
                <Box
                  css={{
                    width: '55%',
                    position: 'relative',
                    zIndex: 2,
                    ...boxShadow(0.9, '0 7px 70px'),
                  }}
                >
                  <Img src={fakerPoster} ratio={764 / 515} />
                </Box>
                <Box
                  css={{
                    width: '50%',
                    position: 'absolute',
                    zIndex: 1,
                    left: '-10%',
                    top: '12%',
                    transition: '1s .5s ease-out',
                    transform: inView ? 'none' : 'translateX(70%)',
                  }}
                >
                  <Img src={gbmPoster} ratio={763 / 515} />
                </Box>
                <Box
                  css={{
                    width: '50%',
                    position: 'absolute',
                    zIndex: 1,
                    right: '-10%',
                    top: '12%',
                    transition: '1s .5s ease-out',
                    transform: inView ? 'none' : 'translateX(-70%)',
                  }}
                >
                  <Img src={xpekePoster} ratio={762 / 514} />
                </Box>
              </Box>
            )}
          </WhenVisible>
        </Box>
        <Space xl tabletMd="xxl" />
        <Space md tabletMd="xl" />
        <Box
          css={{
            display: 'flex',
            width: '90%',
            margin: 'auto',
          }}
        >
          <AutoSlide down delay={2 * 300} style={{ flex: 1 }}>
            <Img src={poster1} ratio={870 / 417} />
          </AutoSlide>
          <AutoSlide down delay={3 * 300} style={{ flex: 1 }}>
            <Img src={poster2} ratio={878 / 410} />
          </AutoSlide>
          <AutoSlide down delay={4 * 300} style={{ flex: 1 }}>
            <Img src={poster3} ratio={969 / 404} />
          </AutoSlide>
          <AutoSlide down delay={5 * 300} style={{ flex: 1 }}>
            <Img src={poster4} ratio={1004 / 381} />
          </AutoSlide>
        </Box>
        <Space xl />
        <WhenVisible>
          {inView => (
            <Box css={{ width: { tabletMd: '66.666%' }, margin: 'auto' }}>
              <On min="tabletMd">
                <Space x={{ tabletMd: '-5.55vmax' }}>
                  <BackgroundText is="h4" animate={inView}>
                    EVENTS
                  </BackgroundText>
                </Space>
              </On>
              <Space y={{ tabletMd: '-6.35vmax' }} />
            </Box>
          )}
        </WhenVisible>
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            height: '47vw',
            position: 'relative',
            margin: 'auto',
          }}
        >
          <Box
            css={{
              width: '53%',
              position: 'absolute',
              zIndex: 2,
              top: '0%',
              left: '0%',
            }}
          >
            <AutoFade delay={0 * 150} style={boxShadow()}>
              <Img src={gamescom1} ratio={415 / 622} />
            </AutoFade>
          </Box>
          <Box
            css={{
              width: '62%',
              position: 'absolute',
              zIndex: 1,
              top: '20%',
              right: '0%',
            }}
          >
            <AutoFade delay={1 * 150} style={boxShadow()}>
              <Img src={gamescom2} ratio={454 / 682} />
            </AutoFade>
          </Box>
          <Box
            css={{
              width: '53%',
              position: 'absolute',
              zIndex: 3,
              top: '43%',
              left: '6%',
            }}
          >
            <AutoFade delay={2 * 150} style={boxShadow()}>
              <Img src={gamescom3} ratio={376 / 578} />
            </AutoFade>
          </Box>
          <Box
            css={{
              width: '37vw',
              position: 'absolute',
              zIndex: 3,
              top: '83%',
              right: '0%',
            }}
          >
            <AutoFade delay={3 * 150} style={boxShadow()}>
              <Title is="p" css={{ fontSize: '2.63vw' }}>
                The new Azubu launched at two of the world's largest gaming
                events, Gamescom Europe and E3 in Los&nbsp;Angeles drawing
                nearly half a million fans.
              </Title>
            </AutoFade>
          </Box>
        </Box>
        <Space xl tabletMd="xxl" />
        <Space mobile={100} tabletMd={200} />
        <Box
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box css={{ width: { mobile: '26vw', tabletSm: 200 } }}>
            <AutoFade>
              <Img src={gamescomLogo} ratio={456 / 630} />
            </AutoFade>
          </Box>
          <Box
            css={{
              width: { mobile: '6vw', tabletSm: 40 },
              margin: { mobile: '-2vw 7vw 0', tabletSm: '-10px 60px 0' },
            }}
          >
            <AutoFade delay={1 * 200}>
              <Img src={x} ratio={1} />
            </AutoFade>
          </Box>
          <Box
            css={{ width: { mobile: '22vw', tabletSm: 170 }, marginTop: -10 }}
          >
            <AutoFade delay={2 * 200}>
              <Img src={azubuLogo} ratio={480 / 609} />
            </AutoFade>
          </Box>
        </Box>
        <Space mobile={100} tabletMd={200} />
        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <Box css={{ width: '80%' }}>
            <AutoFade>
              <Img src={azubuBooth} ratio={619 / 883} />
            </AutoFade>
          </Box>
          <Box css={{ width: '60%', marginTop: '-8%', marginLeft: '45%' }}>
            <AutoFade>
              <Img src={hoodie} ratio={760 / 661} />
            </AutoFade>
          </Box>
          <Box css={{ width: '60%', marginTop: '-15%', marginLeft: '6%' }}>
            <AutoFade>
              <Img src={soda} ratio={561 / 631} />
            </AutoFade>
          </Box>
        </Box>
        <Space mobile={100} tabletMd={200} />
        <Box
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box css={{ width: { mobile: '10vw', tabletSm: 75 } }}>
            <AutoFade>
              <img src={e3Logo} alt="" />
            </AutoFade>
          </Box>
          <Box
            css={{
              width: { mobile: '6vw', tabletSm: 40 },
              margin: { mobile: '-2vw 7vw 0', tabletSm: '-10px 60px 0' },
            }}
          >
            <AutoFade delay={1 * 200}>
              <Img src={x} ratio={1} />
            </AutoFade>
          </Box>
          <Box
            css={{ width: { mobile: '22vw', tabletSm: 170 }, marginTop: -10 }}
          >
            <AutoFade delay={2 * 200}>
              <Img src={azubuLogo} ratio={480 / 609} />
            </AutoFade>
          </Box>
        </Box>
        <Space xl tabletMd="xxl" />
        <AutoSlide up>
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
              boxShadow: '0 20px 50px rgba(0, 0, 0, .3)',
            }}
          >
            <VideoPlayer
              webroll={azubuE3Preview}
              video={azubuE3}
              ratio={1080 / 1920}
            />
          </Box>
        </AutoSlide>
        <Space xl tabletMd="xxl" />
        <Parallax direction="both" height="80vh" maxHeight={650}>
          <Parallax.Background
            height="130%"
            style={{ backgroundColor: '#111' }}
          >
            <img
              src={parallax2}
              alt=""
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Parallax.Background>
          <Parallax.Content>
            <Flex height="100%" center middle>
              <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
                <Title
                  is="blockquote"
                  css={{ maxWidth: '18em', textAlign: 'center' }}
                >
                  Our goal was to turn a best in-class streaming platform into a
                  leading brand for competitive eSports.
                </Title>
              </Inset>
            </Flex>
          </Parallax.Content>
        </Parallax>

        <Box
          css={{
            width: { mobile: '80%', tabletMd: '66.666%' },
            margin: 'auto',
          }}
        >
          <Space xl tabletMd="xxl" />
          <WhenVisible>
            {inView => (
              <Box>
                <On min="tabletMd">
                  <Space x={{ tabletMd: '-5.55vmax' }}>
                    <BackgroundText is="h4" animate={inView}>
                      DIGITAL
                    </BackgroundText>
                  </Space>
                </On>
                <Space y={{ tabletMd: '-6.35vmax' }} />
              </Box>
            )}
          </WhenVisible>
          <AutoSlide up>
            <Img src={webpage} ratio={1327 / 1133} />
          </AutoSlide>
          <Space xl tabletMd="xxl" />
          <AutoSlide
            up
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <BeforeAfterSlider
              before={webpageBefore}
              after={webpageAfter}
              width={window.innerWidth * 0.61}
              height={window.innerWidth * 0.61 * (1566 / 2199)}
            />
          </AutoSlide>
          <Space xl tabletMd="xxl" />
          <Box css={{ position: 'relative' }}>
            <Box
              css={{
                width: '76%',
                position: 'relative',
              }}
            >
              <AutoSlide up>
                <Img src={webpage1} ratio={999 / 860} />
              </AutoSlide>
            </Box>
            <Box
              css={{
                width: '76%',
                position: 'absolute',
                top: '29.7%',
                left: '24%',
                ...boxShadow(),
              }}
            >
              <AutoSlide up delay={100}>
                <Img src={webpage2} ratio={1007 / 860} />
              </AutoSlide>
            </Box>
          </Box>
          <Space xxl tabletMd={200} />
        </Box>
        <Box
          css={{
            background: `${
              currentProject.color
            } linear-gradient(transparent, #502f82 50%) center bottom / 100% 100vh no-repeat`,
          }}
        >
          <Space xxl tabletMd={200} />
          <AutoFade>
            <Img
              src={azubuIcon}
              ratio={1}
              width={150}
              height={150}
              style={{ margin: 'auto', borderRadius: '24%', ...boxShadow() }}
            />
          </AutoFade>
          <AutoSlide
            up
            style={{
              overflow: 'hidden',
            }}
          >
            <Space xxl tabletMd={200} />
            <Relative>
              <Component
                initialState={{ root: React.createRef(), slider: null }}
                didMount={({ state: { root }, setState }) => {
                  const slider = new Swiper(root.current, {
                    initialSlide: 2,
                    centeredSlides: true,
                    grabCursor: true,
                    spaceBetween: 20,
                    pagination: '.swiper-pagination',
                    paginationClickable: true,
                  })

                  setState({ slider })
                }}
                shouldUpdate={() => false}
                willUnmount={({ state: { slider } }) => slider.destroy()}
              >
                {({ state: { root } }) => (
                  <SliderContainer innerRef={root}>
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <Img
                          src={screen1}
                          ratio={416 / 234}
                          style={boxShadow()}
                        />
                      </div>
                      <div className="swiper-slide">
                        <Img
                          src={screen2}
                          ratio={416 / 234}
                          style={boxShadow()}
                        />
                      </div>
                      <div className="swiper-slide">
                        <Img
                          src={screen3}
                          ratio={416 / 234}
                          style={boxShadow()}
                        />
                      </div>
                      <div className="swiper-slide">
                        <Img
                          src={screen4}
                          ratio={416 / 234}
                          style={boxShadow()}
                        />
                      </div>
                      <div className="swiper-slide">
                        <Img
                          src={screen5}
                          ratio={416 / 234}
                          style={boxShadow()}
                        />
                      </div>
                    </div>
                    <div className="swiper-pagination" />
                  </SliderContainer>
                )}
              </Component>
              <On
                min="tablet"
                style={{
                  width: '22.9vw',
                  margin: 'auto',
                  position: 'absolute',
                  zIndex: 1,
                  top: '-4.9vw',
                  left: 'calc(50% - 11.2vw)',
                  pointerEvents: 'none',
                  userSelect: 'none',
                }}
              >
                <Img src={iphone} ratio={644 / 369} />
              </On>
            </Relative>
            <Space xxl tabletMd={200} />
          </AutoSlide>
          <AutoFade>
            <Img
              src={rokuIcon}
              ratio={1}
              width={150}
              height={150}
              style={{ margin: 'auto', borderRadius: '24%', ...boxShadow() }}
            />
          </AutoFade>
          <Space xxl tabletMd={100} />
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
              marginBottom: '-50vmin',
              ...dropShadow(),
            }}
          >
            <AutoSlide up>
              <Img src={tvDisplay} ratio={696 / 1083} />
            </AutoSlide>
          </Box>
          <Space xxl tabletMd={200} />
        </Box>
        <Box css={{ backgroundColor: '#0a0a0a' }}>
          <Space y="30vmin" />
          <Space xl tabletMd="xxl" />
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
            }}
          >
            <WhenVisible>
              {inView => (
                <Fragment>
                  <On min="tabletMd">
                    <Space x={{ tabletMd: '-5.55vmax' }}>
                      <BackgroundText
                        is="h4"
                        animate={inView}
                        style={{ textAlign: 'left' }}
                      >
                        VIDEO
                      </BackgroundText>
                    </Space>
                  </On>
                  <Space y={{ tabletMd: '-7vw' }} />
                </Fragment>
              )}
            </WhenVisible>
          </Box>
          <Box
            css={{
              width: '66.666%',
              height: '47vw',
              position: 'relative',
              margin: 'auto',
            }}
          >
            <Absolute width="36vw" left="2vw" top="1vw" zIndex={1}>
              <AutoFade delay={0 * 150} style={boxShadow()}>
                <Img src={storyboard1} ratio={338 / 601} />
              </AutoFade>
            </Absolute>
            <Absolute width="36vw" left="32.6vw" top="10.5vw" zIndex={2}>
              <AutoFade delay={1 * 150} style={boxShadow()}>
                <Img src={storyboard2} ratio={337 / 601} />
              </AutoFade>
            </Absolute>
            <Absolute width="36vw" left="-5.5vw" top="18.3vw">
              <AutoFade delay={2 * 150} style={boxShadow()}>
                <Img src={storyboard3} ratio={337 / 601} />
              </AutoFade>
            </Absolute>
            <Absolute width="36vw" left="20.7vw" top="27vw">
              <AutoFade delay={3 * 150} style={boxShadow()}>
                <Img src={storyboard4} ratio={338 / 601} />
              </AutoFade>
            </Absolute>
          </Box>
          <Space xl tabletMd="xxl" />
          <AutoSlide up>
            <Box
              css={{
                width: { mobile: '80%', tabletMd: '66.666%' },
                margin: 'auto',
                boxShadow: '0 20px 50px rgba(0, 0, 0, .3)',
              }}
            >
              <VideoPlayer
                webroll={teamImpulseHypePreviewVideo}
                video={teamImpulseHypeVideo}
                ratio={1080 / 1920}
              />
            </Box>
          </AutoSlide>
          <Space xl tabletMd="xxl" />
          <Box
            css={{
              width: { mobile: '80%', tabletMd: '66.666%' },
              margin: 'auto',
              overflow: 'hidden',
            }}
          >
            <AutoSlide up>
              <Img src={team} ratio={701 / 1356} />
            </AutoSlide>
          </Box>
        </Box>
        <Parallax direction="both" height="80vh" maxHeight={650}>
          <Parallax.Background
            height="130%"
            style={{ backgroundColor: '#111' }}
          >
            <img
              src={parallax3}
              alt=""
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Parallax.Background>
          <Parallax.Content>
            <Flex height="100%" center middle>
              <Inset x={{ mobile: 'lg', tabletMd: 0 }}>
                <Title
                  is="blockquote"
                  css={{ maxWidth: '21em', textAlign: 'center' }}
                >
                  Unwavering in their commitment to eSports Azubu acquired
                  competitor Hitbox.tv in early 2017, gaining market share and
                  legitimacy from the gaming community to take on rival Twitch.
                </Title>
              </Inset>
            </Flex>
          </Parallax.Content>
        </Parallax>
      </CaseStudyLayout>
    </ParallaxProvider>
  </Fragment>
)
