import React from "react"
import styled from "react-emotion"
import GSAPComponent from "./GSAPComponent"
import { TimelineMax, TweenMax, Power3, Sine } from "gsap"
import { prop } from "spaced-components"

const Wrapper = styled("div")`
  ${prop("width")};
  position: relative;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .color-triangles-wrapper {
    display: none;
  }

  .color-triangle {
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: screen;
    will-change: transform;
  }

  .board {
    position: absolute;
    z-index: 1;
    top: -50%;
    left: -60%;
    width: 200%;
    height: 200%;
  }
`

const ColorTriangles = {
  reactToMousePosition(e) {
    if (!this.active) return

    const boardBCR = this.board.getBoundingClientRect()
    const x =
      (e.clientX - boardBCR.left - boardBCR.width / 2) / boardBCR.width / 2
    const y =
      (e.clientY - boardBCR.top - boardBCR.height / 2) / boardBCR.height / 2

    TweenMax.to(this.colorTriangles[1], 0.5, {
      x: -100 * x,
      y: -100 * y,
      ease: Power3.easeOut
    })
    TweenMax.to(this.colorTriangles[2], 0.5, {
      x: -200 * x,
      y: -200 * y,
      ease: Power3.easeOut
    })
  },
  init(wrapper) {
    this.board = this.board || wrapper.querySelector("#board")
    this.colorTrianglesWrapper =
      this.colorTrianglesWrapper ||
      wrapper.querySelector("#color-triangles-wrapper")
    this.colorTriangles =
      this.colorTriangles || wrapper.querySelectorAll(".color-triangle")
    this.logo = this.logo || wrapper.querySelector("#logo")
    this.logo.style.display = "none"
    this.colorTrianglesWrapper.style.display = "block"

    this.reactToMousePosition(this.e)

    this.active = true
  },
  destroy() {
    this.active = false

    const foldTriangles = new Promise(resolve => {
      TweenMax.to(this.colorTriangles, 0.5, {
        x: 0,
        y: 0,
        ease: Power3.easeOut,
        onComplete: resolve
      })
    })

    foldTriangles.then(() => {
      this.colorTrianglesWrapper.style.display = "none"
      this.logo.style.display = "block"
    })

    return foldTriangles
  },
  passEvent(e) {
    this.e = e
  }
}

const traingle01 = "M169.56,28.94l-68,136.68L32.24,28.94H169.56z"
const triangle02 = "M169.6,28.9L84.8,199.4L0,28.9L169.6,28.9z"
const flag01 =
  "M219.72,16.55c-46.97,61.93-75.97-35.18-134.5,12.59l40.27,79.01 C146.84,59.29,216.17,102.63,219.72,16.55z"
const flag02 =
  "M219.72,16.55c-77.71,1.62-21.19,72.3-94.23,91.6L85.22,29.14 C137.3,40.58,147.99-31.15,219.72,16.55z"
const flag03 =
  "M211,38.1c-77.7,1.6-12.5,50.7-85.5,70l-40.3-79C137.2,54.1,133.2-12.4,211,38.1z"

class Logo extends GSAPComponent {
  isActive = false

  animate() {
    const flag = this.wrapper.querySelector("#flag")

    const waving = new TimelineMax({ repeat: -1 })
      .to(flag, 2, { morphSVG: flag02, ease: Sine.easeInOut })
      .to(flag, 2, { morphSVG: flag01, ease: Sine.easeInOut })
      .to(flag, 2, { morphSVG: flag03, ease: Sine.easeInOut })
      .to(flag, 2, { morphSVG: flag01, ease: Sine.easeInOut })

    this.wave = new TimelineMax({ paused: true })
      .to(flag, 0.5, { morphSVG: flag01, ease: Power3.easeOut })
      .add(waving)

    this.wave.play(1)

    const _wrapper = this.wrapper
    this.makeTriangle = new TimelineMax({
      paused: true,
      onComplete() {
        ColorTriangles.init(_wrapper)
      }
    })
      .to(flag, 0.5, { morphSVG: traingle01, ease: Power3.easeOut })
      .to(flag, 0, { morphSVG: triangle02 })

    return this.wave
  }

  onMouseMove = e => {
    if (this.isActive) return

    this.isActive = true
    this.wave.invalidate()
    this.wave.kill()
    this.makeTriangle.play(0)
    e.persist()
    ColorTriangles.passEvent(e)
  }

  onMouseLeave = () => {
    if (!this.isActive) return

    ColorTriangles.destroy().then(() => {
      this.makeTriangle.invalidate()
      this.makeTriangle.kill()
      this.wave.restart()
      this.isActive = false
    })
  }

  render() {
    return (
      <Wrapper
        innerRef={ref => (this.wrapper = ref)}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        {...this.props}
      >
        <div
          id="board"
          className="board"
          onMouseMove={e => ColorTriangles.reactToMousePosition(e)}
        />
        <div id="color-triangles-wrapper" className="color-triangles-wrapper">
          <svg className="color-triangle" viewBox="0 0 219.72 199.37">
            <path
              fill="#ff3700"
              d="M169.6,28.9L84.8,199.4L0,28.9L169.6,28.9z"
            />
          </svg>
          <svg className="color-triangle" viewBox="0 0 219.72 199.37">
            <path
              fill="rgba(255, 255, 255, 0.9)"
              d="M169.6,28.9L84.8,199.4L0,28.9L169.6,28.9z"
            />
          </svg>
          <svg className="color-triangle" viewBox="0 0 219.72 199.37">
            <path
              fill="#00fc5a"
              d="M169.6,28.9L84.8,199.4L0,28.9L169.6,28.9z"
            />
          </svg>
        </div>
        <svg viewBox="0 0 219.72 199.37">
          <g id="logo">
            <path
              fill="#fff"
              d="M103.26,162.21l-18.48,37.16L0,28.94h35.67L103.26,162.21z"
            />
            <path
              id="flag"
              fill="#fff"
              d="M219.72,16.55c-46.97,61.93-75.97-35.18-134.5,12.59l40.27,79.01 C146.84,59.29,216.17,102.63,219.72,16.55z"
            />
          </g>
        </svg>
      </Wrapper>
    )
  }
}

export default Logo
