import React from 'react'
import styled from '../styles/styled'
import { Link } from 'react-router-dom'
import { Absolute, Fixed, On, Relative, Space, Grid } from 'spaced-components'
import theme from '../styles/theme'

import Headline from './Headline'
import TextLink from './TextLink'
import NextArrow from './NextArrow'
import FlagVideo from './FlagVideo'
import { DecideWhenToShowFooter, FooterContent } from './Footer'
import FooterData from './FooterData'

const Text = styled('p')(() => ({
  fontWeight: 700,
  fontSize: { mobile: 18, laptop: '1.45vw' },
  lineHeight: 1.6,
}))

const ChallengeUsFooter = () => (
  <DecideWhenToShowFooter>
    <Fixed is="footer" bottom left={theme.barWidth} right={theme.barWidth}>
      <FooterContent>
        <Absolute top left height="100%">
          <FlagVideo />
        </Absolute>

        <Relative width="100%">
          <Grid
            template={{
              mobile: "'. details .' / 1fr 8fr 1fr",
              tabletMd: "'. form . details' / 1fr 3fr 2rem 2fr",
            }}
            alignItems="center"
          >
            <Grid.Item area="form">
              <On min="tabletMd">
                <Headline is="h2">Let’s gameplan!</Headline>
                <Space mobileSm={20} />
                <Text is="p">
                  Yes, we craft marketing plans. And websites. And brand
                  identities. And more… Not sure what you need? No&nbsp;problem.
                </Text>
                <Space mobileSm={30} />
                <Link to="/contact#form">
                  <TextLink is="span" sm>
                    BEGIN BY TALKING TO US <NextArrow width="1.9em" />
                  </TextLink>
                </Link>
              </On>
            </Grid.Item>
            <Grid.Item area="details">
              <Space md tablet={0} />
              <FooterData />
            </Grid.Item>
          </Grid>
        </Relative>
      </FooterContent>
    </Fixed>
  </DecideWhenToShowFooter>
)

export default ChallengeUsFooter
