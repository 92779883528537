const quotes = [
  {
    text:
      'If you’re not a risk taker, you should get the hell out of business.',
    author: "Ray Kroc, Founder of McDonald's",
  },
  {
    text: 'If it doesn’t sell it isn’t creative.',
    author: 'David Ogilvy, founder of Ogilvy & Mather',
  },
  {
    text: 'There are no bad ideas, just bad decisions.',
    author: 'Jacob Cass, Independent Art Director',
  },
  {
    text:
      'You can have an art experience in front of a Rembrandt… or in front of a piece of graphic design.',
    author: 'Stefan Sagmeister, Graphic Designer',
  },
  {
    text: 'Don’t try to be original try to be good.',
    author: 'Paul Rand, Graphic Design Legend',
  },
  {
    text:
      'It’s not about knowing all the gimmicks and photo tricks. If you haven’t got the eye, no program will give it to you.',
    author: 'David Carson, Graphic Designer and Surfer',
  },
  {
    text:
      'Babe Ruth was a Home Run king but he was also a strike out king. Always go for the fences, even if it means some designs strike out with clients. That’s the only way to hit a home run.',
    author: 'Alex Zevallos',
  },
  {
    text: 'Good art inspires; Good design motivates.',
    author: 'Otl Aicher',
  },
  {
    text:
      'Creative without strategy is called ’art’. Creative with strategy is called ’advertising’.',
    author: 'Jef I. Richards',
  },
  {
    text: 'Creativity is magical, but is not magic.',
    author: 'Charles Limb',
  },
  {
    text:
      'In most agencies, account executives outnumber the copywriters two to one. If you were a dairy farmer, would you employ twice as many milkers as you had cows?',
    author: 'David Ogilvy, founder of Ogilvy & Mather',
  },
  {
    text:
      'There’s really no secret about our approach. We keep moving forward—opening up new doors and doing new things—because we’re curious. And curiosity keeps leading us down new paths.',
    author: 'Walt Disney',
  },
  {
    text: 'If you don’t know where you’re going, you might not get there.',
    author: 'Yogi Berra, American Baseball Legend',
  },
  {
    text:
      'The designer does not begin with some preconceived idea. Rather, the idea is the result of careful study and observation, and the design, a product of that idea.',
    author: 'Paul Rand, Graphic Design Legend',
  },
  {
    text: 'Sincerity is everything. If you can fake that, you’ve got it made.',
    author: 'George Burns, American Comedian',
  },
  {
    text: "Don't mistake activity for achievement.",
    author: 'John Wooden, All-time Winningest Coach in Basketball History',
  },
  {
    text:
      'The greater danger for most of us lies not in setting our aim too high and falling short; but in setting our aim too low, and achieving our mark.',
    author: 'Michelangelo, Struggling Artist',
  },
  {
    text:
      "Creativity isn't an occupation. It's a pre-occupation, we're on all the time.",
    author: 'Sir John Hegarty, BBH Co-Founder',
  },
  {
    text:
      'Having a good idea is important, but being able to execute the idea is even more important.',
    author: "Steve Case, AOL's founding CEO",
  },
  {
    text:
      'Rules are what the artist breaks; the memorable never emerged from a formula.',
    author: 'Bill Bernbach, Founder of Doyle Dane Bernbach',
  },
  {
    text: 'The essence of strategy is choosing what not to do.',
    author: 'Michael Porter, Harvard Economist',
  },
]

export default quotes
