import React, { Fragment, useRef } from 'react'
import Helmet from 'react-helmet'
import styled from 'react-emotion'
import { Link } from 'react-router-dom'
import {
  Absolute,
  Board,
  Break,
  Columns,
  On,
  Flex,
  Grid,
  Inset,
  Relative,
  Space,
} from 'spaced-components'
import theme from '../../styles/theme'
import { color, duration } from '../../styles/helpers'

import { NavbarThemeSwitch } from '../../components/NavbarTheme'
import BackgroundText from '../../components/BackgroundText'
import ChallengeUsFooter from '../../components/ChallengeUsFooter'
import ContentColumn from '../../components/ContentColumn'
import { AutoSlide, Slide, Fade } from '../../components/Animated'
import TextLink from '../../components/TextLink'
import { Footer } from '../../components/Footer'
import Headline from '../../components/Headline'
import IntroText from '../../components/IntroText'
import Layout from '../../Layout'
import LinesPattern from '../../components/LinesPattern'
import LogoCell from '../../components/LogoCell'
import ScrollPosition from '../../components/ScrollPosition'
import ShowCells from '../../components/ShowCells'
import TextRotator from '../../components/TextRotator'
import Tile from '../../components/Tile'
import TilesContainer from '../../components/TilesContainer'
import WhenVisible from '../../components/WhenVisible'
import DarkWrapper from '../../components/DarkWrapper'
import HelloFab from '../../components/HelloFab'
import AwardCell from '../../components/AwardCell'
import Box from '../../components/Box'
import Img from '../../components/Img'
import NextArrow from '../../components/NextArrow'
import Timeline from '../../components/Timeline'
import { getProject } from '../../projects'

import clutchHexagon from './images/clutch-hexagon.png'
import davidFace from './images/david-face.png'

const HeaderWrapper = styled('div')(
  {
    color: '#000',
    backgroundColor: '#fff',
    transitionProperty: 'color, background-color',
  },
  props => ({
    transitionDuration: duration('normal')(props),
    ...(props.dark && {
      color: '#fff',
      backgroundColor: color('dark', 700)(props),
    }),
  })
)

const CheckMark = React.forwardRef((props, ref) => {
  const mask = useRef(`mask${Math.random()}`)

  return (
    <svg
      viewBox="0 0 63.8 61.7"
      style={{
        fill: 'currentColor',
        width: '1.5em',
        margin: '0 0 -3px 5px',
      }}
      {...props}
    >
      <mask id={mask.current}>
        <path
          ref={ref}
          fill="none"
          stroke="#fff"
          strokeWidth="7.5"
          strokeMiterlimit="10"
          d="M3.2 30.2L19 54.8C30.5 36.9 44.7 20.2 61 2.5"
        />
      </mask>
      <path
        mask={`url(#${mask.current})`}
        d="M20.9 58c-2 .5-3.8.2-5-2-.6-1.1-1.3-2.2-1.9-3.3-2.5-4.8-5-9.6-7.6-14.4-1.3-2.4-2.1-5-4.9-6.3C3 30.9 4 30.7 6.6 30.7c.7 1.4 1.4 2.8 2.2 4.1.6 1 1.3 1.8 1.9 2.7.9 1.2 1.7 2.4 2.2 3.9.4 1.4 1.6 2.5 2.5 3.7 1 1.4 2.1 2.8 3.3 4.3 1.3-.8 2.3-1.6 2.7-3.4.4-1.6 1.9-3 3-4.5.4-.5 1-.9 1.2-1.5 1-3.1 3.5-5.1 5.3-7.6 1.3-1.8 2.7-3.5 4.2-5.2 1.8-2.2 3.3-4.7 5.4-6.5.2-.2.4-.3.5-.6.7-2.1 2.5-3 4.3-4 .9-.5 1.5-1 1.7-2.1.3-1.2.7-2.5 2.1-2.9.4-.1.6-1 .9-1.5.5-.9.8-2 1.5-2.6 1.8-1.6-3.2 4.9-1.7 3 1.2-1.5 2.7-2.7 4-4 .9-.9 1.8-1.9 2.4-3.1 1.1-.3 2.2-.6 3.4-.9-.1 1.3.8 1.9 1.9 2.2.9.2 1.2.6 1 1.5-1 .7-2 .6-3.1 0-.4-.2-1-.5-1.3-.4-.6.2-1.3.7-1.5 1.3-.2.7.3 1.3 1.2 1.4.6 0 1.2.2 1.7.3 0 .4.1.7 0 .7-1.8 1.2 4.2-4.9 3-3.3s-2.6 2.7-4.4 2.8c-1.2 2-2.3 3.9-3.5 5.7-.5-.1-1-.2-1.7-.3.3.9.5 1.5.6 1.9-.6.9-1.2 1.8-1.9 2.5-.8.9-1.8 1.7-2.7 2.6-.7.7-1.7 1.4-2 2.3-.8 2.3-2.7 3.5-4.5 4.8-.9.7-1.7 1.4-2 2.6-.1.5-6.3 8-6.7 8.4-.4.3-.9.5-1.1.9-1.4 2.8-3.6 5-5.6 7.3-1.5 1.8-2.6 4-3.7 6-1 1.6-1.6 3.2-2.4 4.8z"
      />
    </svg>
  )
})

const Header = () => (
  <Relative is="header" zIndex={1}>
    <LinesPattern right={theme.barWidth} />
    <ScrollPosition below={20}>
      {matches => (
        <HeaderWrapper dark={matches}>
          <ContentColumn>
            <Board
              middle
              minHeight={320}
              height={{ mobileSm: '65vh', laptopSm: '85vh' }}
              overflow="hidden"
            >
              <Board.Item
                offset={{ mobileSm: 1 / 10, tabletMd: 1 / 6 }}
                width={{ mobileSm: 9 / 10 }}
              >
                <IntroText>
                  <Space y={{ mobile: 30, tablet: 0 }} />
                  FOR THOSE <Break max="mobileLg" />
                  WHO <Break min="mobileLg" />
                  Dare <Break max="mobileLg" />
                  to <TextRotator text={['compete.', 'win.', 'dominate.']} />
                  <Space y={{ laptop: 50 }} />
                </IntroText>
              </Board.Item>
            </Board>
          </ContentColumn>
        </HeaderWrapper>
      )}
    </ScrollPosition>
  </Relative>
)

const ClientLogosSection = () => (
  <WhenVisible rootMargin="-25% 0px">
    {inView => (
      <Relative is="section">
        <Board.Item
          offset={{ mobileSm: 1 / 10, tabletMd: 1 / 6 }}
          width={{ mobileSm: 8 / 10, tabletMd: 4 / 6 }}
        >
          <Space y={{ tabletMd: -40 }} />
          <Space x={{ tabletMd: '-5.55vmax' }}>
            <BackgroundText animate={inView}>
              <On max="tabletMd" inline>
                OUR&nbsp;
              </On>
              CLIENTS
            </BackgroundText>
          </Space>
          <On min="tabletMd">
            <Space y={{ tabletMd: '-6.35vmax' }} />
            <Space x={{ tabletMd: -5 }}>
              <Slide left inView={inView}>
                <Headline>
                  Those who <Break max="tabletMd" />
                  challenged us.
                </Headline>
              </Slide>
            </Space>
          </On>
          <Space mobileSm={20} laptopSm={40} laptopLg={50} />
          <ShowCells active={inView}>
            <Inset mobileSm={5}>
              <Columns columnCount={{ mobileSm: 2, tabletSm: 4 }} gap={10}>
                <Columns.Item>
                  <Link to="/case-studies/ac-hotels">
                    <LogoCell color="#c6c4b9">
                      <img
                        width="46%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/ac-hotels-logo.svg`}
                        alt="AC hotels logo"
                      />
                    </LogoCell>
                  </Link>
                </Columns.Item>
                <Columns.Item>
                  <Link to="/case-studies/amc-preacher">
                    <LogoCell color="#ffcd38">
                      <img
                        width="60%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${process.env.PUBLIC_URL}/assets/logos/amc.svg`}
                        alt="AMC logo"
                      />
                    </LogoCell>
                  </Link>
                </Columns.Item>
                <Columns.Item>
                  <Link to="/case-studies/dignity-health">
                    <LogoCell color="#f98d3d">
                      <img
                        width="85%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/dignity-health.svg`}
                        alt="Dignity Health logo"
                      />
                    </LogoCell>
                  </Link>
                </Columns.Item>
                <Columns.Item>
                  <Link to="/case-studies/espn">
                    <LogoCell color="#881110">
                      <img
                        width="60%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/espn-logo.svg`}
                        alt="ESPN logo"
                      />
                    </LogoCell>
                  </Link>
                </Columns.Item>
                <Columns.Item>
                  <Link to="/case-studies/fox-digital">
                    <LogoCell color="#d7d74c">
                      <img
                        width="50%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/fox-logo.svg`}
                        alt="Fox logo"
                      />
                    </LogoCell>
                  </Link>
                </Columns.Item>
                <Columns.Item>
                  <Link to="/case-studies/motiv-ring">
                    <LogoCell color="#2b2e2e">
                      <img
                        width="75%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${process.env.PUBLIC_URL}/assets/logos/motiv.svg`}
                        alt="Motiv logo"
                      />
                    </LogoCell>
                  </Link>
                </Columns.Item>
                <Columns.Item>
                  <Link to="/case-studies/paramount-equity-mortgage">
                    <LogoCell color="#073c8b">
                      <img
                        width="70%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/paramount.svg`}
                        alt="Paramount logo"
                      />
                    </LogoCell>
                  </Link>
                </Columns.Item>
                <Columns.Item>
                  <Link to="/case-studies/tennis-channel">
                    <LogoCell color="#008568">
                      <img
                        width="40%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/logos/tennis-channel.svg`}
                        alt="Tennis Channel logo"
                      />
                    </LogoCell>
                  </Link>
                </Columns.Item>
              </Columns>
            </Inset>
          </ShowCells>
          <Space mobileSm={20} laptopSm={40} laptopLg={50} />
          <Flex center>
            <Link to="/clients">
              <Slide left inView={inView}>
                <TextLink is="span" sm>
                  Brands we work with
                </TextLink>
              </Slide>
            </Link>
          </Flex>
        </Board.Item>
      </Relative>
    )}
  </WhenVisible>
)

const AwardLogosSection = () => (
  <WhenVisible rootMargin="-25% 0px">
    {inView => (
      <Relative is="section">
        <Board.Item
          offset={{ mobileSm: 1 / 10, tabletMd: 1 / 6 }}
          width={{ mobileSm: 8 / 10, tabletMd: 4 / 6 }}
        >
          <Space y={{ tabletMd: -40 }} />
          <Space x={{ tabletMd: '-5.55vmax' }}>
            <BackgroundText animate={inView}>
              <On max="tabletMd" inline>
                OUR&nbsp;
              </On>
              AWARDS
            </BackgroundText>
          </Space>
          <On min="tabletMd">
            <Space y={{ tabletMd: '-6.35vmax' }} />
            <Space x={{ tabletMd: -5 }}>
              <Slide left inView={inView}>
                <Headline>
                  Those who <Break max="tabletSm" />
                  recognized us.
                </Headline>
              </Slide>
            </Space>
          </On>
          <Space mobileSm={20} laptopSm={40} laptopLg={50} />
          <ShowCells active={inView}>
            <Inset mobileSm={5}>
              <Columns columnCount={{ mobileSm: 2, tabletSm: 4 }} gap={10}>
                <Columns.Item>
                  <LogoCell>
                    <AwardCell count={1}>
                      <img
                        width="60%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/awards/webby-awards.svg`}
                        alt="Webby Awards logo"
                      />
                    </AwardCell>
                  </LogoCell>
                </Columns.Item>
                <Columns.Item>
                  <LogoCell>
                    <AwardCell count={1}>
                      <img
                        width="60%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/awards/addy-awards.svg`}
                        alt="Addy Awards logo"
                      />
                    </AwardCell>
                  </LogoCell>
                </Columns.Item>
                <Columns.Item>
                  <LogoCell>
                    <AwardCell count={1}>
                      <img
                        width="60%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${process.env.PUBLIC_URL}/assets/awards/how.svg`}
                        alt="How Awards logo"
                      />
                    </AwardCell>
                  </LogoCell>
                </Columns.Item>
                <Columns.Item>
                  <LogoCell>
                    <AwardCell count={10}>
                      <img
                        width="37%"
                        style={{ transform: 'scale(.8)' }}
                        src={`${
                          process.env.PUBLIC_URL
                        }/assets/awards/W3-awards.svg`}
                        alt="W3 Awards logo"
                      />
                    </AwardCell>
                  </LogoCell>
                </Columns.Item>
              </Columns>
            </Inset>
          </ShowCells>
          <Space mobileSm={20} laptopSm={40} laptopLg={50} />
          <Flex center>
            <Link to="/awards">
              <Slide left inView={inView}>
                <TextLink is="span" sm>
                  AWARDS AND RECOGNITION
                </TextLink>
              </Slide>
            </Link>
          </Flex>
        </Board.Item>
      </Relative>
    )}
  </WhenVisible>
)

const AgencySection = () => (
  <WhenVisible rootMargin="-25% 0px">
    {inView => (
      <Relative is="section">
        <Board.Item
          offset={{ mobileSm: 1 / 10, tabletMd: 1 / 6 }}
          width={{ mobileSm: 8 / 10, tabletMd: 4 / 6 }}
        >
          <Space y={{ tabletMd: -40 }} />
          <Space x={{ tabletMd: '-5.55vmax' }}>
            <BackgroundText animate={inView}>
              <On max="tabletMd" inline>
                OUR&nbsp;
              </On>
              AGENCY
            </BackgroundText>
          </Space>
          <Space y={{ tabletMd: '-6.35vmax' }} />
          <Space y={{ mobile: 20, tabletMd: 0 }} />
          <Space x={{ tabletMd: -5 }}>
            <Slide
              left
              inView={inView}
              style={{ position: 'relative', zIndex: 2 }}
            >
              <Headline
                css={{
                  width: '11em',
                  fontSize: { mobile: 22, tabletMd: '4.05vmax' },
                }}
              >
                We're a creative marketing agency for those who seek to:
              </Headline>
            </Slide>
          </Space>
          <Space lg />
          <Grid
            templateColumns={{ tabletMd: 'repeat(8, 1fr)' }}
            alignItems="start"
          >
            <Grid.Item
              area={{
                mobile: '1 / 1 / 1 / 6',
                laptopLg: '1 / 1 / 1 / 5',
              }}
              zIndex={1}
            >
              <On min="tabletSm">
                <Box
                  is="p"
                  css={{
                    fontWeight: 700,
                    fontSize: { mobile: 18, laptop: '1.45vw' },
                    lineHeight: 1.6,
                  }}
                >
                  <Timeline
                    paused={!inView}
                    delay={0.3}
                    didMount={({ timeline, refs, gsap: { Power3 } }) => {
                      const lines = [
                        refs.line01,
                        refs.line02,
                        refs.line03,
                        refs.link,
                      ]
                      const paths = [refs.path01, refs.path02, refs.path03]

                      timeline.staggerFrom(
                        lines,
                        1,
                        { y: 50, opacity: 0, ease: Power3.easeOut },
                        0.2
                      )
                      timeline.staggerFrom(
                        paths,
                        0.6,
                        { drawSVG: '0%', ease: Power3.easeInOut },
                        0.3,
                        '-=.5'
                      )
                    }}
                  >
                    {({ ref }) => (
                      <Fragment>
                        <div ref={ref('line01')}>
                          Challenge Conventions{' '}
                          <CheckMark ref={ref('path01')} />
                        </div>
                        <div ref={ref('line02')}>
                          Challenge Assumptions{' '}
                          <CheckMark ref={ref('path02')} />
                        </div>
                        <div ref={ref('line03')}>
                          Challenge Market Leaders{' '}
                          <CheckMark ref={ref('path03')} />
                        </div>
                        <Space lg />
                        <Link innerRef={ref('link')} to="/our-agency">
                          <TextLink is="span" sm>
                            MEET OUR TEAM
                          </TextLink>
                        </Link>
                      </Fragment>
                    )}
                  </Timeline>
                </Box>
              </On>
              <On max="tabletSm">
                <AutoSlide left>
                  <Link to="/our-agency">
                    <TextLink is="span" sm>
                      MEET OUR TEAM
                    </TextLink>
                  </Link>
                </AutoSlide>
              </On>
            </Grid.Item>
            <Grid.Item area="1 / 5 / 1 / 9">
              <On min="tabletMd">
                <Box css={{ marginTop: '-16vw' }}>
                  <Fade inView={inView} delay={400}>
                    <Img src={davidFace} ratio={1} />
                  </Fade>
                </Box>
              </On>
            </Grid.Item>
          </Grid>
          <Space md> </Space>
        </Board.Item>
      </Relative>
    )}
  </WhenVisible>
)

const NewsSection = () => (
  <WhenVisible rootMargin="-25% 0px">
    {inView => (
      <Relative is="section">
        <Board.Item
          offset={{ mobileSm: 1 / 10, tabletMd: 1 / 6 }}
          width={{ mobileSm: 8 / 10, tabletMd: 4 / 6 }}
        >
          <On min="tabletMd">
            <Space y={{ tabletMd: -40 }} />
            <Space x={{ tabletMd: '-5.55vmax' }}>
              <BackgroundText animate={inView}>NEWS</BackgroundText>
            </Space>
            <Space y={{ tabletMd: '-2vmax' }} />
          </On>
          <Space lg laptopLg="xl" />
          <Board middle>
            <Board.Item width={{ tabletMd: 0.2 }}>
              <On min="tabletMd">
                <Fade inView={inView} delay={400}>
                  <Grid justifyItems="center" alignItems="center">
                    <Box
                      css={{
                        gridArea: '1 / 1',
                        width: '100%',
                        transform: 'scale(2.4)',
                      }}
                    >
                      <Img src={clutchHexagon} ratio={1} />
                    </Box>
                    <img
                      style={{
                        gridArea: '1 / 1',
                        display: 'block',
                        width: '70%',
                      }}
                      src={`${
                        process.env.PUBLIC_URL
                      }/assets/logos/top-clutch-logo.svg`}
                      alt="Top Clutch logo"
                    />
                  </Grid>
                </Fade>
              </On>
            </Board.Item>
            <Board.Item width={{ tabletMd: 0.8 }}>
              <Slide
                left
                inView={inView}
                style={{ position: 'relative', zIndex: 2 }}
              >
                <Headline
                  css={{ fontSize: { mobile: 22, tabletMd: '3.7vmax' } }}
                >
                  Named Top Los Angeles
                  <Break />
                  Branding Agency 2018
                </Headline>
              </Slide>
              <Space lg />
              <AutoSlide left>
                <Link to="/our-agency">
                  <TextLink is="span" sm>
                    READ OUR CLUTCH REVIEWS <NextArrow width="1.9em" />
                  </TextLink>
                </Link>
              </AutoSlide>
            </Board.Item>
          </Board>
          <Space tabletMd={200} />
        </Board.Item>
      </Relative>
    )}
  </WhenVisible>
)

const Welcome = () => (
  <Fragment>
    <Helmet>
      <meta
        name="description"
        content="We are a competitive branding and marketing agency that combines award-winning creativity and business strategy for those who dare to win."
      />
    </Helmet>
    <NavbarThemeSwitch theme="light" delay />

    <Header />

    <Layout.Shell>
      <Footer>
        <Absolute
          top={{ mobileSm: 40, laptopSm: 64, _1200: 100, laptopLg: 120 }}
          right
          bottom
          left
        >
          <DarkWrapper height="100%" />
          <LinesPattern />
        </Absolute>
        <TilesContainer>
          <Tile data={getProject('motiv-ring')} offset={50} />
          <On min="tabletMd">
            <AgencySection />
          </On>
          <Tile even data={getProject('turnkey')} />
          <Tile data={getProject('azubu')} />
          <ClientLogosSection />
          <Tile even data={getProject('amc-preacher')} />
          <Tile data={getProject('tennis-channel')} />
          <AwardLogosSection />
          <Tile data={getProject('espn')} />
          <Tile even data={getProject('nestea')} />
          <NewsSection />
        </TilesContainer>
      </Footer>
      <ChallengeUsFooter />
      <HelloFab />
    </Layout.Shell>
  </Fragment>
)

export default Welcome
