import { Component } from "react"
import "../lib/gsap"

class GSAPComponent extends Component {
  componentDidMount() {
    // await import(/* webpackChunkName: "gsap" */ "../../lib/gsap")
    this.animation = this.animate()
  }

  componentWillUnmount() {
    this.animation && this.animation.kill()
  }
}

export default GSAPComponent
