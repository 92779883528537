import React, { Fragment } from "react"
import { Switch, Route } from "react-router-dom"

const CurrentPageLabel = () => (
  <Fragment>
    <Switch>
      <Route exact path="/" render={() => "WELCOME"} />
      <Route path="/clients" render={() => "OUR CLIENTS"} />
      <Route path="/awards" render={() => "OUR AWARDS"} />
      <Route path="/our-work" render={() => "OUR WORK"} />
      <Route path="/our-agency" render={() => "OUR AGENCY"} />
      <Route path="/our-playbook" render={() => "OUR PLAYBOOK"} />
      <Route path="/our-blog" render={() => "OUR BLOG"} />
      <Route path="/contact" render={() => "CONTACT"} />
      <Route path="/case-studies" render={() => "OUR WORK"} />
      <Route render={() => "PAGE NOT FOUND"} />
    </Switch>
  </Fragment>
)

export default CurrentPageLabel
