import React from "react"
import styled, { keyframes } from "react-emotion"
import { On, Position } from "spaced-components"

const animateLinesPattern = keyframes`
   10% { opacity: 0.5 }
  100% { transform: scaleY(1) }
`

const LinesPatternWrapper = styled("div")`
  display: flex;
  height: 100%;
  color: ${p => p.color || "rgb(100, 100, 100)"};
  opacity: 0.2;
  // transform-origin: top;
  // transform: scaleY(0);
  // animation: ${animateLinesPattern} 1s 0.5s forwards ease-in-out;
`

const Column = styled("div")`
  ${p => (p.width ? `width: ${p.width}` : "flex: 1")};
  height: 100%;
  border-right: solid 1px currentColor;
`

const LinesPattern = ({ color, ...props }) => (
  <Position
    position="absolute"
    top
    right
    bottom
    left
    style={{ pointerEvents: "none" }}
    {...props}
  >
    <LinesPatternWrapper color={color}>
      <On max="tabletMd" dynamic>
        <Column width="10%" />
        <Column />
        <Column />
        <Column width="10%" />
      </On>
      <On min="tabletMd" dynamic>
        <Column />
        <Column />
        <Column />
        <Column />
        <Column />
        <Column />
      </On>
    </LinesPatternWrapper>
  </Position>
)

export default LinesPattern
