import React from 'react'
import styled from 'react-emotion'
import { withTheme } from 'emotion-theming'
import { NavLink } from 'react-router-dom'
import { rem, Flex, On, Space, Sticky } from 'spaced-components'
import { color, zIndex } from './styles/helpers'

import { TileTransitionObject } from './components/TileTransition'
import Logo from './components/Logo'
import SocialLinks from './components/SocialLinks'
import Navbar from './components/Navbar'
import BarColumn from './components/BarColumn'
import DarkWrapper from './components/DarkWrapper'
import AdVictoremLogo from './components/AdVictoremLogo'
import Box from './components/Box'
import Play from './components/Play'
import IntroAnimation from './components/IntroAnimation'

const basename = process.env.PUBLIC_URL

const LeftBarWrapper = styled('aside')`
  background-color: ${color('dark', 900)};
`

const Layout = ({ children, theme: { barWidth } }) => (
  <IntroAnimation>
    <Flex minHeight="100vh">
      <On min="laptopSm" dynamic>
        <BarColumn>
          <Sticky top zIndex="bar" style={{ willChange: 'transform' }}>
            <LeftBarWrapper>
              <Flex height="100vh" column center>
                <Space lg />
                <NavLink to="/" style={{ width: '65%' }}>
                  <Play
                    sounds={{
                      playOnClick: `${basename}/assets/sounds/click_18.wav`,
                    }}
                  >
                    {({ playOnClick }) => <Logo onClick={playOnClick} />}
                  </Play>
                </NavLink>
                <Flex.Item grow={1} />
                <SocialLinks mobile={10} />
                <Space lg />
              </Flex>
            </LeftBarWrapper>
          </Sticky>
        </BarColumn>
      </On>

      <Flex.Item grow={1}>
        <Box
          css={{
            position: 'fixed',
            top: 0,
            right: 0,
            left: barWidth,
            zIndex: {
              mobileSm: zIndex('mobileNavbar'),
              laptopSm: zIndex('desktopNavbar'),
            },
            willChange: 'transform',
          }}
        >
          <Navbar />
        </Box>
        <TileTransitionObject />
        {children}
      </Flex.Item>
    </Flex>
  </IntroAnimation>
)

Layout.Shell = ({ children }) => (
  <DarkWrapper>
    <Flex is="main">
      <Flex.Item grow={1}>{children}</Flex.Item>
      <On min="laptopSm" dynamic>
        <BarColumn>
          <Space y="15vh" />
          <Sticky
            top={({ lg }) => `calc(100vh - ${rem(180 + lg)})`}
            style={{ willChange: 'transform' }}
          >
            <Flex center>
              <AdVictoremLogo rotated />
            </Flex>
          </Sticky>
        </BarColumn>
      </On>
    </Flex>
  </DarkWrapper>
)

export default withTheme(Layout)
