import React, { Fragment } from 'react'
import styled from 'react-emotion'
import { rule, zIndex } from 'spaced-components'
import { color } from '../styles/helpers'
import { TimelineMax, Power1, Power4 } from 'gsap'
import GSAPComponent from './GSAPComponent'

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const IntroWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex('intro')};
  background-color: ${color('dark', 900)};
  opacity: 1;
  pointer-events: none;

  svg {
    ${rule('width', { mobileSm: '100%', tabletSm: '50%' })};
    fill: #fff;
    ${p => !isSafari && 'filter: url(#introBlurFilter)'};
    visibility: hidden;
  }
`

class IntroAnimation extends GSAPComponent {
  state = {
    textAnimationEnded: false,
    fadeAnimationEnded: false,
  }

  animate() {
    if (process.env.NODE_ENV !== 'production') {
      this.setState({ textAnimationEnded: true, fadeAnimationEnded: true })
      return
    }

    const svg = this.wrapper.querySelector('svg')
    const g1 = this.wrapper.querySelector('#introG1')
    const g2 = this.wrapper.querySelector('#introG2')
    const g1Paths = this.wrapper.querySelectorAll('#introG1 path')
    const g2Paths = this.wrapper.querySelectorAll('#introG2 path')
    const shift = 25

    svg.style.visibility = 'visible'

    const tl = new TimelineMax()
    tl.timeScale(3)

    tl.from(
      g1,
      5,
      { x: (g1Paths.length * -shift) / 2, ease: Power4.easeOut },
      0
    )
    tl.from(
      g1Paths,
      5,
      {
        transformOrigin: 'center',
        scale: 0.5,
        opacity: 0,
        x: i => ++i * shift,
        ease: Power4.easeOut,
      },
      0
    )

    tl.to(g1Paths, 1.5, { opacity: 0, ease: Power1.easeInOut }, '-=1')
    tl.from(g2Paths, 1.5, { opacity: 0, ease: Power1.easeOut })

    tl.to(g2, 2, { x: (g2Paths.length * -shift) / 2, ease: Power4.easeInOut })
    tl.to(
      g2Paths,
      2,
      {
        transformOrigin: 'center',
        scale: 1.2,
        opacity: 0,
        x: i => ++i * shift,
        ease: Power4.easeInOut,
        onComplete: () => this.setState({ textAnimationEnded: true }),
      },
      '-=2'
    )

    tl.to(
      this.wrapper,
      2,
      {
        opacity: 0,
        onComplete: () => this.setState({ fadeAnimationEnded: true }),
      },
      '+=0.3'
    )

    return tl
  }

  render() {
    return (
      <Fragment>
        {this.state.textAnimationEnded && this.props.children}
        {!this.state.fadeAnimationEnded && (
          <IntroWrapper innerRef={ref => (this.wrapper = ref)}>
            <svg viewBox="0 0 460 202">
              <g id="introG1">
                <path d="M105.2,106h-6.4l-1.7,3.4h-3.7l8.6-17.2l8.6,17.2h-3.7L105.2,106z M103.8,103l-1.7-3.5l-1.8,3.5H103.8z" />
                <path d="M123.4,92.6h6.1c4.7,0,8.1,3.7,8.1,8.5s-3.3,8.3-8.1,8.3h-6.1V92.6z M134.2,101.1c0-3.1-2.2-5.5-5.3-5.5h-2.2v10.7h2.2C132,106.4,134.2,104.2,134.2,101.1z" />
                <path d="M174,109.8l-8.6-17.2h3.7l4.9,9.9l4.9-9.9h3.7L174,109.8z" />
                <path d="M198.5,92.6h3.3v16.8h-3.3V92.6z" />
                <path d="M230.9,106.1c-1.5,2.1-4.2,3.5-6.8,3.5c-4.7,0-8.6-3.8-8.6-8.6c0-4.7,3.8-8.6,8.6-8.6c2.4,0,4.7,1,6.4,2.7l-2.2,2.1c-1.1-1-2.7-1.7-4.2-1.7c-3.1,0-5.2,2.5-5.2,5.6c0,3.1,2.1,5.5,5.2,5.5c1.9,0,3.8-1.1,4.8-2.4L230.9,106.1z" />
                <path d="M243.1,92.6h12.1v3h-4.4v13.7h-3.3V95.7h-4.4V92.6z" />
                <path d="M284.4,101c0,4.7-3.8,8.6-8.6,8.6c-4.7,0-8.6-3.8-8.6-8.6c0-4.7,3.8-8.6,8.6-8.6C280.5,92.4,284.4,96.2,284.4,101z M281,101.1c0-3.1-2.2-5.6-5.3-5.6s-5.2,2.5-5.2,5.6c0,3.1,2.1,5.5,5.2,5.5S281,104.1,281,101.1z" />
                <path d="M304.3,103.2h-2.8v6.1h-3.3V92.6h7.9c2.8,0,5,2.2,5,5.3c0,2.3-1.3,4.1-3.1,4.9l3.3,6.5h-3.9L304.3,103.2zM307.7,98c0-1.4-0.9-2.3-1.9-2.3h-4.3v4.5h4.3C306.9,100.2,307.7,99.3,307.7,98z" />
                <path d="M328.1,106.3h8.3v3h-11.7V92.6h11.7v3h-8.3v4.1h6.7v3h-6.7V106.3z" />
                <path d="M366.6,92.2v17.2h-3.3v-8.8l-4.7,4.7l-4.7-4.7v8.8h-3.3V92.2l8.1,8.3L366.6,92.2z" />
              </g>
              <g id="introG2">
                <path d="M108,92.4h12.3v3.1h-4.5v13.9h-3.4V95.5H108V92.4z" />
                <path d="M149.7,100.9c0,4.8-3.9,8.7-8.7,8.7s-8.7-3.9-8.7-8.7s3.9-8.7,8.7-8.7S149.7,96.1,149.7,100.9z M146.3,100.9 c0-3.1-2.2-5.6-5.3-5.6s-5.3,2.5-5.3,5.6s2.2,5.6,5.3,5.6S146.3,104.1,146.3,100.9z" />
                <path d="M186.2,109.8l-8.7-17.5h3.7l4.9,10l4.9-10h3.7L186.2,109.8z" />
                <path d="M210.7,92.4h3.4v17h-3.4V92.4z" />
                <path d="M243.2,106c-1.5,2.2-4.2,3.5-6.9,3.5c-4.8,0-8.7-3.9-8.7-8.7s3.9-8.7,8.7-8.7c2.4,0,4.8,1,6.4,2.8l-2.2,2.1 c-1.1-1-2.7-1.7-4.3-1.7c-3.1,0-5.3,2.5-5.3,5.6s2.2,5.6,5.3,5.6c1.9,0,3.8-1.1,4.9-2.5L243.2,106z" />
                <path d="M255.4,92.4h12.3v3.1h-4.5v13.9h-3.4V95.5h-4.5V92.4z" />
                <path d="M296.7,100.9c0,4.8-3.9,8.7-8.7,8.7c-4.8,0-8.7-3.9-8.7-8.7s3.9-8.7,8.7-8.7C292.8,92.2,296.7,96.1,296.7,100.9z M293.3,100.9c0-3.1-2.2-5.6-5.3-5.6c-3.1,0-5.3,2.5-5.3,5.6s2.2,5.6,5.3,5.6C291.1,106.5,293.3,104.1,293.3,100.9z" />
                <path d="M316.4,103.2h-2.8v6.2h-3.4v-17h8c2.8,0,5.1,2.2,5.1,5.4c0,2.3-1.3,4.2-3.1,5l3.4,6.6h-4L316.4,103.2zM319.9,97.8c0-1.4-0.9-2.3-1.9-2.3h-4.4v4.6h4.4C319.1,100,319.9,99.2,319.9,97.8z" />
                <path d="M348,92.3h4l-6.5,10.7v6.4h-3.4V103l-6.5-10.7h4l4.3,6.9L348,92.3z" />
              </g>
            </svg>
          </IntroWrapper>
        )}
      </Fragment>
    )
  }
}

export default IntroAnimation
