import React from 'react'
import { Absolute, Board, Flex, On, Relative, Space } from 'spaced-components'
import WhenVisible from './WhenVisible'
import TextLink from './TextLink'
import { TileTransitionLink } from './TileTransition'
import TileTitle from './TileTitle'
import { Slide, Reveal } from './Animated'
import Img from './Img'
import { projectURL, projectImageURL } from '../projects'

const Tile = ({
  even,
  offset,
  data: { key, title, titleMarkup, color, colors },
  ...props
}) => (
  <div>
    <Board.Item
      offset={{
        mobileSm: 1 / 10,
        tabletMd: 1 / 6,
        laptopSm: even ? 2 / 6 : 1 / 6,
      }}
      width={{ mobileSm: 8 / 10, tabletMd: 4 / 6 }}
      className="hoverTextLink"
      {...props}
    >
      <TileTransitionLink to={projectURL(key)} imgSrc={projectImageURL(key)}>
        <WhenVisible rootMargin="-25% 0px">
          {inView => (
            <Relative>
              <Flex middle>
                <Absolute left zIndex={1}>
                  <Slide left inView={inView}>
                    {offset && <Space laptopLg={offset} />}
                    <TileTitle underlineColors={colors}>
                      {titleMarkup}
                    </TileTitle>
                    {/* <TextUnderline
                      colors={colors}
                      top={0.67}
                      left={0.07}
                      height={[0.22, 0.44]}
                    >
                      {titleMarkup}
                    </TextUnderline> */}
                  </Slide>
                  <On min="tabletSm">
                    <Space mobileSm={25} />
                    <Slide left inView={inView} delay={250}>
                      <TextLink is="span" sm>
                        View Case Study
                      </TextLink>
                    </Slide>
                  </On>
                </Absolute>
                <Board.Item
                  offset={{ mobileSm: 1 / 8, mobileLg: 1 / 4 }}
                  width={{ mobileSm: 7 / 8, mobileLg: 3 / 4 }}
                >
                  <Reveal className="TileImg" inView={inView} color={color}>
                    <Img src={projectImageURL(key)} alt={title} color={color} />
                  </Reveal>
                </Board.Item>
              </Flex>
            </Relative>
          )}
        </WhenVisible>
      </TileTransitionLink>
    </Board.Item>
  </div>
)

export default Tile
